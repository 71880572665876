import { SupportedUnits } from "./kpi-data-card";
import { IconProp } from "~/lib/ui/types";
import { KPIRatioView, KPIRatioViewProps } from "./kpi-ratio-view";
import { FormatValue } from "./FormatValue";
import { KPICardWrapper } from "./kpi-card-wrapper";
import { ReactNode } from "react";
import { getIcon } from "~/lib/ui";

export function KPIBetaCard({
  label = "Untitled",
  description,
  unit,
  estimated,
  current,
  remainder,
  href,
  Icon = getIcon("unknownType"),
  extraUnits,
  barClasses,
  containerClass,
  warning,
}: {
  unit: SupportedUnits;
  current: {
    value: number;
    label: string;
    className?: string;
  };
  estimated: {
    value: number;
    label: string;
    className?: string;
  };
  extraUnits?: KPIRatioViewProps["extraUnits"];
  remainder: {
    label: string;
    className?: string;
  };
  label?: string;
  description?: ReactNode;
  href?: string;
  Icon?: IconProp;
  barClasses?: KPIRatioViewProps["barClasses"];
  containerClass?: string;
  warning?: string;
}) {
  // estimated - current = remainder
  // if current > estimated then we show estimated on the left and current on the right
  // if estimated >= current then we show current on the left and estimated on the right

  // Happy Path (current < estimated)
  const left: KPIRatioViewProps["left"] = {
    label: current.label,
    percent: (current.value / estimated.value) * 100,
    value: current.value,
  };
  let right: KPIRatioViewProps["right"] = {
    label: remainder.label,
    percent: ((estimated.value - current.value) / estimated.value) * 100,
    value: estimated.value - current.value,
  };
  if (estimated.value - current.value < 0) {
    right = undefined;
  }

  return (
    <KPICardWrapper
      label={label}
      Icon={Icon}
      href={href}
      description={description}
      containerClass={containerClass}
      warning={warning}
    >
      <div className="flex flex-row items-center justify-end gap-x-2">
        <span className="text-md text-gray-400">{estimated.label}</span>
        <span className="text-xl font-bold">
          <FormatValue value={estimated.value} unit={unit} />
        </span>
      </div>
      <KPIRatioView
        left={left}
        right={right}
        unit={unit}
        extraUnits={extraUnits}
        barClasses={barClasses}
      />
    </KPICardWrapper>
  );
}
