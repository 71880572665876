import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { Contact, ContactPerson } from "@apacta/sdk";
import { useCallback, useState } from "react";

/**
 * Please don't use this... -Andri
 * @deprecated We set ourselves up to fail, by passing full Contact/Customer objects around as arguments.
 *
 * I recommend using useQuery and SDK directly instead. We don't have full-objects available everywhere in this causes pains.
 *
 * @returns
 */
export function useContacts(
  defaultContact?: Contact | null,
  defaultContactPerson?: ContactPerson | null
) {
  const api = useAPI();

  const [contactQuery, setContactQuery] = useState<string>("");
  const [contactPersonQuery, setContactPersonQuery] = useState<string>("");

  const [contact, setContact] = useState<Contact | null | undefined>(defaultContact || null);
  const [contactPerson, setContactPerson] = useState<ContactPerson | null | undefined>(
    defaultContactPerson || null
  );

  const contactQ = useQuery({
    queryKey: ["getContacts", contactQuery],
    queryFn: () => api.getContacts({ q: contactQuery }),
  });

  const contactPersonQ = useQuery({
    queryKey: ["getContactPersons", contact, contactPersonQuery],
    queryFn: () => contact && api.getContactPersonsList({ contactId: contact.id as string }),
    enabled: !!contact,
  });

  const getContactById = useCallback(
    (id: string): Promise<Contact | null> => {
      return api.getContact({ contactId: id }).then((res) => res.data);
    },
    [api]
  );

  const refreshContact = useCallback(async () => {
    if (contact?.id) {
      const freshContact = await getContactById(contact.id);
      if (freshContact) {
        setContact(freshContact);
      }
    }
  }, [contact?.id, getContactById]);

  return {
    contact,
    contactPerson,
    refreshContact,

    contacts: contactQ.data?.data || [],
    contactPersons: contactPersonQ.data?.data || [],

    contactQuery,
    contactPersonQuery,

    setContactQuery,
    setContactPersonQuery,
    setContact,
    setContactPerson,
    getContactById,

    isFetchingContacts: contactQ.isFetching,
    isFetchingContactPersons: contactPersonQ.isFetching,
  };
}
