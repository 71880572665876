import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { Identifier } from "dnd-core";
import { OrderLine, OrderLinesColumns } from "~/lib/ui/order-lines/lib/types";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import {
  OrderLinesBuilderCostPart,
  OrderLinesBuilderDescriptionPart,
  OrderLinesBuilderDiscountPart,
  OrderLinesBuilderPricePart,
  OrderLinesBuilderQuantityPart,
  OrderLinesBuilderTextRow,
  OrderLinesBuilderTotalPart,
} from "~/lib/ui/order-lines/table/row-parts";
import { OrderLinesBuilderTotalCostPart } from "~/lib/ui/order-lines/table/row-parts/order-lines-builder-total-cost-part";
import { OrderLinesBuilderUnitPart } from "~/lib/ui/order-lines/table/row-parts/order-lines-builder-unit-part";
import { OrderLineColumnWithWidth } from "./order-lines-builder-table";

export type LineRef = {
  focus: (highlight?: boolean) => void;
};

export const DragDropOrderLineType = "ORDER_LINE";

type LineProps = {
  line: Partial<OrderLine>;
  editMode: boolean;
  depth: number;
  parentId?: string | null;
  columns?: OrderLinesColumns;
  columnWidths: Record<keyof OrderLinesColumns, OrderLineColumnWithWidth>;
  usePlainTextEditor: boolean;
  allowNegativeNumbers?: boolean;
};

const OrderLinesBuilderTableRow = forwardRef<LineRef, LineProps>(function OfferLinesRowInner(
  {
    line,
    editMode,
    depth,
    parentId,
    columns,
    columnWidths,
    usePlainTextEditor,
    allowNegativeNumbers,
  }: LineProps,
  ref
) {
  const quantityRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus(highlight?: boolean): void {
      if (highlight && dragRef.current) {
        dragRef.current.classList.add("animate-shake");
        setTimeout(() => {
          if (dragRef.current) {
            dragRef.current.classList.remove("animate-shake");
          }
        }, 500);
      }
      if (quantityRef.current) {
        quantityRef.current.focus();
        quantityRef.current.select();
      }
    },
  }));

  const { deleteLine, repositionLine } = useOrderLinesBuilder();

  const dragRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  const [hoverState, setHoverState] = useState<-1 | 0 | 1>(0);

  const handleSetHoverState = (state: -1 | 0 | 1) => {
    if (hoverState !== state) {
      setHoverState(state);
    }
  };

  const [{ handlerId, isOver }, drop] = useDrop<
    LineProps,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: DragDropOrderLineType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
    drop(item) {
      setTimeout(() => repositionLine(item.line, line.position ?? 0), 1);
    },
    hover(item: LineProps, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragPos = item.line.position ?? 0;
      const hoverPos = line.position ?? 0;

      // Don't replace items with themselves
      if (dragPos === hoverPos) {
        handleSetHoverState(0);
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (hoverClientY < hoverMiddleY) {
        handleSetHoverState(-1);
        return;
      }

      // Dragging upwards
      if (hoverClientY > hoverMiddleY) {
        handleSetHoverState(1);
        return;
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DragDropOrderLineType,
    item: () => {
      return {
        line,
        editMode,
        depth,
        parentId,
        columns,
        columnWidths,
        width: dragRef.current?.getBoundingClientRect().width,
      };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  /**
   * Initialize drag and drop
   */
  preview(dragRef);
  drop(dropRef);

  return (
    <div
      ref={!parentId ? dropRef : undefined}
      className={twMerge(
        columns?.handle.visible &&
          "before:absolute before:-ml-20 before:h-20 before:w-20 before:content-['']", // extra space for drag handle drop area
        isDragging && "opacity-40"
      )}
      data-pdf-rule="no-split"
    >
      <div
        className={twMerge("w-full transition-height duration-300 ease-in-out")}
        style={{
          height:
            isOver && hoverState === -1
              ? `${dragRef.current?.getBoundingClientRect().height ?? 0}px`
              : "0rem",
        }}
      ></div>
      <div
        ref={!parentId ? dragRef : undefined}
        className={twMerge("flex w-full border-b border-gray-200 bg-white")}
      >
        {editMode && columns?.handle.visible && (
          <div
            ref={(r) => {
              if (!parentId) {
                drag(r);
              }
            }}
            className={twMerge(
              "flex w-12 shrink-0 px-3 pb-5 pt-7",
              !parentId ? "cursor-pointer" : ""
            )}
          >
            {!parentId && <Bars3Icon className="h-6 w-6" />}
          </div>
        )}
        {line.type === "text" ? (
          <OrderLinesBuilderTextRow
            line={line}
            editMode={editMode}
            depth={depth}
            usePlainTextEditor={usePlainTextEditor}
          />
        ) : (
          <>
            {columns?.product.visible && (
              <OrderLinesBuilderDescriptionPart
                {...{ line, editMode, depth, parentId, width: columnWidths["product"].width }}
              />
            )}
            {columns?.quantity.visible && (
              <OrderLinesBuilderQuantityPart
                {...{
                  line,
                  editMode,
                  quantityRef,
                  parentId,
                  width: columnWidths["quantity"].width,
                }}
                allowNegativeNumbers={allowNegativeNumbers}
              />
            )}
            {columns?.unit.visible && (
              <OrderLinesBuilderUnitPart
                {...{
                  line,
                  editMode,
                  parentId,
                  width: columnWidths["unit"].width,
                }}
              />
            )}
            {columns?.costPrice.visible && editMode && (
              <OrderLinesBuilderCostPart
                {...{
                  line,
                  editMode: !columns.costPrice.readOnly && editMode,
                  parentId,
                  width: columnWidths["costPrice"].width,
                }}
                columnOptions={columns["costPrice"]}
                allowNegativeNumbers={allowNegativeNumbers}
              />
            )}
            {columns?.salePrice.visible && !parentId && (
              <OrderLinesBuilderPricePart
                {...{ line, editMode, parentId, width: columnWidths["salePrice"].width }}
                allowNegativeNumbers={allowNegativeNumbers}
              />
            )}
            {columns?.discount.visible && (
              <OrderLinesBuilderDiscountPart
                {...{
                  line,
                  editMode: !columns.discount.readOnly && editMode,
                  parentId,
                  width: columnWidths["discount"].width,
                }}
              />
            )}
            {columns?.total.visible && columns?.salePrice.visible && (
              <OrderLinesBuilderTotalPart
                {...{ line, editMode, parentId, width: columnWidths["total"].width }}
              />
            )}
            {columns?.total.visible && !columns?.salePrice.visible && (
              <OrderLinesBuilderTotalCostPart {...{ line }} />
            )}
          </>
        )}

        {editMode && (
          <div
            className={twMerge(
              "w-1/24 flex-shrink-0 px-3 pb-5 pt-8 text-right text-zinc-900",
              columns?.total.visible ? "" : "grow"
            )}
          >
            {!parentId && (
              <div className="flex justify-end">
                <span
                  className="flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-gray-400 text-white hover:bg-gray-600"
                  onClick={() => deleteLine(line.identifier)}
                >
                  <XMarkIcon className="h-3 w-3" />
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={twMerge("w-full transition-height duration-300 ease-in-out")}
        style={{
          height:
            isOver && hoverState === 1
              ? `${dragRef.current?.getBoundingClientRect().height ?? 0}px`
              : "0rem",
        }}
      ></div>
    </div>
  );
});

OrderLinesBuilderTableRow.displayName = "OfferLinesRowNew";

export { OrderLinesBuilderTableRow };
