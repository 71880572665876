import { OrderLine, OrderLineTotalType } from "~/lib/ui/order-lines/lib/types";

/**
 * Calculation utility methods and automation
 */

export const calculateLineTotal = (line: Partial<OrderLine>): number | null => {
  if (line.sellingPrice === undefined) return null;

  const quantity = line.quantity ? line.quantity : 0;
  const sellingPrice = line.sellingPrice ? line.sellingPrice : 0;
  const discount = line.discount ?? 0;

  const discountTotal =
    line.discountType === "fixed" ? discount : sellingPrice * quantity * (discount / 100);

  return quantity * sellingPrice - discountTotal;
};

export const calculateLineTotalCost = (line: Partial<OrderLine>): number => {
  return (line.quantity ? line.quantity : 0) * (line.costPrice ? line.costPrice : 0);
};

export const calculateLinesTotal = (
  lines: Array<Partial<OrderLine>>,
  type: OrderLineTotalType,
  vatPercent: number
): number => {
  const subTotal =
    lines
      .flatMap((line) => {
        return calculateLineTotal(line) ?? 0;
      })
      .reduce((a, b) => a + b, 0) ?? 0;

  const totalCostAmount =
    lines
      .flatMap((line) => {
        return calculateLineTotalCost(line);
      })
      .reduce((a, b) => a + b, 0) ?? 0;
  switch (type) {
    case "subAmount":
      return subTotal;
    case "vatAmount":
      return subTotal * (vatPercent / 100);
    case "totalAmount":
      return subTotal * (1 + vatPercent / 100);
    case "totalCostAmount":
      return totalCostAmount;
  }
};

/**
 * Utility method used to calculate the cost price of a line that has childOfferLines
 * @param childLines
 */
export const calculateChildLinesCost = (childLines: Array<Partial<OrderLine>>): number => {
  return childLines.reduce((a, b) => a + (b.costPrice ?? 0) * (b.quantity ?? 0), 0);
};
/**
 * Utility method used to calculate the cost price of a line that has childOfferLines
 * @param childLines
 */
export const calculateChildLinesPrice = (childLines: Array<Partial<OrderLine>>): number => {
  return childLines.reduce((a, b) => a + (b.sellingPrice ?? 0) * (b.quantity ?? 0), 0);
};

/**
 * Utility method used to calculate the KPI Data points for an offer
 * @param orderLines - The orderLines to run the calculation on
 * @param overwriteSalesPrice - If set, the sales price will be overwritten with this value
 */
export const calculateKPIDataPoints = (
  orderLines: Array<Partial<OrderLine>>,
  overwriteSalesPrice?: number
) => {
  const productLines = orderLines.filter((line) => {
    return (
      line.type === "product" || line.type === "expense_line" || line.type === "vendor_product"
    );
  });
  const bundleLines = orderLines.filter((line) => line.type === "bundle");
  const hoursLines = orderLines.filter((line) => line.type === "hours");

  // Look for product lines in bundles and add them to the list with a quantity multiplier based on the product bundle quantity
  const extraProductLines: Array<Partial<OrderLine>> = bundleLines.flatMap(
    (line) =>
      line.childOfferLines
        ?.filter((c) => c.type === "product" || c.type === "vendor_product")
        .map((l) => {
          return {
            ...l,
            quantity: l.quantity ? l.quantity * (line.quantity ?? 1) : 0,
          };
        }) ?? []
  );

  // Look for product lines in bundles and add them to the list with a quantity multiplier based on the product bundle quantity
  const extraHoursLines: Array<Partial<OrderLine>> = bundleLines.flatMap(
    (line) =>
      line.childOfferLines
        ?.filter((c) => c.type === "hours")
        .map((l) => {
          return {
            ...l,
            quantity: l.quantity ? l.quantity * (line.quantity ?? 1) : 0,
          };
        }) ?? []
  );

  const productCost = [...productLines, ...extraProductLines].reduce(
    (acc, line) => acc + (line.costPrice ?? 0) * (line.quantity ?? 0),
    0
  );

  const hoursCount = [...hoursLines, ...extraHoursLines].reduce(
    (acc, line) => acc + (line.quantity ?? 0),
    0
  );
  const hoursCost = [...hoursLines, ...extraHoursLines].reduce(
    (acc, line) => acc + (line.costPrice ?? 0) * (line.quantity ?? 0),
    0
  );

  const totalCost = productCost + hoursCost;

  const useOverwriteSalesPrice =
    overwriteSalesPrice &&
    overwriteSalesPrice !== calculateLinesTotal(orderLines, "totalAmount", 0);

  const salesPrice = useOverwriteSalesPrice
    ? overwriteSalesPrice
    : orderLines.reduce((acc, line) => acc + (calculateLineTotal(line) ?? 0), 0);

  const contributionMargin = salesPrice - productCost - hoursCost;
  let contributionMarginPerHour = 0;
  if (contributionMargin > 0 && hoursCount > 0) {
    contributionMarginPerHour = contributionMargin / hoursCount;
  }
  let contributionRatio = 0;

  if (contributionMargin > 0 && salesPrice > 0) {
    contributionRatio = contributionMargin / salesPrice;
  }

  return {
    productCost,
    hoursCount,
    hoursCost,
    totalCost,
    salesPrice,
    contributionMargin,
    contributionRatio,
    contributionMarginPerHour,
  };
};
