import { PageLayout } from "~/lib/ui/page-layout";
import { useParams } from "react-router";
import { PriceAnalysisTable } from "./_cmp/price-analysis-table";
import { useTranslation } from "react-i18next";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useGraphQL } from "~/lib/gql";
import { useLocale } from "~/lib/utils/date";
import { PriceAnalysisKpiSection } from "~/pages/procurement/[procurementId]/_cmp/price-analysis-kpi-section";

export const CACHE_PRICE_ANALYSIS = "price-analysis";

export default function ProcurementViewPage() {
  const { procurementId } = useParams<{ procurementId: string }>();
  const { t } = useTranslation();
  const sdk = useGraphQL();
  const { format } = useLocale();

  const dataQ = useSuspenseQuery({
    queryKey: [CACHE_PRICE_ANALYSIS, procurementId],
    queryFn: () =>
      sdk.procurementDetail({
        id: procurementId as string,
      }),
  });

  const data = dataQ.data?.procurementAnalysis;

  return (
    <PageLayout title={t("common:price_analysis")}>
      <PageLayout.Signal slot="description" id="description">
        <div>
          <span className="text-lg font-medium">{`${t("common:for_period", { count: 1 })} ${format(data.dateStart, { shortDate: true })} - ${format(data.dateEnd, { shortDate: true })}`}</span>
        </div>
      </PageLayout.Signal>
      <div className="flex flex-col gap-4">
        <PriceAnalysisKpiSection analysis={data} isFetching={dataQ.isFetching} />
        <PriceAnalysisTable companiesVendors={data?.companiesVendors ?? []} />
      </div>
    </PageLayout>
  );
}
