import { twMerge } from "tailwind-merge";
import { decimalToTimeString, getDateWithoutTime, Week } from "~/lib/utils/date/date-utils";
import { useTranslation } from "react-i18next";
import { WeekSectionProjectForm } from "~/pages/_cmp/weekly-registration-table/form-entry-card";
import { WeeklyProject } from "~/pages/employees/index/weekly";

type DayHourData = {
  approved: { [key: number]: number };
  notApproved: { [key: number]: number };
};

type RowData = {
  label: string;
  totalHours: number;
  dayDataKey: keyof DayHourData;
};

export default function WeekSectionFooter({
  week,
  projects,
}: {
  week: Week;
  projects: Array<WeeklyProject>;
}) {
  const { t } = useTranslation();

  const getHours = (form: WeekSectionProjectForm) => {
    // Disabled form is outside of target date range selection
    if (form.disabled) {
      return 0;
    }
    return form.entries.reduce((acc, entry) => {
      if (entry.isSummable && !entry.isAbsence && !["km", "daily"].includes(entry.timeUnit)) {
        return acc + entry.total;
      }
      return acc;
    }, 0);
  };

  // reducer function to get the total hours of each day based on the days timestamp, using the hours from each entry in each form in each project
  const dayHourData = projects.reduce(
    (acc: DayHourData, project: WeeklyProject) => {
      const forms = project.forms.filter((form) => !!form.date);
      return forms.reduce((acc2: DayHourData, form: WeekSectionProjectForm) => {
        const formDate = getDateWithoutTime(form.date!);
        const totalHours = getHours(form);
        const dateTime = formDate.getTime();

        // Track approved hours
        if (form.approved) {
          if (!acc2.approved[dateTime]) {
            acc2.approved[dateTime] = totalHours;
          } else {
            acc2.approved[dateTime] += totalHours;
          }
        }

        // Track not approved hours
        if (form.approved === null) {
          if (!acc2.notApproved[dateTime]) {
            acc2.notApproved[dateTime] = totalHours;
          } else {
            acc2.notApproved[dateTime] += totalHours;
          }
        }

        return acc2;
      }, acc);
    },
    { approved: {}, notApproved: {} }
  );

  // Calculate totals
  const totalApprovedHours = Object.values(dayHourData.approved).reduce((acc, n) => acc + n, 0);
  const totalNotApprovedHours = Object.values(dayHourData.notApproved).reduce(
    (acc, n) => acc + n,
    0
  );

  // Helper function to render the row for either approved or not approved hours
  const renderRow = ({ label, totalHours, dayDataKey }: RowData) => (
    <tr className="border-t-4 text-sm font-semibold print:divide-x-2">
      <td className="border-r px-3 py-3">
        <div className="flex flex-col justify-between gap-2 sm:flex-row sm:gap-8">
          <div className="text-nowrap">{label}</div>
          <div
            className="text-shade-400"
            title={`${t("common:total", { count: 2 })} ${t("common:hour", { count: 2 }).toLowerCase()}`}
          >
            {decimalToTimeString(totalHours)}
          </div>
        </div>
      </td>
      {week.days.map((day, idx) => {
        const dayData = dayHourData[dayDataKey][day.date.getTime()] ?? 0;
        return (
          <td
            key={day.date.getTime()}
            className={twMerge(
              "w-24 border-x p-1.5 text-center text-sm",
              day.isHoliday && "bg-yellow-50",
              day.isOutOfRange && !day.isWeekend && "opacity-40",
              day.isWeekend && "bg-shade-200/50",
              idx === week.days.length - 1 && "border-r-0"
            )}
          >
            {dayData === 0 || day.isOutOfRange ? (
              <span>-</span>
            ) : (
              <span>{decimalToTimeString(dayData)}</span>
            )}
          </td>
        );
      })}
    </tr>
  );

  return (
    <tfoot>
      {renderRow({
        label: t("users:sum_of_hours"),
        totalHours: totalApprovedHours,
        dayDataKey: "approved",
      })}
      {renderRow({
        label: t("users:not_approved_hours"),
        totalHours: totalNotApprovedHours,
        dayDataKey: "notApproved",
      })}
    </tfoot>
  );
}
