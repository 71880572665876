import { Context, useContext, useLayoutEffect } from "react";
import { SlotStoreItem } from "./types";
import { SlotContext } from "./slot-context";

export function Signal<SlotName extends string>(
  props: {
    slot: SlotName;
    context: Context<SlotContext | null>;
  } & SlotStoreItem
) {
  const ctx = useContext(props.context);
  if (!ctx) {
    throw new Error("Signal must be used within a SlotProvider");
  }
  const { addSignal, removeSignal } = ctx;
  const { slot, ...rest } = props;

  useLayoutEffect(() => {
    addSignal(slot, {
      ...rest,
    });
    return () => removeSignal(slot, props);
  }, [props.children]); // Only react to changes in children

  return null; // This component doesn't render anything directly
}
