import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, ReactNode, Ref } from "react";
import { twMerge } from "tailwind-merge";
import {
  ButtonSizes,
  ButtonVariant,
  getButtonClasses,
  getButtonIconClass,
} from "./shared/button.shared";
import { IconProp } from "../types";
import { Spinner } from "../spinner";
import { ConfirmationDialog, Dialog } from "../dialog";
import { Link } from "react-router";
import {
  entityConfirmationTemplate,
  ConfirmationTemplateProps,
} from "../dialog/templates/confirmation-templates";
import { useTranslation } from "react-i18next";
import { useButtonClick } from "./use-button-click";

// Used to customize the confirmation dialog
export type ButtonConfirmOptions = ConfirmationTemplateProps;

export const Button = forwardRef(function ButtonInner(
  props: {
    children?: ReactNode;
    variant?: ButtonVariant;
    size?: ButtonSizes;
    disabled?: boolean;
    className?: string;
    /**
     * Note: Icon should be on the left side. Chevron and the like should be built on top of this instead of adding
     */
    Icon?: IconProp;
    iconClassName?: string;
    loading?: boolean;
    confirm?: ButtonConfirmOptions;
    href?: string; // Wraps the button in a Link
    target?: string; // Tells the link to open in a new window, if any
    as?: "button" | "span";
  } & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  forwardedRef: Ref<HTMLButtonElement>
) {
  const {
    variant,
    className,
    Icon,
    children,
    iconClassName,
    onClick,
    loading,
    size,
    confirm,
    href,
    target,
    as = "button",
    ...restProps
  } = props;
  const baseIconClass = getButtonIconClass({ size });
  const { t } = useTranslation();
  const [handleClick, isLoading] = useButtonClick(onClick, loading);

  const Comp = as;

  const buttonFragment = (
    <Comp
      className={twMerge(
        getButtonClasses({ variant, disabled: restProps.disabled || isLoading, size }),
        className
      )}
      type={restProps.type || "button"}
      ref={forwardedRef}
      {...restProps}
      onClick={confirm ? undefined : handleClick}
      disabled={restProps.disabled || isLoading}
    >
      <>
        {isLoading && (
          <Spinner className={twMerge(baseIconClass, children && "mr-3", iconClassName)} />
        )}
        {Icon && !isLoading && (
          <Icon className={twMerge(baseIconClass, children && "mr-3", iconClassName)} />
        )}
        {children && <>{children}</>}
      </>
    </Comp>
  );

  if (href !== undefined) {
    return (
      <Link to={href} target={target}>
        {buttonFragment}
      </Link>
    );
  }

  if (confirm !== undefined) {
    return (
      <Dialog
        trigger={buttonFragment}
        render={({ onClose }) => (
          <ConfirmationDialog
            // If confirmation template is provided, apply it
            {...(confirm !== undefined ? entityConfirmationTemplate({ ...confirm, t }) : {})}
            onClose={onClose}
            onSubmit={handleClick}
          />
        )}
      />
    );
  }

  return buttonFragment;
});
