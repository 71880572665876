import { useQuery } from "@tanstack/react-query";
import { PaymentConditionSelection } from "./payment-condition-selection";
import { CACHE_INVOICES } from "~/pages/invoices";
import { LabelInput } from "../form-elements";
import { useAPI } from "~/lib/api";
import { useLocale } from "~/lib/utils/date";
import { useTranslation } from "react-i18next";
import { ControlPanelApiPaymentTerm } from "@apacta/sdk";
import { useState } from "react";

// Should encompas the payment condition selection and the due date
export function PaymentConditionWithInvoiceDueDate({
  initialConditionId,
  issuedDate,
  invoiceId,
  onPaymentConditionChange,
}: {
  initialConditionId: string | undefined;
  issuedDate: Date | undefined;
  invoiceId: string;
  onPaymentConditionChange: (paymentTermId: string | null) => void;
}) {
  const [internalConditionId, setInternalConditionId] = useState<string | null>(
    initialConditionId ?? null
  );

  const api = useAPI();
  const locale = useLocale();
  const { t } = useTranslation();

  const isEnabled = !!issuedDate && !!initialConditionId;

  const dueDateQ = useQuery({
    queryKey: [CACHE_INVOICES, invoiceId, "dueDate", internalConditionId, issuedDate],
    queryFn: async () => {
      const res = await api.calculateInvoiceDueDate({
        calculateInvoiceDueDateRequest: {
          paymentTermId: internalConditionId!,
          invoiceId: invoiceId, // never called if not defined
          issueDate: issuedDate,
        },
      });
      return res;
    },
    enabled: isEnabled,
  });

  const dueDate = dueDateQ.data?.data?.paymentDueDate;
  const dueValue = (() => {
    if (dueDateQ.isLoading) return t("common:loading") + "...";
    if (!isEnabled) return t("common:not_available");
    return locale.format(dueDate, {
      excludeTime: true,
      shortDate: true,
    });
  })();

  function handleConditionChange(condition: ControlPanelApiPaymentTerm | null) {
    onPaymentConditionChange(condition?.id ?? null);
    setInternalConditionId(condition?.id ?? null);
  }

  return (
    <>
      <PaymentConditionSelection
        initialConditionId={initialConditionId}
        onChange={handleConditionChange}
      />
      <div className="mt-4">
        <LabelInput label={t("common:due_date")} value={dueValue} readOnly />
      </div>
    </>
  );
}
