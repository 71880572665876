import { getSharedTableDefaults, SharedTableBase } from "~/lib/ui/data-table/shared-table-base";
import { CACHE_PROJECTS } from "..";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useQuery } from "@tanstack/react-query";
import { useProjectTableColumns } from "./project-table-columns";
import { useAPI } from "~/lib/api";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { DataTable, useDataTable } from "~/lib/ui/data-table";
import { FilterGroupProjectStatus } from "~/lib/ui/filters/filter-group-project-status";
import { FilterGroupCustomers } from "~/lib/ui/filters/filter-group-customers";
import { FilterGroupEmployees } from "~/lib/ui/filters/filter-group-employees";
import { useTranslation } from "react-i18next";
import { useMe } from "~/lib/auth/use-me";

interface ProjectTableProps extends SharedTableBase {
  customerId?: string;
}

export function ProjectTable(props: ProjectTableProps) {
  const { t } = useTranslation();
  const api = useAPI();
  const columns = useProjectTableColumns();
  const { companySettings } = useMe();
  const defaultSortBy = companySettings.projectNumbers ? "project_number" : "created";

  const [projectFilters, setProjectFilters] = useTypedSearchParams<{
    isOffer?: boolean;
    status?: string;
    employees?: Array<string>;
    activities?: Array<string>;
    customer?: string;
    q?: string;
  }>();

  const tableState = useDataTableState({
    columnVisibility: props.columnVisibility,
    sorting: [
      {
        desc: true,
        id: defaultSortBy,
      },
    ],
  });
  const dataQ = useQuery({
    queryKey: [
      CACHE_PROJECTS,
      "index",
      tableState.state.search,
      tableState.state.pagination,
      tableState.sortingDirection,
      tableState.sortBy,
      tableState.pageNumber,
      projectFilters,
      props.customerId,
    ],
    queryFn: () =>
      api.iListProjects({
        page: tableState.pageNumber,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
        q: tableState.state.search,
        isOffer: projectFilters.isOffer,
        projectStatusId: projectFilters.status,
        activityIds: projectFilters.activities,
        employeeIds: projectFilters.employees,
        contactId: props.customerId ?? projectFilters.customer,
      }),
  });
  const defaults = getSharedTableDefaults(props.parentType);

  const table = useDataTable(
    {
      columns,
      tableState,
      data: dataQ.data?.data,
      isLoading: dataQ.isLoading,
      backendPagination: dataQ.data?.pagination,
      getRowId: (row) => row.id,
    },
    {
      enableGlobalFilter: props.enableGlobalFilter ?? defaults.enableGlobalFilter ?? true,
      enableFilters: props.enableFilters ?? defaults.enableFilters ?? true,
    }
  );

  return (
    <>
      <DataTable
        error={dataQ.error}
        table={table}
        search={{
          placeholder: t("projects:search_placeholder", "Search by project number or name"),
        }}
        renderFilters={() => (
          <>
            <FilterGroupProjectStatus
              value={projectFilters.status}
              onConfirmSelection={(selection) => setProjectFilters("status", selection[0].id)}
              onClear={() => setProjectFilters("status", undefined)}
            />

            <FilterGroupCustomers
              value={projectFilters.customer}
              onConfirmSelection={(selectedCustomers) => {
                setProjectFilters("customer", selectedCustomers[0].id);
              }}
              onClear={() => setProjectFilters("customer", undefined)}
            />

            <FilterGroupEmployees
              value={projectFilters.employees}
              onConfirmSelection={(selectedUsers) => {
                setProjectFilters(
                  "employees",
                  selectedUsers.map((u) => u.id)
                );
              }}
              onClear={() => setProjectFilters("employees", undefined)}
            />
          </>
        )}
      />
    </>
  );
}
