import { useQuery } from "@tanstack/react-query";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useAPI } from "~/lib/api";
import { FormatValue } from "~/pages/projects/_cmp/FormatValue";
import { useToastOnError } from "~/lib/utils/hooks";
import { ProjectType } from "~/pages/projects/[id]";
import { useProjectParams } from "~/pages/projects/_cmp/use-project-params";
import { KpiCardHoursSummedIndividualEntry } from "@apacta/sdk";

// Note: Pagination was decided against.
export default function SummedHours() {
  const { entity: project } = useOutletContext<{ entity: ProjectType }>();
  const projectParams = useProjectParams();
  const api = useAPI();
  const { t } = useTranslation();

  const dataQ = useQuery({
    queryFn: () =>
      api.projectGetKpiCardHoursSummedByUserPriceGroupSubPage({
        projectId: project.id as string,
        dateAfter: projectParams.startDate,
        dateBefore: projectParams.endDate,
        activityIds: projectParams.activities,
        isInvoiced: projectParams.isInvoiced,
      }),
    queryKey: ["project-kpi-hours-index", project.id as string, projectParams],
  });

  useToastOnError(dataQ.error);

  const items = dataQ.data?.data ?? [];

  // Calculate sums using useMemo to avoid recalculation on every render
  const { hourSum, hourEstimateSum } = useMemo(() => {
    return items.reduce(
      (acc, item) => ({
        hourSum: acc.hourSum + (item.totalHours ?? 0),
        hourEstimateSum: acc.hourEstimateSum + (item.estimatedHours ?? 0),
      }),
      { hourSum: 0, hourEstimateSum: 0 }
    );
  }, [items]);

  return (
    <div className="table">
      <table className="text-right">
        <thead>
          <tr>
            <th className="text-left">{t("common:price_group", { count: 1 })}</th>
            <th className="text-left">{t("common:type", { count: 1 })}</th>
            <th>{t("common:used")}</th>
            {project.isOffer && <th className="text-right">{t("common:estimated")}</th>}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <Fragment key={item.id}>
              <tr>
                <td className="text-left font-semibold">
                  {item.userPriceGroup?.name ?? t("projects:without_price_group")}
                </td>
                <td className="text-left">{t("common:total")}</td>
                <td>
                  <FormatValue value={item.totalHours} unit="hours" />
                </td>
                {project.isOffer && (
                  <td>
                    <FormatValue value={item.estimatedHours} unit="hours" />
                  </td>
                )}
              </tr>
              {item.entries?.map((entry, idx) => (
                <tr key={`entry-${idx}`}>
                  <td></td>
                  <td className="text-left">
                    <EntryLabel entry={entry} />
                  </td>
                  <td className="text-right">
                    <FormatValue value={entry.count} unit="hours" />
                  </td>
                  {project.isOffer && <td></td>}
                </tr>
              ))}
            </Fragment>
          ))}
          <tr className="bg-shade-50 font-semibold">
            <td className="text-left">{t("common:total")}</td>
            <td>&nbsp;</td>
            <td>
              <FormatValue value={hourSum} unit="hours" />
            </td>
            {project.isOffer && (
              <td className="text-right">
                <FormatValue value={hourEstimateSum} unit="hours" />
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function EntryLabel({ entry }: { entry: KpiCardHoursSummedIndividualEntry }): string {
  const { t } = useTranslation();

  switch (entry.type) {
    case "reduce":
    case "replace":
      return t(`common:${entry.label}`);
    default:
      return entry.label;
  }
}
