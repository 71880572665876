import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { CACHE_INVOICES } from "..";
import { ProjectStatusSelection } from "~/lib/ui/selection-combobox/project-status-selection";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { useInvoiceActionContext } from "./invoice-action-context";
import { useMe } from "~/lib/auth/use-me";
import { InformationBox } from "~/lib/ui/dialog/components/information-box";

interface DraftInvoiceDialogProps {
  onClose: () => void;
}

export function DraftInvoiceDialog({ onClose }: DraftInvoiceDialogProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const api = useAPI();
  const { invoice, pendingSave } = useInvoiceActionContext();
  const me = useMe();

  const formState = useFormState({
    schema: {
      projectStatusId: z.string().uuid(),
    },
    initialValues: {
      projectStatusId: invoice.project.projectStatusId,
    },
    mutationError: null,
  });

  const isERP = me.integrationsEnabled.erp;

  const mut = useMutation({
    mutationFn: async () => {
      await pendingSave?.();
      await api.editInvoice({
        invoiceId: invoice.id,
        editInvoiceRequest: {
          isDraft: true,
          toErp: isERP,
          projectStatusId: formState.getValue("projectStatusId"),
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, invoice.id] });
      onClose();
    },
  });

  return (
    <div className="flex flex-col gap-4">
      {pendingSave && (
        <InformationBox variant="warning">
          <div className="whitespace-pre-line">
            {t("common:unsaved_changes_will_be_saved_before_proceeding")}
          </div>
        </InformationBox>
      )}
      <ProjectStatusSelection
        required
        label={t("invoices:change_project_status_after_saving")}
        onSelect={(v) => v && formState.onChange("projectStatusId", v)}
        defaultValue={formState.getValue("projectStatusId")}
      />
      <DialogFooter
        onClose={onClose}
        primary={{
          label: t("common:save"),
          onClick: () => mut.mutateAsync(),
          disabled: !formState.isValid || mut.isPending,
        }}
      />
    </div>
  );
}
