import { DropTargetMonitor, useDrop } from "react-dnd";
import { RefObject, useEffect, useState } from "react";
import { DragDropOrderLineType } from "~/lib/ui/order-lines/table/order-lines-builder-table-row";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";

export default function OrderLinesBuilderTableScrollLayer({
  viewRef,
  isDragging,
}: {
  viewRef: RefObject<HTMLDivElement | null>;
  isDragging: boolean;
}) {
  const [canScrollTop, setCanScrollTop] = useState<boolean>(false);
  const [canScrollBottom, setCanScrollBottom] = useState<boolean>(false);

  const [{ overTop }, dropTop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: DragDropOrderLineType,
    // Props to collect
    collect: (monitor: DropTargetMonitor) => ({
      overTop: monitor.isOver({ shallow: true }),
    }),
  }));

  const [{ overBottom }, dropBottom] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: DragDropOrderLineType,
    // Props to collect
    collect: (monitor: DropTargetMonitor) => ({
      overBottom: monitor.isOver({ shallow: true }),
    }),
  }));

  useEffect(() => {
    let i: ReturnType<typeof setInterval>;
    const el = document.querySelector("html");
    if (!el || !viewRef.current) return;

    // Get offsets for the entire page to see if scrolling is possible in the view
    const scrollTop = el.scrollTop > 0;
    const scrollBottom =
      el.scrollHeight > el.clientHeight && el.scrollTop < el.scrollHeight - el.clientHeight;

    // To avoid cluttering the screen, only show the scroll layer overlays when necessary
    // Get the offsets and check that the viewRef.current is in the view
    const viewRect = viewRef.current.getBoundingClientRect();

    // If the top is outside the current view (negative value) including an offset of 100, allow scrolling up
    if (viewRect.top < 100) {
      setCanScrollTop(scrollTop);
    }

    // If the bottom is outside the current view (positive beyond clientHeight of el), allow scrolling down
    if (viewRect.bottom > el.clientHeight) {
      setCanScrollBottom(scrollBottom);
    }

    if (overTop && isDragging) {
      i = setInterval(() => {
        el.scrollBy({ left: 0, top: -100, behavior: "smooth" });
      }, 100);
    }
    if (overBottom && isDragging) {
      i = setInterval(() => {
        el.scrollBy({ left: 0, top: 100, behavior: "smooth" });
      }, 100);
    }

    return () => clearInterval(i);
  }, [overTop, overBottom, isDragging]);

  return (
    <div className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-[200] ">
      <div
        ref={(ref) => {
          dropTop(ref);
        }}
        className={twMerge(
          "pointer-events-auto z-[200] flex h-16 flex-shrink-0 items-center justify-center bg-gradient-to-t from-transparent to-black/30",
          isDragging && canScrollTop ? "fixed left-0 right-0 top-0" : "hidden"
        )}
      >
        <div className="animate-pulse rounded-full bg-white p-1">
          <Icon name="chevronDoubleUp" className="h-10 w-10 text-zinc-700 drop-shadow-md" />
        </div>
      </div>
      <div
        ref={(ref) => {
          dropBottom(ref);
        }}
        className={twMerge(
          "pointer-events-auto z-[200] flex h-16 flex-shrink-0 items-center justify-center bg-gradient-to-b from-transparent to-black/30",
          isDragging && canScrollBottom ? "fixed bottom-0 left-0 right-0" : "hidden"
        )}
      >
        <div className="animate-pulse rounded-full bg-white p-1">
          <Icon name="chevronDoubleDown" className="h-10 w-10 text-zinc-700 drop-shadow-md" />
        </div>
      </div>
    </div>
  );
}
