import { Project } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useProjectParams } from "../../_cmp/use-project-params";
import { InvoiceTable } from "~/pages/invoices/_cmp/invoice-table";

export default function ProjectFinanceInvoicesPage() {
  const context = useOutletContext<{ entity: Project }>();
  const { t } = useTranslation();
  const projectPeriod = useProjectParams();

  const project = context.entity;

  return (
    <div className="flex w-full flex-col gap-12">
      <div className="w-full">
        <div>
          <div className="flex flex-row items-start justify-between">
            <h2>{t("common:invoice", { count: 2 })}</h2>
          </div>
          <div className="flex w-full flex-col gap-10 rounded-md"></div>
          <InvoiceTable
            parentType="kpi"
            projectId={project.id}
            columnVisibility={{
              project: false,
              netPayment: false,
            }}
            dateFrom={projectPeriod.startDate ? new Date(projectPeriod.startDate) : undefined}
            dateTo={projectPeriod.endDate ? new Date(projectPeriod.endDate) : undefined}
          />
        </div>
      </div>
    </div>
  );
}
