import { useTranslation } from "react-i18next";
import { FormatValue } from "../_cmp/FormatValue";
import { KPIRatioView, KPIRatioViewProps } from "../_cmp/kpi-ratio-view";
import { KPICardWrapper, KPICardWrapperProps } from "../_cmp/kpi-card-wrapper";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";
import { printPercent } from "~/lib/utils/utils";

export function InvoicedCardFixed({
  salesPrice = 0,
  invoiced = 0,
  notInvoiced = 0,
  creditNotes = 0,
  deliveredPercentage = 0,
  ...kpiWrapperProps
}: KPICardWrapperProps & {
  salesPrice: number;
  notInvoiced: number;
  creditNotes: number;
  invoiced: number;
  deliveredPercentage: number;
}) {
  const { t } = useTranslation();

  const invoicedPercent = (invoiced / salesPrice) * 100;
  const notInvoicedPercent = (notInvoiced / salesPrice) * 100;
  const extraUnits: KPIRatioViewProps["extraUnits"] =
    creditNotes !== 0
      ? [
          {
            unit: "currency",
            rightLabel: t("common:credited", "Credited"),
            rightValue: creditNotes,
          },
        ]
      : [];

  return (
    <KPICardWrapper
      label={t("finance:invoiced")}
      Icon={DocumentCheckIcon}
      href="./finance/invoices"
      {...kpiWrapperProps}
    >
      <div className="flex flex-row items-center justify-end gap-x-2">
        <span className="text-md text-gray-400">
          {t("common:delivered")} ({printPercent(deliveredPercentage)})
        </span>
        <span className="text-xl font-bold">
          <FormatValue value={salesPrice} unit="currency" />
        </span>
      </div>

      <KPIRatioView
        left={{
          label: t("finance:invoiced"),
          value: invoiced,
          percent: invoicedPercent,
        }}
        right={{
          label: t("finance:not_invoiced"),
          value: notInvoiced,
          percent: notInvoicedPercent,
        }}
        unit="currency"
        extraUnits={extraUnits}
        barClasses={{
          primary: "bg-green-600",
          remainder: "bg-red-600",
        }}
      />
    </KPICardWrapper>
  );
}
