import { FormTemplateModelWithJsonFormsData } from "@apacta/sdk";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useState } from "react";
import { useAPI } from "~/lib/api";

export function FormTemplatePicker({
  onFormChanged,
  disabled,
}: {
  onFormChanged: (form: FormTemplateModelWithJsonFormsData) => void;
  disabled?: boolean;
}) {
  const api = useAPI();
  const q = useQuery({
    queryKey: ["formTemplates"],
    queryFn: () => api.formTemplatesList({}),
  });

  const items = q.data?.data ?? [];

  const [, setCurrent] = useState<FormTemplateModelWithJsonFormsData>();

  function handleSelect(item: FormTemplateModelWithJsonFormsData) {
    setCurrent(item);
    onFormChanged(item);
  }

  return (
    <div className="grid grid-cols-4 gap-4">
      {items.map((item) => (
        <Fragment key={item.id}>
          <button
            onClick={() => handleSelect(item)}
            disabled={disabled}
            className="disabled:hover-none group rounded-md border bg-white p-6 text-left shadow-md hover:cursor-pointer hover:text-hover hover:shadow-lg disabled:text-shade-400"
          >
            <div className="mb-2 font-semibold">{item.name}</div>
          </button>
        </Fragment>
      ))}
    </div>
  );
}
