import { Context, useContext } from "react";
import { SlotContext } from "./slot-context";

export function useSlot<K extends string>(identifier: K, context: Context<SlotContext | null>) {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error("useSlot must be used within a SlotProvider");
  }
  const { slots } = ctx;
  return slots.get(identifier);
}
