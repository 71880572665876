import KpiSection from "~/pages/procurement/_cmp/kpi/kpi-section";
import { ProcurementTable } from "~/pages/procurement/_cmp/table/procurement-table";
import { useOutletContext } from "react-router";
import { DateRange } from "~/lib/utils/date/date-utils.types";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";
import { Icon } from "~/lib/ui";
import { useTranslation } from "react-i18next";

export default function Index() {
  const { dateRange } = useOutletContext<{ dateRange: Required<DateRange> }>();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <h2 className="mb-0 flex items-center gap-1">
        {t("procurement:tabs.price_analysis")}
        <Tooltip
          side="right"
          trigger={
            <span>
              <Icon name="tooltip" />
            </span>
          }
        >
          <div className="flex flex-col gap-2">
            <div className="text-base font-medium">{t("procurement:tabs.price_analysis")}</div>
            <div>
              På denne side kan du se og oprette prisanalyser for indkøb. En prisanalyse er en
              sammenligning af priser på varer fra forskellige leverandører.
            </div>
            <div>
              Analyserne kan bruges til at vurdere, om priserne er konkurrencedygtige, og om der er
              mulighed for at opnå besparelser.
            </div>
          </div>
        </Tooltip>
      </h2>

      <KpiSection dateFrom={dateRange.from} dateTo={dateRange.to} />
      <ProcurementTable dateFrom={dateRange.from} dateTo={dateRange.to} />
    </div>
  );
}
