import { ReactNode, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";
import { Popover, PopoverContent } from "~/lib/ui/popover/popover";
import { Spinner } from "~/lib/ui/spinner";

type StatusVariant = keyof typeof styleMap;
type StatusBadgeBaseOption = {
  id: string;
  variant: StatusVariant;
  label: ReactNode;
};

type ReadonlyStatusBadgeOption = StatusBadgeBaseOption & {
  onSelect: never;
};

type EditableStatusBadgeOption = StatusBadgeBaseOption & {
  onSelect: (item: EditableStatusBadgeOption) => Promise<boolean>;
};

export type StatusBadgeOption = ReadonlyStatusBadgeOption | EditableStatusBadgeOption;

type StatusBadgeOptions<ReadOnly extends boolean | undefined> = ReadOnly extends true
  ? Array<ReadonlyStatusBadgeOption>
  : Array<EditableStatusBadgeOption>;

const styleMap = {
  green: "bg-green-500",
  yellow: "bg-yellow-500",
  red: "bg-red-500",
  gray: "bg-gray-400",
};

export default function StatusBadge<ReadOnly extends boolean | undefined>({
  options,
  selectedStatusId,
  readonly,
}: {
  options: StatusBadgeOptions<ReadOnly>;
  selectedStatusId: string;
  readonly?: ReadOnly;
}) {
  const [selectedValue, setSelectedValue] = useState<string>(selectedStatusId);
  const [pendingChange, setPendingChange] = useState<boolean>(false);

  const selectedOption = useMemo(
    () => options.find((option) => option.id === selectedValue),
    [selectedValue]
  );

  if (!selectedOption) {
    console.error(
      new Error(`StatusBadge: No option found for selectedStatusId: ${selectedStatusId}`)
    );
    return null;
  }

  if (readonly) {
    return (
      <div className="py-2">
        <StatusBadgeRender option={selectedOption} />
      </div>
    );
  }

  const handleSelect = async (item: StatusBadgeOption) => {
    // Set loading state
    setPendingChange(true);

    // Call onSelect function
    const success = await item.onSelect?.(item);

    // Set selected value if successful, otherwise keep the current value
    if (success) {
      setSelectedValue(item.id);
    }

    // Reset loading state
    setPendingChange(false);
  };

  return (
    <Popover
      config={{
        sideOffset: 2,
      }}
      disabled={pendingChange}
      triggerRender={({ open }) => (
        <div className="flex items-center gap-2 rounded-lg border bg-white px-2 py-2">
          <StatusBadgeRender option={selectedOption} pending={pendingChange} />
          {!readonly && (
            <Icon
              name="chevronDown"
              className={twMerge(
                "h-4 w-4 shrink-0 grow-0 transition-transform duration-300 ease-in-out",
                open ? "-rotate-180" : "rotate-0"
              )}
            />
          )}
        </div>
      )}
    >
      {(close) => (
        <PopoverContent className="p-0">
          {options.map((option) => (
            <div
              key={option.id}
              className="cursor-pointer px-2 py-2 hover:bg-shade-100"
              onClick={async () => {
                handleSelect(option);
                close();
              }}
            >
              <StatusBadgeRender option={option} />
            </div>
          ))}
        </PopoverContent>
      )}
    </Popover>
  );
}

const StatusBadgeRender = ({
  option,
  pending = false,
}: {
  option: StatusBadgeOption;
  pending?: boolean;
}) => {
  return (
    <div className={twMerge("flex w-fit items-center gap-2 text-xs")}>
      <div
        className={twMerge(
          "h-4 w-4 shrink-0 grow-0 rounded-full",
          pending ? "" : styleMap[option.variant]
        )}
      >
        {pending && <Spinner className="h-4 w-4" />}
      </div>
      <div className={twMerge(pending && "opacity-40")}>{option.label}</div>
    </div>
  );
};
