import { Contact } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useOutletContext } from "react-router";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useMount } from "~/lib/lifecycle-helpers";
import { InvoiceTable } from "~/pages/invoices/_cmp/invoice-table";

export default function InvoicesTab() {
  const pageTitle = usePageTitle();
  const { customer } = useOutletContext<{ customer: Contact }>();
  const { t } = useTranslation();
  const { id: customerId } = useParams();

  useMount(() => {
    pageTitle.set(`${customer?.name} | ${t("common:invoice", { count: 2 })}`);
  });

  if (!customerId) return null;

  return (
    <InvoiceTable parentType="tab" customerId={customerId} columnVisibility={{ customer: false }} />
  );
}
