import { Invoice } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { Badge } from "~/lib/ui/badge";

export function InvoiceBadge({ invoice }: { invoice: Invoice }) {
  const { t } = useTranslation();
  // Badge that shows "sent to X" or "draft" or "booked by X"
  const label = (() => {
    if (!invoice.isDraft && !!invoice.integrationId) {
      return t("invoices:sent_as_status_to_x", {
        replace: {
          x: invoice.integration?.name ?? "Apacta",
          status: t("invoices:booked").toLowerCase(),
        },
        defaultValue: "Sent as {{status}} to {{x}}",
      });
    }

    if (invoice.isDraft && !!invoice.integrationId) {
      return t("invoices:sent_as_status_to_x", {
        replace: {
          x: invoice.integration?.name ?? "Apacta",
          status: t("common:draft").toLowerCase(),
        },
        defaultValue: "Sent as {{status}} to {{x}}",
      });
    }

    if (invoice.isLocked) {
      return t("invoices:locked", {
        defaultValue: "Locked",
      });
    }

    if (!invoice.isDraft) {
      return t("invoices:booked");
    }

    return t("common:draft");
  })();
  return <Badge variant="green" label={label} />;
}
