import { createContext, useContext } from "react";
import type { Invoice } from "@apacta/sdk";
import type { InvoiceFormState } from "~/pages/invoices/_cmp/use-invoice-formstate";

interface InvoiceActionContextType {
  invoice: Invoice;
  pendingSave?: () => Promise<unknown>;
  invoiceState: InvoiceFormState;
}

const InvoiceActionContext = createContext<InvoiceActionContextType | undefined>(undefined);

export const useInvoiceActionContext = () => {
  const context = useContext(InvoiceActionContext);
  if (context === undefined) {
    throw new Error(
      "useInvoiceActionContext must be used within an InvoiceActionDialog. " +
        "Ensure this component is a child of InvoiceActionDialog or InvoiceActionContext.Provider."
    );
  }
  return context;
};

export { InvoiceActionContext };
