import { useMutation } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import { z } from "zod";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import { useToasts } from "~/lib/toast/use-toasts";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import TextArea from "~/lib/ui/form-elements/textarea";
import { getIcon, Icon, LabelInput } from "~/lib/ui";
import { ISendProjectFormsOperationRequest } from "@apacta/sdk";
import { emailRequired } from "~/lib/form-state/zod";

export function SendFormsDialog({
  onClose,
  defaultEmail = "",
  defaultSubject = "",
  defaultMessage = "",
  formIds,
  projectId,
  onBeforeSave,
  isDirty,
}: {
  onClose: () => void;
  defaultEmail?: string;
  defaultSubject?: string;
  defaultMessage?: string;
  formIds: Array<string>;
  projectId: string;
  isDirty?: boolean;
  onBeforeSave?: () => Promise<unknown>;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const toast = useToasts();

  const m = useMutation({
    mutationFn: async (args: ISendProjectFormsOperationRequest) => {
      if (isDirty && onBeforeSave) {
        await onBeforeSave();
      }
      return api.iSendProjectForms(args);
    },

    onSuccess: () => {
      toast.showTemplate("MESSAGE_SENT");
      onClose();
    },
  });

  const { getValues, registerStringInput, setValues } = useFormState({
    schema: {
      email: emailRequired(),
      subject: z.string().min(1),
      message: z.string().min(1),
    },
    initialValues: {
      email: defaultEmail,
      subject: defaultSubject,
      message: defaultMessage,
    },
    mutationError: m.error,
  });

  const { message, email, subject } = getValues();

  async function handleSend() {
    await m.mutateAsync({
      iSendProjectFormsRequest: {
        body: message,
        forms: formIds,
        subject: subject,
        recipient: email,
      },
      projectId,
    });
  }

  return (
    <>
      <DialogHeader Icon={getIcon("send")} title={t("projects:send_registrations")} />
      {isDirty && (
        <div className="mb-4 flex gap-4">
          <div>
            <Icon name="warningTriangle" className="h-8 w-8 text-orange-600" />
          </div>
          <div className="whitespace-pre-line">
            {t("forms:warning_unsaved_changes_description")}
          </div>
        </div>
      )}

      <div className="mb-6">
        <Trans
          i18nKey="projects:sending_x_registrations"
          values={{ x: formIds.length }}
          count={formIds.length}
        />
      </div>
      <div className="flex flex-col gap-4">
        <LabelInput {...registerStringInput("email")} label={t("common:email", "Email")} />
        <LabelInput {...registerStringInput("subject")} label={t("common:subject", "Subject")} />
        <TextArea
          label={t("common:message")}
          required
          initialValue={message}
          onChange={(val: string) => setValues({ message: val })}
        />
      </div>
      <DialogFooter primary={{ label: t("common:send"), onClick: handleSend }} onClose={onClose} />
    </>
  );
}
