import { createElement, ElementRef, forwardRef, Ref, JSX } from "react";
import { twMerge } from "tailwind-merge";

/**
 * A utility function to extend DOM elements with Tailwind CSS classes.
 * - This should be used sparingly, as this will make the code harder to read.
 * - Look up horror stories on styled-components if you don't believe me.
 */
export const extendElement = <
  T extends keyof JSX.IntrinsicElements,
  Variants extends Record<string, string>,
  Sizes extends Record<string, string>,
>(
  /** Element to extend. Only accepts intrinsic elements  */
  as: T,
  options?: {
    /** Manual displayName, otherwise `Extended${as}`
     * If you find yourself in a displayName conflict
     * - then we're probably using this utility too much
     */
    displayName?: string;
    /** className will be added before the component props are applied  */
    className: string;
    /** Variants will create a typed variant option and append classes */
    variants?: Variants;
    /** Sizes will create a typed size props and append classes  */
    sizes?: Sizes;
  }
) => {
  const {
    className: defaultClassname = "",
    displayName = `Extended${as}`,
    variants,
    sizes,
  } = options ?? {};
  type Props = JSX.IntrinsicElements[T] & { variant?: keyof Variants; size?: keyof Sizes };
  const Component = (props: Props, ref: Ref<ElementRef<T>>) => {
    const { variant, size, ...rest } = props;
    const variantClass = variants !== undefined && variant ? variants[variant] : "";
    const sizeClass = sizes !== undefined && size ? sizes[size] : "";
    return createElement(as, {
      ...rest,
      ref,
      className: twMerge(defaultClassname, sizeClass, variantClass, props.className),
    });
  };
  Component.displayName = displayName;
  return forwardRef(Component);
};
