import { useParams } from "react-router";
import type { IEditProductBundleRequest, ProductBundleLine } from "@apacta/sdk";
import { Suspense, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useToasts } from "~/lib/toast/use-toasts";
import { OrderLinesBuilder } from "~/lib/ui/order-lines/order-lines-builder";
import type { OrderLinesBuilderRef, OrderLinesColumns } from "~/lib/ui/order-lines/lib/types";
import OrderLinesBuilderProvider from "~/lib/ui/order-lines/order-lines-builder-context";
import Textarea from "~/lib/ui/form-elements/textarea";
import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import ImageUpload, { type PreviewFile } from "~/lib/ui/form-elements/image-upload";
import {
  orderLinesToProductBundleLinesTransformer,
  productBundleLineToOrderLineTransformer,
} from "~/lib/ui/order-lines/lib/transformers/product-bundle-transformers";
import { useMe } from "~/lib/auth/use-me";
import { Button, LabelInput } from "~/lib/ui";
import { PageLayout } from "~/lib/ui/page-layout";
import { CACHE_PRODUCT_BUNDLES } from "~/pages/products";
import { useToastOnError } from "~/lib/utils/hooks";
import type { ListResponse } from "~/lib/entity-ui/types";
import { revenueFromCostAndRatio } from "~/lib/utils/finance";
import { useKpiProductBundleFormstate } from "~/pages/products/_cmp/use-kpi-product-bundle-formstate";
import { useProductBundleFormState } from "~/pages/products/_cmp/use-product-bundle-formstate";
import { SalesPriceSection } from "~/pages/products/_cmp/sales-price-section";
import { KPICardsSection } from "~/pages/products/_cmp/kpi-cards-section";

export default function ProductBundleViewPage() {
  const me = useMe();
  const { t } = useTranslation();
  const api = useAPI();
  const { id: productBundleId } = useParams();
  const orderLinesBuilderRef = useRef<OrderLinesBuilderRef>(null);
  const [linesModified, setLinesModified] = useState<boolean>(false);
  const [totalCostPrice, setTotalCostPrice] = useState<number>(0);
  const queryClient = useQueryClient();

  const { data, error } = useSuspenseQuery({
    queryKey: [CACHE_PRODUCT_BUNDLES, productBundleId],
    queryFn: () =>
      api.productBundlesView({
        productBundleId: productBundleId as string,
      }),
  });

  useToastOnError(error);

  const productBundle = data?.data;

  const kpiQuery = useSuspenseQuery({
    queryKey: ["product_bundle_kpi", productBundleId],
    queryFn: () =>
      api.productBundlesGetKpiCardsData({
        productBundleId: productBundleId as string,
      }),
  });
  /** Calculating total sales price */
  const handleChangeContributionRatio = (ratio: number) => {
    const totalSalesPrice = revenueFromCostAndRatio(totalCostPrice, ratio);
    formState.setValues({
      contribution_ratio: ratio,
      total_sales_price: totalSalesPrice,
    });
  };

  const handleChangeSalesPrice = (totalSalesPrice: number) => {
    formState.setValues({
      total_sales_price: totalSalesPrice,
      contribution_ratio: Math.round(kpiFormData.getValue("contributionMargin")),
    });
  };

  const orderLinesActionVisibility = {
    showAddProduct: true,
    showAddHours: true,
    showAddProductBundle: false,
    showAddText: false,
    showTotal: true,
    showVAT: false,
    showSubtotal: false,
    sumCostPrice: true,
  };
  const orderLinesColumnOptions: OrderLinesColumns = {
    handle: { visible: true },
    product: { visible: true },
    quantity: { visible: true },
    unit: { visible: true },
    costPrice: { visible: true },
    salePrice: { visible: false },
    discount: { visible: false },
    total: { visible: true },
    actions: { visible: true },
  };
  const kpiData = kpiQuery.data.data;

  const kpiFormData = useKpiProductBundleFormstate(kpiData);

  const formState = useProductBundleFormState(productBundle);

  const toast = useToasts();

  const productBundleEdit = useMutation({
    mutationFn: (args: IEditProductBundleRequest) => api.iEditProductBundle(args),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED");
    },
  });

  const handleSaveProductBundle = () => {
    const lines = orderLinesBuilderRef.current?.getLines();
    const productBundleLines = orderLinesToProductBundleLinesTransformer(lines ?? [], {
      productBundleId: productBundle?.id,
    });
    orderLinesBuilderRef.current?.resetInitialValues();

    productBundleEdit.mutate(
      {
        productBundleId: productBundleId as string,
        iCreateProductBundleRequest: {
          name: formState.getValue("name"),
          description: formState.getValue("description"),
          salesPrice: formState.getValue("total_sales_price"),
          fileId: productBundle.file?.id ?? null,
          productBundleLines: productBundleLines,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [CACHE_PRODUCT_BUNDLES, productBundleId] });
        },
      }
    );
  };

  const initialFile: PreviewFile | undefined = productBundle.imageUrls?.thumbnail
    ? {
        url: productBundle.imageUrls.thumbnail,
        type: productBundle.imageMimeType ?? "image/*",
        name: "product_bundle_image",
      }
    : undefined;

  const fileRemove = async () => {
    await api.iEditProductBundle({
      productBundleId: productBundleId as string,
      iCreateProductBundleRequest: {
        name: productBundle.name,
        fileId: null,
      },
    });

    await queryClient.invalidateQueries({ queryKey: [CACHE_PRODUCT_BUNDLES, productBundleId] });
  };

  const fileUpload = async (file: File) => {
    const uploadedFile = await api.iProductBundleUploadFile({
      productBundleId: productBundleId as string,
      file: file,
    });

    await queryClient.invalidateQueries({ queryKey: [CACHE_PRODUCT_BUNDLES, productBundleId] });
    return uploadedFile;
  };

  const registerModified = () => {
    if (orderLinesBuilderRef.current) {
      setLinesModified(orderLinesBuilderRef.current.isModified);
      let lineTotalHoursCostPrice = 0;
      let lineTotalHoursSalesPrice = 0;
      let lineTotalProductCostPrice = 0;
      let lineTotalProductSalesPrice = 0.0;
      let totalHours = 0.0;
      orderLinesBuilderRef.current?.getLines().forEach((line) => {
        if (!line.usersPriceGroupId && line.costPrice && line.sellingPrice && line.quantity) {
          lineTotalProductCostPrice += line.costPrice * line.quantity;
          lineTotalProductSalesPrice += line.sellingPrice * line.quantity;
        }
        if (line.usersPriceGroupId && line.costPrice && line.sellingPrice && line.quantity) {
          lineTotalHoursCostPrice += line.costPrice * line.quantity;
          lineTotalHoursSalesPrice += line.sellingPrice * line.quantity;
          totalHours += line.quantity;
        }
      });
      setTotalCostPrice(getTotalCostPrice());
      const totalSalesPrice = formState.getValue("total_sales_price") ?? getTotalSalesPrice();
      const contributionMargin = totalSalesPrice - totalCostPrice;

      kpiFormData.setValues({
        hoursCostPrice: lineTotalHoursCostPrice,
        productsCostPrice: lineTotalProductCostPrice,
        totalCostPrice: totalCostPrice,
        hoursSalesPrice: lineTotalHoursSalesPrice,
        productsSalesPrice: lineTotalProductSalesPrice,
        totalSalesPrice: totalSalesPrice,
        contributionMargin: contributionMargin,
        workingHoursMargin: contributionMargin > 0 ? contributionMargin / totalHours : 0,
        profitMargin: contributionMargin > 0 ? contributionMargin / totalSalesPrice : 0,
      });
      formState.setValues({
        total_sales_price: totalSalesPrice,
      });
    }
  };

  useEffect(() => {
    registerModified();
    const totalSalesPrice = formState.getValue("total_sales_price") ?? getTotalSalesPrice();

    const contributionMargin =
      totalSalesPrice > 0 && totalSalesPrice > totalCostPrice
        ? totalSalesPrice - totalCostPrice
        : 0;

    kpiFormData.setValues({
      totalSalesPrice: totalSalesPrice,
      profitMargin: totalSalesPrice > 0 ? contributionMargin / totalSalesPrice : 0,
    });

    formState.setValues({
      total_sales_price: totalSalesPrice,
      contribution_ratio:
        totalSalesPrice > 0
          ? Math.round((contributionMargin / totalSalesPrice) * 100)
          : formState.getValue("contribution_ratio"),
    });

    return () => undefined;
  }, [
    formState.getValue("total_sales_price"),
    formState.getValue("contribution_ratio"),
    totalCostPrice,
  ]);

  const getTotalCostPrice = () => {
    let totalLineCostPrice = 0;
    if (orderLinesBuilderRef.current) {
      let lineTotalHoursCostPrice = 0;
      let lineTotalProductCostPrice = 0;
      orderLinesBuilderRef.current?.getLines().forEach((line) => {
        // Working hours
        if (!line.usersPriceGroupId && line.costPrice && line.quantity) {
          lineTotalProductCostPrice += line.costPrice * line.quantity;
        }
        // Product
        if (line.usersPriceGroupId && line.costPrice && line.quantity) {
          lineTotalHoursCostPrice += line.costPrice * line.quantity;
        }
      });
      totalLineCostPrice = lineTotalHoursCostPrice + lineTotalProductCostPrice;
    }
    return totalLineCostPrice;
  };

  const getTotalSalesPrice = () => {
    let totalSalesPrice = 0;
    if (orderLinesBuilderRef.current) {
      let lineTotalHoursSalesPrice = 0;
      let lineTotalProductSalesPrice = 0.0;
      orderLinesBuilderRef.current?.getLines().forEach((line) => {
        if (!line.usersPriceGroupId && line.sellingPrice && line.quantity) {
          lineTotalProductSalesPrice += line.sellingPrice * line.quantity;
        }
        if (line.usersPriceGroupId && line.sellingPrice && line.quantity) {
          lineTotalHoursSalesPrice += line.sellingPrice * line.quantity;
        }
      });

      totalSalesPrice = lineTotalHoursSalesPrice + lineTotalProductSalesPrice;
    }

    return totalSalesPrice;
  };

  return (
    <Suspense>
      {productBundle && (
        <PageLayout title={productBundle ? productBundle.name : t("common:product_bundle")}>
          <PageLayout.Signal slot="actions" id="save-product-bundle">
            <Button
              onClick={() => handleSaveProductBundle()}
              variant="tertiary"
              disabled={(!formState.isModified && !linesModified) || !formState.isValid}
            >
              {t("common:save_changes")}
            </Button>
          </PageLayout.Signal>
          <div className="flex w-full flex-col gap-12">
            <>
              <div className="flex  flex-col gap-8">
                <div className="flex flex-col gap-8 md:flex-row">
                  <div className="flex-1">
                    <div className="flex flex-col gap-6 sm:rounded-lg">
                      <KPICardsSection kpiFormData={kpiFormData} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-8 md:flex-row">
                  <div className="flex-1">
                    <SalesPriceSection
                      formState={formState}
                      totalCostPrice={totalCostPrice}
                      handleChangeContributionRatio={handleChangeContributionRatio}
                      handleChangeSalesPrice={handleChangeSalesPrice}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-8 md:flex-row">
                  <div className="flex-1">
                    <h2 className="mb-5">{t("common:description")}</h2>
                    <div className="flex bg-white p-6 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                      <div className="flex h-auto flex-col gap-6 md:basis-1/2">
                        <LabelInput
                          {...formState.registerStringInput("name")}
                          label={t("common:name")}
                        />
                        <div className="flex h-32 basis-3/5 flex-col gap-8">
                          <Textarea
                            {...formState.registerStringInput("description")}
                            initialValue={formState.getValue("description")}
                            onChange={(desc) => formState.onChange("description", desc)}
                            label={t("common:description")}
                            autoHeight={false}
                            fullHeight={true}
                          />
                        </div>
                      </div>

                      <div className="flex flex-1 flex-col justify-start gap-6 md:basis-1/2">
                        {/* Here we should insert labels when implemented */}
                        <ImageUpload
                          initialFile={initialFile}
                          uploadFn={(file: File) => fileUpload(file)}
                          removeFn={fileRemove}
                          label={t("common:image", "Image")}
                        ></ImageUpload>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex h-full flex-col gap-8">
                <div className="flex md:flex-row">
                  <div className="flex-1">
                    <h2 className="mb-5">{t("common:content")}</h2>
                    <div className="flex bg-white px-20 py-6 shadow sm:gap-8 sm:rounded-lg md:flex-row">
                      <OrderLinesBuilderProvider
                        cacheKey={[CACHE_PRODUCT_BUNDLES, productBundleId, "product-bundle-lines"]}
                        dataFn={() =>
                          api
                            .productBundleLinesList({ productBundleId: productBundleId as string })
                            .then((r) => {
                              return {
                                success: true,
                                data: r.data ?? [],
                              } satisfies ListResponse<ProductBundleLine>;
                            })
                        }
                        transformerFn={productBundleLineToOrderLineTransformer}
                        onChange={registerModified}
                        initialVatPercent={me.company.vatPercent}
                      >
                        <OrderLinesBuilder
                          ref={orderLinesBuilderRef}
                          editMode={true}
                          showAll={true}
                          companyVatPercent={me.company.vatPercent}
                          options={{
                            orderLinesActionVisibility: orderLinesActionVisibility,
                            columns: orderLinesColumnOptions,
                          }}
                        />
                      </OrderLinesBuilderProvider>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </PageLayout>
      )}
    </Suspense>
  );
}
