import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { getIcon } from "~/lib/ui";
import { DialogBody } from "~/lib/ui/dialog/dialog-body";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useState } from "react";
import { ProjectSelection } from "~/lib/ui/selection-combobox/project-selection";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { CACHE_INVOICES } from "~/pages/invoices";
import { DuplicateInvoiceOperationRequest } from "../../../../apacta-sdk";
import { linkToInvoiceV3 } from "~/lib/utils";
import { useToasts } from "~/lib/toast/use-toasts";
import { useAPI } from "~/lib/api";
import { useNavigate, useParams } from "react-router";
import { InformationBox } from "~/lib/ui/dialog/components/information-box";

export default function DuplicateInvoiceDialog({
  pendingSave,
  onClose,
}: {
  pendingSave: (() => Promise<void>) | undefined;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { invoiceId } = useParams();
  const [projectId, setProjectId] = useState<string | undefined>(undefined);
  const api = useAPI();
  const navigate = useNavigate();
  const { showTemplate } = useToasts();

  const copyToProjectMutation = useMutation({
    mutationKey: [CACHE_INVOICES],
    mutationFn: (args: DuplicateInvoiceOperationRequest) => api.duplicateInvoice(args),
    onSuccess: (data) => {
      if (!data?.data?.id) return;
      showTemplate("CREATED");
      navigate(linkToInvoiceV3(data.data.id));
    },
  });

  const handleSubmit = async () => {
    if (projectId) {
      if (!!pendingSave) {
        await pendingSave();
      }
      await copyToProjectMutation.mutateAsync({
        invoiceId: invoiceId as string,
        asCreditNote: false,
        duplicateInvoiceRequest: {
          projectId,
        },
      });
      onClose();
    }
  };

  return (
    <>
      <DialogHeader title={t("invoices:copy_to_project")} Icon={getIcon("duplicate")} />
      <DialogBody>
        <div className="flex flex-col gap-4">
          <div className="text-sm">{t("invoices:copy_to_project_description")}</div>
          <ProjectSelection required onSelectValue={setProjectId} />
          {!!pendingSave && (
            <InformationBox variant="warning">
              {t("invoices:unsaved_changes_saved_warning")}
            </InformationBox>
          )}
        </div>
      </DialogBody>
      <DialogFooter
        primary={{
          label: t("common:copy"),
          onClick: handleSubmit,
          disabled: !projectId,
        }}
        onClose={onClose}
      />
    </>
  );
}
