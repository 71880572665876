import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { Dialog, getIcon, Icon, Label } from "~/lib/ui";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogBody } from "~/lib/ui/dialog/dialog-body";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { useFormState } from "~/lib/form-state";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateProcurementAnalysisInput, useGraphQL } from "~/lib/gql";
import z from "zod";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { CACHE_PROCUREMENT, ProcurementPageSearchParams } from "~/pages/procurement";
import { useTranslation } from "react-i18next";
import { DateRange } from "~/lib/utils/date/date-utils.types";
import CompaniesVendorSelection, {
  CompaniesVendorSelectionType,
} from "~/lib/ui/vendor/companies-vendor-selection";
import { useMemo, useState } from "react";
import { useMe } from "~/lib/auth/use-me";
import { FieldError } from "~/lib/ui/form-elements/field-error";

export default function CreatePriceAnalysisDialog({
  dateFrom,
  dateTo,
  onClose,
}: {
  dateFrom: Date;
  dateTo: Date;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const me = useMe();
  const [searchParams] = useTypedSearchParams<ProcurementPageSearchParams>();
  const [aoVendorId, setAoVendorId] = useState<string | undefined>(undefined);

  const aoWarningEnabled = me?.companySettings.procurementDeactivateVendorAoJohansen;

  const sdk = useGraphQL();
  const queryClient = useQueryClient();

  const createPriceAnalysisMutation = useMutation({
    mutationFn: (args: CreateProcurementAnalysisInput) =>
      sdk.procurementAnalysisCreate({
        input: args,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_PROCUREMENT] });
      onClose();
    },
  });

  const formState = useFormState({
    schema: {
      dateStart: z.date(),
      dateEnd: z.date(),
      companiesVendorIds: z.array(z.string()).nullish(), // Array of vendor ids
    },
    initialValues: {
      dateStart: dateFrom,
      dateEnd: dateTo,
      companiesVendorIds: searchParams.companiesVendorIds,
    },
    mutationError: createPriceAnalysisMutation.error,
  });

  const handleCreatePriceAnalysis = async () => {
    await createPriceAnalysisMutation.mutateAsync({
      dateInterval: {
        from: formState.getValue("dateStart"),
        to: formState.getValue("dateEnd"),
      },
      companiesVendorIds: formState.getValue("companiesVendorIds"),
    });
  };

  const handleVendorSelect = (selectedIds: Array<string>) => {
    formState.setValues({ companiesVendorIds: selectedIds.length > 0 ? selectedIds : undefined });
  };

  const checkAoWarning = (vendors: Array<CompaniesVendorSelectionType>) => {
    if (aoWarningEnabled) {
      if (vendors.some((vendor) => vendor.node.vendor.name === "AO Johansen")) {
        setAoVendorId(vendors.find((vendor) => vendor.node.vendor.name === "AO Johansen")?.node.id);
      }
    }
  };

  const clearAoWarning = () => {
    const selectedVendors = formState.getValue("companiesVendorIds");
    if (!!selectedVendors) {
      const newVendors = selectedVendors.filter((id) => id !== aoVendorId);
      formState.setValues({ companiesVendorIds: newVendors });
    }
    setAoVendorId(undefined);
  };

  const handleDateRangeChange = ({ from, to }: DateRange) => {
    formState.setValues({
      dateStart: from,
      dateEnd: to,
    });
  };

  const selectedDateRange = useMemo(() => {
    return {
      to: formState.getValue("dateEnd"),
      from: formState.getValue("dateStart"),
    };
  }, [formState]);

  return (
    <>
      <DialogHeader
        title={t("common:create", {
          entity: t("common:price_analysis", { count: 1 }).toLowerCase(),
        })}
        Icon={getIcon("add")}
      />
      <DialogBody>
        <div className="flex flex-col gap-6">
          <div>
            <Label required>{t("common:date_range")}</Label>
            <DateRangePopover
              compact={false}
              dateFrom={
                !!formState.getValue("dateStart")
                  ? new Date(formState.getValue("dateStart"))
                  : undefined
              }
              dateTo={
                !!formState.getValue("dateEnd")
                  ? new Date(formState.getValue("dateEnd"))
                  : undefined
              }
              onChange={handleDateRangeChange}
            />
            <FieldError error={formState.getField("dateStart").errors} />
          </div>

          <div>
            <CompaniesVendorSelection
              key={aoVendorId}
              defaultSelected={formState.getValue("companiesVendorIds") ?? undefined}
              dateRange={{
                from: selectedDateRange.from,
                to: selectedDateRange.to,
              }}
              onSelectIds={handleVendorSelect}
              onSelectItems={checkAoWarning}
            />
          </div>

          <div className="flex flex-col gap-2 border bg-gray-100 p-2">
            <div className="flex items-center gap-4">
              <div className="rounded-full bg-yellow-500 p-1.5 text-white">
                <Icon name="warningTriangle" className="h-6 w-6" />
              </div>
              <div className="text-sm">{t("procurement:create_dialog.vendor_warning")}</div>
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter
        primary={{
          label: t("common:create", {
            entity: t("common:price_analysis", { count: 1 }).toLowerCase(),
          }),
          onClick: handleCreatePriceAnalysis,
          disabled: !formState.isValid && !createPriceAnalysisMutation.isPending,
          loading: createPriceAnalysisMutation.isPending,
        }}
        onClose={onClose}
      />
      <Dialog
        open={!!aoVendorId}
        onOpenChange={clearAoWarning}
        render={() => (
          <>
            <DialogHeader
              title="Leverandør ikke tilgængelig"
              Icon={getIcon("warningTriangle")}
              variant="alert"
            />
            <DialogBody>
              <div>
                <div>Prisanalyse med data fra AO Johansen er deaktiveret i systemet.</div>
                <div>AO Johansen vil blive fjernet fra de valgte leverandører.</div>
              </div>
            </DialogBody>
            <DialogFooter
              primary={{ label: "OK", onClick: clearAoWarning, variant: "primary" }}
              hideCancel
              onClose={() => {
                clearAoWarning();
              }}
            />
          </>
        )}
      />
    </>
  );
}

/*

Leave in for now

<div className="flex flex-col gap-4">
                <div>
                  Du har valgt en leverandør, der desværre ikke ønsker at være transparente med
                  deres priser. I modsætning til andre leverandører, der konkurrerer åbent og giver
                  vores brugere de bedste betingelser, har denne leverandør valgt ikke at deltage på
                  lige vilkår.
                </div>
                <div>
                  Det betyder, at vi ikke kan vise dig deres priser direkte, og du må selv vurdere,
                  om dette er den rette løsning for dig. Vi anbefaler altid at vælge leverandører,
                  der er åbne og konkurrencedygtige – det giver dig de bedste muligheder.
                </div>
                <div>
                  Skulle du have spørgsmål ang. dette, er du velkommen til at kontakte AO Johansen
                  ved at skrive til dem på{" "}
                </div>
                <div className="text-center">
                  <a href="mailto:support@ao.dk" className="text-xl text-primary hover:underline">
                    support@ao.dk
                  </a>
                </div>
              </div>

 */
