import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Button, Dialog, Icon } from "~/lib/ui";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { t } from "i18next";
import { MediaPreview } from "~/lib/ui/media/";
import { MediaDetail } from "~/lib/ui/media/media-detail";
import useDownload from "~/lib/utils/download";

export type MediaCarouselMimeType = `image/${string}` | `video/${string}`;

type MediaCarouselItem = {
  id: string;
  previewUrl: string;
  downloadUrl: string;
  title: string;
  mimeType: MediaCarouselMimeType; // Only allow image and video
};

/**
 * MediaCarousel - Same as MediaPreviewLink, but with a carousel of multiple items.
 * @param {Array<MediaCarouselItem>} items - Array of items to display in the carousel
 * @param {string} focusedId - The id of the item to focus on
 * @param {ReactNode} children - The children to display as the preview, functions as the trigger
 * @param {string} className - Optional classes for the trigger (children)
 */
export function MediaCarousel({
  items,
  focusedId,
  children,
  className,
}: {
  items: Array<MediaCarouselItem>;
  focusedId: string;
  children: ReactNode;
  className?: string;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <span
        onClick={() => setIsOpen(true)}
        className={twMerge("cursor-pointer hover:opacity-80", className)}
      >
        {children}
      </span>
      <Dialog
        className="max-h-[95vh] md:max-w-4xl"
        open={isOpen}
        onOpenChange={(state) => setIsOpen(state)}
        render={() => <MediaCarouselDialog items={items} focusedId={focusedId} />}
      />
    </>
  );
}

function MediaCarouselDialog({
  items,
  focusedId,
}: {
  items: Array<MediaCarouselItem>;
  focusedId: string;
}) {
  const [scrollSettings, setScrollSettings] = useState<{
    left: boolean;
    right: boolean;
    isOverflowing: boolean;
  }>({
    left: false,
    right: false,
    isOverflowing: false,
  });
  const { download } = useDownload();
  const [shownItem, setShownItem] = useState<MediaCarouselItem>(
    items.find((f) => f.id === focusedId) ?? items[0]
  );

  // Need to keep track of rendered items to know if we need to update scroll settings - because react...
  const [renderedItems, setRenderedItems] = useState<Array<MediaCarouselItem>>([]);

  // Thumbnail refs, used to scroll to the correct item
  const renderedItemsRef = useRef<Array<HTMLDivElement | null>>([]);

  // Carousel ref, used to listen for scroll events and decide if we can scroll
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const getNextIndex = () => {
    const currentIndex = items.findIndex((f) => f.id === shownItem.id);
    return currentIndex === items.length - 1 ? 0 : currentIndex + 1;
  };

  const getPreviousIndex = () => {
    const currentIndex = items.findIndex((f) => f.id === shownItem.id);
    return currentIndex === 0 ? items.length - 1 : currentIndex - 1;
  };

  const canScrollRight = () => {
    if (!carouselRef.current) return false;
    return (
      carouselRef.current.scrollLeft <
      carouselRef.current.scrollWidth - carouselRef.current.clientWidth
    );
  };

  const canScrollLeft = () => {
    if (!carouselRef.current) return false;
    return carouselRef.current.scrollLeft > 0;
  };

  const isOverflowing = () => {
    if (!carouselRef.current) return false;
    return carouselRef.current.scrollWidth > carouselRef.current.clientWidth;
  };

  const handleScroll = (direction: "left" | "right") => {
    if (!carouselRef.current) return;
    const scrollAmount = carouselRef.current.clientWidth;

    carouselRef.current.scrollBy({
      left: direction === "left" ? -scrollAmount : scrollAmount,
      behavior: "smooth",
    });
  };

  // Show the item and scroll to the thumbnail if it's not in view
  const handleShowItem = (index: number) => {
    setShownItem(items[index]);
    renderedItemsRef.current[index]?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  // We have to listen for the renderedItems to update the scroll settings as React cannot handle scrollWidth and clientWidth updates
  // and it also can't figure out when its children have rendered or not. Thus we have to coerce it to do so.
  useLayoutEffect(() => {
    if (!carouselRef.current) return;

    const updateScrollSettings = () => {
      setScrollSettings({
        left: canScrollLeft(),
        right: canScrollRight(),
        isOverflowing: isOverflowing(),
      });
    };

    if (renderedItems.length === items.length) {
      // Listen for scroll events
      carouselRef.current?.addEventListener("scroll", updateScrollSettings);
      // Init settings
      updateScrollSettings();
    }
    // Clean up
    return () => carouselRef.current?.removeEventListener("scroll", updateScrollSettings);
  }, [renderedItems]);

  const handleDownload = async (url: string) => {
    await download(url);
  };

  return (
    <div className="flex grow-0 flex-col flex-nowrap items-center justify-center gap-4">
      <div className="group relative flex w-full select-none justify-center">
        {items.length > 1 && (
          <div className="invisible absolute -left-8 top-0 flex h-full w-16 items-center justify-center group-hover:visible">
            <div
              role="button"
              onClick={() => handleShowItem(getPreviousIndex())}
              className="group/button flex h-32 w-16 cursor-pointer items-center justify-center rounded-r-lg border border-l-0 bg-white shadow-md hover:bg-shade-100"
            >
              <Icon
                name="chevronLeft"
                className="h-8 w-8 text-shade-400 group-hover/button:text-zinc-700"
              />
            </div>
          </div>
        )}
        <MediaDetail href={shownItem.previewUrl} mimeType={shownItem.mimeType} />
        {items.length > 1 && (
          <div className="invisible absolute -right-8 top-0 flex h-full w-16 items-center justify-center group-hover:visible">
            <div
              role="button"
              onClick={() => handleShowItem(getNextIndex())}
              className="group/button flex h-32 w-16 cursor-pointer items-center justify-center rounded-l-lg border border-r-0 bg-white shadow-md hover:bg-shade-100"
            >
              <Icon
                name="chevronRight"
                className="h-8 w-8 text-shade-400 group-hover/button:text-zinc-700"
              />
            </div>
          </div>
        )}
      </div>
      <div className="group relative flex w-full items-center justify-center">
        {scrollSettings.left && (
          <div className="absolute -left-8 top-0 flex h-full w-16 items-center justify-center">
            <div
              role="button"
              onClick={() => handleScroll("left")}
              className="group/button flex h-16 w-16 cursor-pointer items-center justify-center rounded-r-lg border border-l-0 bg-white shadow-md hover:bg-shade-100"
            >
              <Icon
                name="chevronLeft"
                className="h-8 w-8 text-shade-400 group-hover/button:text-zinc-700"
              />
            </div>
          </div>
        )}
        <div ref={carouselRef} className="w-[calc(100%-6em)] overflow-x-hidden">
          {items.length !== renderedItems.length && (
            <div className="flex select-none items-center gap-2">
              <SkeletonLoader template="carouselThumbnails" />
            </div>
          )}
          <div
            className={twMerge(
              items.length === renderedItems.length
                ? "visible"
                : "invisible absolute overflow-hidden",
              scrollSettings.isOverflowing ? "justify-start" : "justify-center",
              "flex select-none gap-2"
            )}
          >
            {items.map((item, idx) => (
              <div
                ref={(e) => {
                  renderedItemsRef.current[idx] = e;
                }}
                onLoad={() => setRenderedItems((prevState) => [...prevState, item])}
                key={`carousel-${item.id}`}
                className={twMerge(
                  "shrink-0 cursor-pointer",
                  shownItem.id === item.id ? "" : "opacity-40 hover:opacity-100"
                )}
                onClick={() => handleShowItem(idx)}
              >
                <MediaPreview href={item.downloadUrl} mimeType={item.mimeType} height={16} />
              </div>
            ))}
          </div>
        </div>
        {scrollSettings.right && (
          <div className="absolute -right-8 top-0 flex h-full w-16 items-center justify-center">
            <div
              role="button"
              onClick={() => handleScroll("right")}
              className="group/button flex h-16 w-16 cursor-pointer items-center justify-center rounded-l-lg border border-r-0 bg-white shadow-md hover:bg-shade-100"
            >
              <Icon
                name="chevronRight"
                className="h-8 w-8 text-shade-400 group-hover/button:text-zinc-700"
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 flex w-full items-center justify-between">
        <div>{shownItem.title}</div>
        <Button variant="secondary" onClick={() => handleDownload(shownItem.downloadUrl)}>
          {t("common:download")}
        </Button>
      </div>
    </div>
  );
}
