import { ArrowTopRightOnSquareIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";

export default function IntegrationsPage() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center gap-4">
        <ExclamationTriangleIcon className="h-10 w-10 text-orange-500" />
        <h2 className="m-0">{t("settings:under_construction.title")}</h2>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <p>{t("settings:under_construction.description_line_1")}</p>
          <p>{t("settings:under_construction.description_line_2")}</p>
        </div>
        <div className="flex items-center gap-2">
          <Link to="/companies/companies/edit" className="text-lg">
            {t("settings:under_construction.link")}
          </Link>
          <ArrowTopRightOnSquareIcon className="h-6 w-6" />
        </div>
      </div>
    </div>
  );
}
