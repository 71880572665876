import { MouseEvent, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Button, Dialog } from "~/lib/ui";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { MediaPreview } from "~/lib/ui/media/";
import { MediaDetail } from "~/lib/ui/media/media-detail";
import useDownload from "~/lib/utils/download";
import { useTranslation } from "react-i18next";

export function MediaPreviewLink({
  href,
  previewURL,
  downloadURL,
  mimeType,
  title,
  height,
  className,
  disabled = false,
  allowDownload = true,
  stopPropagation = false,
}: {
  href: string; // Link to image, used for preview if no previewURL
  previewURL?: string; // Preview URL. Overrides href if set
  downloadURL?: string; // Download URL. Overrides href if set
  className?: string;
  title?: string;
  height?: number;
  disabled?: boolean;
  mimeType: string;
  allowDownload?: boolean;
  stopPropagation?: boolean;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { t } = useTranslation();
  const { download } = useDownload();

  const allowPreview =
    (!hasError &&
      mimeType &&
      (mimeType.startsWith("video") || mimeType.startsWith("image") || mimeType.endsWith("pdf"))) ||
    false;
  const isPdf = mimeType && mimeType.endsWith("pdf");

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    if (allowPreview) {
      e.preventDefault();
      if (stopPropagation) e.stopPropagation();
      setIsOpen(true);
    } else {
      window.open(href);
    }
  }

  const preview = previewURL ?? href;
  const downloadLink = downloadURL ?? href;

  const handleDownload = async () => {
    await download(downloadLink);
  };

  return (
    <>
      {disabled ? (
        <MediaPreview
          href={preview}
          mimeType={mimeType}
          filename={title}
          height={height}
          onError={() => setHasError(true)}
        />
      ) : (
        <>
          <a
            href={!allowPreview && !hasError ? href : undefined}
            onClick={allowPreview ? handleClick : undefined}
            className={twMerge(
              allowPreview && !hasError && "cursor-zoom-in hover:opacity-80",
              "rounded-lg",
              className
            )}
          >
            <MediaPreview
              href={preview}
              mimeType={mimeType}
              filename={title}
              height={height}
              onError={() => setHasError(true)}
            />
          </a>
          {allowPreview && !isPdf && (
            <Dialog
              className="max-h-[95vh] md:max-w-4xl"
              open={isOpen}
              onOpenChange={(state) => setIsOpen(state)}
              render={() => (
                <div className="flex grow-0 flex-col flex-nowrap items-center justify-center">
                  <MediaDetail href={href} mimeType={mimeType} />
                  <div className="mt-4 flex w-full items-center justify-between">
                    <div>{title}</div>
                    {allowDownload && (
                      <Button variant="secondary" onClick={handleDownload}>
                        {t("common:download")}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            />
          )}
          {allowPreview && isPdf && (
            <FullScreenFilePreview
              open={isOpen}
              fileUrl={preview}
              onClose={() => setIsOpen(false)}
            />
          )}
        </>
      )}
    </>
  );
}
