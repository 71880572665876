import FileCrudSection from "~/lib/ui/files/file-crud-section";
import { BaseFile } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog, Dialog, getIcon } from "~/lib/ui";
import { useState } from "react";

export function InvoiceFileSection({
  files,
  editMode = false,
  onSelectFiles,
  onRemoveFile,
}: {
  files: Array<File | BaseFile>;
  editMode?: boolean;
  onSelectFiles?: (files: Array<File>) => void;
  onRemoveFile?: (file: File | BaseFile | null, confirmed?: boolean) => void;
}) {
  const { t } = useTranslation();

  const [confirmDeleteAlertOpen, setConfirmDeleteAlertOpen] = useState<boolean>(false);
  const [stagedStandardFile, setStagedStandardFile] = useState<BaseFile | null>(null);

  const handleSelectFiles = (fileList: FileList) => {
    onSelectFiles?.([...Array.from(fileList)]);
  };

  const handleRemoveFile = (file: File | BaseFile | null, confirmed?: boolean) => {
    if (!file) return;

    // If the file is an existing file, we need to add it to the delete list, otherwise just remove it from the files to be uploaded
    if ((file as BaseFile).id) {
      if ((file as BaseFile).isStandardFile && !confirmed) {
        setConfirmDeleteAlertOpen(true);
        setStagedStandardFile(file as BaseFile);
        return;
      }
      setConfirmDeleteAlertOpen(false);
      setStagedStandardFile(null);
      onRemoveFile?.(file as BaseFile);
    } else {
      onRemoveFile?.(file);
    }
  };

  return (
    <>
      <FileCrudSection
        files={files}
        editMode={editMode}
        loading={false}
        onSelectFiles={handleSelectFiles}
        onRemoveFile={handleRemoveFile}
        title={t("common:files")}
      />
      <Dialog
        open={confirmDeleteAlertOpen}
        onOpenChange={() => setConfirmDeleteAlertOpen(false)}
        render={({ onClose }) => (
          <ConfirmationDialog
            title={t("offers:delete_standard_file_title")}
            description={t("invoices:delete_standard_file_description")}
            variant="warning"
            submitLabel={t("common:confirm")}
            Icon={getIcon("warningTriangle")}
            onSubmit={() => handleRemoveFile(stagedStandardFile, true)}
            onClose={() => onClose()}
          />
        )}
      />
    </>
  );
}
