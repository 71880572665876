import { ProductPriceTypeEnum } from "@apacta/sdk";
import { z } from "zod";

export const productPricingSchema = {
  buyingPrice: z.number().optional(),
  sellingPrice: z.number().optional(),
  contributionRatio: z.number().optional(),
  priceType: z.nativeEnum(ProductPriceTypeEnum),
  variantType: z.enum(["expense_line", "vendor_product"]).optional(),
  variantId: z.string().optional(),
};
