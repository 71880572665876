import { useCallback, useEffect, useMemo } from "react";
import { DateRange } from "./date/date-utils.types";
import { useTypedSearchParams } from "./use-typed-search-params";
import { fromLocalDateString, toLocalDateString } from "./date";

/** Date Range Params with required parameters */
export function useDateRangeParams(
  defaultRange: Required<DateRange>
): [Required<DateRange>, (range: DateRange) => void];

/** Date Range Params with optional parameters */
export function useDateRangeParams(
  defaultRange?: DateRange
): [DateRange, (range: DateRange) => void];

/** Implementation  */
export function useDateRangeParams(
  defaultRange?: DateRange
): [DateRange, (range: DateRange) => void] {
  const [params, setParams] = useTypedSearchParams<{
    dateFrom?: string;
    dateTo?: string;
  }>();

  const handleChange = useCallback((range: DateRange) => {
    setParams("dateFrom", toLocalDateString(range.from));
    setParams("dateTo", toLocalDateString(range.to));
  }, []);

  const rangeWithLocalDates = useMemo(() => {
    const fromDate = params.dateFrom ? fromLocalDateString(params.dateFrom) : defaultRange?.from;
    const toDate = params.dateTo ? fromLocalDateString(params.dateTo) : defaultRange?.to;

    return {
      from: fromDate,
      to: toDate,
    };
    // Note: defaultRange is purposfully not included in the deps array
    // Should that change, we don't care. They are only defaults
  }, [params.dateFrom, params.dateTo]);

  // Setting of defaults if params are not set
  useEffect(() => {
    if (defaultRange && !params.dateFrom && !params.dateTo) {
      handleChange(defaultRange);
    }
  }, [rangeWithLocalDates]);

  return [rangeWithLocalDates, handleChange];
}
