import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getRoot,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
} from "lexical";
import { useEffect, JSX } from "react";
import { VARIABLE_NODE_TRANSFORMER } from "~/lib/ui/rich-text-editor/editor/plugins/variable-plugin/VariableNode";
import { RichTextEditorInsertAction } from "~/lib/ui/rich-text-editor";
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";

export const INSERT_TEMPLATE_COMMAND: LexicalCommand<RichTextEditorInsertAction> = createCommand();

export default function TemplatePlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand<RichTextEditorInsertAction>(
      INSERT_TEMPLATE_COMMAND,
      (payload) => {
        if (payload.clear) {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        }

        editor.update(() => {
          $getRoot();
          $convertFromMarkdownString(payload.content, [...TRANSFORMERS, VARIABLE_NODE_TRANSFORMER]);
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
