import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAPI } from "~/lib/api";
import { LoginLayout } from "./layout";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, LabelInput } from "~/lib/ui";
import z from "zod";
import { useFormState } from "~/lib/form-state";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { useMount } from "~/lib/lifecycle-helpers";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { ChangePasswordWithTokenOperationRequest } from "@apacta/sdk";

export default function CreatePassword() {
  const [mutationState, setMutationState] = useState<"success" | "failed" | undefined>();

  const { t } = useTranslation();
  const api = useAPI();
  const navigate = useNavigate();
  const [searchParams] = useTypedSearchParams<{ token: string }>();

  // Redirect to login page if the reset key is not present
  useMount(() => {
    if (searchParams.token === undefined) {
      navigate("/login", { replace: true });
    }
  });

  const changePasswordMutation = useMutation({
    mutationFn: (args: ChangePasswordWithTokenOperationRequest) =>
      api.changePasswordWithToken(args),
    onSettled: () => setMutationState("success"),
  });

  const form = useFormState({
    schema: {
      passwordOne: z.string().min(1),
      passwordTwo: z.string().min(1),
    },
    initialValues: {
      passwordOne: "",
      passwordTwo: "",
    },
    mutationError: changePasswordMutation.error,
  });

  /**
   * Verify the reset key before allowing the user to change their password
   * This is done to prevent users from changing their password without a valid reset key
   * This is also done to prevent users from changing their password if the reset key has expired
   */
  const verifyQuery = useQuery({
    enabled: searchParams.token !== undefined,
    queryKey: ["resetKey", searchParams.token],
    queryFn: () => api.verifyResetToken({ verifyResetTokenRequest: { token: searchParams.token } }),
    // Stale immediately to prevent the query _result_ from being cached
    // Avoids the token verification returning true after the token has expired
    staleTime: 0,
    // Garbage collect immediately to prevent the _query_ from being cached
    // Avoids the token verification returning true after the token has expired
    gcTime: 0,
    retry: 1, // Only retry once to avoid excessive waiting
  });

  const verified = verifyQuery.data?.success ?? false;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    // Safeguard to prevent submitting the form if the passwords do not match
    if (form.getValue("passwordOne") !== form.getValue("passwordTwo")) return;

    await changePasswordMutation.mutateAsync({
      changePasswordWithTokenRequest: {
        token: searchParams.token,
        passwordOne: form.getValue("passwordOne"),
        passwordTwo: form.getValue("passwordTwo"),
      },
    });
  };

  const passwordMatch = form.getValue("passwordOne") === form.getValue("passwordTwo");

  // Do not display page until the reset key has been verified
  if (verifyQuery.isFetching) {
    return <PageSpinner />;
  }

  const canSubmit = form.isValid && passwordMatch && verified;

  return (
    <LoginLayout>
      <div>
        <div className="relative mt-6">
          <h3>{t("ui:login.create_new_password", "Create new password")}</h3>
        </div>
      </div>

      <div>
        {mutationState === "failed" ? (
          <div className="pb-2 text-red-600">{t("common:password_change_failed")}</div>
        ) : null}
        {!verified && (
          <div>
            <div className="whitespace-pre-line pb-2 text-red-600">
              {t("common:reset_token_invalid")}
            </div>
            <Button variant="primary" onClick={() => navigate("/login/reset", { replace: true })}>
              {t("common:try_again")}
            </Button>
          </div>
        )}
      </div>

      <div className="mt-6">
        {mutationState === "success" ? (
          <div>
            <p>{t("common:password_change_success")}</p>
            <Button
              className="mt-4"
              variant="primary"
              onClick={() => navigate("/login", { replace: true })}
            >
              {t("ui:login.primary_button")}
            </Button>
          </div>
        ) : (
          <form className="-space-y-2" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4">
              <div className="pb-2">
                <LabelInput
                  required
                  id="password_one"
                  name="password_one"
                  type="password"
                  label={t("common:change_password")}
                  disabled={!verified}
                  onChange={(e) => form.setValues({ passwordOne: e.currentTarget.value })}
                />
              </div>
              <div>
                <LabelInput
                  required
                  id="password_two"
                  name="password_two"
                  type="password"
                  label={t("common:change_password_repeat")}
                  disabled={!verified}
                  onChange={(e) => form.setValues({ passwordTwo: e.currentTarget.value })}
                  error={!passwordMatch ? t("common:passwords_do_not_match") : undefined}
                />
              </div>
              <div className="flex gap-4">
                <Button
                  variant="tertiary"
                  disabled={!canSubmit}
                  loading={changePasswordMutation.isPending}
                  type="submit"
                >
                  {t("users:change_password")}
                </Button>
                <Button variant="secondary" onClick={() => navigate("/login", { replace: true })}>
                  {t("common:cancel")}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </LoginLayout>
  );
}
