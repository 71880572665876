import { Tooltip } from "~/lib/ui/tooltips/tooltip";
import { Icon } from "~/lib/ui";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { useTranslation } from "react-i18next";
import { useId } from "react";

export function DurationSection({ formState }: { formState: TaskFormState }) {
  const { t } = useTranslation();
  const durationId = useId();
  return (
    <div className="w-fit">
      <Tooltip
        delay={500}
        className="text-sm"
        trigger={
          <label
            htmlFor={durationId}
            className="flex cursor-pointer items-center border-b-2 border-b-transparent px-3 focus-within:cursor-auto focus-within:border-b-hover focus-within:bg-shade-100 hover:bg-shade-100"
          >
            <div className="w-32">
              <div className="flex w-full items-center gap-2">
                <span>{t("common:duration")}</span>
                <Icon name="tooltip" className="h-5 w-5" />
              </div>
            </div>
            <input
              id={durationId}
              type="number"
              placeholder="0"
              defaultValue={formState.getValue("duration")?.toString() ?? ""}
              onChange={(e) => formState.setValues({ duration: Number(e.currentTarget.value) })}
              className="hide-arrow w-20 cursor-pointer border-0 bg-transparent px-2 text-right text-sm font-semibold focus:cursor-auto focus:outline-none focus:ring-transparent"
            />
            <span>{t("common:hour", { count: 2 }).toLowerCase()}</span>
          </label>
        }
      >
        {t("planning:create_modal.estimate")}
      </Tooltip>
    </div>
  );
}
