import { SelectionCombobox, getSelectedIds } from "./selection-combobox";
import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Dialog } from "..";
import { CreateEmployeeDialog } from "~/pages/employees/_cmp/create-employee-dialog";
import { SelectionComboboxExtendable, ValueChangeHandler } from "./combobox-types";
import { GetUsersRequest, User } from "@apacta/sdk";

export function EmployeeSelection<D extends User = User>({
  requestOverride,
  ...props
}: SelectionComboboxExtendable<D> & {
  requestOverride?: GetUsersRequest;
}) {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [createQuery, setCreateQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchDialogOpen, setIsSearchdialogOpen] = useState(false);

  const api = useAPI();
  const vcRef = useRef<ValueChangeHandler | undefined>(undefined); // value change ref

  // Array of ids to select. Undefined if searchquery is set
  const idParam = getSelectedIds({
    value: props.value,
    defaultValue: props.defaultValue,
    isSearchDialogOpen,
  });

  const uq = useQuery({
    queryKey: ["users", searchQuery, requestOverride, idParam],
    queryFn: () =>
      api.getUsers({
        ids: idParam,
        q: searchQuery,
        isActive: true,
        limit: 1000,
        ...requestOverride,
      }),
  });
  const items = uq.data?.data || [];

  const pendingSelection = useRef<string | undefined>(undefined);
  async function handleCreated(userId: string) {
    pendingSelection.current = userId;
    uq.refetch();
  }

  // Set the value after the data has been loaded
  useEffect(() => {
    if (pendingSelection.current === undefined) return;
    vcRef.current?.(pendingSelection.current);
    pendingSelection.current = undefined;
  }, [items]);

  return (
    <div>
      <SelectionCombobox<D>
        {...props}
        data={items as Array<D>}
        valueFn={(user) => user.id}
        valueChangeRef={vcRef}
        labelFn={(user) => user.fullName}
        loading={uq.isFetching}
        onQueryChange={setSearchQuery}
        onOpenChange={setIsSearchdialogOpen}
        onCreateNew={(query) => {
          setCreateQuery(query);
          setIsCreateDialogOpen(true);
        }}
      />
      <Dialog
        open={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        render={({ onClose }) => (
          <CreateEmployeeDialog
            onEmployeeCreated={handleCreated}
            onClose={onClose}
            firstName={createQuery}
          />
        )}
      />
    </div>
  );
}
