import { forwardRef, HTMLProps, Ref, useId } from "react";
import { Input } from "./input";
import { Label } from "../label";
import { twMerge } from "tailwind-merge";
import { FieldError } from "./field-error";

export type LabelInputProps = HTMLProps<HTMLInputElement> & {
  label?: string;
  labelClassName?: string;
  error?: string;
  containerClassName?: string;
};

/**
 * A combination of a label and an input field. Use this if you want to add a label to an input field.
 */
export const LabelInput = forwardRef(function LabelInputInner(
  props: LabelInputProps,
  inputRef: Ref<HTMLInputElement>
) {
  const id = useId();
  const { label, labelClassName, required, containerClassName, ...restProps } = props;
  const error = props.error;

  return (
    <div className={twMerge("w-full", containerClassName)}>
      {label && (
        <Label htmlFor={id} required={required} className={labelClassName}>
          {label}
        </Label>
      )}
      <Input
        id={id}
        {...restProps}
        ref={inputRef}
        className={twMerge(error && "border-error", props.className)}
      />
      <FieldError error={props.error} />
    </div>
  );
});
