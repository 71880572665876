import { Ref, forwardRef } from "react";
import { Button } from "./button";
import { Link } from "react-router";

interface LinkedButtonProps extends Omit<Parameters<typeof Button>[0], "onClick"> {
  to: Parameters<typeof Link>[0]["to"];
  linkOptions?: Omit<Parameters<typeof Link>[0], "to">;
}

export const LinkedButton = forwardRef(function LinkedButtonInner(
  { to, linkOptions, ...props }: LinkedButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <Link to={to} {...linkOptions}>
      <Button ref={ref} {...props} />
    </Link>
  );
});
