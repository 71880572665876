import {
  EditUserRequestCustomIntegrationSettingsInner,
  Integration,
  IntegrationSetting,
  User,
} from "@apacta/sdk";
import { IntegrationSettingInput } from "~/pages/employees/_cmp/integration-setting-input";
import { useState } from "react";

/**
 * Component for managing user integration settings and displaying integration types
 * @param user - User object containing integration settings
 * @param onChange - triggered when integration setting value is updated
 */
export function IntegrationTypes({
  user,
  onChange,
}: {
  user: User;
  onChange: (value: Array<EditUserRequestCustomIntegrationSettingsInner>) => void;
}) {
  const [settings, setSettings] = useState<Array<EditUserRequestCustomIntegrationSettingsInner>>(
    user.integrationSettings ?? []
  );

  /**
   * Updates or creates an integration setting for a user
   * @param integrationSettingId - Unique identifier for the integration setting
   * @param value - New value to be set for the integration setting
   */
  const updateSettings = (integrationSettingId: string, value: string): void => {
    setSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((setting) =>
        setting.id === integrationSettingId ? { ...setting, value } : setting
      );

      if (!updatedSettings.some((setting) => setting.id === integrationSettingId)) {
        updatedSettings.push({ id: integrationSettingId, value });
      }

      onChange(updatedSettings);
      return updatedSettings;
    });
  };
  /**
   * Renders input fields for each integration setting
   * @param integrationSettings - Array of integration settings to render
   * @returns Array of IntegrationSettingInput components
   */
  const renderIntegrationSettingInputs = (integrationSettings: Array<IntegrationSetting>) =>
    integrationSettings?.map((setting) => {
      const initialIntegrationValue =
        settings.find((s) => s.id === setting.id)?.value || setting?.users?.[0]?.joinData?.value;

      return (
        <div key={setting.id}>
          <IntegrationSettingInput
            onChange={(value) => updateSettings(setting.id, value as string)}
            initialIntegrationValue={initialIntegrationValue}
            integrationSetting={setting}
          />
        </div>
      );
    });
  /**
   * Renders individual integration cards with their settings
   * @param integrations - Array of integrations to render
   * @returns Array of integration card components
   */
  const renderIntegrations = (integrations: Array<Integration>) =>
    integrations.map((integration) => (
      <div
        key={integration.id}
        className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row"
      >
        <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
          {integration.integrationSettings && (
            <>
              <div className="flex flex-col">
                <div className="text-lg">{integration.name}</div>
                <div className="text-sm font-light">{integration.description}</div>
              </div>
              <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                {renderIntegrationSettingInputs(integration.integrationSettings)}
              </div>
            </>
          )}
        </div>
      </div>
    ));
  /**
   * Renders all integration types except ERP
   * Groups integrations by their type and displays them in sections
   * @returns Array of integration type sections with their respective integrations
   */
  const renderIntegrationTypes = () =>
    user.integrationTypes
      ?.filter((type) => type.identifier !== "erp")
      .map((type) => (
        <div key={type.id}>
          <div className="heading-page-title mb-2 text-gray-500">{type.name}</div>
          <div className="heading-page-title font-light">{type.description}</div>
          {renderIntegrations(type.integrations ?? [])}
        </div>
      ));

  return (
    <>
      <div className="flex h-full flex-col gap-8">
        <div className="flex flex-col gap-8 md:flex-row">
          <div className="flex-1">{renderIntegrationTypes()}</div>
        </div>
      </div>
    </>
  );
}
