import { toLocalDateString } from "~/lib/utils/date";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";

// Thin wrapper for search-params with a few helpers
export function useInvoicesParams() {
  const [invoicesParams, setInvoicesParams] = useTypedSearchParams<{
    startDate?: string;
    endDate?: string;
  }>();

  // No defaults for invoice table
  const defaultFromDate = undefined;
  const defaultToDate = undefined;

  // Parse dates with fallback to defaults
  const startDate = invoicesParams.startDate ? new Date(invoicesParams.startDate) : defaultFromDate;
  const endDate = invoicesParams.endDate ? new Date(invoicesParams.endDate) : defaultToDate;

  function handlePeriodChange(start?: Date, end?: Date) {
    setInvoicesParams("startDate", start ? toLocalDateString(start) : undefined);
    setInvoicesParams("endDate", end ? toLocalDateString(end) : undefined);
  }

  function handleUnsetPeriod() {
    setInvoicesParams("startDate", undefined);
    setInvoicesParams("endDate", undefined);
  }

  function handleResetPeriod(): void {
    setInvoicesParams("startDate", toLocalDateString(defaultFromDate));
    setInvoicesParams("endDate", toLocalDateString(defaultToDate));
  }

  return {
    handlePeriodChange,
    setInvoicesParams,
    handleUnsetPeriod,
    handleResetPeriod,
    startDate,
    endDate,
  };
}
