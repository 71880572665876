import { useTranslation } from "react-i18next";
import { KPICardWrapper, KPICardWrapperProps } from "../_cmp/kpi-card-wrapper";
import { FormatValue } from "../_cmp/FormatValue";
import { KPIRatioView } from "../_cmp/kpi-ratio-view";
import { getIcon } from "~/lib/ui";

export function HourConsumptionCardFixed({
  hoursCostPriceDelivered = 0,
  hoursCostPriceEstimated = 0,
  isProjectFinished,
  ...kpiWrapperProps
}: KPICardWrapperProps & {
  hoursCostPriceEstimated?: number;
  hoursCostPriceDelivered?: number;
  isProjectFinished: boolean;
}) {
  const { t } = useTranslation();

  const costRemainder = (hoursCostPriceEstimated ?? 0) - (hoursCostPriceDelivered ?? 0);
  const costPercent = ((hoursCostPriceDelivered ?? 0) / (hoursCostPriceEstimated ?? 0)) * 100;
  let remainderClass = "";
  let overShootClass = "";

  let rightLabel = t("common:not_delivered");
  if (isProjectFinished) {
    const isProjectOverPerforming = hoursCostPriceDelivered <= hoursCostPriceEstimated;
    if (isProjectOverPerforming) {
      rightLabel = t("finance:surplus");
      remainderClass = "bg-green-600";
    } else {
      rightLabel = t("finance:deficit");
      overShootClass = "bg-red-600";
    }
  }

  return (
    <KPICardWrapper
      label={t("common:work_hour_usage", "Hours")}
      href="./finance/hours"
      Icon={getIcon("hours")}
      {...kpiWrapperProps}
    >
      <div className="flex flex-row items-center justify-end gap-x-2">
        <div className="text-md text-gray-400">{t("common:estimated")}</div>
        <div className="text-xl font-bold">
          <FormatValue value={hoursCostPriceEstimated} unit="currency" />
        </div>
      </div>

      <KPIRatioView
        left={{
          label: t("common:delivered"),
          value: hoursCostPriceDelivered,
          percent: costPercent ?? 0,
        }}
        right={{
          label: rightLabel,
          value: Math.abs(costRemainder),
        }}
        unit="currency"
        barClasses={{
          overshoot: overShootClass,
          remainder: remainderClass,
        }}
      />
    </KPICardWrapper>
  );
}
