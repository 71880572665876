import { EditorConfig, ParagraphNode, SerializedParagraphNode } from "lexical";

export class DatasetParagraphNode extends ParagraphNode {
  static getType() {
    return "dataset-paragraph";
  }

  static clone(node: ParagraphNode) {
    return new DatasetParagraphNode(node.__key);
  }

  createDOM(config: EditorConfig) {
    const dom: HTMLElement = super.createDOM(config);
    dom.dataset.pdfRule = "no-split";
    return dom;
  }

  static importJSON(serializedNode: SerializedParagraphNode): ParagraphNode {
    return ParagraphNode.importJSON(serializedNode);
  }

  exportJSON(): SerializedParagraphNode {
    return {
      ...super.exportJSON(),
      type: DatasetParagraphNode.getType(),

      version: 1,
    };
  }
}
