import { ProcurementKpiCard } from "~/pages/procurement/_cmp/kpi/procurement-kpi-card";
import { formatCurrency } from "~/lib/utils/number";
import { useTranslation } from "react-i18next";
import { useGraphQL } from "~/lib/gql";
import { useQuery } from "@tanstack/react-query";
import { VendorProduct } from "@apacta/sdk";
import { useOutletContext } from "react-router";
import { CACHE_VENDOR_PRODUCT } from "~/pages/products/vendor-products/[id]";

export function ProcurementSection() {
  const { vendorProduct } = useOutletContext<{ vendorProduct: VendorProduct }>();

  const { t } = useTranslation();
  const sdk = useGraphQL();

  const dataQ = useQuery({
    queryKey: [CACHE_VENDOR_PRODUCT, vendorProduct.id, "procurement"],
    queryFn: () =>
      sdk.vendorProductMarketPriceQuery({
        id: vendorProduct?.id as string,
      }),
  });

  const data = dataQ.data?.vendorProduct?.marketPrices;

  if (!vendorProduct) return null;

  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      <ProcurementKpiCard
        value={data?.min ?? 0}
        valueRender={(value) => formatCurrency(value)}
        title={t("products:procurement.lowest_market_price")}
        description={t("products:procurement.lowest_market_price.description")}
        iconName="currency"
      />
      <ProcurementKpiCard
        value={data?.avg ?? 0}
        valueRender={(value) => formatCurrency(value)}
        title={t("products:procurement.average_market_price")}
        description={t("products:procurement.average_market_price.description")}
        iconName="currency"
      />
    </div>
  );
}
