import { Icon } from "~/lib/ui";
import { Popover } from "~/lib/ui/popover/popover";
import { ReactNode, useId, useState } from "react";
import { twMerge } from "tailwind-merge";
import { HorizontalDivider } from "~/lib/ui/horizontal-divider";
import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";

type LineFeedProps<T> = {
  lines: Array<T>;
  renderLine: (line: T, isInitial: boolean) => ReactNode;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
};

// TODO: Use this in activity log sections instead of reinventing the wheel

/**
 * Line Feed component
 * Renders a list of lines with dividers between them hidden behind a popover
 * Automatically shows the first line as the default line
 * If no lines are provided, nothing is rendered
 * @param {Array<T>} lines - Array of lines to render in the order received
 * @param {(line: T) => ReactNode} renderLine - Function to render each line
 * @param {string} label - Label to show above the line feed in the popover
 * @param {boolean} loading - If true, shows a loading skeleton otherwise shows the first line
 * @param {boolean} disabled - If true, disables the popover from being opened
 */
export function LineFeed<T>({ lines, renderLine, label, loading, disabled }: LineFeedProps<T>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const id = useId();

  if (!lines.length) return null;

  return (
    <Popover
      triggerAsChild
      onOpenChange={setIsOpen}
      disabled={disabled}
      config={{
        align: "start",
        side: "bottom",
        contentClassName: "w-[--radix-popover-trigger-width]",
      }}
      triggerRender={() => (
        <div className="cursor-pointer select-none items-center rounded-lg border bg-white p-4 shadow-sm hover:border-hover">
          {loading ? (
            <ContentLoader className="h-5 w-full rounded-md">
              <rect x={0} y={0} width="100%" height="100%"></rect>
            </ContentLoader>
          ) : (
            <div className="flex w-full items-center justify-between">
              <div>{renderLine(lines[0], true)}</div>
              <div className="flex grow items-center justify-end gap-2 text-sm">
                <div>{t("common:show_all")}</div>
                <div>
                  {isOpen ? (
                    <Icon name="chevronUp" className="h-auto w-4" />
                  ) : (
                    <Icon name="chevronDown" className="h-auto w-4" />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    >
      {(close) => (
        <div className="planning-scrollbar max-h-72 overflow-y-auto rounded-lg border bg-white p-4 shadow-sm">
          {!!label && (
            <div className="pb-4">
              <HorizontalDivider lineClassName="border-shade-200">
                <span className="shrink-0 text-sm font-semibold">{label}</span>
              </HorizontalDivider>
            </div>
          )}
          <ul role="list" className="">
            {lines.map((line, index) => (
              <li
                key={`line-feed-${id}-${index}`}
                className={twMerge(
                  index !== lines.length - 1 &&
                    // Create the vertical line between the actions
                    // Calc explanation: 0.75rem is half of the icon width and padding used in ActivityLogLine
                    // 1px is to compensate for half the width of the after:width of 0.5 (2px)
                    // If you change the icon width or padding, you will need to update the rem value
                    // We are doing this differently than Tailwind, because we do not like the relative positioning of this element
                    "after:contents-[''] after:my-1 after:ml-[calc(0.75rem+1px)] after:block after:h-4 after:w-0.5 after:bg-shade-200"
                )}
              >
                {renderLine(line, false)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Popover>
  );
}
