import { fromLocalDateString, toLocalDateString } from "@apacta/sdk";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";

export const ENTIRE_PERIOD = "now";

// Thin wrapper for search-params with a few helpers
export function useProjectParams() {
  const [searchParams, setSearchParams] = useTypedSearchParams<{
    period?: string;
    activities?: Array<string>;
    isInvoiced?: boolean;
  }>();

  function setPeriod(start?: Date, end?: Date) {
    if (start === undefined && end === undefined) {
      setSearchParams("period", undefined);
      return;
    }
    const newPeriod = makePeriodString(
      toLocalDateString(start),
      end ? toLocalDateString(end) : undefined
    );
    setSearchParams("period", newPeriod);
  }

  const [startDateString, endDateString] = parsePeriodString(searchParams.period);

  return {
    setPeriod,
    startDate: startDateString ? fromLocalDateString(startDateString) : undefined,
    endDate: endDateString ? fromLocalDateString(endDateString) : undefined,
    activities: searchParams.activities,
    isInvoiced: searchParams.isInvoiced,
    setSearchParams,
    periodString: makePeriodString(startDateString, endDateString),
  };
}

function parsePeriodString(period?: string): [string?, string?] {
  if (!period || period === ENTIRE_PERIOD) return [undefined, undefined];
  const list = period?.split(",");
  if (!list[0]) throw new Error("Received an invalid period string");
  const start = list[0];
  const end = list[1] ? list[1] : list[0]; // Important to handle single-day periods
  return [start, end];
}

export function makePeriodString(startStr?: string, endStr?: string) {
  if (!startStr && !endStr) {
    return ENTIRE_PERIOD;
  }

  if (!endStr) {
    return startStr;
  }
  return `${startStr},${endStr}`;
}
