import { useTranslation } from "react-i18next";
import { KPICardWrapper, KPICardWrapperProps } from "../_cmp/kpi-card-wrapper";
import { FormatValue } from "../_cmp/FormatValue";
import { KPIRatioView } from "../_cmp/kpi-ratio-view";
import { getIcon } from "~/lib/ui";

export function TotalVariableClosedCard({
  salesPriceCurrent = 0,
  costPriceCurrent = 0,
  contributionMargin = 0,
  label,
  ...kpiWrapperProps
}: KPICardWrapperProps & {
  salesPriceCurrent?: number;
  costPriceCurrent?: number;
  contributionMargin?: number;
  label?: string;
}) {
  const { t } = useTranslation();
  return (
    <KPICardWrapper
      label={label ?? t("common:total")}
      Icon={getIcon("currency")}
      {...kpiWrapperProps}
    >
      <div className="flex flex-row items-center justify-end gap-x-2">
        <span className="text-md text-gray-400">{t("finance:sales_price")}</span>
        <span className="text-xl font-bold">
          <FormatValue value={salesPriceCurrent} unit="currency" />
        </span>
      </div>

      <KPIRatioView
        left={{
          label: t("finance:cost_price"),
          value: costPriceCurrent ?? 0,
          percent: ((costPriceCurrent ?? 0) / salesPriceCurrent) * 100,
        }}
        right={{
          label: t("finance:gross_profit"),
          value: contributionMargin,
          percent: (contributionMargin / salesPriceCurrent) * 100,
        }}
        unit="currency"
        extraUnits={[]}
        barClasses={{
          primary: "bg-gray-300",
          remainder: "bg-green-600",
        }}
      />
    </KPICardWrapper>
  );
}
