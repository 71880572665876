import { EditUserOperationRequest, LabelEntity, ListUsersDirectionEnum, User } from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { twMerge } from "tailwind-merge";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToasts } from "~/lib/toast/use-toasts";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { Button, Dialog, getIcon } from "~/lib/ui";
import { captureException } from "~/lib/error-reporting";
import { CreateEmployeeDialog } from "~/pages/employees/_cmp/create-employee-dialog";
import { ManageSeatsDialog } from "~/pages/employees/_cmp/manage-seats-dialog";
import EmployeesKpiCards from "~/pages/employees/_cmp/employees-kpi-cards";
import { FilterGroupBoolean } from "~/lib/ui/filters/filter-group-boolean";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useEmployeesParams } from "~/pages/employees/_cmp/use-users-params";
import { CACHE_EMPLOYEES } from "~/pages/employees";
import { useState } from "react";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";
import { FilterGroupLabels } from "~/lib/ui/filters/filter-group-labels";
import { UICategoryLabel } from "~/lib/ui/category-label/u-i-category-label";
import { LabelColor } from "~/lib/utils/colors";
import { Popover } from "~/lib/ui/popover/popover";
import { Boundary } from "~/lib/ui/boundary";
import { useMe } from "~/lib/auth/use-me";
import { CACHE_ME } from "~/lib/auth/context";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { EmployeeCell } from "~/lib/ui/table/cells/employee-cell";
import { useFeatureFlags } from "~/lib/feature-flags";
import { useRoles } from "~/lib/auth/use-roles";
import { KPISkeletonItem } from "~/lib/ui/skeleton/kpi-skeleton";

export default function UsersPage() {
  const api = useAPI();
  const navigate = useNavigate();
  const { show: addToast } = useToasts();

  const me = useMe();

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const activeUsers = me?.subscription.activeUsers;
  const totalSeats = me?.subscription.totalSeats;
  const availableSeats = totalSeats - activeUsers;
  const features = useFeatureFlags();
  const roles = useRoles();
  const allowNoSubscription = features.has("allow_no_subscription");
  const apactaCompany = roles.has("admin");
  const ableToCreateEmployee = availableSeats > 0 || allowNoSubscription || apactaCompany;

  const employeesParams = useEmployeesParams();
  const [previewEmployeeURL, setPreviewEmployeeURL] = useState<string | undefined>();

  const [createEmployeeDialogOpen, setCreateEmployeeDialogOpen] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useTypedSearchParams<{
    labels?: Array<string>;
    isActive?: boolean;
  }>();

  const columns = useDataColumns<User>((columnHelper) => [
    columnHelper.accessor("fullName", {
      header: t("common:name"),
      cell: ({ row }) => <EmployeeCell user={row.original} />,
      enableSorting: true,
      sortDescFirst: true,
      meta: {
        skeletonType: "avatar",
      },
    }),
    columnHelper.accessor("email", {
      header: t("common:email_address"),
      cell: ({ row }) => (
        <div className={twMerge("flex flex-col", !row.original.email && "text-gray-400")}>
          {row.original.email ? (
            <span>{row.original.email}</span>
          ) : (
            <span>{t("common:not_available")}</span>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("phone", {
      header: t("common:phone_number"),
      cell: ({ row }) => (
        <div className={twMerge("flex flex-col", !row.original.mobile && "text-gray-400")}>
          {row.original.mobile ? (
            <span>{row.original.mobile}</span>
          ) : (
            <span>{t("common:not_available")}</span>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("labels", {
      header: t("common:label", { count: 2 }),
      cell: ({ row }) => renderLabels(row.original),
    }),
    columnHelper.accessor("isActive", {
      header: t("common:active"),
      cell: ({ row }) => (
        <div className="flex justify-center">
          <div
            className={twMerge(
              "h-4 w-4 rounded-full",
              row.original.isActive ? "bg-success" : "bg-error"
            )}
          ></div>
        </div>
      ),
      meta: { className: "text-center" },
    }),
    columnHelper.display({
      id: "actions",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              Icon: getIcon("preview"),
              label: t("common:preview"),
              onClick: () => setPreviewEmployeeURL(row.original.previewPdf ?? undefined),
            },
            {
              Icon: getIcon("playCircle"),
              label: t("users:activate_tooltip_message"),
              onClick: () => handleUserActivation(row.original),
              isHidden: row.original.isActive || row.original.id === me?.company.contactPersonId,
            },
            {
              Icon: getIcon("pauseCircle"),
              label: t("users:deactivate_tooltip_message"),
              onClick: () => handleUserActivation(row.original),
              isHidden: !row.original.isActive || row.original.id === me?.company.contactPersonId,
            },
          ]}
        />
      ),
    }),
  ]);

  const tableState = useDataTableState();

  const dataQ = useQuery({
    queryKey: [
      CACHE_EMPLOYEES,
      tableState.pageNumber,
      tableState.sortBy,
      tableState.sortingDirection,
      tableState.state.search,
      searchParams,
      employeesParams,
    ],
    queryFn: () =>
      api.listUsers({
        page: tableState.pageNumber,
        limit: tableState.state.pagination.pageSize,
        q: tableState.state.search,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection as ListUsersDirectionEnum,
        isActive: searchParams.isActive,
        ids: employeesParams.employeeIds,
        startDate: employeesParams.startDate,
        endDate: employeesParams.endDate,
        labels: searchParams.labels,
      }),
  });

  const table = useDataTable(
    {
      columns,
      data: dataQ.data?.data,
      tableState,
      isLoading: dataQ.isLoading,
      getRowId: (row) => row.id,
      backendPagination: dataQ.data?.pagination,
    },
    {
      enableGlobalFilter: true,
      enableFilters: true,
    }
  );

  // Resets when either/create or edit modal is closed
  async function handleSeatsUpdate() {
    await queryClient.invalidateQueries({
      queryKey: [CACHE_EMPLOYEES],
    });
    await queryClient.invalidateQueries({
      queryKey: [CACHE_ME],
    });
  }

  const userActivation = useMutation({
    mutationFn: (args: EditUserOperationRequest) => api.editUser(args),
    onError: (error) => {
      addToast({
        title: t("users:user_activation_error_title"),
        description: t("users:user_activation_error_description"),
        iconName: "errorCircle",
        variant: "error",
      });
      captureException(error);
    },
    onSuccess: async () => {
      await handleSeatsUpdate();
    },
  });

  const handleUserActivation = async (user: User) => {
    if (!user) return;
    await userActivation
      .mutateAsync({
        userId: user.id as string,
        editUserRequest: {
          isActive: !user.isActive,
        },
      })
      .then(() => {
        if (!!user.isActive) {
          addToast({
            title: t("users:access_deactivated_title"),
            description: t("users:access_deactivated_message"),
            iconName: "infoCircle",
            variant: "warning",
          });
        }
      });
  };

  const renderLabels = (employee: User) => (
    <div className="flex max-w-56 flex-wrap gap-1">
      {employee.labels &&
        employee.labels
          .sort((first: LabelEntity, next: LabelEntity) => (first.text > next.text ? 1 : -1))
          .slice(0, 2)
          .map((label: LabelEntity) => (
            <UICategoryLabel
              id={label.id}
              key={employee.id + label.id}
              readonly
              text={label.text ?? ""}
              bgColor={label.bgColor as LabelColor}
            />
          ))}
      {employee.labels && employee.labels.length > 2 && (
        <Popover
          triggerAsChild={true}
          triggerRender={() =>
            employee.labels &&
            employee.labels.length && (
              <Button variant="secondary" size="small" className="text-xs">
                {`+${employee.labels.length - 2}`}
              </Button>
            )
          }
        >
          {() => (
            <div className="min-w-[10em] rounded-lg border bg-white p-2 text-base shadow-md">
              {employee.labels
                ?.slice(2, employee.labels.length)
                .map((label: LabelEntity) => (
                  <UICategoryLabel
                    id={label.id}
                    key={employee.id + label.id}
                    readonly
                    text={label.text ?? ""}
                    bgColor={label.bgColor as LabelColor}
                  />
                ))}
            </div>
          )}
        </Popover>
      )}
    </div>
  );

  async function handleCreated(userId: string) {
    // Navigate first, then invalidate the cache
    navigate(userId);
    handleSeatsUpdate();
  }

  return (
    <div className="mb-6">
      <TabHeading
        subtitle={
          <>
            {activeUsers}/{totalSeats} {t("users:seats_available", "seats available")}
          </>
        }
        actionArea={
          <div className="flex w-full flex-row gap-5">
            <Dialog
              trigger={
                <Button
                  disabled={allowNoSubscription || apactaCompany}
                  variant="secondary"
                  className="print:hidden"
                >
                  {t("users:adjust_seats", { defaultValue: "Adjust seats" })}
                </Button>
              }
              render={({ onClose }) => (
                <ManageSeatsDialog
                  previousAvailableSeats={totalSeats}
                  usedSeats={activeUsers}
                  subscription={me?.subscription}
                  onSeatsUpdated={async () => {
                    await handleSeatsUpdate();
                    onClose();
                  }}
                />
              )}
            />
            <Tooltip
              disabled={ableToCreateEmployee}
              trigger={
                <Button
                  disabled={!ableToCreateEmployee}
                  onClick={() => setCreateEmployeeDialogOpen(true)}
                  variant="tertiary"
                  className="print:hidden"
                >
                  {t("users:create")}
                </Button>
              }
            >
              {t("users:create_disabled_warning")}
            </Tooltip>
            <Dialog
              open={createEmployeeDialogOpen}
              onOpenChange={setCreateEmployeeDialogOpen}
              render={({ onClose }) => (
                <CreateEmployeeDialog onEmployeeCreated={handleCreated} onClose={onClose} />
              )}
            />
          </div>
        }
      >
        {t("common:employees")}
      </TabHeading>
      <div className="mt-6 w-full">
        <Boundary
          variant="element"
          suspenseProps={{
            fallback: (
              <div className="flex w-full grow flex-col gap-5 pb-5 sm:flex-row">
                <KPISkeletonItem />
                <KPISkeletonItem />
                <KPISkeletonItem />
                <KPISkeletonItem />
              </div>
            ),
          }}
        >
          <EmployeesKpiCards />
        </Boundary>
      </div>
      <DataTable
        table={table}
        error={dataQ.error}
        renderFilters={() => (
          <>
            <FilterGroupBoolean
              name={t("users:filters.active", "Active")}
              value={searchParams.isActive}
              onClear={() => {
                setSearchParams("isActive", undefined);
              }}
              onConfirmSelection={(active) => setSearchParams("isActive", active)}
            />
            <FilterGroupLabels
              value={searchParams.labels}
              onConfirmSelection={(selectedLabels) => {
                setSearchParams(
                  "labels",
                  selectedLabels.map((label) => label.id)
                );
              }}
              onClear={() => setSearchParams("labels", undefined)}
            />
          </>
        )}
      />
      <FullScreenFilePreview
        open={!!previewEmployeeURL}
        fileUrl={previewEmployeeURL}
        onClose={() => setPreviewEmployeeURL(undefined)}
      />
    </div>
  );
}
