import { Button, getIcon } from "~/lib/ui";
import { MouseEvent, ReactNode, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";

/**
 * Button used to navigate back in the router history.
 * Utilizes the location key to determine if the user can go back.
 * @param onBackOverride - Optional callback to override the default back behavior.
 * @param hidden - Optional flag to hide the button.
 * @param children - Optional children to render inside the button.
 * React Router V7 Docs: https://api.reactrouter.com/v7/interfaces/react_router.Location.html#key
 * "...This value is always "default" on the initial location..."
 */
export function PageBackButton({
  onBackOverride,
  hidden = false,
  children,
}: {
  onBackOverride?: (e?: MouseEvent<HTMLButtonElement>) => void;
  hidden?: boolean;
  children?: ReactNode;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const canGoBack = useMemo(() => {
    const isRootPage = location.pathname.split("/").length === 2;

    // If the location key is "default" or the page is a root page, the user cannot go back.
    return location.key !== "default" && !isRootPage;
  }, [location]);

  const handleGoBack = (e?: MouseEvent<HTMLButtonElement>) => {
    if (onBackOverride) {
      onBackOverride(e);
    } else {
      if (!canGoBack) return;
      navigate(-1);
    }
  };

  if ((!canGoBack && !onBackOverride) || hidden) {
    return null;
  }

  return (
    <Button variant="secondary" Icon={getIcon("back")} onClick={handleGoBack}>
      {children}
    </Button>
  );
}
