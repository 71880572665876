import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { DNDUpload } from "~/lib/ui/d-n-d-upload";
import { getIcon, Icon, Label } from "~/lib/ui";
import { useState } from "react";
import { MediaPreviewContainer } from "~/lib/ui/media/media-preview-container";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { UploadButton } from "~/lib/ui/buttons/upload-button";
import { IStandardFileUploadTypeEnum } from "@apacta/sdk";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";

const DEFAULT_FILE_TYPE = "quote";

export default function CreateStandardFileDialog({
  cacheKey,
  onClose,
}: {
  cacheKey: string;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const [entity, setEntity] = useState<IStandardFileUploadTypeEnum>(DEFAULT_FILE_TYPE);
  const [filesToUpload, setFilesToUpload] = useState<Array<File>>([]);

  const handleSubmit = async () => {
    await createStandardFileMutation.mutateAsync({ type: entity, file: filesToUpload[0] });
    await queryClient.invalidateQueries({ queryKey: [cacheKey] });
    onClose();
  };

  const handleRemoveFile = () => {
    setFilesToUpload([]);
  };

  const createStandardFileMutation = useMutation({
    mutationFn: (data: { type: IStandardFileUploadTypeEnum; file: File }) =>
      api.iStandardFileUpload({ type: data.type, file: data.file }),
  });

  const standardFileTypeOptions: Array<{ label: string; value: IStandardFileUploadTypeEnum }> = [
    { label: t("settings:standard_files.availability.quote"), value: "quote" },
    { label: t("settings:standard_files.availability.estimate"), value: "estimate" },
    { label: t("settings:standard_files.availability.invoice"), value: "invoice" },
  ];

  return (
    <div>
      <DialogHeader
        title={t("common:create", {
          entity: t("common:standard_file", { count: 1 }).toLowerCase(),
        })}
        Icon={getIcon("file")}
      />
      <div className="mb-12 flex flex-col gap-4">
        <div>
          <SelectionCombobox
            required
            label={t("common:availability")}
            data={standardFileTypeOptions}
            defaultValue={entity}
            valueFn={(v) => v.value}
            labelFn={(v) => v.label}
            onSelectValue={(value) =>
              setEntity((value ?? DEFAULT_FILE_TYPE) as IStandardFileUploadTypeEnum)
            }
          />
        </div>
        <div>
          <Label>{t("common:file", { count: 1 })}</Label>
          {filesToUpload.length ? (
            <div>
              {filesToUpload.map((file) => (
                <MediaPreviewContainer
                  key={file.name}
                  file={file}
                  onRemoveFile={handleRemoveFile}
                />
              ))}
            </div>
          ) : (
            <DNDUpload
              onSelected={(files) => setFilesToUpload(Array.from(files))}
              renderArea={({ onClick, isOver }) => (
                <div
                  className={twMerge(
                    isOver ? "border-hover text-hover" : "border-shade-400 text-shade-400",
                    "group flex h-72 items-center justify-center rounded-lg border-2 border-dashed transition-colors duration-300 ease-in-out"
                  )}
                >
                  <div className="flex flex-col items-center gap-4">
                    <div className="flex flex-col items-center gap-2">
                      <div>
                        <Icon
                          name="upload"
                          className={twMerge(
                            isOver ? "text-hover" : "text-primary",
                            "transition-color h-24 w-24 duration-300 ease-in-out"
                          )}
                        />
                      </div>
                      <div>{t("ui:dnd.drag_drop_files_upload", { count: 1 })}</div>
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="w-24">
                        <hr className="border-t-1 border-shade-400" />
                      </div>
                      {t("common:or").toUpperCase()}
                      <div className="w-24">
                        <hr className="border-t-1 border-shade-400" />
                      </div>
                    </div>
                    <div>
                      <UploadButton variant="primary" onSelected={onClick}>
                        {t("ui:select_file", { count: 1 })}
                      </UploadButton>
                    </div>
                  </div>
                </div>
              )}
            />
          )}
        </div>
      </div>
      <DialogFooter
        primary={{
          label: t("common:create", {
            entity: t("common:standard_file", { count: 1 }).toLowerCase(),
          }),
          disabled: filesToUpload.length !== 1,
          onClick: () => handleSubmit(),
        }}
        onClose={onClose}
      />
    </div>
  );
}
