/** Hack to update the search params without tripping up React and React Router
 *
 * This is used for our DataTable component to update the search params without causing a re-render.
 * React Router kept throwing up errors in the console if we use their setSearchParams function directly.
 */
export function setWindowSearchParam(key: string, value: string | undefined) {
  const url = new URL(window.location.toString());
  const currentValue = url.searchParams.get(key);
  if (currentValue === value) {
    // Safe-guard to spare the browser API from unnecessary work
    console.debug(`[set-window-search-param] ignoring redundant value change: ${key}=${value}`);
    return;
  }
  if (value === undefined || value === "") {
    url.searchParams.delete(key);
  } else {
    url.searchParams.set(key, value);
  }
  window.history.replaceState(null, "", url.toString());
}
