import { and, ControlProps, rankWith } from "@jsonforms/core";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";

import { isProductEntity } from "./testers";
import { Label } from "~/lib/ui";
import { useTranslation } from "react-i18next";

// Shows search input if no product_id, otherwise shows product name

export function ProductRenderer(props: ControlProps) {
  return (
    <div className="w-96">{props.data ? <ProductItemView productId={props.data} /> : null}</div>
  );
}
function ProductItemView(props: { productId: string }) {
  const api = useAPI();
  const { t } = useTranslation();
  const productId = props.productId;
  const q = useSuspenseQuery({
    queryKey: ["product", props.productId],
    queryFn: () =>
      api.productsView({
        productId,
      }),
  });

  return (
    <div className="flex flex-1 flex-row gap-4">
      <img src={q.data.data.imageUrl ?? "/image_not_found.jpg"} className="h-16 w-16" />
      <div className="flex flex-col gap-1.5">
        <Label>{t("common:product", { count: 1 })}</Label>
        <div>{q.data.data.name}</div>
      </div>
    </div>
  );
}
export const productTester = rankWith(4, and(isProductEntity()));
