import { User } from "@apacta/sdk";
import { generateBackgroundColor } from "~/lib/utils/colors";
import colors from "tailwindcss/colors";
import { EmployeeCard } from "~/pages/planning/_cmp/employee-card";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, usePlanning } from "~/lib/planning";
import DropCell from "../drop-cell";
import { getDateWithoutTime } from "~/lib/utils/date/date-utils";
import { useOutletContext } from "react-router";
import { OutletContext } from "~/pages/planning/_cmp/types";
import { twMerge } from "tailwind-merge";
import ContentLoader from "react-content-loader";
import { PlanningTableRowCollapsedContent } from "~/pages/planning/_cmp/table/planning-table-row-collapsed-content";
import { PlanningTableRowExpandedContent } from "~/pages/planning/_cmp/table/planning-table-row-expanded-content";

export default function PlanningTableRow({
  user,
  isLoading,
  collapsed = false,
}: {
  user: User;
  isLoading: boolean;
  collapsed?: boolean;
}) {
  // Handle collapse state
  const [localCollapsed, setLocalCollapsed] = useState(collapsed);

  // React to when user chooses to collapse ALL rows
  useEffect(() => {
    if (collapsed !== localCollapsed) {
      setLocalCollapsed(collapsed);
    }
  }, [collapsed]);

  // Get the planning context
  const { viewDates, cardStateViews, modalCreate, cardCanPaste, view } = usePlanning();

  const isDayView = view === "day";

  // Get the out context data
  const { isDragging } = useOutletContext<OutletContext>();

  const userCardsByDate: Record<string, Array<Card>> = useMemo(
    () =>
      cardStateViews.plannedAssigned.reduce(
        (acc, card) => {
          if (card.userId === user.id && card.date) {
            if (!acc[getDateWithoutTime(card.date).getTime()]) {
              acc[getDateWithoutTime(card.date).getTime()] = [card];
            } else {
              acc[getDateWithoutTime(card.date).getTime()].push(card);
            }
          }
          return acc;
        },
        {} as Record<string, Array<Card>>
      ),
    [cardStateViews.plannedAssigned]
  );

  const sortByListIndex = (a: Card, b: Card) => {
    if (a.listIndex === null && b.listIndex === null) {
      return 0;
    }
    if (a.listIndex === null) {
      return 1;
    }
    if (b.listIndex === null) {
      return -1;
    }
    return a.listIndex - b.listIndex;
  };

  const userTotalEstimate: number = useMemo(
    () =>
      cardStateViews.plannedAssigned.reduce((sum: number, card) => {
        if (card.userId === user.id) {
          return sum + (card.estimate ?? 0);
        }
        return sum;
      }, 0),
    [cardStateViews.plannedAssigned]
  );

  const getNextIndex = useCallback(
    (date: Date) => userCardsByDate[getDateWithoutTime(date).getTime()]?.length ?? 0,
    [userCardsByDate]
  );

  return (
    <tr>
      <td
        className={twMerge(
          "sticky left-0 w-72 border-r bg-white p-0 align-top",
          isDragging && "z-[1]"
        )}
        style={{
          borderTopColor: user ? generateBackgroundColor(user.fullName) : colors["gray"][400],
        }}
      >
        <EmployeeCard
          user={user}
          collapsed={localCollapsed}
          allowCollapse={!isDayView}
          onTriggerCollapse={(val) => setLocalCollapsed(val)}
          totalEstimates={userTotalEstimate}
        />
      </td>
      {viewDates.map((date, i) => {
        const cards =
          userCardsByDate[getDateWithoutTime(date).getTime()]?.sort(sortByListIndex) ?? [];

        return (
          <DropCell
            key={`dropCell-${user.id}-${date.getTime()}-${cards.length ?? 0}`}
            user={user}
            date={date}
            nextIndex={getNextIndex(date)}
          >
            {isLoading && (
              <div
                className={twMerge(
                  "relative flex h-full w-full items-center",
                  isDayView ? "justify-start" : "justify-center"
                )}
              >
                <ContentLoader className={twMerge("h-6 w-auto px-2")}>
                  <rect x={0} y={0} width="100%" height="100%"></rect>
                </ContentLoader>
              </div>
            )}
            {!isLoading && (
              <>
                {localCollapsed ? (
                  <PlanningTableRowCollapsedContent
                    cards={cards}
                    onAddNew={() =>
                      modalCreate({
                        userId: user.id,
                        index: getNextIndex(date),
                        date,
                      })
                    }
                  />
                ) : (
                  <PlanningTableRowExpandedContent
                    cards={cards}
                    cardCanPaste={cardCanPaste}
                    isDragging={isDragging}
                    onAddNew={() =>
                      modalCreate({
                        userId: user.id,
                        index: getNextIndex(date),
                        date,
                      })
                    }
                  />
                )}
              </>
            )}
          </DropCell>
        );
      })}
    </tr>
  );
}
