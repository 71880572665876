import { useTranslation } from "react-i18next";
import { Contact } from "@apacta/sdk";
import { useParams } from "react-router";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useOutletContext } from "react-router";
import { useMount } from "~/lib/lifecycle-helpers";
import { ProjectTable } from "~/pages/projects/_cmp/project-table";

// TODO - Align with new projects table and API before launching projects

export default function ProjectsTab() {
  const pageTitle = usePageTitle();
  const { customer } = useOutletContext<{ customer: Contact }>();
  const { id: customerId } = useParams();

  const { t } = useTranslation();

  useMount(() => {
    pageTitle.set(`${customer?.name} | ${t("common:project", { count: 2 })}`);
  });

  if (!customerId) return null;

  return (
    <div className="flex w-full flex-col gap-12">
      <div className="w-full">
        <div>
          <div className="flex flex-row items-start justify-between">
            <h2>{t("common:project", { count: 2 })}</h2>
          </div>
        </div>
        <ProjectTable
          parentType="tab"
          customerId={customerId}
          columnVisibility={{ customer: false }}
        />
      </div>
    </div>
  );
}
