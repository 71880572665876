import { User } from "@apacta/sdk";
import { Trans } from "react-i18next";
import { useLocale } from "~/lib/utils/date";
import { IconLine } from "~/lib/ui/text/icon-line";

export function DeletedLine({
  deleted,
  deletedBy,
  visible = true,
}: {
  deleted?: Date | null;
  deletedBy?: User;
  visible?: boolean; // In case approval is disabled by a company setting
}) {
  const locale = useLocale();
  const showDeletedBy = deleted && deletedBy;
  const showDeleted = !!deleted && !showDeletedBy;

  if (!deleted) return null;
  if (!visible) return null;

  return (
    <IconLine icon="delete">
      {showDeletedBy && (
        <Trans
          i18nKey="common:deleted_by_ago"
          components={{
            by: <span title={deletedBy?.fullName} />,
            ago: <span title={locale.format(deleted)} />,
          }}
          values={{
            by: deletedBy?.firstName,
            ago: locale.timeAgo(deleted),
          }}
          defaults="Deleted by <by>{{by}}</by> <ago>{{ago}}</ago>"
        />
      )}
      {showDeleted && (
        <Trans
          i18nKey="common:deleted_ago"
          components={{
            ago: <span title={locale.format(deleted)} />,
          }}
          values={{
            ago: locale.timeAgo(deleted),
          }}
          defaults="Deleted <ago>{{ago}}</ago>"
        />
      )}
      .
    </IconLine>
  );
}
