import { PageLayout } from "~/lib/ui/page-layout";
import { Button, Dialog, getIcon, IconName, LabelInput } from "~/lib/ui";
import { TemplateIdentifier, useToasts } from "~/lib/toast/use-toasts";
import { useFormState } from "~/lib/form-state";
import z from "zod";
import PageSection from "~/lib/ui/page-section";
import { NumberInput } from "~/lib/ui/form-elements";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";
import { ToastVariantClasses } from "~/lib/toast/toast";
import Switch from "~/lib/ui/switch";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { DialogBody } from "~/lib/ui/dialog/dialog-body";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useState } from "react";

const availableIcons: Array<IconName> = [
  "add",
  "success",
  "successCircle",
  "warningCircle",
  "infoCircle",
  "warningTriangle",
];

const availableVariants: Array<keyof typeof ToastVariantClasses> = [
  "success",
  "warning",
  "error",
  "info",
];

const availableTemplates: Array<TemplateIdentifier> = [
  "CHANGES_SAVED",
  "OPERATION_FAILED",
  "UNEXPECTED_ERROR",
  "CREATED",
  "DELETED",
  "MESSAGE_SENT",
];

export default function RadixToastPage() {
  const { show, showTemplate } = useToasts();
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  const formState = useFormState({
    schema: {
      title: z.string().min(1),
      description: z.string().optional(),
      duration: z.number().optional(),
      icon: z.string().nullish(),
      variant: z.string().nullish(),
      action: z.boolean(),
    },
    initialValues: {
      title: "",
      description: "",
      duration: undefined,
      icon: undefined,
      variant: undefined,
      action: false,
    },
    mutationError: undefined,
  });

  const triggerCustomToast = () => {
    const icon = formState.getValue("icon") ? (formState.getValue("icon") as IconName) : undefined;

    const variant = formState.getValue("variant")
      ? (formState.getValue("variant") as keyof typeof ToastVariantClasses)
      : undefined;

    const action = formState.getValue("action")
      ? {
          label: "See details",
          onClick: () => setDetailsOpen(true),
        }
      : undefined;

    show({
      title: formState.getValue("title"),
      description: formState.getValue("description"),
      duration: formState.getValue("duration"),
      iconName: icon,
      variant,
      action,
    });
  };

  return (
    <PageLayout title="Radix Toasts">
      <Dialog
        open={detailsOpen}
        onOpenChange={setDetailsOpen}
        className="md:max-w-2xl"
        render={({ onClose }) => (
          <div>
            <DialogHeader
              title="Action Rendered Information"
              variant="alert"
              Icon={getIcon("warningTriangle")}
            />
            <DialogBody>Action message displayed here</DialogBody>
            <DialogFooter
              hideCancel
              primary={{ label: "Close", onClick: onClose, variant: "primary" }}
              onClose={onClose}
            />
          </div>
        )}
      />
      <div className="grid grid-cols-2 gap-8">
        <PageSection>
          <div>
            <h3>Custom Toast</h3>
            <div className="flex flex-col gap-4">
              <LabelInput label="Title" {...formState.registerStringInput("title")} />
              <LabelInput label="Description" {...formState.registerStringInput("description")} />
              <NumberInput
                label="Duration (default: 5000ms)"
                defaultValue={formState.getValue("duration")}
                onChange={(e) => formState.onChange("duration", Number(e.currentTarget.value))}
              />
              <div>
                <SelectionCombobox
                  label="Icon (default: none)"
                  data={availableIcons}
                  onSelectValue={(v) => formState.onChange("icon", v)}
                  valueFn={(v) => v}
                  labelFn={(v) => v}
                />
              </div>
              <div>
                <SelectionCombobox
                  label={`Variant (default: "info")`}
                  data={availableVariants}
                  onSelectValue={(v) => formState.onChange("variant", v)}
                  valueFn={(v) => v}
                  labelFn={(v) => v}
                />
              </div>
              <Switch
                label="Show action"
                defaultChecked={false}
                onCheckedChange={(v) => formState.onChange("action", v)}
              />
              <div>
                <Button
                  variant="primary"
                  disabled={!formState.isValid}
                  onClick={triggerCustomToast}
                >
                  Trigger Custom Toast
                </Button>
              </div>
            </div>
          </div>
        </PageSection>
        <PageSection>
          <div>
            <h3>Template Toasts</h3>
            <div className="flex flex-wrap gap-4">
              {availableTemplates.map((template) => (
                <Button key={template} variant="primary" onClick={() => showTemplate(template)}>
                  {template}
                </Button>
              ))}
            </div>
          </div>
        </PageSection>
      </div>
    </PageLayout>
  );
}
