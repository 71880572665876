import { useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Spinner } from "../ui/spinner";
import { useMount } from "~/lib/lifecycle-helpers";
import { useMe } from "~/lib/auth/use-me";

export function AISupportDialog() {
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const me = useMe();

  const listener = (event: MessageEvent) => {
    if (
      iframeRef.current &&
      event.data.type === "init" &&
      event.source === iframeRef.current.contentWindow
    ) {
      const message = {
        type: "identify",
        id: me.user.id,
      };
      iframeRef.current.contentWindow?.postMessage(message, "*");
    }
  };

  // Clean up
  useMount(() => {
    return () => window.removeEventListener("message", listener);
  });

  function handleOnLoad() {
    window.addEventListener("message", listener);
    setLoading(false);
  }

  return (
    <div className="flex min-h-[35rem] w-full grow flex-col">
      {loading ? (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      ) : null}
      <iframe
        ref={iframeRef}
        src="https://chat.applai.io/chatbot/bJD4qBuAh1sdpn8Ly8PC?isClosable=false"
        className={twMerge(loading ? "hidden" : "flex h-full w-full grow")}
        onLoad={handleOnLoad}
      />
    </div>
  );
}
