import { useTranslation } from "react-i18next";
import {
  Procurement_Analysis_Line_List_Sort_Fields,
  ProcurementDetailQuery,
  useGraphQL,
} from "~/lib/gql";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useToastOnError } from "~/lib/utils/hooks";
import { formatCurrency, formatDecimals } from "~/lib/utils/number";
import { twMerge } from "tailwind-merge";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";
import { Icon } from "~/lib/ui";
import { MediaPreviewLink } from "~/lib/ui/media";
import MinNumberFilter from "./min-number-filter";
import { useState } from "react";

const CACHE_PRICE_ANALYSIS_LINES = "price-analysis-lines";

export function PriceAnalysisTable({
  companiesVendors,
}: {
  companiesVendors: ProcurementDetailQuery["procurementAnalysis"]["companiesVendors"];
}) {
  const { procurementId } = useParams<{ procurementId: string }>();
  const { t } = useTranslation();
  const sdk = useGraphQL();

  const [minPotentialSavings, setMinPotentialSavings] = useState<number>(0);

  const tableState = useDataTableState({
    sorting: [
      {
        desc: true,
        id: "OPTIMIZATION_POTENTIAL",
      } satisfies {
        desc: boolean;
        id: Procurement_Analysis_Line_List_Sort_Fields;
      },
    ],
  });

  const dataQ = useQuery({
    queryKey: [
      CACHE_PRICE_ANALYSIS_LINES,
      procurementId,
      tableState.state.search,
      tableState.sortBy,
      tableState.sortingDirection,
      minPotentialSavings,
    ],
    queryFn: () =>
      sdk.procurementAnalysisLines({
        id: procurementId as string,
        linesParams: {
          q: tableState.state.search,
          optimizationPotentialGte: minPotentialSavings,
          sortBy: tableState.sortBy as Procurement_Analysis_Line_List_Sort_Fields,
          direction: tableState.sortingDirection === "desc" ? "DESC" : "ASC",
        },
      }),
  });

  useToastOnError(dataQ.error);

  const lines = dataQ.data?.procurementAnalysis.procurementAnalysisLines ?? [];
  const vendorLogoList: Record<string, { imageUrl: string | undefined; vendorName: string }> =
    companiesVendors?.reduce(
      (acc, companyVendor) => {
        if (companyVendor.vendor.imageUrl) {
          acc[companyVendor.id] = {
            imageUrl: companyVendor.vendor.imageUrl,
            vendorName: companyVendor.vendor.name,
          };
        } else {
          acc[companyVendor.id] = { imageUrl: undefined, vendorName: companyVendor.vendor.name };
        }
        return acc;
      },
      {} as Record<string, { imageUrl: string | undefined; vendorName: string }>
    ) ?? {};

  const columns = useDataColumns<(typeof lines)[number]>((columnHelper) => [
    columnHelper.display({
      header: t("common:vendor", { count: 1 }),
      meta: {
        cellClassName: "text-center max-w-10",
      },
      cell: ({ row }) => {
        const vendorImageUrl = vendorLogoList[row.original.companiesVendorId as string]?.imageUrl;
        const vendorName = vendorLogoList[row.original.companiesVendorId as string]?.vendorName;

        return vendorImageUrl ? (
          <img src={vendorImageUrl} alt="" className="h-auto w-16 self-center" />
        ) : (
          <div className="line-clamp-1" title={vendorName}>
            {vendorName}
          </div>
        );
      },
    }),
    columnHelper.accessor("name", {
      enableSorting: true,
      id: Procurement_Analysis_Line_List_Sort_Fields.Name,
      header: t("common:name"),
      cell: ({ row }) => (
        <div className="flex max-w-60 grow items-center gap-4">
          <div className="h-16 w-16 shrink-0">
            {row.original.productImageUrls?.thumbnail ? (
              <MediaPreviewLink
                href={row.original.productImageUrls?.original}
                previewURL={row.original.productImageUrls?.thumbnail}
                mimeType={"image/*"}
                allowDownload={false}
                stopPropagation={true}
                height={16}
              />
            ) : (
              <img className="object-cover" alt="" src="/image_not_found.jpg" />
            )}
          </div>
          <div className="break-all" title={row.original.name}>
            {row.original.name}
          </div>
        </div>
      ),
    }),
    columnHelper.accessor("countOfExpenses", {
      enableSorting: true,
      id: Procurement_Analysis_Line_List_Sort_Fields.CountOfExpenses,
      header: () => (
        <div className="flex items-center gap-2">
          {t("procurement:count_of_expenses")}
          <Tooltip
            trigger={
              <span>
                <Icon name="tooltip" className="h-5 w-5" />
              </span>
            }
          >
            <span>{t("procurement:tooltip.count_of_expenses")}</span>
          </Tooltip>
        </div>
      ),
      meta: {
        headerClassName: "text-right",
        cellClassName: "text-right",
      },
      cell: ({ row }) => <span>{formatDecimals(row.original.countOfExpenses, 0)}</span>,
    }),
    columnHelper.accessor("countBought", {
      enableSorting: true,
      id: Procurement_Analysis_Line_List_Sort_Fields.CountBought,
      header: () => (
        <div className="flex items-center gap-2">
          {t("procurement:count_bought")}
          <Tooltip
            trigger={
              <span>
                <Icon name="tooltip" className="h-5 w-5" />
              </span>
            }
          >
            <span>{t("procurement:tooltip.count_bought")}</span>
          </Tooltip>
        </div>
      ),
      meta: {
        headerClassName: "text-right",
        cellClassName: "text-right",
      },
      cell: ({ row }) => <span>{formatDecimals(row.original.countBought, 0)}</span>,
    }),
    columnHelper.display({
      enableSorting: true,
      id: "averageMarketPrice",
      header: () => (
        <div className="flex items-center gap-2">
          {t("procurement:market_price")}
          <Tooltip
            trigger={
              <span>
                <Icon name="tooltip" className="h-5 w-5" />
              </span>
            }
          >
            <span>{t("procurement:tooltip.average_market_price")}</span>
          </Tooltip>
        </div>
      ),
      meta: {
        headerClassName: "text-right",
        cellClassName: "text-right",
      },
      cell: ({ row }) => (
        <span>
          {row.original.averageMarketPrice != null && row.original.minimumMarketPrice != null ? (
            `${formatCurrency(row.original.averageMarketPrice)} / ${formatCurrency(row.original.minimumMarketPrice)}`
          ) : (
            <span className="text-shade-400">{t("common:not_available")}</span>
          )}
        </span>
      ),
    }),
    columnHelper.accessor("totalAmountPaid", {
      id: Procurement_Analysis_Line_List_Sort_Fields.TotalAmountPaid,
      enableSorting: true,
      header: () => (
        <div className="flex items-center gap-2">
          {t("procurement:last_price_paid")}
          <Tooltip
            trigger={
              <span>
                <Icon name="tooltip" className="h-5 w-5" />
              </span>
            }
          >
            <span>{t("procurement:tooltip.last_price_paid")}</span>
          </Tooltip>
        </div>
      ),
      meta: {
        headerClassName: "text-right",
        cellClassName: "text-right",
      },
      cell: ({ row }) => (
        <div className="flex flex-col">
          <span className="text-lg font-medium">
            {formatCurrency(row.original.totalAmountPaid)}
          </span>
          <span className="text-xs">{`${formatCurrency(row.original.lastPricePaid)} /${t("common:each_abbr")}`}</span>
        </div>
      ),
    }),
    columnHelper.accessor("optimizationPotential", {
      enableSorting: true,
      id: Procurement_Analysis_Line_List_Sort_Fields.OptimizationPotential,
      header: () => (
        <div className="flex items-center gap-2">
          {t("procurement:potential_savings")}
          <Tooltip
            trigger={
              <span>
                <Icon name="tooltip" className="h-5 w-5" />
              </span>
            }
          >
            <div className="flex flex-col gap-2">
              <div>
                <div className="font-medium">
                  {t("procurement:tooltip.optimization_potential.title")}
                </div>
                <p className="text-sm">
                  {t("procurement:tooltip.optimization_potential.description")}
                </p>
              </div>
              <div>
                <div className="font-medium">
                  <span className="text-red-500">
                    {t("procurement:tooltip.optimization_potential.red")}
                  </span>{" "}
                  {t("common:number", { count: 2 }).toLowerCase()}
                </div>
                <p className="text-sm">
                  {t("procurement:tooltip.optimization_potential.red.description")}
                </p>
              </div>
              <div>
                <div className="font-medium">
                  <span className="text-green-600">
                    {t("procurement:tooltip.optimization_potential.green")}
                  </span>{" "}
                  {t("common:number", { count: 2 }).toLowerCase()}
                </div>
                <p className="text-sm">
                  {t("procurement:tooltip.optimization_potential.red.description")}
                </p>
              </div>
            </div>
          </Tooltip>
        </div>
      ),
      meta: {
        headerClassName: "text-right",
        cellClassName: "text-right",
      },
      cell: ({ row }) => (
        <div className="flex flex-col">
          <span
            className={twMerge(
              "text-lg font-medium",
              row.original.optimizationPotential > 0 ? "text-red-500" : "text-green-600"
            )}
          >
            {formatCurrency(row.original.optimizationPotential)}
          </span>
          <span className="text-xs">{`${formatCurrency(row.original.diffBetweenAverageAndLastPrice)} /${t("common:each_abbr")}`}</span>
        </div>
      ),
    }),
  ]);

  const table = useDataTable(
    {
      columns,
      tableState,
      data: lines,
      isLoading: dataQ.isFetching,
      getRowId: (row) => row.id,
    },
    { enableFilters: true, enableGlobalFilter: true }
  );

  return (
    <div>
      <DataTable
        error={dataQ.error}
        table={table}
        renderFilters={() => (
          <MinNumberFilter
            label={t("procurement:min_potential_savings", {
              defaultValue: "Min. potential savings",
            })}
            min={0}
            max={1000}
            value={minPotentialSavings}
            valueRender={(v) => formatCurrency(v)}
            onSubmit={setMinPotentialSavings}
          />
        )}
      />
    </div>
  );
}
