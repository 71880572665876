import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { KPIBetaCard } from "../_cmp/kpi-beta-card";
import { useTranslation } from "react-i18next";
import { KPICardWrapperProps } from "~/pages/projects/_cmp/kpi-card-wrapper";

export function ProductConsumptionCardVariable({
  costPrice = 0,
  salesPrice = 0,
  warningZeroCost,
  ...cardWrapperProps
}: KPICardWrapperProps & {
  costPrice?: number;
  salesPrice?: number;
  warningZeroCost?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <KPIBetaCard
      Icon={WrenchScrewdriverIcon}
      label={t("common:product_usage", "Products")}
      warning={
        warningZeroCost ? t("common:warning_zero_price", "One or more prices are zero") : undefined
      }
      href="./finance/products"
      unit="currency"
      estimated={{
        label: t("finance:sales_price"),
        value: salesPrice,
      }}
      current={{
        label: t("finance:cost_price"),
        className: "bg-gray-300 text-black",
        value: costPrice,
      }}
      remainder={{
        label: t("finance:contribution", "Contribution"),
        className: "bg-green-600 text-white",
      }}
      barClasses={{
        primary: "bg-gray-300",
        remainder: "bg-green-600",
      }}
      {...cardWrapperProps}
    />
  );
}
