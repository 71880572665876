import { useState } from "react";
import { Icon } from "~/lib/ui";
import { Button } from "~/lib/ui/buttons/button";
import { ButtonGroup } from "~/lib/ui/buttons/button-group";
import { LabelInput } from "~/lib/ui/form-elements";
import { PageLayout } from "~/lib/ui/page-layout";
import { PaginationView } from "~/lib/ui/pagination-view";

export default function PaginationPlayground() {
  const [canGetPreviousPage, setCanGetPreviousPage] = useState(true);
  const [canGetNextPage, setCanGetNextPage] = useState(true);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <PageLayout title="Pagination">
      <div className="mb-8 flex flex-col items-center">
        <strong>Controls</strong>
        <div className="flex flex-col gap-2">
          <ButtonGroup className="gap-2 text-sm">
            <Button variant="secondary" onClick={() => setCanGetPreviousPage(!canGetPreviousPage)}>
              canGetPreviousPage {canGetPreviousPage && <Icon name="approve" />}
            </Button>
            <Button variant="secondary" onClick={() => setCanGetNextPage(!canGetNextPage)}>
              canGetNextPage {canGetNextPage && <Icon name="approve" />}
            </Button>
          </ButtonGroup>
          <div className="flex flex-row gap-2">
            <LabelInput
              label="Page Count"
              type="number"
              onChange={(e) => setPageCount(e.currentTarget.valueAsNumber)}
              value={pageCount}
            />
            <LabelInput
              label="Current Page"
              type="number"
              value={currentPage}
              onChange={(e) => setCurrentPage(e.currentTarget.valueAsNumber)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-center">
          <h3>Simple, no page count</h3>
          <p>Used where the page count is not known.</p>
          <PaginationView
            currentPage={currentPage}
            canGetPreviousPage={canGetPreviousPage}
            canGetNextPage={canGetNextPage}
            onNextPage={() => setCurrentPage(currentPage + 1)}
            onPrevPage={() => setCurrentPage(currentPage - 1)}
          />
        </div>
        <div className="flex flex-col items-center">
          <h3>Full version</h3>
          <p>Used where the page count is known.</p>
          <PaginationView
            currentPage={currentPage}
            pageCount={pageCount}
            canGetPreviousPage={canGetPreviousPage}
            canGetNextPage={canGetNextPage}
            onNextPage={() => setCurrentPage(currentPage + 1)}
            onPrevPage={() => setCurrentPage(currentPage - 1)}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </PageLayout>
  );
}
