import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { getIcon } from "~/lib/ui";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogBody } from "~/lib/ui/dialog/dialog-body";
import { Checkbox } from "~/lib/ui/form-elements";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function EconomicWarningDialog({
  onSubmit,
  onClose,
}: {
  onSubmit: (dismiss: boolean) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const [dismiss, setDismiss] = useState<boolean>(false);

  const handleSubmit = () => {
    onSubmit(dismiss);
  };

  return (
    <>
      <DialogHeader
        title={t("invoices:economic_message_length_warning.title")}
        Icon={getIcon("warningTriangle")}
        variant="warning"
      />
      <DialogBody>
        <div className="flex flex-col gap-4">
          <div className="whitespace-break-spaces text-sm">
            {t("invoices:economic_message_length_warning.description")}
          </div>
          <div className="flex items-center gap-1">
            <Checkbox
              id="dismiss"
              defaultChecked={false}
              onChange={(e) => setDismiss(e.currentTarget.checked)}
            />
            <label htmlFor="dismiss" className="cursor-pointer text-sm">
              {t("ui:do_not_show_again")}
            </label>
          </div>
        </div>
      </DialogBody>
      <DialogFooter
        hideCancel
        onClose={onClose}
        primary={{
          label: t("common:accept"),
          variant: "primary",
          onClick: handleSubmit,
        }}
      />
    </>
  );
}
