import { useCallback, useMemo } from "react";
import { NavigateOptions } from "react-router";
import { useSearchParams } from "react-router";
import { setWindowSearchParam } from "./history/search-params";

export type SUPPORTED_PRIMITIVES = string | number | boolean | Array<string>;
// Hours were spent trying to avoid the any. If you can fix it, please do.
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is a generic type
export type SUPPORTED_PARAM_TYPES = SUPPORTED_PRIMITIVES | Record<string, any> | object;

export interface SetParamOptions extends NavigateOptions {
  /** Updates the history directly and does not trigger navigation or React updates */
  directHistoryUpdate?: boolean;
}
/**
 *
 * @description A hook for getting and setting typed search params. It translates primitives to strings, but not the other way around.
 */
export function useTypedSearchParams<
  AvailableParams extends {
    [P in keyof AvailableParams]: SUPPORTED_PARAM_TYPES;
  },
>(): [
  AvailableParams,
  (
    field: keyof AvailableParams,
    value: AvailableParams[typeof field],
    options?: NavigateOptions
  ) => void,
] {
  const [searchParams, setParams] = useSearchParams();

  const mParams = useMemo(() => {
    const params: Record<string, unknown> = {};

    Object.entries(Object.fromEntries(searchParams.entries()) as AvailableParams).forEach(
      ([key, value]) => {
        params[key] = urlStringToType(value as string);
      }
    );
    return params;
  }, [searchParams]);

  const setTypedParams = useCallback(
    (
      field: keyof AvailableParams,
      value: AvailableParams[typeof field],
      { directHistoryUpdate, ...options }: SetParamOptions = {
        replace: true, // Otherwise, the browser will add a new entry to the history stack
      }
    ) => {
      // Note: It's tempting to check if the value is the same as the current value and skip the update.
      // - But, the params here are only from React Router, and we can't trust them to be up-to-date.
      if (directHistoryUpdate) {
        setWindowSearchParam(field as string, typetoUrlString(value));
      } else {
        setParams((prev) => {
          if (value === undefined) {
            prev.delete(field as string);
            return prev;
          }
          const newValue = typetoUrlString(value);
          prev.set(field as string, newValue);
          return prev;
        }, options);
      }
    },
    [setParams]
  );

  return [mParams as AvailableParams, setTypedParams] satisfies [
    AvailableParams,
    typeof setTypedParams,
  ];
}

const ARRAY_SEPARATOR = ",";

function urlStringToType(value: string): unknown {
  if (value === "") {
    return undefined;
  }
  if (value.startsWith("[") && value.endsWith("]")) {
    const arrValue = value
      .slice(1, value.length - 1)
      .split(ARRAY_SEPARATOR)
      .map((v) => urlStringToType(v));

    if (arrValue.length === 1 && arrValue[0] === undefined) {
      return undefined;
    }
    return arrValue;
  }
  if (value.startsWith("{") && value.endsWith("}")) {
    return JSON.parse(value);
  }
  if (value === "true") {
    return true;
  }
  if (value === "false") {
    return false;
  }
  if (isNaN(Number(value))) {
    return value;
  }
  return Number(value);
}

function typetoUrlString(value: unknown): string {
  if (Array.isArray(value)) {
    return `[${value.map((v) => typetoUrlString(v)).join(ARRAY_SEPARATOR)}]`;
  }
  if (value === null) return "";

  switch (typeof value) {
    case "boolean":
      return value ? "true" : "false";
    case "number":
      return value.toString();
    case "string":
      return value as string;
    case "object":
      return JSON.stringify(value);
    default:
      // If this happens, the type system has failed us
      throw new Error("Invalid type " + typeof value);
  }
}
