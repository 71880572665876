import { EffectCallback, useEffect, useRef, useState } from "react";

type Destructor = Exclude<ReturnType<EffectCallback>, void>;
type DebounceCallback = (signal: AbortController["signal"]) => Destructor | void;
type DependencyList = ReadonlyArray<unknown>;

/**
 * @description The signature is identical to useEffect with the addition of a delay parameter to control the debouncing
 * @param debounce Imperative function that will automatically be cleaned up upon bounce
 * @param deps If present, effect will only activate if the values in the list change
 * @param delay If present, effect will activate after the supplied amount of milliseconds has passed
 * @example
 * // Prints "Hello world" after 2 seconds:
 * useDebounce(() => console.log("Hello world"), [], 2000);
 *
 * // Runs myFunc() after 500ms when myInput changes if no additional changes to myInput happen within those 500ms:
 * useDebounce(() => myFunc(), [myInput], 500);
 */
export function useDebounce(debounce: DebounceCallback, deps: DependencyList = [], delay = 1) {
  useEffect(() => {
    const abortController = new AbortController();
    const timer = setTimeout(() => debounce(abortController.signal), delay);
    return () => clearTimeout(timer);
  }, [...deps]);
}

/**
 * @description Just like setState, but debounces the sets.
 * @param initialState
 * @param delay
 * @returns
 */
export function useDebouncedValue<T>(initialState: T, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState<T>(initialState);
  const [isPending, setIsPending] = useState<boolean>(false);
  const timer = useRef<ReturnType<typeof setTimeout>>(undefined);
  const setValue = (newState: T) => {
    setIsPending(true);
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setDebouncedValue(newState);
      setIsPending(false);
    }, delay);
    return;
  };

  return [debouncedValue, { setValue, isPending }] satisfies [
    T,
    {
      setValue: (newState: T) => void;
      isPending: boolean;
    },
  ];
}

/** Monitors `value`, but only updates after a given delay has passed */
export function useDelayedValue<T>(value: T, delay = 300) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [isPending, setIsPending] = useState<boolean>(false);
  useEffect(() => {
    setIsPending(true);
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      setIsPending(false);
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  return { value: debouncedValue, isPending };
}
