import { Outlet, useParams } from "react-router";
import { Suspense } from "react";
import { useAPI } from "~/lib/api";
import { keepPreviousData, QueryObserverBaseResult, useQuery } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { useTranslation } from "react-i18next";
import { PageSpinner } from "~/lib/ui/page-spinner";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { Product } from "@apacta/sdk";
import { getIcon } from "~/lib/ui/icons/icon";
import { Button } from "~/lib/ui";
import { useNavigator } from "~/lib/utils/use-navigator";
import { Spinner } from "~/lib/ui/spinner";
import { formatCurrency, roundToDecimal } from "~/lib/utils/number";
import { PillBadge } from "~/lib/ui/pill-badge";
import { calculateContributionRatio } from "~/lib/utils/finance";

export type OutletContext = {
  product: Product;
  refetch: QueryObserverBaseResult<Product, unknown>["refetch"];
};

export default function ProductPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const { id: productId } = useParams();
  const { copyToClipboard } = useNavigator();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["product", productId],
    queryFn: () => api.productsView({ productId: productId as string, includeVariants: true }),
    placeholderData: keepPreviousData,
  });
  const pageTabs = [
    {
      id: "overview",
      label: t("common:overview"),
      to: "./",
      Icon: getIcon("entityOverview"),
    },
    {
      id: "pricing",
      label: t("projects:pricing", "Pricing"),
      to: "pricing/",
      Icon: getIcon("masterData"),
    },
  ];
  const product = data?.data;

  if (isLoading) {
    return (
      <div className="relative h-96 w-full">
        <PageSpinner loadingMessage={t("common:loading")} />
      </div>
    );
  }

  return (
    <PageLayout title={product?.name ?? ""}>
      <PageLayout.Signal slot="description" id="old-actions">
        <div className="flex gap-4 text-sm">
          <div className="inline-flex items-center gap-2">
            <PillBadge
              label={t("products:buying_price")}
              color="yellow"
              value={` ${formatCurrency(product?.buyingPrice ?? 0)} ${product?.unit ? ` / ${product.unit}` : ""}`}
            />
            <PillBadge
              label={t("products:selling_price")}
              color="green"
              value={` ${formatCurrency(product?.sellingPrice ?? 0)} ${product?.unit ? ` / ${product.unit}` : ""}`}
            />
            <PillBadge
              label={t("finance:operating_margin")}
              color="gray"
              value={`${calculateContributionRatio(roundToDecimal(product?.sellingPrice ?? 0), roundToDecimal(product?.buyingPrice ?? 0))}%`}
            />
          </div>
        </div>
      </PageLayout.Signal>
      <PageLayout.Signal slot="actions" id="copy-erp-to-clipboard" hidden={!product?.erpId}>
        <Button
          Icon={getIcon("clipboard")}
          className="px-2.5"
          title={t("products:copy_erp_to_clipboard")}
          variant="secondary"
          onClick={() =>
            copyToClipboard({
              value: product!.erpId!, // signal hidden makes this safe
              toastTitle: t("products:copied_to_clipboard.title"),
              toastDescription: t("products:copied_to_clipboard.description"),
            })
          }
        ></Button>
      </PageLayout.Signal>
      <PageTabs tabs={pageTabs} />
      <Suspense
        fallback={
          <div className="flex items-center justify-center py-12">
            <Spinner />
          </div>
        }
      >
        <Outlet context={{ product, refetch }} />
      </Suspense>
    </PageLayout>
  );
}
