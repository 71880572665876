import { useEffect, useMemo, useRef } from "react";
import { OptionalLink } from "../utils/routing/optional-link";
import { twMerge } from "tailwind-merge";
import DeveloperActionFab from "~/lib/ui/developer-action-fab";
import { ScrollToTop } from "./scroll-to-top";
import { Boundary } from "./boundary";
import { usePageTitle } from "../navigation/use-page-title";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";
import { PageBackButton } from "~/lib/navigation/page-back-button";
import { PageLayoutProps } from "./page-layout.types";
import { withSlotProvider } from "../react-ui-slots/with-slot-provider";

export const PageLayout = withSlotProvider(PageLayoutInner, ["actions", "description"]);

/**
 * A page layout with a title, description, actions and children.
 * @param title - Title of the page
 * @param titleLabel - Optional label to render below the title
 * @param renderDescription - Optional description to render below the title
 * @param renderActions - Optional actions to render on the right side of the page
 * @param onBackClick - Optional callback to call when the back button is clicked
 * @param children - Page contents
 * @param titleHref - Optional href to wrap the title in
 * @param developerActions - Optional developer actions to render in a popover
 * @constructor
 */
function PageLayoutInner({
  title,
  titleLabel,
  renderActions,
  renderDescription,
  children,
  titleHref,
  developerActions,
}: PageLayoutProps) {
  const isDevelopment = import.meta.env.MODE === "development";
  const pageTitle = usePageTitle();

  const container = useRef<HTMLDivElement>(null);

  // Should the layout title change, then update the page titl
  useEffect(() => {
    pageTitle.set(title);
  }, [title]);

  const renderLegacyActions = useMemo(
    () => (
      <PageLayout.Signal slot="actions" id="legacy-action-bridge">
        {renderActions?.()}
      </PageLayout.Signal>
    ),
    [renderActions]
  );

  const renderLegacyDescription = useMemo(() => {
    return (
      <PageLayout.Signal slot="description" id="legacy-description-bridge">
        {renderDescription?.()}
      </PageLayout.Signal>
    );
  }, [renderDescription]);

  return (
    <Boundary variant="page">
      {developerActions?.length && isDevelopment && (
        <DeveloperActionFab actions={developerActions} />
      )}
      <div
        ref={container}
        className="overflow-x-hidden overflow-y-hidden pb-8 pt-2 sm:pb-18 sm:pt-4"
      >
        <div className="flex max-w-7xl flex-col flex-wrap justify-between gap-8 px-4 py-4 sm:flex-row sm:flex-nowrap sm:px-6 md:px-8 print:px-0">
          <div>
            <div className={twMerge("flex items-start gap-6")}>
              <PageBackButton />
              <div className="flex">
                <h1 className="m-0 text-4xl font-bold text-zinc-800 print:text-3xl">
                  <Tooltip
                    triggerAsChild={false}
                    trigger={
                      <OptionalLink className="line-clamp-2 text-left" to={titleHref}>
                        {title}
                      </OptionalLink>
                    }
                  >
                    {title}
                  </Tooltip>
                </h1>
                {titleLabel && <div className="m-0">{titleLabel()}</div>}
              </div>
            </div>
            <PageLayout.Slot
              slot="description"
              render={(description) => (
                <div className="mt-4 inline-flex flex-col gap-2">{description}</div>
              )}
            />
          </div>
          <PageLayout.Slot
            slot="actions"
            render={(actions) => (
              <div className="flex flex-shrink-0 flex-row items-start gap-4">{actions}</div>
            )}
          />
        </div>
        <section className="max-w-7xl px-4 pt-8 sm:px-6 md:px-8 print:px-0 print:pt-2">
          {children}
          <ScrollToTop container={container} />
        </section>
      </div>
      {renderLegacyActions}
      {renderLegacyDescription}
    </Boundary>
  );
}
