import { ResponseError } from "@apacta/sdk";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Outlet } from "react-router";
import { getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { Boundary } from "~/lib/ui/boundary";
import { PageLayout } from "~/lib/ui/page-layout";
import PageTabs from "~/lib/ui/tabs/page-tabs";

export default function ErrorPlayground(_props: { error: Error }) {
  return (
    <PageLayout title="Error Page">
      <PageLayout.Signal slot="description" id="crash-actions">
        <CrashActions key="page" />
      </PageLayout.Signal>
      <PageTabs
        tabs={[
          {
            to: "./",
            id: "root",
            label: "Root",
          },
        ]}
      ></PageTabs>
      <Boundary variant="tab">
        <Outlet />
      </Boundary>
    </PageLayout>
  );
}

export function CrashActions({ key }: { key: string }) {
  const [state, setState] = useState<Parameters<typeof crashAPI>["0"]>({
    crashing: false,
    type: "crash",
  });

  useSuspenseQuery({
    queryKey: ["crash", key, state],
    queryFn: () => crashAPI({ crashing: state.crashing, type: state.type }),
  });

  return (
    <ActionButtons
      actions={[
        {
          collapseBehavior: "never",
          Icon: getIcon("devDebug"),
          label: "Crash",
          onClick: () =>
            setState({
              crashing: true,
              type: "crash",
            }),
        },
        {
          collapseBehavior: "never",
          Icon: getIcon("warningCircle"),
          label: "404",
          onClick: () =>
            setState({
              crashing: true,
              type: "404",
            }),
        },
      ]}
    />
  );
}

function crashAPI({ crashing, type }: { crashing: boolean; type: "crash" | "404" }) {
  return new Promise((resolve, reject) => {
    if (!crashing) {
      resolve({ data: [] });
      return;
    }
    setTimeout(() => {
      switch (type) {
        case "404":
          const resp = new Response(null, { status: 404 });
          const err: ResponseError = new ResponseError(resp, "Not found");
          reject(err);
          break;
        default:
          reject(new Error("Crash"));
          break;
      }
    }, 2000);
  });
}
