"use client";
import { ComponentProps, ReactNode, Suspense, SuspenseProps } from "react";
import { ErrorBoundary, ErrorBoundaryProps } from "react-error-boundary";
import { ErrorView } from "~/lib/error-reporting/error-view";
import { SkeletonLoader } from "../skeleton";
import { Spinner } from "../spinner";
import { ErrorElement } from "~/lib/errors/error-element";

/**
 * Boundary component wraps its' children in an ErrorBoundary and a Suspense boundary.
 * It works without extra props, but a  variant can be set, based on the context.
 * Please don't add direct fallback override here. Add a variant instead.
 *
 * Wrap this around `useSuspenseQuery` components to catch errors and show a spinner.
 */
export function Boundary({
  children,
  variant = "element",
  suspenseProps,
}: {
  children: ReactNode;
  variant?: "page" | "element" | "tab"; // affects defaults
  suspenseProps?: SuspenseProps;
}) {
  return (
    <ErrorBoundary {...defaultErrorBoundary(variant)}>
      <Suspense {...defaultSuspense(variant)} {...suspenseProps}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
}

type SuspenseFn = (variant: ComponentProps<typeof Boundary>["variant"]) => SuspenseProps;
type ErrorBoundaryFn = (variant: ComponentProps<typeof Boundary>["variant"]) => ErrorBoundaryProps;

const defaultSuspense: SuspenseFn = (variant) => {
  switch (variant) {
    case "page":
    case "tab":
      return {
        fallback: (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner />
          </div>
        ),
      };
    default:
      return {
        fallback: <SkeletonLoader template="box" />,
      };
  }
};

const defaultErrorBoundary: ErrorBoundaryFn = (variant) => {
  switch (variant) {
    case "page":
      return {
        FallbackComponent: ErrorView,
      };
    case "tab":
      return {
        fallbackRender(props) {
          return <ErrorView error={props.error} className="p-0" />;
        },
      };

    case "element":
    default:
      return {
        FallbackComponent: ErrorElement,
      };
  }
};
