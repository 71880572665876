import { useCallback, useEffect, useRef, useState } from "react";
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragDropCardType } from "~/pages/planning/_cmp/drag/drag-drop.types";
import { twMerge } from "tailwind-merge";
import colors from "tailwindcss/colors";
import { Card, Task, usePlanning } from "~/lib/planning";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";
import { useTranslation } from "react-i18next";
import CardTooltip from "~/pages/planning/_cmp/card-tooltip";
import { isSameContainer } from "~/lib/planning/card-reducer.helpers";
import { moveDateRetainTime } from "~/lib/utils/date/date-utils";

export type CardProps = {
  card: Card;
  index: number;
  draggedTask?: Task;
  isLast?: boolean;
  useFullWidth?: boolean;
  useBgColor?: boolean;
};

export function DraggableCard({
  card,
  index,
  draggedTask, // This is a prop that is passed when dragging a card
  isLast,
  useFullWidth = false,
  useBgColor = false,
}: CardProps) {
  const { cardGetTask, setSelectedCard, updateCard, view } = usePlanning();
  const { t } = useTranslation();

  const isDayView = view === "day";

  const task = draggedTask ?? cardGetTask({ card });

  const dragRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);

  const [hoverState, setHoverState] = useState<-1 | 0 | 1>(0);

  const [{ isOver }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: DragDropCardType,
    // Props to collect
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item: CardProps, monitor) => {
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();
      if (hoverBoundingRect) {
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Get horizontal middle
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Get pixels to the left
        const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

        if (isDayView) {
          // Left of
          if (hoverClientX < hoverMiddleX) {
            handleDrop(item, 0);
            return;
          }

          // Right of
          if (hoverClientX > hoverMiddleX) {
            handleDrop(item, 1);
            return;
          }
        } else {
          // Above current card
          if (hoverClientY < hoverMiddleY) {
            handleDrop(item, 0);
            return;
          }

          // Below current card
          if (hoverClientY > hoverMiddleY) {
            handleDrop(item, 1);
            return;
          }
        }

        handleDrop(item, 1);
      }
    },
    hover: (dragItem: CardProps, monitor) => {
      if (!dropRef.current) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Get pixels to the left
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // We are working horizontally in day view, vertically in others
      if (isDayView) {
        // Dragging rightwards
        if (hoverClientX < hoverMiddleX && hoverState !== -1) {
          setHoverState(-1);
          return;
        }

        // Dragging leftwards
        if (hoverClientX > hoverMiddleX && hoverState !== 1) {
          setHoverState(1);
          return;
        }
      } else {
        // Dragging downwards
        if (hoverClientY < hoverMiddleY && hoverState !== -1) {
          setHoverState(-1);
          return;
        }

        // Dragging upwards
        if (hoverClientY > hoverMiddleY && hoverState !== 1) {
          setHoverState(1);
          return;
        }
      }
    },
  }));

  const handleDrop = (droppedItem: { card: Card }, indexOffset: number) => {
    const sameContainer = isSameContainer(droppedItem.card, card);
    // We need to handle the scenario where we drag a card that has a lesser index than the card we drop it on
    // This only applies when the cards are in the same container
    // The logic is that when we drop the card in such a fashion, the card we dropped it on, will move up in position (down in index)
    if (
      sameContainer &&
      droppedItem.card.listIndex !== null &&
      droppedItem.card.listIndex < index
    ) {
      indexOffset -= 1;
    }

    const newIndex = (index ?? 0) + indexOffset;

    const updatedCard: Card = {
      ...droppedItem.card,
      listIndex: newIndex ?? 0, // new index
      date: card.date, // same date as the card you dropped the item on
      userId: card.userId, // same user as the card you dropped the item on
      startTime:
        droppedItem.card.startTime && card.date
          ? moveDateRetainTime(droppedItem.card.startTime, card.date)
          : undefined, // update startTime if it exists
    };

    updateCard(updatedCard, {}, false);
  };

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DragDropCardType,
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: {
        card,
        index,
        draggedTask: task,
      } satisfies CardProps,
    }),
    [card]
  );

  useEffect(() => {
    // this useEffect hides the default preview
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  drag(dragRef);
  drop(preview(dropRef));

  const getCardName = useCallback(() => {
    if (task) {
      return task?.project ? `${task.project.name}` : t("planning:card.task_name_missing");
    }
    return t("planning:card.task_name_missing");
  }, [task]);

  return (
    <div ref={dropRef} className={twMerge(isDragging && "opacity-50", isDayView && "flex")}>
      <div
        className={twMerge(
          "bg-gray-200 duration-200 ease-in",
          isDayView ? "transition-width" : "transition-height"
        )}
        style={
          isDayView
            ? {
                width:
                  isOver && hoverState === -1
                    ? `${dragRef.current?.getBoundingClientRect().width ?? 0}px`
                    : "0rem",
                height: isOver && hoverState === -1 ? `2rem` : "0rem",
              }
            : {
                height:
                  isOver && hoverState === -1
                    ? `${dragRef.current?.getBoundingClientRect().height ?? 0}px`
                    : "0rem",
              }
        }
      ></div>
      <Tooltip
        disabled={isDragging || isOver}
        side={isDayView ? "top" : "right"}
        delay={500}
        trigger={
          <div
            ref={dragRef}
            onClick={() => setSelectedCard(card)}
            className={twMerge(
              "flex h-8 shrink-0 cursor-pointer select-none items-center gap-2 overflow-hidden bg-transparent px-2 text-xs hover:bg-black/5",
              useFullWidth ? "w-full" : "w-[calc(10rem-1px)]",
              useBgColor ? "bg-white" : ""
            )}
          >
            <div
              style={{ backgroundColor: task?.labels?.[0]?.bgColor ?? colors["gray"][200] }}
              className={twMerge("h-3 w-3 shrink-0 rounded-full")}
            ></div>
            <div className="flex w-full justify-between">
              <div className="line-clamp-1 text-left">{getCardName()}</div>
            </div>
          </div>
        }
      >
        <div className="whitespace-pre-line">
          <CardTooltip card={card} />
        </div>
      </Tooltip>

      {!isLast && (
        <div
          className="bg-gray-200 transition-height duration-200 ease-in"
          style={
            isDayView
              ? {
                  width:
                    isOver && hoverState === 1
                      ? `${dragRef.current?.getBoundingClientRect().width ?? 0}px`
                      : "0rem",
                  height: isOver && hoverState === -1 ? `2rem` : "0rem",
                }
              : {
                  height:
                    isOver && hoverState === 1
                      ? `${dragRef.current?.getBoundingClientRect().height ?? 0}px`
                      : "0rem",
                }
          }
        ></div>
      )}
    </div>
  );
}
