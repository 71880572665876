import { OrderComplaintsTable } from "~/pages/procurement/index/_cmp/order-complaints-table";
import { useOutletContext } from "react-router";
import { DateRange } from "~/lib/utils/date/date-utils.types";

export default function OrderComplaints() {
  const { dateRange } = useOutletContext<{ dateRange: Required<DateRange> }>();

  return (
    <>
      <OrderComplaintsTable dateFrom={dateRange.from} dateTo={dateRange.to} />
    </>
  );
}
