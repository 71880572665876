import { Card, usePlanning } from "~/lib/planning";
import { numberToTime } from "~/lib/utils/time";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { linkToProject } from "~/lib/utils";
import { Icon } from "~/lib/ui";

export default function CardTooltip({ card }: { card: Card }) {
  const { t } = useTranslation();
  const { cardGetTask } = usePlanning();
  const task = cardGetTask({ card });
  const project = task?.project;
  const formattedStartTime = card.startTime
    ? `${numberToTime(card.startTime.getHours())}:${numberToTime(card.startTime.getMinutes())}`
    : t("common:not_available");

  const formattedDuration = card.estimate
    ? `${card.estimate} ${t("common:hour", { count: card.estimate }).toLowerCase()}`
    : t("common:not_available");

  return (
    <div className="p-2 text-sm">
      <div className="flex items-center gap-2 pb-2 font-semibold">
        {project?.id && (
          <div className="flex items-start gap-2">
            <Link to={linkToProject(project.id)} className="hover:underline" target="_blank">
              {project?.fullName}
            </Link>
            <Icon name="externalLink" className="flex h-auto w-4 shrink-0" />
          </div>
        )}
      </div>
      <hr />
      <div className="flex flex-col gap-2 pt-2">
        <div>
          <div className="font-medium">{t("planning:create_modal.task_name")}</div>
          <div>{task?.name ?? t("common:not_available")}</div>
        </div>
        <div>
          <div className="font-medium">{t("planning:create_modal.start_time")}</div>
          <div>{formattedStartTime}</div>
        </div>
        <div>
          <div className="font-medium">{t("common:duration")}</div>
          <div>{formattedDuration}</div>
        </div>
      </div>
    </div>
  );
}
