import { useOutletContext } from "react-router";
import { InvoiceEditPage } from "../_cmp/invoice-edit";
import { InvoiceView } from "../_cmp/invoice-view";
import { Invoice } from "@apacta/sdk";

export default function InvoiceDetailPage() {
  const { invoice } = useOutletContext<{ invoice: Invoice }>();

  // You can't edit invoice entities that are sent to ERP. It owns them now.
  if (invoice.integrationId) return <InvoiceView />;
  // You can't edit views
  if (!invoice.isDraft) return <InvoiceView />;
  // You can't edit locked invoices
  if (invoice.isLocked) return <InvoiceView />;
  return <InvoiceEditPage />;
}
