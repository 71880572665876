import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $insertNodes,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
} from "lexical";
import { useEffect, JSX } from "react";
import {
  $createVariableNode,
  VariableNode,
} from "~/lib/ui/rich-text-editor/editor/plugins/variable-plugin/VariableNode";
import { RichTextEditorInsertAction } from "~/lib/ui/rich-text-editor";
import { $insertNodeToNearestRoot } from "@lexical/utils";

export const INSERT_VARIABLE_COMMAND: LexicalCommand<RichTextEditorInsertAction> = createCommand();

export type VariableData = {
  id: string;
  name: string;
  type: "string" | "number" | "date" | "boolean";
  description: string;
  identifier: string;
};

export default function VariablePlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([VariableNode])) {
      throw new Error("VariablePlugin: VariableNode not registered on editor");
    }

    return editor.registerCommand<RichTextEditorInsertAction>(
      INSERT_VARIABLE_COMMAND,
      (payload) => {
        const variableNode = $createVariableNode(payload);

        if (payload.clear) {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        }

        if (payload.inline) {
          $insertNodes([variableNode]);
        } else {
          $insertNodeToNearestRoot(variableNode);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
