import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { capitalize } from "~/lib/utils/string";
import { KPICardWrapper } from "~/pages/projects/_cmp/kpi-card-wrapper";
import { FormatValue } from "~/pages/projects/_cmp/FormatValue";
import { CACHE_EMPLOYEES } from "~/pages/employees";
import { useEmployeesParams } from "~/pages/employees/_cmp/use-users-params";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { useSuspenseQuery } from "@tanstack/react-query";

export default function EmployeesKpiCards() {
  const api = useAPI();
  const { t } = useTranslation();
  const employeesParams = useEmployeesParams();
  const [searchParams] = useTypedSearchParams<{
    activities?: Array<string>;
    isActive?: boolean;
  }>();

  const q = useSuspenseQuery({
    queryKey: [CACHE_EMPLOYEES, "users-kpi-cards", employeesParams, searchParams],
    queryFn: () =>
      api.usersGetKpiCards({
        startDate: employeesParams.startDate,
        endDate: employeesParams.endDate,
        employeeIds: employeesParams.employeeIds,
        active: searchParams.isActive,
        activityIds: searchParams.activities,
      }),
  });

  const kpiCards = q.data?.data;
  return (
    <div className="flex w-full grow flex-col gap-5 pb-5 sm:flex-row">
      {kpiCards.map((kpiCard, idx: number) => (
        <KPICardWrapper
          key={idx}
          label={capitalize(t(`users:kpi_card.${kpiCard.label}`))}
          description={
            kpiCard.showDescription ? t(`users:kpi_card.${kpiCard.label}.description`) : undefined
          }
          href={kpiCard.showLink ? "product_usage/" : undefined}
        >
          <div className="flex flex-row justify-start gap-x-2">
            <span className="text-xl font-bold">
              <FormatValue value={kpiCard.value ?? undefined} unit={kpiCard.type} decimals={2} />
            </span>
          </div>
        </KPICardWrapper>
      ))}
    </div>
  );
}
