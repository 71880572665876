import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useAPI } from "~/lib/api";
import { LoginLayout } from "./layout";
import { RequestResetPasswordOperationRequest, ResponseError } from "@apacta/sdk";
import { useMutation } from "@tanstack/react-query";
import { captureException } from "~/lib/error-reporting";
import { Button, LabelInput } from "~/lib/ui";
import { HorizontalDivider } from "~/lib/ui/horizontal-divider";

export default function ResetPasswordPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const location = useLocation();
  const [email, setEmail] = useState<string>(location.state?.email || "");
  const [mobile, setMobile] = useState<string>(location.state?.mobile || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<"success" | "failed" | "unknown">("unknown");
  const [type, setType] = useState<"email" | "mobile">("email");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>(t("ui:login.reset_failed"));
  const mutation = useMutation({
    mutationFn: (resetPasswordData: RequestResetPasswordOperationRequest) => {
      return api.requestResetPassword(resetPasswordData);
    },
    onSuccess: (data) => {
      setState("success");
    },
    onError: (error: ResponseError) => {
      if (error.response.status === 422) {
        setErrorMessage(t("ui:login.reset_error_description"));
      }
      setState("failed");
      captureException(new Error("Operation failed", { cause: error }));
    },
    onSettled: (data) => {
      setType(!!mobile ? "mobile" : "email");
      setEmail("");
      setMobile("");
      setLoading(false);
    },
  });

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setLoading(true);
    const requestParams = {
      requestResetPasswordRequest: {
        email,
        mobile,
      },
    };

    return mutation.mutate(requestParams);
  }

  const canSubmit = !!email || !!mobile;

  return (
    <LoginLayout>
      <div>
        {state === "failed" ? <div className="pb-2 text-red-600">{errorMessage}</div> : null}
      </div>
      <div>
        <div className="relative mt-6">
          <h3>{t("ui:login.reset_password", "Reset password")}</h3>
        </div>
      </div>

      <div className="mt-6">
        {state === "success" ? (
          <div>
            <p className="whitespace-pre-line">
              {t("ui:login.reset_success", {
                device: type === "mobile" ? t("common:sms") : t("common:email").toLowerCase(),
              })}
            </p>
            <Button
              className="mt-4"
              variant="primary"
              onClick={() => navigate("/login", { replace: true })}
            >
              {t("common:back")}
            </Button>
          </div>
        ) : (
          <form action="#" method="POST" className="-space-y-2" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4">
              <div>
                <LabelInput
                  defaultValue={email}
                  id="email"
                  name="email"
                  type="email"
                  label={t("common:email_address")}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  disabled={mobile.length > 0}
                />
              </div>
              <div className="pt-2">
                <HorizontalDivider lineClassName="border-gray-300">
                  <div className="text-center">{t("common:or")}</div>
                </HorizontalDivider>
              </div>
              <div className="pb-8">
                <LabelInput
                  defaultValue={mobile}
                  id="mobile"
                  name="mobile"
                  type="text"
                  label={t("common:phone_number")}
                  onChange={(e) => setMobile(e.currentTarget.value)}
                  disabled={email.length > 0}
                />
              </div>
              <div className="flex flex-row gap-4">
                <Button variant="primary" disabled={loading || !canSubmit} type="submit">
                  {t("ui:login.reset_password")}
                </Button>
                <Button variant="secondary" onClick={() => navigate("/login", { replace: true })}>
                  {t("common:cancel")}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </LoginLayout>
  );
}
