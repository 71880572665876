import { useQuery } from "@tanstack/react-query";
import { DataTable, useDataTable } from "~/lib/ui/data-table";
import { getSharedTableDefaults, SharedTableBase } from "~/lib/ui/data-table/shared-table-base";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { CACHE_OFFERS } from "..";
import { useAPI } from "~/lib/api";
import { useState } from "react";
import { OfferStatusItem } from "~/lib/offers/use-offer-status";
import { useToastOnError } from "~/lib/utils/hooks";
import { useOfferTableColumns } from "./offer-table-columns";
import OfferStatusFilter from "./offer-status-filter";
import { PillBadge } from "~/lib/ui/pill-badge";
import { Icon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

interface OfferTableProps extends SharedTableBase {
  customerId?: string;
}

export function OfferTable(props: OfferTableProps) {
  const { t } = useTranslation();
  const api = useAPI();
  const [filterStatus, setFilterStatus] = useState<OfferStatusItem | undefined>();
  const navigate = useNavigate();
  const columns = useOfferTableColumns({
    onDuplicateOffer: (id) => {
      if (props.parentType === "page") {
        navigate(id);
      }
    },
  });
  const tableState = useDataTableState({
    columnVisibility: props.columnVisibility,
  });
  const dataQ = useQuery({
    queryKey: [
      CACHE_OFFERS,
      "index",
      tableState.state.search,
      tableState.state.pagination,
      tableState.sortingDirection,
      tableState.sortBy,
      tableState.pageNumber,
      filterStatus?.id,
      props.customerId,
    ],
    queryFn: () =>
      api.offersList({
        page: tableState.pageNumber,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
        q: tableState.state.search,
        offerStatusId: filterStatus?.id,
        contactId: props.customerId,
      }),
  });
  useToastOnError(dataQ.error);
  const defaults = getSharedTableDefaults(props.parentType);
  const table = useDataTable(
    {
      columns,
      tableState,
      data: dataQ.data?.data,
      isLoading: dataQ.isLoading,
      backendPagination: dataQ.data?.pagination,
      getRowId: (row) => row.id,
    },
    {
      enableGlobalFilter: props.enableGlobalFilter ?? defaults.enableGlobalFilter ?? true,
      enableFilters: props.enableFilters ?? defaults.enableFilters ?? true,
    }
  );
  return (
    <DataTable
      error={dataQ.error}
      table={table}
      renderFilters={() => (
        <>
          <OfferStatusFilter
            triggerRender={() => (
              <PillBadge
                label={t("common:status")}
                isLoading={!dataQ.data}
                value={filterStatus?.translatedLabel}
                onAction={() => {
                  if (filterStatus) {
                    setFilterStatus(undefined);
                  }
                }}
                action={
                  filterStatus === undefined ? (
                    <Icon name="chevronDown" className="h-4 w-4" />
                  ) : (
                    <Icon name="close" className="h-3 w-3" />
                  )
                }
                className="outline-none hover:border-hover hover:text-hover focus:border-indigo-500 focus-visible:outline-hover"
              />
            )}
            onSelect={(item) => setFilterStatus(item)}
          />
        </>
      )}
    />
  );
}
