import { UsersPriceGroup } from "@apacta/sdk";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";

export function UsersPriceGroups({
  onChange,
  initialPriceGroupId,
  hideLabel = false,
}: {
  onChange: (condition: UsersPriceGroup) => void;
  initialPriceGroupId?: string;
  hideLabel?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = useState<UsersPriceGroup | null | undefined>(undefined);

  const { data } = useSuspenseQuery({
    queryKey: ["users_price_groups"],
    queryFn: () => api.usersPriceGroupsList({}),
  });

  const usersPriceGroups = data?.data ?? [];

  useEffect(() => {
    if (!selectedGroup) return;
    onChange?.(selectedGroup);
  }, [selectedGroup]);

  const manageSelection = function (id?: string) {
    const userPriceGroup = usersPriceGroups?.find((group) => group.id === id);
    setSelectedGroup(userPriceGroup ?? null);
  };

  return (
    <>
      <SelectionCombobox
        label={hideLabel ? "" : t("common:users_price_groups")}
        defaultValue={initialPriceGroupId}
        data={usersPriceGroups}
        valueFn={(e) => e.id ?? ""}
        labelFn={(e) => `${e.name} (${e.salePrice})`}
        onSelect={(v) => manageSelection(v)}
      />
    </>
  );
}
