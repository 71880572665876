import { useTranslation } from "react-i18next";
import { Button } from "./buttons/button";
import { ButtonGroup } from "./buttons/button-group";
import { Icon } from "./icons/icon";
import { twMerge } from "tailwind-merge";

const BUTTON_CLASS = "gap-2 items-center";
const BUTTON_ICON_CLASS = "h-5 w-5";

type Props =
  | {
      currentPage?: number;
      pageCount?: never;
      canGetPreviousPage: boolean;
      canGetNextPage: boolean;
      onNextPage: () => void;
      onPrevPage: () => void;
      onPageChange?: never;
      className?: string;
    }
  | {
      currentPage: number;
      pageCount: number;
      canGetPreviousPage: boolean;
      canGetNextPage: boolean;
      onNextPage: () => void;
      onPrevPage: () => void;
      onPageChange: (page: number) => void;
      className?: string;
    };

/** A dumb (style-only) component for showing Pagination Controls */
export function PaginationView({
  currentPage = 1,
  pageCount,
  canGetPreviousPage,
  canGetNextPage,
  onNextPage,
  onPrevPage,
  onPageChange,
  className,
}: Props) {
  const { t } = useTranslation();
  const pages = pageCount ? generatePagesFromPageCount(pageCount, currentPage) : [];

  const showPrevPage =
    canGetPreviousPage === true || (currentPage !== 1 && currentPage !== undefined);
  const showNextPage = canGetNextPage === true && currentPage !== pageCount;

  if (!showPrevPage && !showNextPage) return null;

  const buttons = [
    <Button
      key="prev-page"
      onClick={onPrevPage}
      aria-label={t("ui:navigation.back", "Back")}
      disabled={!showPrevPage}
      variant="secondary"
    >
      <Icon name="chevronLeft" className={BUTTON_ICON_CLASS} />
      {t("common:previous")}
    </Button>,
    ...pages.map(({ page, isCurrentPage }, idx) => (
      <Button
        key={`page-button-${idx}`}
        variant="secondary"
        disabled={isCurrentPage}
        className={isCurrentPage ? "bg-primary" : ""}
        onClick={() => onPageChange?.(Number(page))}
      >
        {page}
      </Button>
    )),
    <Button
      key="next-page"
      onClick={onNextPage}
      aria-label={t("ui:navigation.next", "Next")}
      disabled={!canGetNextPage}
      variant="secondary"
    >
      {t("common:next")}
      <Icon name="chevronRight" className={BUTTON_ICON_CLASS} />
    </Button>,
  ];

  return (
    <nav className={twMerge("flex h-10 items-center justify-center", className)}>
      <ButtonGroup className={BUTTON_CLASS}>{buttons}</ButtonGroup>
    </nav>
  );
}

function generatePagesFromPageCount(pageCount: number, currentPage: number) {
  const availablePages = Array.from({ length: pageCount }, (_, i) => i + 1); // Array w. page numbers
  let leftPages: Array<string> = availablePages
    .filter((page) => page < currentPage)
    .map((page) => page.toString());
  let rightPages: Array<string> = availablePages
    .filter((page) => page > currentPage)
    .map((page) => page.toString());

  // When there is no room to show all the pages, we'll try to make it fit by removing some pages
  if (leftPages.length > 3) {
    leftPages = [
      leftPages[0],
      "...",
      leftPages[leftPages.length - 2],
      leftPages[leftPages.length - 1],
    ];
  }
  if (rightPages.length > 3) {
    rightPages = [rightPages[0], rightPages[1], "...", rightPages[rightPages.length - 1]];
  }
  const allPages = [...leftPages, currentPage.toString(), ...rightPages];
  return allPages.map((page) => ({
    page,
    isCurrentPage: page === currentPage.toString(),
  }));
}
