import { useTranslation } from "react-i18next";
import { Icon } from "~/lib/ui//icons/icon";
import { usePreferences } from "~/lib/preferences/use-preferences";
import { Button } from "~/lib/ui/buttons/button";
import supportedBrowsersRegex from "./generated/browsers.gen";

export function UnsupportedBrowserWarning() {
  const { t } = useTranslation();
  const [p, setP] = usePreferences();

  if (supportedBrowsersRegex.test(navigator.userAgent)) return null;
  if (p.dismissBrowswerWarning === true) return null;

  return (
    <div className="relative bg-red-100 p-6">
      <div className="mb-2 flex flex-row items-center gap-4 font-bold">
        <Icon size="medium" name="warningTriangle" />
        <div>{t("errors:unsupported_browser")}</div>
      </div>
      {t("errors:unsupported_browser_description")}
      <Button
        className="absolute right-1 top-1 text-shade-500 hover:underline"
        onClick={() => setP("dismissBrowswerWarning", true)}
      >
        {t("ui:do_not_show_again", "Don't show again")}
      </Button>
    </div>
  );
}
