import { useAPI } from "~/lib/api";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { ExpenseLine, PriceHistory } from "@apacta/sdk";
import { getResolvedLanguage } from "~/lib/i18n/i18n";
import { formatDate } from "~/lib/utils/date";
import { useSuspenseQuery } from "@tanstack/react-query";
import { formatCurrency } from "~/lib/utils/number";

export default function ExpenseLinePriceHistoryPage() {
  const api = useAPI();
  const { expenseLine } = useOutletContext<{ expenseLine: ExpenseLine }>();
  const { t } = useTranslation();
  const queryKey = ["expense_line_price_history"];
  const dataQuery = useSuspenseQuery({
    queryFn: () =>
      api.productPriceHistory({
        expenseLineId: expenseLine.id,
      }),
    queryKey: queryKey,
  });

  const expenseLinePriceHistory = dataQuery.data?.data ?? [];
  return (
    <div className="table">
      <table className="mt-0 text-left">
        <thead>
          <tr>
            <th>{t("common:name")}</th>
            <th>{t("products:variants.latest_update")}</th>
            <th>{t("products:variants.latest_price")}</th>
            <th>{t("common:vendor")}</th>
            <th>{t("common:type")}</th>
          </tr>
        </thead>
        <tbody>
          {expenseLinePriceHistory.map((item: PriceHistory) => (
            <Fragment key={item.id}>
              <tr>
                <td className="font-semibold">
                  <div className="items-center">{item.name}</div>
                </td>
                <td>
                  <div className="items-center">
                    {formatDate(item.latestUpdate, getResolvedLanguage(), { shortDate: true })}
                  </div>
                </td>
                <td className="items-center">
                  {formatCurrency(item.price, { maximumFractionDigits: 2 })}
                </td>
                <td className="items-center">
                  <div>
                    {item.vendorImage ? (
                      <img src={item.vendorImage} width={60} alt={item.vendorName}></img>
                    ) : (
                      <span className="font-semibold">{item.vendorName}</span>
                    )}
                  </div>
                </td>
                <td className="items-center">
                  <a
                    href={
                      item.type === "expense_line"
                        ? `/companies/expenses/edit/${item.parentId}`
                        : `/products/vendor-products/${item.parentId}`
                    }
                  >
                    <div>
                      {item.parentId
                        ? item.type === "expense_line"
                          ? t("products:expense_line", { count: 1 })
                          : t("products:product_type.vendor_product", { count: 1 })
                        : t("common:price_file")}
                    </div>
                  </a>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
