import { BaseFile } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { Spinner } from "~/lib/ui/spinner";
import { randomUUID } from "~/lib/uuid/random-uuid";
import { twMerge } from "tailwind-merge";
import { BadgeLabel } from "~/lib/ui/badge-label";
import { Icon } from "~/lib/ui";
import { DNDUpload } from "~/lib/ui/d-n-d-upload";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { FileCrudPreview } from "~/lib/ui/files/file-crud-preview";

export default function FileCrudSection({
  files,
  editMode,
  loading,
  onSelectFiles,
  onRemoveFile,
  title,
  tooltipText,
}: {
  files: Array<File | BaseFile>;
  editMode: boolean;
  loading: boolean;
  onSelectFiles: (files: FileList) => Promise<void> | void;
  onRemoveFile: (file: File | BaseFile) => void;
  title: string;
  tooltipText?: string;
}) {
  const { t } = useTranslation();

  /**
    The randomUUID assignment will only happen for staged files that are not yet uploaded.
    It should never be the target of a new render cycle, unless it's needed, in which case, it's preferred to have it get a new unique ID.
   */
  const fileIterKey = (file: File | BaseFile) => {
    return `${file.name}-${(file as File).size}-${(file as BaseFile).id ?? randomUUID()}`;
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <h2 className="m-0">{title}</h2>
        {tooltipText && (
          <Tooltip
            trigger={
              <span>
                <QuestionMarkCircleIcon className="h-6 w-6" />
              </span>
            }
          >
            {tooltipText}
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-wrap gap-6">
          {loading ? (
            <Spinner className="h-24 w-24" />
          ) : files.length ? (
            files.map((file) => (
              <div
                key={fileIterKey(file)}
                className={twMerge(
                  "flex w-56 flex-col items-center justify-center gap-2 rounded-lg border bg-white text-sm shadow-md",
                  editMode ? "pt-2" : "pt-8"
                )}
              >
                <div className="flex w-full justify-between p-1">
                  <div>
                    {(file as BaseFile).isStandardFile && (
                      <BadgeLabel className="ml-2 px-3 py-1 text-xs">
                        {t("common:standard_file", { count: 1 })}
                      </BadgeLabel>
                    )}
                  </div>
                  {editMode && (
                    <div
                      onClick={() => onRemoveFile(file)}
                      className="transition-color mr-2 h-6 w-6 cursor-pointer self-end rounded-full bg-shade-100 p-1 duration-200 ease-in-out hover:bg-red-500 hover:text-white"
                    >
                      <Icon name="close" className="h-4 w-4" />
                    </div>
                  )}
                </div>
                <div className="flex flex-col items-center gap-4 px-6">
                  <div className="pt-2">
                    <FileCrudPreview file={file} />
                  </div>
                  <div className="flex pb-6">
                    <div className="w-44 overflow-hidden text-ellipsis whitespace-nowrap">
                      {(file as BaseFile).originalFilename || file.name}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-shade-400">{t("offers:no_files")}</div>
          )}
        </div>
        {editMode && <DNDUpload onSelected={onSelectFiles} multiple />}
      </div>
    </div>
  );
}
