import { ICreateProjectRequest, IGetProject200Response } from "@apacta/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import { useAPI } from "~/lib/api";
import { useToasts } from "~/lib/toast/use-toasts";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { CACHE_PROJECTS } from "..";
import { captureException } from "@sentry/core";
import PriceSection from "~/pages/projects/_cmp/data-components/price-section";
import ProjectSection from "~/pages/projects/_cmp/data-components/project-section";
import EmployeeAccessSection from "../_cmp/data-components/employee-access";
import BlockNavigation from "~/lib/navigation/block-navigation";
import Switch from "~/lib/ui/switch";
import CustomFieldsSection from "~/pages/projects/_cmp/data-components/custom-fields-section";
import ZeroCostWarning from "~/pages/projects/[id]/_cmp/zero-cost-warning";
import { Button } from "~/lib/ui";

type ProjectEntity = IGetProject200Response["data"];

export default function ProjectDataPage() {
  const { entity: project } = useOutletContext<{ entity: ProjectEntity }>();
  const { t } = useTranslation();
  const api = useAPI();

  const updateM = useMutation({
    mutationFn: () =>
      api.iEditProject({ projectId: project.id, iCreateProjectRequest: createMutationObject() }),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED");

      queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS],
      });
      formState.resetInitialValues(formState.getValues());
    },
    onError: (err) => {
      toast.showTemplate("UNEXPECTED_ERROR");
      captureException(new Error("Failed to populate entity list", { cause: err }));
    },
  });

  const formState = useFormState({
    schema: projectFormState,
    initialValues: {
      name: project.name,
      description: project.description,
      contactId: project.contactId,
      contactPersonId: project.contactPersonId,
      streetName: project.streetName,
      zipCode: project.zipCode,
      cityId: project.cityId,
      cityName: project.cityName,
      isFixedPrice: project.isFixedPrice,
      invoiceDiscountPercent: project.invoiceDiscountPercent || 0,
      productsTotalCostPrice: project.productsTotalCostPrice || 0,
      totalSalesPrice: project.totalSalesPrice || 0,
      workingHoursTotalCostPrice: project.workingHoursTotalCostPrice || 0,
      customFields: project.customFields ?? {},
    },
    mutationError: updateM.error,
  });

  const { getValue, onChange } = formState;

  const queryClient = useQueryClient();
  const toast = useToasts();

  const createMutationObject = (): ICreateProjectRequest => {
    const v = formState.getValues();
    return {
      name: v.name,
      description: v.description,
      contactId: v.contactId,
      contactPersonId: v.contactPersonId,
      streetName: v.streetName,
      zipCode: v.zipCode,
      cityId: v.cityId,
      cityName: v.cityName,
      isFixedPrice: v.isFixedPrice,
      invoiceDiscountPercent: v.invoiceDiscountPercent,
      productsTotalCostPrice: v.productsTotalCostPrice,
      totalSalesPrice: v.totalSalesPrice,
      workingHoursTotalCostPrice: v.workingHoursTotalCostPrice,
      customFields: v.customFields,
    };
  };

  const handleAutomaticFix = ({
    productCost,
    hoursCost,
  }: {
    productCost: number;
    hoursCost: number;
  }) => {
    formState.resetInitialValues({
      ...formState.getValues(),
      productsTotalCostPrice: productCost,
      workingHoursTotalCostPrice: hoursCost,
    });
  };

  async function handleSave() {
    await updateM.mutateAsync();
  }

  const showZeroCostWarning =
    project.productsTotalCostPrice === 0 &&
    project.workingHoursTotalCostPrice === 0 &&
    project.totalSalesPrice > 0;

  return (
    <>
      <BlockNavigation when={formState.isModified} onSaveBeforeNavigate={handleSave} />
      <TabHeading
        actionArea={
          <Button
            disabled={!formState.isModified || !formState.isValid}
            variant="tertiary"
            tabIndex={0}
            onClick={handleSave}
            loading={updateM.isPending}
          >
            {t("common:save")}
          </Button>
        }
      >
        {t("projects:master_data")}
      </TabHeading>
      <div className="flex h-full flex-col gap-8">
        <div className="flex flex-col gap-8 md:flex-row">
          <ProjectSection project={project} formState={formState} />
        </div>
        <div className="flex flex-col">
          <div className="mb-2 text-gray-500">{t("projects:pricing")}</div>
          <div className="flex flex-col gap-6 rounded-md bg-white p-4 shadow-md">
            <Switch
              label={t("projects:fixed_price")}
              defaultChecked={getValue("isFixedPrice")}
              onCheckedChange={(v) => onChange("isFixedPrice", v)}
              disabled={project.isOffer}
            />
            {showZeroCostWarning && (
              <ZeroCostWarning
                project={project}
                onAutomaticFix={handleAutomaticFix}
                showAutomaticButton={true}
              />
            )}
            <div className="flex w-full">
              <div className="basis-3/4">
                <PriceSection
                  isOffer={project.isOffer}
                  isFixedPrice={getValue("isFixedPrice")}
                  invoiceDiscountPercent={getValue("invoiceDiscountPercent")}
                  onInvoiceDiscountPercentChange={(v) => onChange("invoiceDiscountPercent", v)}
                  onIsFixedPriceChange={(v) => onChange("isFixedPrice", v)}
                  productsTotalCostPrice={getValue("productsTotalCostPrice")}
                  onProductsTotalCostPriceChange={(v) => onChange("productsTotalCostPrice", v)}
                  totalSalesPrice={getValue("totalSalesPrice")}
                  onTotalSalesPriceChange={(v) => onChange("totalSalesPrice", v)}
                  workingHoursTotalCostPrice={getValue("workingHoursTotalCostPrice")}
                  onWorkingHoursTotalCostPriceChange={(v) =>
                    onChange("workingHoursTotalCostPrice", v)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 md:flex-row">
          <CustomFieldsSection project={project} formState={formState} />
        </div>

        <EmployeeAccessSection project={project} />
      </div>
    </>
  );
}

export const projectFormState = {
  name: z.string(),
  description: z.string().optional(),
  contactId: z.string().nullish(),
  contactPersonId: z.string().nullish(), // null is used to remove reference
  streetName: z.string().optional(),
  zipCode: z.string().optional(),
  cityId: z.string().optional(),
  cityName: z.string().optional(),
  isFixedPrice: z.boolean(),
  invoiceDiscountPercent: z.number().optional(),
  productsTotalCostPrice: z.number().optional(),
  totalSalesPrice: z.number().optional(),
  workingHoursTotalCostPrice: z.number().optional(),
  customFields: z.record(z.string(), z.string()).optional(),
};
