import { Week } from "~/lib/utils/date/date-utils";
import { WeekSectionHeader } from "~/pages/_cmp/weekly-registration-table/week-section-header";
import { useTranslation } from "react-i18next";
import { WeekSectionProject } from "~/pages/_cmp/weekly-registration-table/week-section-project";
import WeekSectionFooter from "~/pages/_cmp/weekly-registration-table/week-section-footer";
import { Icon } from "~/lib/ui";
import { WeekSectionProjectForm } from "~/pages/_cmp/weekly-registration-table/form-entry-card";
import { WeeklyProject } from "~/pages/employees/index/weekly";
import { twMerge } from "tailwind-merge";
import FormEntryCardPopover from "~/pages/_cmp/weekly-registration-table/form-entry-card-popover";
import { useDevice } from "~/lib/utils/device/use-device";
import { useState } from "react";

export type WeekSectionData = {
  week: Week;
  forms?: Array<WeekSectionProjectForm>;
  projects?: Array<WeeklyProject>;
  onFormClick?: (form: WeekSectionProjectForm) => void;
  canApproveForms?: boolean;
  onToggle?: (projectId: string, state: boolean, forms: Array<string>) => void;
};

export function WeekSection({
  week,
  projects = [],
  canApproveForms = false,
  onToggle,
}: WeekSectionData) {
  const [selectedForm, setSelectedForm] = useState<WeekSectionProjectForm | null>(null);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const { isTouchDevice } = useDevice();

  return (
    <>
      <div className="flex flex-col overflow-hidden rounded-lg shadow-md">
        <div className="flex items-center gap-2 bg-shade-600 py-1 pl-3 text-xl text-white print:bg-inherit print:pl-0 print:text-black">
          <Icon name="calendarDays" />{" "}
          <div>
            {week.year} - {t("common:week", { count: 1 })} {week.weekNumber}
          </div>
        </div>

        <div className="planning-scrollbar overflow-x-auto">
          <table className="w-full border-collapse border bg-white shadow-md">
            <WeekSectionHeader weekDates={week.days} />

            <tbody className="divide-y">
              {projects.length ? (
                projects.map((data) => (
                  <WeekSectionProject
                    key={`p-${week.year}-${week.weekNumber}-${data.id}`}
                    week={week}
                    project={data}
                    onToggle={onToggle}
                    onFormClick={
                      isTouchDevice
                        ? (form: WeekSectionProjectForm) => {
                            setSelectedForm(form);
                            setOpen(true);
                          }
                        : undefined
                    }
                    canApproveForms={canApproveForms}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={8} className="px-3 py-4 sm:text-center">
                    {t("common:no_entity_found", {
                      entity: t("common:registration", { count: 2 }).toLowerCase(),
                    })}
                  </td>
                </tr>
              )}
            </tbody>
            {!!projects.length && <WeekSectionFooter week={week} projects={projects} />}
          </table>
        </div>
      </div>
      {isTouchDevice && (
        <div
          className={twMerge(
            "fixed bottom-0 left-0 right-0 rounded-t-lg border bg-white p-8 transition-transform duration-300 ease-in-out sm:hidden",
            open ? "translate-y-0" : "translate-y-full"
          )}
          onTransitionEnd={() => !open && setSelectedForm(null)}
        >
          <div>
            <div onClick={() => setOpen(false)} className="absolute right-4 top-4 text-shade-400">
              <Icon name="close" className="h-6 w-6" />
            </div>
            {!!selectedForm && <FormEntryCardPopover form={selectedForm} />}
          </div>
        </div>
      )}
    </>
  );
}
