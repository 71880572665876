import { useTranslation } from "react-i18next";
import { IconProp } from "./types";
import { twMerge } from "tailwind-merge";
import { OptionalLink } from "../utils/routing/optional-link";
import { getIcon } from "~/lib/ui/icons/icon";
import { formatAddress } from "~/lib/customer/utils";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";

export interface ContactEntryProps {
  ean?: string;
  email?: string;
  phone?: string;
  address?:
    | string
    | {
        street?: string | null;
        zipCode?: string | null;
        cityName?: string | null;
      };
  inline?: boolean;
  href?: string;
  showEmpty?: boolean;
}

/**
 * Similar to ContactCard, except much more compact.
 */
export function CustomerEntry({ ean, email, phone, address, showEmpty }: ContactEntryProps) {
  const { t } = useTranslation();

  const formattedAddress = formatAddress(address);

  const isCompletelyEmpty = !email && !phone && !formattedAddress && !ean;
  if (isCompletelyEmpty && !showEmpty) return null;

  return (
    <div className={twMerge("col-span-1 divide-y divide-gray-200 rounded-md border")}>
      <div>
        <div className="flex flex-col divide-y divide-gray-200 text-sm">
          <CustomerEntryItem
            label={t("common:address")}
            Icon={getIcon("address")}
            value={formattedAddress}
            showEmpty={showEmpty}
          />
          <CustomerEntryItem
            label={t("common:email")}
            href={email ? `mailto:${email}` : undefined}
            Icon={getIcon("send")}
            value={email}
            showEmpty={showEmpty}
          />
          <CustomerEntryItem
            label={t("common:ean")}
            Icon={getIcon("customerId")}
            value={ean}
            showEmpty={showEmpty}
          />
          <CustomerEntryItem
            label={t("common:phone")}
            href={phone ? `tel:${phone}` : undefined}
            Icon={getIcon("customerContact")}
            value={phone}
            showEmpty={showEmpty}
          />
        </div>
      </div>
    </div>
  );
}

interface EachEntryProps {
  label: string;
  Icon: IconProp;
  value?: string;
  href?: string;
  showEmpty?: boolean;
}

function CustomerEntryItem({ label, Icon, value, href, showEmpty }: EachEntryProps) {
  const { t } = useTranslation();
  if (!showEmpty && !value) return null;

  return (
    <div className="flex flex-1 py-2">
      <OptionalLink to={href}>
        <div className="flex flex-row items-center px-4" title={label}>
          <Tooltip
            trigger={<Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
            className="text-sm"
          >
            {label}
          </Tooltip>
          <span className={twMerge("ml-3", !value && "text-gray-400")}>
            {value || t("common:not_available")}
          </span>
        </div>
      </OptionalLink>
    </div>
  );
}
