import { IListInvoicesFilterEnum, Invoice } from "@apacta/sdk";
import { DataTable, useDataTable } from "~/lib/ui/data-table";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { useState } from "react";
import { Dialog } from "~/lib/ui";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { CACHE_INVOICES } from "..";
import { useAPI } from "~/lib/api";
import { useToastOnError } from "~/lib/utils/hooks";
import { FilterGroup } from "~/lib/ui/filters/filter-group";
import { InvoiceSendDialog } from "./invoice-send-dialog";
import { getSharedTableDefaults, SharedTableBase } from "~/lib/ui/data-table/shared-table-base";
import { useInvoiceTableColumns } from "./invoice-table-columns";
import { useFeatureFlags } from "~/lib/feature-flags";

interface InvoiceTableProps extends SharedTableBase {
  customerId?: string;
  projectId?: string;
  dateFrom?: Date;
  dateTo?: Date;
}

/**
 * Used in invoice-index, but can also be imported for sub-pages of other entities
 */
export function InvoiceTable({
  customerId,
  parentType,
  projectId,
  columnVisibility,
  enableFilters,
  enableGlobalFilter,
  dateFrom,
  dateTo,
}: InvoiceTableProps) {
  const { t } = useTranslation();
  const api = useAPI();
  const flags = useFeatureFlags();

  const [selectedInvoiceForSendDialog, setSelectedInvoiceForSendDialog] = useState<Invoice>();
  const [searchParams, setSearchParams] = useTypedSearchParams<{
    status?: IListInvoicesFilterEnum;
    creditNote?: boolean;
  }>();

  const columns = useInvoiceTableColumns({ onSendInvoice: setSelectedInvoiceForSendDialog });

  const tableState = useDataTableState({
    columnVisibility: {
      actions: flags.has("cp3.invoices") ? true : false,
      ...columnVisibility,
    },
  });
  const dataQ = useQuery({
    queryKey: [
      CACHE_INVOICES,
      "index",
      tableState.state.search,
      tableState.state.pagination,
      tableState.sortingDirection,
      tableState.sortBy,
      tableState.pageNumber,
      searchParams.status,
      searchParams.creditNote,
      customerId,
      projectId,
      dateFrom,
      dateTo,
    ],
    queryFn: () =>
      api.iListInvoices({
        page: tableState.pageNumber,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
        q: tableState.state.search,
        filter: searchParams.status,
        contactId: customerId,
        projectId,
        gteIssuedDate: dateFrom,
        lteIssuedDate: dateTo,
        isCreditNote: searchParams.creditNote,
      }),
  });
  useToastOnError(dataQ.error);
  const defaults = getSharedTableDefaults(parentType);

  const table = useDataTable(
    {
      columns,
      tableState,
      data: dataQ.data?.data,
      isLoading: dataQ.isLoading,
      backendPagination: dataQ.data?.pagination,
      getRowId: (row) => row.id,
    },
    {
      enableGlobalFilter: enableGlobalFilter ?? defaults.enableGlobalFilter ?? true,
      enableFilters: enableFilters ?? defaults.enableFilters ?? true,
    }
  );

  return (
    <>
      <DataTable
        error={dataQ.error}
        table={table}
        search={{
          placeholder: t("invoices:search_placeholder", "Search by number, project or customer"),
        }}
        renderFilters={() => (
          <>
            <FilterGroup
              name={t("common:status")}
              onClear={() => setSearchParams("status", undefined)}
              value={searchParams.status}
              selection={{
                valueFn: (v) => v.value,
                allowMultiple: false,
                onConfirmSelection: (v) => setSearchParams("status", v[0].value),
                renderSelectionItem: (item) => item.label,
                items: [
                  {
                    value: IListInvoicesFilterEnum.Drafted,
                    label: t("common:draft"),
                  },
                  {
                    value: IListInvoicesFilterEnum.Booked,
                    label: t("common:invoice", { count: 1 }),
                  },
                ],
              }}
            />
            <FilterGroup
              name={t("common:credit_note", { count: 1 })}
              onClear={() => setSearchParams("creditNote", undefined)}
              value={searchParams.creditNote}
              selection={{
                valueFn: (v) => v.value,
                allowMultiple: false,
                onConfirmSelection: (v) => setSearchParams("creditNote", v[0].value),
                renderSelectionItem: (item) => item.label,
                items: [
                  {
                    value: true,
                    label: t("common:yes"),
                  },
                  {
                    value: false,
                    label: t("common:no"),
                  },
                ],
              }}
            />
          </>
        )}
      />
      <Dialog
        open={selectedInvoiceForSendDialog !== undefined}
        onOpenChange={() => setSelectedInvoiceForSendDialog(undefined)}
        render={({ onClose }) =>
          selectedInvoiceForSendDialog && (
            <InvoiceSendDialog
              invoiceState={undefined} // Not editing, use existing entity
              invoice={selectedInvoiceForSendDialog}
              onClose={onClose}
            />
          )
        }
      />
    </>
  );
}
