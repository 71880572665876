import { twMerge } from "tailwind-merge";
import { Button, getIcon, Icon, Label } from "~/lib/ui";
import { CardProps, DraggableCard } from "~/pages/planning/_cmp/draggable-card";
import { Dropdown } from "~/lib/ui/dropdown";
import { useTranslation } from "react-i18next";
import { Card, usePlanning } from "~/lib/planning";
import { useEffect, useState } from "react";
import { LabelEntity } from "@apacta/sdk";
import { CategoryLabelPopover } from "~/lib/ui/category-label/category-label-popover";
import { UICategoryLabel } from "~/lib/ui/category-label/u-i-category-label";
import { CardContextMenu } from "~/pages/planning/_cmp/context/card-context-menu";
import { DropZone } from "~/pages/planning/_cmp/drag/drop-zone";
import { randomId } from "~/lib/utils/string";
import { DropZoneOrientation } from "~/pages/planning/_cmp/drag/drag-drop.types";
import SidePanel from "~/lib/ui/side-panel";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";

type SidePanelSortOptionType = "title" | "date";
type SidePanelSortOptionDirection = "asc" | "desc";

export default function UnplannedPanel({
  open,
  isDragging,
  draggedItem,
  onClose,
}: {
  open: boolean;
  isDragging: boolean;
  draggedItem?: CardProps;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { cardStateViewsRaw, cardGetTask, updateCard, cardCanPaste, modalCreate } = usePlanning();

  const [sortKey, setSortKey] = useState<SidePanelSortOptionType>("title");
  const [sortDirection, setSortDirection] = useState<SidePanelSortOptionDirection>("asc");

  const [labelFilter, setLabelFilter] = useState<Array<LabelEntity>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(open);

  const [sortedCards, setSortedCards] = useState<Array<Card>>([]);

  const sortBy = (
    key?: SidePanelSortOptionType,
    direction?: SidePanelSortOptionDirection
  ): void => {
    if (!key || !direction) return;
    setSortKey(key);
    setSortDirection(direction);
  };

  const getNextIndex = (): number => {
    return cardStateViewsRaw.unplanned.length;
  };

  // Handles open state for the panel
  // Rules are as follows:
  // If we are doing nothing, the panel should be open
  // If we are dragging an item TO the panel, we should keep the panel open
  // If we are dragging an item FROM the panel, we should close the panel, and then reopen it again afterwards
  useEffect(() => {
    if (open) {
      if (isDragging && draggedItem) {
        // We are dragging an item
        if (
          cardStateViewsRaw.unplanned.filter((c: Card) => c.id === draggedItem.card.id).length === 0
        ) {
          // Item is coming from outside the panel
          setIsOpen(true);
        } else {
          // Item is coming from inside the panel
          setIsOpen(false);
        }
      } else {
        // We're done dragging, reopen the panel
        setIsOpen(true);
      }
    } else {
      // We're not doing anything
      setIsOpen(false);
    }
  }, [open, isDragging, draggedItem]);

  const handleShowCreate = async () => {
    await modalCreate();
  };

  useEffect(() => {
    setSortedCards(() => sortCards(filterCards()));
  }, [cardStateViewsRaw.unplanned, sortKey, sortDirection, labelFilter]);

  const filterCards = () => {
    const c = [...cardStateViewsRaw.unplanned];
    if (labelFilter.length > 0) {
      return c.filter((card) => {
        const task = cardGetTask({ card });
        if (!task) return false;
        const taskLabelIds = task.labels?.map((l) => l.id) || [];
        const filterIds = labelFilter.map((l) => l.id);

        return filterIds.some((id) => taskLabelIds.includes(id));
      });
    }
    return c;
  };

  const sortCards = (c: Array<Card>) => {
    return c.sort((a, b) => {
      if (sortKey === "title") {
        const ac = cardGetTask({ card: a })?.name;
        const bc = cardGetTask({ card: b })?.name;

        if (!ac || !bc) return 0;

        return sortDirection === "asc" ? ac.localeCompare(bc) : bc.localeCompare(ac);
      } else {
        const ac = a.created;
        const bc = b.created;

        if (!ac || !bc) return 0;

        return sortDirection === "asc"
          ? +new Date(ac) - +new Date(bc)
          : +new Date(bc) - +new Date(ac);
      }
    });
  };

  const handleDrop = (item: { card: Card }) => {
    const updatedCard: Card = {
      ...item.card,
      listIndex: getNextIndex() ?? 0, // new index
      date: undefined,
      userId: undefined, // same user as the card you dropped the item on
      startTime: undefined,
    };

    updateCard(updatedCard, {}, false);
  };

  return (
    <SidePanel
      open={isOpen}
      onClose={() => onClose()}
      header={
        <div className="flex flex-col gap-2 px-6">
          <div className="flex items-center gap-2 text-xl">
            <div>{`${t("planning:side_panel.not_planned")} ${t("common:task", { count: 2 }).toLowerCase()}`}</div>
            <Tooltip
              className="text-sm"
              delay={200}
              trigger={
                <span>
                  <Icon name="tooltip" className="h-5 w-5" />
                </span>
              }
            >
              {t("planning:side_panel.not_planned_tooltip")}
            </Tooltip>
          </div>
          <div className="whitespace-pre-line text-sm text-shade-500">
            {t("planning:side_panel.not_planned_description")}
          </div>
        </div>
      }
      actionButtons={[
        [
          {
            text: t("common:close"),
            onClick: () => onClose(),
            className: "px-3 hover:underline",
          },
        ],
      ]}
    >
      <>
        <div className="flex gap-4 px-6">
          <div className="w-full">
            <Label className="text-xs">{t("planning:side_panel.sort.label")}</Label>
            <Dropdown
              trigger={
                <Button
                  variant="secondary"
                  className="flex w-full flex-shrink-0 flex-grow"
                  tabIndex={-1}
                >
                  <div className="flex flex-grow text-xs">
                    <span className="flex flex-grow">
                      {`${t(`planning:side_panel.sort.options.${sortKey}.label`)}: ${t(
                        `planning:side_panel.sort.options.${sortKey}.${sortDirection}`
                      )}`}
                    </span>
                    <Icon name="chevronDown" className="h-4 w-4" />
                  </div>
                </Button>
              }
              options={{ trigger: { asChild: true, className: "flex flex-grow flex-shrink-0" } }}
            >
              <Dropdown.Item
                className="text-sm"
                onSelect={() => sortBy("title", "asc")}
                Icon={getIcon("sortAsc")}
              >
                {`${t("planning:side_panel.sort.options.title.label")}: ${t(
                  "planning:side_panel.sort.options.title.asc"
                )}`}
              </Dropdown.Item>
              <Dropdown.Item
                className="text-sm"
                onSelect={() => sortBy("title", "desc")}
                Icon={getIcon("sortDesc")}
              >
                {`${t("planning:side_panel.sort.options.title.label")}: ${t(
                  "planning:side_panel.sort.options.title.desc"
                )}`}
              </Dropdown.Item>
              <Dropdown.Item
                className="text-sm"
                onSelect={() => sortBy("date", "asc")}
                Icon={getIcon("sortAsc")}
              >
                {`${t("planning:side_panel.sort.options.date.label")}: ${t(
                  "planning:side_panel.sort.options.date.asc"
                )}`}
              </Dropdown.Item>
              <Dropdown.Item
                className="text-sm"
                onSelect={() => sortBy("date", "desc")}
                Icon={getIcon("sortDesc")}
              >
                {`${t("planning:side_panel.sort.options.date.label")}: ${t(
                  "planning:side_panel.sort.options.date.desc"
                )}`}
              </Dropdown.Item>
            </Dropdown>
          </div>
          <div>
            <Label className="text-xs">{t("common:filtering")}</Label>
            <CategoryLabelPopover
              triggerRender={() => (
                <Button variant="secondary" className="flex flex-shrink-0 flex-grow" tabIndex={-1}>
                  <div className="flex flex-grow gap-4 text-xs">
                    <span className="flex flex-grow">{t("common:label", { count: 1 })}</span>
                    <Icon name="chevronDown" className="h-4 w-4" />
                  </div>
                </Button>
              )}
              entity="task"
              initialSelectedLabels={labelFilter}
              onSelect={(labels) => setLabelFilter(labels)}
              options={{ multiselect: true, side: "bottom" }}
            />
          </div>
        </div>

        {labelFilter.length > 0 && (
          <div className="flex flex-wrap gap-2 px-6">
            {labelFilter.map((f) => (
              <div key={f.id}>
                <UICategoryLabel
                  id={f.id}
                  text={f.text}
                  bgColor={f.bgColor}
                  onRemove={(id) =>
                    setLabelFilter((prevState) => prevState.filter((l) => l.id !== id))
                  }
                />
              </div>
            ))}
          </div>
        )}

        {/* Content */}
        <div className="planning-scrollbar flex grow flex-col gap-2 overflow-y-auto">
          <div className="flex items-center justify-between px-6">
            <Label className="mb-0 text-xs">{t("common:task", { count: 2 })}</Label>
            <Button
              variant="tertiary"
              onClick={() => handleShowCreate()}
              className="h-6 w-6 rounded-full p-0.5"
            >
              <Icon name="add" className="h-5 w-5" />
            </Button>
          </div>
          <div className="flex w-full grow flex-col px-6">
            {sortedCards.map((sectionCard, idx, cc) => (
              <div
                key={`card-${sectionCard.id}-${idx}`}
                className={twMerge("flex flex-col", idx === cc.length - 1 && isDragging && "grow")}
              >
                <CardContextMenu card={sectionCard} canPaste={cardCanPaste}>
                  <DraggableCard
                    card={sectionCard}
                    isLast={idx === cc.length - 1}
                    index={idx}
                    useFullWidth={true}
                  />
                </CardContextMenu>
              </div>
            ))}
            <DropZone
              key={randomId()}
              isDragging={isDragging}
              onDrop={(item: { card: Card }) => handleDrop(item)}
              orientation={DropZoneOrientation.VERTICAL}
              showMarker={true}
            />
          </div>
        </div>
      </>
    </SidePanel>
  );
}
