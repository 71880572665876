import { useQuery } from "@tanstack/react-query";
import { DateInterval, useGraphQL, VendorSelectionQuery } from "~/lib/gql";
import { useMemo, useState } from "react";
import { useToastOnError } from "../utils/hooks";

/**
 * ONLY USED FOR PROCUREMENT
 * DO NOT USE IN OTHER PLACES
 */

const CACHE_COMPANIES_VENDORS = "companies-vendors";

export function useCompaniesVendors(dR?: DateInterval) {
  const [dateRange, setDateRange] = useState<DateInterval | undefined>(dR);
  const sdk = useGraphQL();

  const companiesVendorsQuery = useQuery({
    queryKey: [CACHE_COMPANIES_VENDORS, dateRange],
    queryFn: () =>
      sdk.vendorSelection({
        params: {
          sortBy: "VENDOR_NAME",
          direction: "ASC",
          withExpensesWithinDateInterval: dateRange,
        },
      }),
  });
  useToastOnError(companiesVendorsQuery.error);

  const companiesVendors: VendorSelectionQuery["companiesVendors"]["edges"] = useMemo(() => {
    return companiesVendorsQuery.data?.companiesVendors.edges ?? [];
  }, [companiesVendorsQuery.data?.companiesVendors.edges]);

  return { companiesVendors, isFetching: companiesVendorsQuery.isFetching, setDateRange };
}
