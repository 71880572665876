import { ComponentProps, forwardRef, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, getIcon } from "~/lib/ui";
import {
  FullScreenFilePreview,
  FullScreenFilePreviewBannerConfig,
  FullScreenFilePreviewProps,
} from "~/lib/ui/media/full-screen-file-preview";

type Props = Omit<ComponentProps<typeof Button>, "children"> & {
  children?: ReactNode;
  preview: Partial<ComponentProps<typeof FullScreenFilePreview>>;
  unsavedChanges?: boolean;
  onSaveChanges?: () => Promise<unknown>;
};

// Wrap <Button /> with ref and add FullScreenPDFPreviewDialog
export const PreviewButton = forwardRef<HTMLButtonElement, Props>(
  function PreviewButton(props, ref) {
    const { children, Icon, preview, unsavedChanges, onSaveChanges, ...restProps } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [lastUpdated, setLastUpdated] =
      useState<FullScreenFilePreviewProps["lastUpdated"]>("unknown");

    const handleSaveChanges = async () => {
      if (!!onSaveChanges) {
        // Set loading state in the preview
        setLastUpdated("loading");

        // Perform save action
        await onSaveChanges();

        // Force reload the content of the preview
        setLastUpdated(new Date());
      }
    };

    const bannerOptions: FullScreenFilePreviewBannerConfig = {
      visible: !!unsavedChanges,
      description: t("common:unsaved_changes_preview_banner.title"),
      buttonText: t("common:unsaved_changes_preview_banner.button_text"),
      buttonOnClick: handleSaveChanges,
    };

    return (
      <>
        <Button
          Icon={Icon ?? getIcon("preview")}
          ref={ref}
          onClick={() => setOpen(true)}
          {...restProps}
        >
          {children ?? `${t("common:see")} ${t("common:pdf")}`}
        </Button>
        <FullScreenFilePreview
          lastUpdated={lastUpdated}
          open={open}
          onClose={() => setOpen(false)}
          {...preview}
          banner={bannerOptions}
        />
      </>
    );
  }
);
