/**
 * Finance Utilities
 *
 * Used in Product Pricing for now. Hopefully other places later.
 *
 * Note: I (Andri) hate that we're rounding floats to 2 decimals, but it's a necessary evil for now.
 * - I have no good way of displaying a rounded value in our inputs, while keeping full precision in the calculations.
 */

import { PriceMargin } from "@apacta/sdk";

/** Contribution Ratio (Dækningsgrad)
 */
export function calculateContributionRatio(
  revenue: number | undefined = 0,
  cost: number | undefined = 0
): number {
  // **Salgspris - omkostninger = dækningsbidrag**
  if (revenue === 0) return 0;

  const margin = revenue - cost;
  if (margin === 0) return 0; // Can't divide by zero

  return displayFloat((margin / revenue) * 100);
}

export function costFromRevenueAndRatio(revenue: number = 0, ratio: number = 0): number {
  if (ratio > 100) throw ErrorRatio;
  if (ratio === 100) return 0;
  return displayFloat(revenue - revenue * (ratio / 100));
}

const ErrorRatio = new Error("Ratio can't be 100 or more");

export function revenueFromCostAndRatio(cost: number = 0, ratio: number = 0): number {
  if (cost > 0 && ratio >= 100) throw ErrorRatio;
  if (cost === 0 && ratio > 100) throw ErrorRatio;

  return displayFloat(cost / (1 - ratio / 100));
}

export function displayFloat(num: number = 0, decimals: number = 2): number {
  return Number(num.toFixed(decimals));
}

export function contributionRatioFromCostAndPriceMargins(
  cost: number = 0,
  margins: Array<PriceMargin>
): number {
  const sorted = margins.toSorted((a, b) => a.amountFrom - b.amountFrom);
  const selectedPriceMargin = sorted.find((m) => cost >= m.amountFrom && cost <= m.amountTo);
  if (!selectedPriceMargin) {
    console.warn(
      "Price margins enabled. But no price margin found for cost - defaulting to 0",
      cost
    );
    return 0; // We should warn in UI if the ratio is 0
  }
  return displayFloat(selectedPriceMargin.percentageRatio);
}
