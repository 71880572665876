import * as SwitchPrimitive from "@radix-ui/react-switch";
import { useId } from "react";
import { twMerge } from "tailwind-merge";
import { Label } from "./label";

type BaseSwitchProps = {
  label?: string;
  onCheckedChange: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  error?: string;
  size?: "small" | "medium";
};

type ControlledSwitchProps = BaseSwitchProps & {
  defaultChecked?: never;
  checked: boolean;
  controlled: true;
};

type UncontrolledSwitchProps = BaseSwitchProps & {
  defaultChecked?: boolean;
  controlled?: never;
  checked?: never;
};

type SwitchProps = ControlledSwitchProps | UncontrolledSwitchProps;

export default function Switch({
  label,
  defaultChecked,
  checked,
  disabled = false,
  onCheckedChange,
  className,
  controlled,
  required,
  error,
  size = "medium",
}: SwitchProps) {
  const id = useId();

  const sizes = {
    small: {
      root: "h-4 w-7",
      thumb: "h-3 w-3 data-[state=checked]:translate-x-3.5 data-[state=unchecked]:translate-x-0.5",
    },
    medium: {
      root: "h-6 w-10",
      thumb: "h-4 w-4 data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-1",
    },
  };

  return (
    <div className={twMerge("flex flex-col items-start justify-center", className)}>
      {label && (
        <Label className="" htmlFor={id} required={required}>
          {label}
        </Label>
      )}
      <SwitchPrimitive.Root
        className={twMerge(
          "relative rounded-full bg-secondary fill-red-50  data-[state=checked]:bg-tertiary",
          sizes[size].root,
          disabled ? "bg-gray-300 data-[state=checked]:bg-gray-300" : "hover:bg-hover"
        )}
        id={id}
        disabled={disabled}
        defaultChecked={controlled ? undefined : defaultChecked}
        checked={controlled ? checked : undefined}
        onCheckedChange={onCheckedChange}
        onClick={(e) => e.stopPropagation()}
      >
        <SwitchPrimitive.Thumb
          className={twMerge(
            "block rounded-full bg-white transition-transform duration-100 ease-in-out",
            sizes[size].thumb
          )}
        />
      </SwitchPrimitive.Root>
      {error && <p className="mt-2 text-left text-sm text-red-600">{error}</p>}
    </div>
  );
}
