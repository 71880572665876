import { formatCurrency } from "~/lib/utils/number";
import { useTranslation } from "react-i18next";
import {
  ProcurementKpiCard,
  ProcurementKpiCardProps,
} from "~/pages/procurement/_cmp/kpi/procurement-kpi-card";
import { ProcurementDetailQuery } from "~/lib/gql";
import { KPISkeletonItem } from "~/lib/ui/skeleton/kpi-skeleton";

export function PriceAnalysisKpiSection({
  analysis,
  isFetching,
}: {
  analysis: ProcurementDetailQuery["procurementAnalysis"];
  isFetching: boolean;
}) {
  const { t } = useTranslation();

  const kpiCards: Array<ProcurementKpiCardProps> = [
    {
      title: t("procurement:kpi.summed_potential.title"),
      iconName: "chart",
      value: analysis.summedPotential,
      valueRender: (value: number) => <span>{formatCurrency(value)}</span>,
    },
  ];

  if (isFetching) {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        {kpiCards.map((_, idx) => (
          <KPISkeletonItem key={idx} />
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
      {kpiCards.map((kpiCard, idx) => (
        <ProcurementKpiCard {...kpiCard} key={`procurement-kpi-${idx}`} />
      ))}
    </div>
  );
}
