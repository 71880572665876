import { To, useLocation, useNavigate } from "react-router";
import { TabHeader, TabHeaderItem } from "~/lib/ui/tabs/tab-header";
import { createSearchParams } from "react-router";

export default function PageTabs({
  tabs,
  preserveSearchParams = false,
  searchParamsToPreserve = [],
}: {
  tabs: Array<TabHeaderItem>;
  preserveSearchParams?: boolean;
  searchParamsToPreserve?: Array<string>;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelectTab = (tab: TabHeaderItem) => {
    let search = createSearchParams(location.search);

    // Preserve only certain search params
    if (searchParamsToPreserve.length) {
      // Based on searchParamsToPreserve, remove all other search params
      const newSearch = createSearchParams();

      searchParamsToPreserve.forEach((param) => {
        if (search.has(param)) {
          newSearch.set(param, search.get(param)!);
        }
      });

      search = newSearch;
    }

    const to: To = {
      pathname: tab.to,
      search: preserveSearchParams ? search.toString() : undefined,
    };

    if (to.pathname === undefined) return;

    navigate(to, { replace: true });
  };

  // This isn't bulletproof, but works.
  let selectedIndex = tabs.findIndex((tab) => {
    const to = tab.to;
    if (!to) return false;
    return location.pathname.includes(to);
  });
  if (selectedIndex === -1) {
    selectedIndex = 0;
  }

  return (
    <TabHeader
      selectedTab={tabs[selectedIndex]}
      tabs={tabs}
      onSelect={handleSelectTab}
      className="mb-8 print:hidden"
    />
  );
}
