import { OutletContext } from "../_cmp/types";
import { useOutletContext } from "react-router";
import { PlanningViewSingleDay } from "../_cmp/planning-view-single-day";
import PlanningViewMultipleDays from "../_cmp/planning-view-multiple-days";

export default function DayViewPage() {
  const { view } = useOutletContext<OutletContext>();

  switch (view) {
    case "day":
      return <PlanningViewSingleDay />;
    case "week":
    case "fortnight":
    case "month":
      return <PlanningViewMultipleDays />;
    default:
      throw new Error("Unhandled planning view");
  }
}
