export default function useDownload() {
  const download = async (url: string) => {
    const blob = await fetch(url)
      .then((res) => res.blob())
      .catch(() => {
        // Data could not be fetched, try location.href
        window.location.href = url;
      });

    if (!blob) {
      return;
    }

    const _url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = _url;
    a.download = url.split("/").pop() ?? "";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(_url);
  };

  return { download };
}
