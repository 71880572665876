import { CustomerSelection } from "~/lib/ui/selection-combobox/customer-selection";
import { ContactPersonSelection } from "~/lib/ui/selection-combobox/contact-person-selection";
import { Contact, ContactPerson } from "@apacta/sdk";

export default function CustomerAndContactPersonSelection({
  selectedCustomerId,
  selectedContactPersonId,
  onSelectCustomer,
  onSelectContactPerson,
}: {
  selectedCustomerId?: string;
  selectedContactPersonId?: string;
  onSelectCustomer: (customer: Contact | undefined) => void;
  onSelectContactPerson: (contactPerson: ContactPerson | undefined) => void;
}) {
  const handleSelectCustomer = (customer: Contact | undefined) => {
    onSelectCustomer(customer);
    onSelectContactPerson(undefined); // Reset contact person on customer change
  };

  const handleSelectContactPerson = (contactPerson: ContactPerson | undefined) => {
    onSelectContactPerson(contactPerson);
  };

  return (
    <>
      <CustomerSelection defaultValue={selectedCustomerId} onSelectItem={handleSelectCustomer} />
      <ContactPersonSelection
        disabled={!selectedCustomerId}
        customerId={selectedCustomerId}
        defaultValue={selectedContactPersonId}
        onSelectItem={handleSelectContactPerson}
      />
    </>
  );
}
