import { Button, Icon } from "../..";
import { forwardRef, Ref } from "react";
import { Spinner } from "../../spinner";
import { twMerge } from "tailwind-merge";
import { Badge } from "../../badge";
import { useTranslation } from "react-i18next";
import { useSelectionCombobox } from "../context";

/** Higher than this, and we show a single badge instead of listing everything */
const MAX_NUMBER_OF_SHOWN_ITEMS = 20;

export const DefaultMultiSelectTrigger = forwardRef(function TriggerInner<T>(
  props: object = {}, // Radix will inject something here that we don't care about
  ref: Ref<HTMLButtonElement>
) {
  const { t } = useTranslation();
  const { triggerItems, placeholder, labelFn, onRemoveItem, valueFn, readonly, error, loading } =
    useSelectionCombobox<T>();
  const renderedLabel = (idx: number) => {
    if (!triggerItems[idx]) return placeholder;
    return labelFn(triggerItems[idx]);
  };

  function handleRemoveItem(e: React.MouseEvent<HTMLButtonElement>, item: unknown) {
    e.stopPropagation(); // So it doesn't trigger the popover
    onRemoveItem?.(valueFn(item as T));
  }

  return (
    <Button
      ref={ref}
      role="combobox"
      className="w-full cursor-pointer items-center rounded-md border  bg-white px-3 py-1 text-left text-sm font-normal text-gray-700 shadow-sm transition-colors duration-200 focus-visible:outline-hover sm:hover:border-hover sm:hover:text-hover"
      {...props}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex min-h-[2rem] flex-wrap items-center gap-2">
          {loading && <Badge variant="gray">{t("common:loading")}</Badge>}
          {!loading && triggerItems && triggerItems.length > MAX_NUMBER_OF_SHOWN_ITEMS && (
            <Badge variant="gray">
              {t("common:x_selected", { replace: { x: triggerItems.length } })}
            </Badge>
          )}
          {triggerItems &&
            triggerItems.length <= MAX_NUMBER_OF_SHOWN_ITEMS &&
            triggerItems?.map((item, idx) => (
              <Badge key={valueFn(item)} variant="gray" className="inline-flex gap-2">
                {renderedLabel(idx)}
                {!readonly && (
                  <Button
                    as="span"
                    onClick={(e) => handleRemoveItem(e, item)}
                    className="cursor-pointer rounded-md p-0.5 hover:bg-black/20"
                  >
                    <Icon name="remove" className="h-3 w-3" />
                  </Button>
                )}
              </Badge>
            ))}
          {!loading && triggerItems && triggerItems.length === 0 && (
            <span className={twMerge("text-gray-500", !readonly && "mr-4")}>{placeholder}</span>
          )}
          {readonly ? null : (
            <Button
              as="span"
              className={twMerge(
                "flex h-6 w-6 items-center justify-center overflow-hidden rounded-full border border-gray-300 bg-shade-100 p-1 text-shade-800 hover:border-none hover:bg-hover hover:text-white",
                error && "border-error"
              )}
            >
              {loading ? <Spinner className="h-4 w-4" /> : <Icon name="add" className="h-4 w-4" />}
            </Button>
          )}
        </div>
      </div>
    </Button>
  );
});
