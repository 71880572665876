import { useCallback, useMemo, useState } from "react";
import * as htmlparser2 from "htmlparser2";
import { useAPI } from "~/lib/api";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import { Icon } from "~/lib/ui";
import { Badge } from "~/lib/ui/badge";
import { useMount } from "~/lib/lifecycle-helpers";
import { useSidebarContext } from "~/lib/navigation/navigation-layout/sidebar-context";
import { twMerge } from "tailwind-merge";
import { Tooltip } from "~/lib/ui/tooltips/tooltip";

export function useFetchReleaseNotes() {
  const api = useAPI();
  const q = useSuspenseQuery({
    queryKey: ["release-notes"],
    queryFn: () => api.getReleaseNotes(),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const feed = useMemo(() => {
    const f = htmlparser2.parseFeed(q.data, {
      xmlMode: true,
    });
    return f;
  }, [q.data]);

  return feed;
}

export function ReleaseNotesNavItem() {
  return (
    <ErrorBoundary fallback={null}>
      <NewsComponent />
    </ErrorBoundary>
  );
}

const LAST_READ_KEY = "APACTA_RELEASE_NOTES_LAST_READ";

const NewsComponent = () => {
  const { t } = useTranslation();
  const f = useReleaseNotes();
  const { sidebarCollapsed } = useSidebarContext();

  const handleClick = () => {
    f.markRead();
  };

  return (
    <Tooltip
      disabled={!sidebarCollapsed}
      side="right"
      contentClassName="bg-hover text-white"
      arrowClassName="fill-hover"
      trigger={
        <a
          href={f.feed?.link}
          target="_blank"
          rel="noreferrer"
          onClick={handleClick}
          className="w-full"
        >
          <div className="m-0 flex w-full min-w-12 rounded-lg p-0 px-2 py-2 text-sm text-shade-200 hover:bg-hover hover:text-white">
            <div
              className={twMerge(
                "flex w-full flex-row items-center",
                sidebarCollapsed ? "relative justify-center" : "justify-between"
              )}
            >
              <div className="flex items-center">
                <Icon
                  name="news"
                  className={
                    !sidebarCollapsed
                      ? "mr-3 h-5 w-5 flex-shrink-0 text-gray-200 hover:bg-hover"
                      : "h-6 w-6 flex-shrink-0 text-gray-200 hover:bg-hover"
                  }
                />
                {!sidebarCollapsed && t("common:news", "News")}
              </div>
              {f.unreadCount > 0 && (
                <>
                  {sidebarCollapsed ? (
                    <div className="absolute -right-1 -top-1">
                      <div className="flex h-4 w-4 items-center justify-center rounded-full bg-red-700 pt-px text-center">
                        {f.unreadCountString}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Badge
                        label={f.unreadCountString}
                        className="ml-4 bg-red-700 text-xs text-white"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </a>
      }
    >
      {t("common:news", "News")}
    </Tooltip>
  );
};

function useReleaseNotes() {
  const f = useFetchReleaseNotes();
  const [lastRead, setLastRead] = useState<Date>();

  useMount(() => {
    const lr = localStorage.getItem(LAST_READ_KEY);
    if (lr) {
      setLastRead(new Date(lr));
    }
  });

  const markRead = useCallback(() => {
    // Mark last read timestamp
    const newRead = new Date();
    localStorage.setItem(LAST_READ_KEY, newRead.toISOString());
    setLastRead(newRead);
  }, []);

  const unreadCount = useMemo(() => {
    if (!f) return 0;
    const count = f.items.filter((i) => {
      if (!lastRead) return true;
      if (!i.pubDate) return false;
      const idate = new Date(i.pubDate);
      return idate > lastRead;
    }).length;

    return count;
  }, [lastRead]);

  return {
    unreadCount: unreadCount satisfies number,
    unreadCountString: unreadCount > 5 ? "5+" : unreadCount.toString(),
    markRead,
    feed: f,
  };
}
