import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { NumberInput } from "~/lib/ui/form-elements";
import { z } from "zod";
import { getIcon, Icon } from "~/lib/ui";
import { Suspense, useEffect, useRef, useState } from "react";
import { useToasts } from "~/lib/toast/use-toasts";
import { EditSubscriptionOperationRequest, GetMe200ResponseSubscription } from "@apacta/sdk";

export function ManageSeatsDialog({
  previousAvailableSeats,
  usedSeats,
  subscription,
  onSeatsUpdated,
}: {
  previousAvailableSeats: number;
  usedSeats: number;
  subscription: GetMe200ResponseSubscription;
  onSeatsUpdated: (seats?: number) => void;
}) {
  const [localSeats, setLocalSeats] = useState<number>(previousAvailableSeats);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const toast = useToasts();

  const updateSeats = (newSeats: number) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the existing timeout
    }

    timeoutRef.current = setTimeout(() => {
      setLocalSeats(newSeats); // Update state after debounce
    }, 500); // 500ms debounce
  };
  const { t } = useTranslation();
  const api = useAPI();

  const mutation = useMutation({
    mutationFn: (args: EditSubscriptionOperationRequest) => api.editSubscription(args),
    onSuccess: (data) => {
      if (!data.data.id) {
        return;
      }
      onSeatsUpdated(getValue("availableSeats"));
      const toastDescription = isDecreasingSeatCount
        ? t("users:seats_modal.decreasing_seats_description")
        : t("common:changes_saved_description");
      toast.show({
        title: t("common:changes_saved"),
        description: toastDescription,
        iconName: "selectedCheck",
      });
    },
  });

  // Field inputs
  const { isValid, getValue, setValues } = useFormState({
    schema: {
      availableSeats: z.number(),
    },
    initialValues: {
      availableSeats: previousAvailableSeats,
    },
    mutationError: mutation.error,
  });

  useEffect(() => {
    setValues({ availableSeats: localSeats });
  }, [localSeats]);

  const isReducingBelowActiveUsers = getValue("availableSeats") < usedSeats;
  const isDecreasingSeatCount = localSeats < previousAvailableSeats;
  const scheduledSeatsCount = subscription.seatsScheduledChanges;

  async function handleSubmit(): Promise<void> {
    if (!isValid) {
      return;
    }
    await mutation.mutateAsync({
      subscriptionId: subscription.id,
      editSubscriptionRequest: { seats: getValue("availableSeats") },
    });
  }

  return (
    <Suspense>
      <div className="mb-6">
        <DialogHeader title={t("users:adjust_seats")} Icon={getIcon("employee")} />
        <div>
          {isReducingBelowActiveUsers && (
            <div className="mb-8 flex flex-col gap-2 border bg-gray-100 p-2">
              <div className="flex items-center gap-4">
                <div className="rounded-full bg-red-500 p-1.5 text-white">
                  <Icon name="warningTriangle" className="h-6 w-6" />
                </div>
                <div className="text-sm">{t("users:seats_modal.warning_message")}</div>
              </div>
            </div>
          )}
        </div>
        <div>
          {isDecreasingSeatCount && (
            <div className="mb-8 flex flex-col gap-2 border bg-gray-100 p-2">
              <div className="flex items-center gap-4">
                <div className="rounded-full bg-yellow-500 p-1.5 text-white">
                  <Icon name="warningTriangle" className="h-6 w-6" />
                </div>
                <div className="text-sm">
                  {t("users:seats_modal.decreasing_seats_count", {
                    seatCount: previousAvailableSeats - localSeats,
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {scheduledSeatsCount && true && (
            <div className="mb-8 flex flex-col gap-2 border bg-gray-100 p-2">
              <div className="flex items-center gap-4">
                <div className="rounded-full bg-yellow-500 p-1.5 text-white">
                  <Icon name="warningTriangle" className="h-6 w-6" />
                </div>
                <div className="text-sm">
                  {t("users:seats_modal.scheduled_decreasing_seats", {
                    seatCount: previousAvailableSeats - scheduledSeatsCount,
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="mt-2">
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
                <NumberInput
                  step={1}
                  defaultValue={previousAvailableSeats}
                  onChange={(e) => updateSeats(Number(e.currentTarget.value))}
                  label={t("users:current_seats")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          {usedSeats} {t("users:seats_modal.used_seats")}
        </div>
      </div>
      <DialogFooter
        onClose={onSeatsUpdated}
        primary={{
          onClick: handleSubmit,
          label: t("common:save"),
          disabled: !isValid || isReducingBelowActiveUsers,
          loading: mutation.isPending,
        }}
      />
    </Suspense>
  );
}
