import { DropTargetMonitor, useDrop } from "react-dnd";
import { DragDropCardType } from "~/pages/planning/_cmp/drag/drag-drop.types";
import { Card, usePlanning } from "~/lib/planning";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { dateIsWeekend, moveDateRetainTime } from "~/lib/utils/date/date-utils";
import { useHolidays } from "~/lib/calendar/use-holidays";
import { generateBackgroundColor } from "~/lib/utils/colors";
import colors from "tailwindcss/colors";
import { User } from "@apacta/sdk";
import { DropzoneContextMenu } from "~/pages/planning/_cmp/context/dropzone-context-menu";

export default function DropCell({
  user,
  date,
  nextIndex,
  children,
}: {
  user?: User;
  date: Date;
  nextIndex: number;
  children: ReactNode;
}) {
  const { updateCard, view, cardCanPaste } = usePlanning();
  const { isHoliday } = useHolidays(date);

  const [{ isOver }, dropRef] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: DragDropCardType,
    // Props to collect
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
    drop: (item: { card: Card }, monitor) => {
      // Check if the item was dropped on a child
      // If so, just return, we don't want to handle the drop twice
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }
      handleDrop(item);
    },
  }));

  const handleDrop = (item: { card: Card }) => {
    const updatedCard: Card = {
      ...item.card,
      listIndex: nextIndex ?? 0, // new index
      date: date, // same date as the card you dropped the item on
      userId: user?.id, // same user as the card you dropped the item on
      startTime:
        item.card.startTime && date ? moveDateRetainTime(item.card.startTime, date) : undefined, // update startTime if it exists
    };

    updateCard(updatedCard, {}, false);
  };

  const isDayView = view === "day";

  const weekendBgClassName = dateIsWeekend(date) ? "bg-gray-200/50" : undefined;
  const holidayBgClassName = isHoliday(date) ? "bg-yellow-50" : undefined;
  const bgClassName = holidayBgClassName ?? weekendBgClassName ?? "bg-white";

  const cellUserPrimaryColor = !!user ? generateBackgroundColor(user.fullName) : undefined;
  const cellNoUserPrimaryColor = !user ? colors["gray"][400] : undefined;
  const cellPrimaryColor = cellUserPrimaryColor ?? cellNoUserPrimaryColor ?? undefined;

  return (
    <DropzoneContextMenu
      triggerAsChild
      date={date}
      index={nextIndex}
      userId={user?.id}
      canPaste={cardCanPaste}
    >
      <td
        className={twMerge(
          "group border-r p-0 align-top",
          isDayView ? "w-full" : "w-40",
          bgClassName
        )}
        style={{
          backgroundColor: isOver ? cellPrimaryColor + "10" : undefined, // "10" represents opacity in hex
        }}
        ref={(ref) => {
          dropRef(ref);
        }}
      >
        <div
          className={twMerge(
            "group flex min-h-16 grow border-t-4",
            isDayView ? "" : "flex-col",
            isOver ? "" : ""
          )}
          style={{
            borderTopColor: cellPrimaryColor,
          }}
        >
          {children}
        </div>
      </td>
    </DropzoneContextMenu>
  );
}
