import { createContext, ReactElement, useCallback, useState } from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";
import { Toast, ToastVariantClasses } from "~/lib/toast/toast";
import { IconName } from "~/lib/ui";

/**
 * Public Toast Message
 * Used to create toast messages
 */
export type ToastMessage = {
  title: string;
  description?: string;
  iconName?: IconName;
  variant?: keyof typeof ToastVariantClasses;
  duration?: number;
  action?: {
    label: string;
    onClick: () => unknown | Promise<unknown>;
  };
};

/**
 * Internal Toast Message
 * Contains additional properties that are not required to be set during creation
 */
export type InternalToastMessage = ToastMessage & {
  id: string;
  onDestroy: () => void;
};

export const ToastContext = createContext<{
  add: (toast: ToastMessage) => void;
}>({
  add: () => undefined,
});

export const ToastProvider = ({ children }: { children: ReactElement }) => {
  const [items, setItems] = useState<Map<string, InternalToastMessage>>(
    new Map<string, InternalToastMessage>()
  );

  const add = useCallback(
    (toast: ToastMessage): void => {
      setItems((prev) => {
        const id = new Date().getTime().toString();
        const onDestroy = () => remove(id);

        const newMap = new Map<string, InternalToastMessage>(prev);
        newMap.set(id, { ...toast, id, onDestroy } satisfies InternalToastMessage);

        return newMap;
      });
    },
    [items, setItems]
  );

  const remove = useCallback(
    (id: string): void => {
      setItems((prev) => {
        prev.delete(id);
        return prev;
      });
    },
    [setItems, items]
  );

  return (
    <ToastContext.Provider value={{ add }}>
      <ToastPrimitive.Provider>
        {/* Render children*/}
        {children}

        {/* Render toasts directly */}
        {Array.from(items).map(([key, value]) => (
          <Toast key={key} {...value} />
        ))}

        {/*Toasts will be caught by the viewport*/}
        <ToastPrimitive.Viewport
          id="toast-viewport"
          className="fixed bottom-2 right-2 flex flex-col gap-2"
        />
      </ToastPrimitive.Provider>
    </ToastContext.Provider>
  );
};
