import {
  ProcurementKpiCard,
  ProcurementKpiCardProps,
} from "~/pages/procurement/_cmp/kpi/procurement-kpi-card";
import { useTranslation } from "react-i18next";
import { useGraphQL } from "~/lib/gql";
import { useQuery } from "@tanstack/react-query";
import { formatCurrency, formatDecimals } from "~/lib/utils/number";
import { ProcurementPageSearchParams } from "~/pages/procurement";
import { KPISkeletonItem } from "~/lib/ui/skeleton/kpi-skeleton";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";

const CACHE_PROCUREMENT_KPI = "procurement-kpi";

export default function KpiSection({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) {
  const { t } = useTranslation();
  const [searchParams] = useTypedSearchParams<ProcurementPageSearchParams>();
  const sdk = useGraphQL();

  const { data, isFetching } = useQuery({
    queryKey: [CACHE_PROCUREMENT_KPI, dateFrom, dateTo, searchParams.companiesVendorIds],
    queryFn: () =>
      sdk.procurementIndex({
        kpiParams: {
          companiesVendorIds: searchParams.companiesVendorIds,
          dateInterval: {
            from: dateFrom,
            to: dateTo,
          },
        },
      }),
  });

  const kpiCardData = data?.procurementKpiCards || {};

  const kpiCards: Record<string, Omit<ProcurementKpiCardProps, "value" | "loading">> = {
    priceUpdatesCount: {
      title: t("procurement:kpi.price_updates_count.title"),
      iconName: "chart",
      description: t("procurement:kpi.price_updates_count.description"),
      valueRender: (value: number) => <span>{formatDecimals(value, 0)}</span>,
    },
    totalMoneySpent: {
      title: t("procurement:kpi.total_money_spent.title"),
      iconName: "currency",
      description: t("procurement:kpi.total_money_spent.description"),
      valueRender: (value: number) => <span>{formatCurrency(value, { currency: "DKK" })}</span>,
    },
    countOfProductsBought: {
      title: t("procurement:kpi.count_of_products_bought.title"),
      iconName: "product",
      description: t("procurement:kpi.count_of_products_bought.description"),
      valueRender: (value: number) => <span>{formatDecimals(value, 0)}</span>,
    },
    averageTotalPricePerExpense: {
      title: t("procurement:kpi.average_total_price_per_expense.title"),
      iconName: "currency",
      description: t("procurement:kpi.average_total_price_per_expense.description"),
      valueRender: (value: number) => <span>{formatCurrency(value, { currency: "DKK" })}</span>,
    },
  };

  if (isFetching) {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        {Array.from({ length: Object.entries(kpiCards).length }).map((_, idx) => (
          <KPISkeletonItem key={idx} />
        ))}
      </div>
    );
  }

  const hasData = Object.keys(kpiCardData).length > 0;

  if (!isFetching && !hasData) {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        {Object.values(kpiCards).map((c, idx) => (
          <ProcurementKpiCard key={`procurement-kpi-${idx}`} {...c} value={0} />
        ))}
      </div>
    );
  }

  if (!isFetching && hasData) {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        {Object.entries(kpiCardData).map(
          ([key, value]) =>
            key !== "__typename" && (
              <ProcurementKpiCard
                key={`procurement-kpi-${key}`}
                {...kpiCards[key]}
                value={Number(value)}
              />
            )
        )}
      </div>
    );
  }
}
