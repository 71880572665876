import { useState } from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";
import { Icon } from "~/lib/ui";
import { twMerge } from "tailwind-merge";
import { InternalToastMessage } from "~/lib/toast/context";
import { TextLink } from "~/lib/ui/text-link";

export const ToastVariantClasses = {
  success: "bg-green-50 text-green-700",
  warning: "bg-yellow-50 text-yellow-800",
  error: "bg-red-50 text-red-700",
  info: "bg-white text-zinc-700",
};

export function Toast({
  title,
  description,
  iconName,
  variant = "info",
  duration,
  onDestroy,
  action,
}: InternalToastMessage) {
  const [open, setOpen] = useState<boolean>(true);

  const handleAnimationEnd = () => {
    if (!open) {
      onDestroy?.();
    }
  };

  const handleOpenChange = (o: boolean) => {
    setOpen(o);
    // Workaround for issue https://github.com/radix-ui/primitives/issues/2233
    document.getElementById("toast-viewport")?.blur();
  };

  return (
    <ToastPrimitive.Root
      open={open}
      onOpenChange={handleOpenChange}
      onAnimationEnd={handleAnimationEnd}
      className={twMerge(
        "relative w-fit min-w-64 self-end rounded-lg border text-sm shadow-md data-[state=closed]:animate-toast-hide data-[state=open]:animate-toast-slide-in-right",
        ToastVariantClasses[variant]
      )}
      duration={duration}
    >
      <div className="flex w-full gap-4 px-4 py-2">
        {!!iconName && (
          <div>
            <Icon name={iconName} className="h-7 w-7" />
          </div>
        )}
        <div className="flex w-full flex-col gap-1 ">
          <div className="flex grow items-center pr-6">
            <ToastPrimitive.Title className="text-base font-semibold">{title}</ToastPrimitive.Title>
          </div>
          <ToastPrimitive.Close className="absolute right-1 top-1 flex h-6 w-6 items-center justify-center rounded-full hover:bg-black/5">
            <Icon name="close" />
          </ToastPrimitive.Close>
          {!!description && <ToastPrimitive.Description>{description}</ToastPrimitive.Description>}
          {!!action && (
            <div className="mt-1.5 flex justify-end">
              <ToastPrimitive.Action asChild altText="action" className="flex w-fit">
                <TextLink className="cursor-pointer font-semibold" onClick={action.onClick}>
                  {action.label}
                </TextLink>
              </ToastPrimitive.Action>
            </div>
          )}
        </div>
      </div>
    </ToastPrimitive.Root>
  );
}
