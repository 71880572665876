import { User } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { formatDecimals } from "~/lib/utils/number";
import { generateBackgroundColor } from "~/lib/utils/colors";
import colors from "tailwindcss/colors";
import { Icon } from "~/lib/ui";
import { UserAvatar } from "~/lib/ui/avatar/user-avatar";

export function EmployeeCard({
  user,
  allowCollapse = true,
  collapsed,
  totalEstimates,
  onTriggerCollapse,
}: {
  user?: User;
  allowCollapse?: boolean;
  collapsed: boolean;
  totalEstimates: number;
  onTriggerCollapse: (value: boolean) => void;
}) {
  const { t } = useTranslation();

  const handleCollapse = (val: boolean): void => {
    onTriggerCollapse(val);
  };

  return (
    <div
      className="flex w-full select-none gap-3 border-t-4 bg-white px-3 text-sm"
      style={{
        borderTopColor: user ? generateBackgroundColor(user.fullName) : colors["gray"][400],
      }}
    >
      {allowCollapse && (
        <div className="absolute right-1.5 top-3">
          <button
            title={collapsed ? t("common:expand") : t("common:collapse")}
            onClick={() => handleCollapse(!collapsed)}
            className="rounded-full p-0.5 hover:bg-shade-100"
          >
            {collapsed ? (
              <Icon name="expandRow" className="h-4 w-4" />
            ) : (
              <Icon name="retractRow" className="h-4 w-4" />
            )}
          </button>
        </div>
      )}
      <div className="min-h-16 py-2.5">
        <div className="rounded-full border bg-white p-1 shadow-sm">
          {!!user ? (
            <UserAvatar user={user} className="h-8 w-8" />
          ) : (
            <div className="h-8 w-8 rounded-full bg-shade-400" />
          )}
        </div>
      </div>
      <div className="flex flex-col justify-center py-2.5">
        <div
          title={user?.fullName ?? t("planning:employee_section.not_assigned")}
          className="flex items-end pr-6"
        >
          {user?.fullName ?? t("planning:employee_section.not_assigned")}
        </div>
        <div className="flex items-start gap-2 text-xs">
          <div className="flex items-center gap-2">
            <Icon name="time" className="h-4 w-4" />
            <div>{formatDecimals(totalEstimates)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
