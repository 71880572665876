import { HTMLProps, ReactNode, Ref, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

type LabelOptions = { required?: boolean; linkSlot?: ReactNode };
type LabelProps = HTMLProps<HTMLLabelElement> & LabelOptions;

/**
 * DOM label with an optional requirement indicator
 */
export const Label = forwardRef(function LabelInner(
  { linkSlot, ...props }: LabelProps,
  ref: Ref<HTMLLabelElement>
) {
  const { children, required, className, ...restProps } = props;
  const BASE_CLASS =
    "flex items-center justify-between gap-2 text-sm font-medium text-gray-700 mb-1";

  return (
    <label className={twMerge(BASE_CLASS, className)} {...restProps} ref={ref}>
      <span>
        {children} {required && <span className="text-error">*</span>}
      </span>
      {linkSlot}
    </label>
  );
});
