import { useState, ComponentType, ComponentProps, createContext } from "react";
import { Slot } from "./slot";
import { Signal } from "./signal";
import { SlotContext } from "./slot-context";
import { SlotStore, SlotStoreItem } from "./types";

/**
 * Wraps a component with a slot provider.
 *
 * Purpose of its' creation was to allow React driven UI portals/slots to be affected by children components.
 *
 * Example Usecase:
 * - PageLayout was forcing us to move state like forms at a top-level, only because the edit pages needed
 * to add a few buttons or affect the description. This was causing problems in terms of syncing state
 * between the pages and the layout.
 *
 * **If you're reaching for this. You should probably be using React Context instead.**
 *
 * @param Component - The component to wrap.
 * @param slots - Names of the slots to provide to the component.
 * @returns A new component with the slot provider.
 * @description This is a higher-order component that wraps a component with a slot provider.
 * It creates a unique context for the component and provides the slots to the component.
 * The component can then use the Slot and Signal components to add and remove signals to the slots.
 * @example
 * const MyComponent = withSlotProvider(MyComponent, ["actions", "sidebar"]);
 * @warning This it not a hammer, it's a special tool for a special job. Before using it:
 * - Use dumb-components, state, side-effects, context and hooks before reaching for this!
 */
export function withSlotProvider<P extends object, SlotName extends string>(
  Component: ComponentType<P>,
  slots: Array<SlotName>
) {
  // Create a unique context for this wrapped component
  const UniqueSlotContext = createContext<SlotContext | null>(null);

  function ComponentWithSlotProvider(componentProps: P) {
    const [slotStore, setSlotStore] = useState<SlotStore>(new Map());

    function handleAddSignal(slotName: SlotName, signal: SlotStoreItem) {
      setSlotStore((prev) => {
        // All slots need to be declared in the wrapper function. If not, we abort
        if (!slots.includes(slotName)) return prev;

        const slotDraft = new Map(prev.get(slotName));
        slotDraft.set(signal.id, signal);
        return new Map(prev).set(slotName, slotDraft);
      });
    }

    function handleRemoveSignal(slotName: SlotName, signal: SlotStoreItem) {
      setSlotStore((prev) => {
        if (!slots.includes(slotName)) return prev;
        const newSlot = new Map(prev.get(slotName));
        newSlot.delete(signal.id);
        return new Map(prev).set(slotName, newSlot);
      });
    }

    return (
      <UniqueSlotContext.Provider
        value={{
          slots: slotStore,
          addSignal: (slotName, signal) => handleAddSignal(slotName as SlotName, signal),
          removeSignal: (slotName, signal) => handleRemoveSignal(slotName as SlotName, signal),
        }}
      >
        <Component {...(componentProps as P)} />
      </UniqueSlotContext.Provider>
    );
  }

  // Attach the context to the component for Signal/Slot to use
  ComponentWithSlotProvider.SlotContext = UniqueSlotContext;
  ComponentWithSlotProvider.displayName = `Slotted${Component.displayName ?? Component.name}`;
  ComponentWithSlotProvider.Signal = function SlottedSignal(
    props: Omit<ComponentProps<typeof Signal<SlotName>>, "context">
  ) {
    return <Signal {...props} context={UniqueSlotContext} />;
  };
  ComponentWithSlotProvider.Slot = function SlottedSlot(
    props: Omit<ComponentProps<typeof Slot<SlotName>>, "context">
  ) {
    return <Slot {...props} context={UniqueSlotContext} />;
  };
  return ComponentWithSlotProvider;
}
