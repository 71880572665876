import { Icon, IconName } from "~/lib/ui";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type InformationBoxVariant = "info" | "warning" | "danger";
type InformationBoxVariantProps = {
  className: string;
  iconName: IconName;
};

const variantData: Record<InformationBoxVariant, InformationBoxVariantProps> = {
  info: {
    className: "bg-white text-zinc-700",
    iconName: "infoCircle",
  },
  warning: {
    className: "bg-yellow-500 text-white",
    iconName: "warningTriangle",
  },
  danger: {
    className: "bg-red-500 text-white",
    iconName: "warningTriangle",
  },
};

export function InformationBox({
  variant,
  children,
}: {
  variant: InformationBoxVariant;
  children: ReactNode;
}) {
  return (
    <div className="flex flex-col gap-2 border bg-gray-100 p-2">
      <div className="flex items-center gap-4">
        <div className={twMerge("rounded-full p-1.5", variantData[variant].className)}>
          <Icon name={variantData[variant].iconName} className="h-6 w-6" />
        </div>
        <div className="text-sm">{children}</div>
      </div>
    </div>
  );
}
