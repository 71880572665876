import { Contact, ContactPerson, ICreateProjectRequest } from "@apacta/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { StreetAddressInput } from "~/lib/ui/street-address-input";
import { ZipInput } from "~/lib/ui/zip-input";
import { CACHE_PROJECTS } from "..";
import { getIcon, LabelInput } from "~/lib/ui";
import PriceSection from "./data-components/price-section";
import Switch from "~/lib/ui/switch";
import { LabeledTextarea } from "~/lib/ui/form-elements/labeled-textarea";
import CustomerAndContactPersonSelection from "~/lib/ui/selection-combobox/customer-and-contact-person-selection";
import { useToasts } from "~/lib/toast/use-toasts";

type CreateProjectDialogProps = {
  defaultName?: string;
  defaultDescription?: string;
  defaultCustomerId?: string;
  defaultContactPersonId?: string;
  defaultWorkAddress?: string;
  defaultZipCode?: string;
  onProjectCreated?: (projectId: string) => void | Promise<void>;
  onOpenChange?: (isOpen: boolean) => void;
};

export function CreateProjectDialog(props: CreateProjectDialogProps) {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const { showTemplate } = useToasts();

  const createM = useMutation({
    mutationFn: (args: ICreateProjectRequest) =>
      api.iCreateProject({ iCreateProjectRequest: args }),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS], // this causes a refetch
      });
      if (data.data.id) {
        showTemplate("CREATED");
        await props.onProjectCreated?.(data.data.id);
      }
    },
  });

  const formState = useFormState({
    schema: {
      name: z.string().min(1),
      description: z.string(),
      customerId: z.string().uuid().optional(),
      contactPersonId: z.string().uuid().optional(),
      workAddress: z.string().optional(),
      zipCode: z.string().optional(),
      cityId: z.string().uuid().optional(),
      cityName: z.string().optional(),
      isFixedPrice: z.boolean(),
      totalSalesPrice: z.number(),
      workingHoursTotalCostPrice: z.number(),
      productsTotalCostPrice: z.number(),
    },
    initialValues: {
      name: props.defaultName || "",
      description: props.defaultDescription || "",
      customerId: props.defaultCustomerId,
      contactPersonId: props.defaultContactPersonId,
      workAddress: props.defaultWorkAddress || undefined,
      zipCode: props.defaultZipCode || undefined,
      cityId: undefined,
      cityName: undefined,
      isFixedPrice: false,
      productsTotalCostPrice: 0,
      workingHoursTotalCostPrice: 0,
      totalSalesPrice: 0,
    },
    mutationError: createM.error,
  });

  function handleSubmit() {
    createM.mutate({
      name: formState.getValue("name"),
      description: formState.getValue("description"),
      contactId: formState.getValue("customerId"),
      contactPersonId: formState.getValue("contactPersonId"),
      cityId: formState.getValue("cityId"),
      cityName: formState.getValue("cityName"),
      streetName: formState.getValue("workAddress"),
      zipCode: formState.getValue("zipCode"),
      isFixedPrice: formState.getValue("isFixedPrice"),
      productsTotalCostPrice: formState.getValue("productsTotalCostPrice"),
      totalSalesPrice: formState.getValue("totalSalesPrice"),
      workingHoursTotalCostPrice: formState.getValue("workingHoursTotalCostPrice"),
    });
  }

  const handleSelectCustomer = (customer?: Contact) => {
    if (!customer) {
      formState.onChange("customerId", undefined);
    } else {
      formState.onChange("customerId", customer.id);
      formState.setValues({
        cityId: customer.cityId ?? undefined,
        cityName: customer.cityName ?? undefined,
        workAddress: customer.address ?? undefined,
        zipCode: customer.zipCode ?? undefined,
      });
    }
    // Clear contact person on change
    formState.onChange("contactPersonId", undefined);
  };

  const handleSelectContactPerson = (contactPerson?: ContactPerson) => {
    if (!contactPerson) {
      formState.onChange("contactPersonId", undefined);
    } else {
      formState.onChange("contactPersonId", contactPerson.id);
    }
  };

  function handleOpenChange(isOpen: boolean) {
    props.onOpenChange?.(isOpen);
  }

  return (
    <>
      <DialogHeader
        title={t("common:create", {
          replace: { entity: t("common:project", { count: 1 }).toLocaleLowerCase() },
        })}
        Icon={getIcon("project")}
      />
      <div className="mb-8 flex h-full w-full flex-col gap-6 md:flex-row">
        <div className="flex basis-1/2 flex-col gap-4">
          <LabelInput {...formState.registerStringInput("name")} label={t("common:name")} />

          <LabeledTextarea
            label={t("common:description")}
            onChange={(e) => formState.onChange("description", e.currentTarget.value)}
            className="h-fit"
          />
          <Switch
            label={t("projects:fixed_price")}
            defaultChecked={formState.getValue("isFixedPrice")}
            onCheckedChange={(v) => formState.onChange("isFixedPrice", v)}
          />
        </div>
        <div className="flex basis-1/2 flex-col gap-4">
          <CustomerAndContactPersonSelection
            selectedCustomerId={formState.getValue("customerId")}
            selectedContactPersonId={formState.getValue("contactPersonId")}
            onSelectCustomer={handleSelectCustomer}
            onSelectContactPerson={handleSelectContactPerson}
          />
          <StreetAddressInput
            value={formState.getValue("workAddress")}
            label={t("projects:worksite_address")}
            onChange={(val) => formState.onChange("workAddress", val)}
            onSelect={(selection) => {
              formState.setValues({
                workAddress: selection.addressWithNumber,
                zipCode: selection.zipCode,
                cityName: selection.cityName,
                cityId: selection.cityId,
              });
            }}
          />
          <div className="flex flex-row gap-4">
            <ZipInput
              placeholder={t("common:zip_code")}
              value={formState.getValue("zipCode")}
              label={t("common:zip_code", { defaultValue: "Zip code" })}
              disabled={true}
            />
            <LabelInput
              disabled
              value={formState.getValue("cityName")}
              label={t("common:city_name")}
            />
          </div>
        </div>
      </div>
      <div id="project_price_settings">
        <PriceSection
          isOffer={false}
          isFixedPrice={formState.getValue("isFixedPrice")}
          onIsFixedPriceChange={(v) => formState.onChange("isFixedPrice", v)}
          productsTotalCostPrice={formState.getValue("productsTotalCostPrice")}
          onProductsTotalCostPriceChange={(v) => formState.onChange("productsTotalCostPrice", v)}
          totalSalesPrice={formState.getValue("totalSalesPrice")}
          onTotalSalesPriceChange={(v) => formState.onChange("totalSalesPrice", v)}
          workingHoursTotalCostPrice={formState.getValue("workingHoursTotalCostPrice")}
          onWorkingHoursTotalCostPriceChange={(v) =>
            formState.onChange("workingHoursTotalCostPrice", v)
          }
        />
      </div>
      <DialogFooter
        primary={{
          label: t("common:create", {
            replace: { entity: t("common:project", { count: 1 }).toLocaleLowerCase() },
          }),
          onClick: handleSubmit,
          disabled: !formState.isValid || createM.isPending,
          loading: createM.isPending,
        }}
        onClose={() => handleOpenChange(false)}
      />
    </>
  );
}
