import { TextTemplate } from "@apacta/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import { Button } from "~/lib/ui";
import { TextInput } from "~/lib/ui/form-elements";
import { RichTextEditor, RichTextEditorRef } from "~/lib/ui/rich-text-editor";
import Switch from "~/lib/ui/switch";

export function TemplateEditSection({
  entity,
  onExpandChange,
  onIsModifiedChange,
}: {
  entity: TextTemplate;
  onIsModifiedChange: (isModified: boolean) => void;
  onExpandChange: (isExpanded: boolean) => void;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const editorRef = useRef<RichTextEditorRef>(null);
  const api = useAPI();
  const [_editorValidState, setEditorValidState] = useState<boolean>(true);

  const schema = {
    name: z.string().min(1),
    content: z.string().min(1),
    availability: z.object({
      expense: z.boolean().optional(),
      invoice: z.boolean().optional(),
      offer: z.boolean().optional(),
    }),
  };
  const saveChanges = useMutation({
    mutationFn: async () => {
      await api.editTextTemplate({
        textTemplateId: entity.id,
        editTextTemplateRequest: {
          content: v.content,
          expense: v.availability.expense,
          invoice: v.availability.invoice,
          offer: v.availability.offer,
          title: v.name,
        },
      });
      await queryClient.invalidateQueries({
        queryKey: ["templates"],
      });
      resetInitialValues(v);

      onExpandChange(false);
    },
  });

  const { register, isValid, onChange, getValues, isModified, resetInitialValues } = useFormState({
    schema,
    initialValues: {
      name: entity.title,
      content: entity.content,
      availability: {
        expense: entity.expense,
        invoice: entity.invoice,
        offer: entity.offer,
      },
    },
    mutationError: saveChanges.error,
  });

  const v = getValues();

  // Tells parent if modified state has changed
  useEffect(() => {
    onIsModifiedChange(isModified);
  }, [isModified]);

  const handleRTEChange = async (validState: boolean) => {
    setEditorValidState(validState);
    if (editorRef.current) {
      const content = await editorRef.current.getEditorMarkdown();
      onChange("content", content);
    }
  };

  const handleAvailabilityChange = (
    entityId: string,
    key: keyof z.infer<typeof schema.availability>, // use the same as form

    value: boolean
  ) => {
    onChange("availability", {
      ...v.availability,
      [key]: value,
    });
  };

  const cancelEdit = (originalContent: string) => {
    if (editorRef.current) {
      editorRef.current.setEditorMarkdown(originalContent);
      onExpandChange(false);
    }
  };

  return (
    <div className="flex flex-col gap-8">
      <TextInput {...register("name")} label={t("settings:templates.table.name")} />
      <RichTextEditor
        ref={editorRef}
        label={`${t("common:template", { count: 1 })}*`}
        required={true}
        initialData={entity.content.trim()}
        onChange={(text, html, valid) => handleRTEChange(valid)}
      />
      <div>
        <span className="mb-1 block text-left text-sm font-medium text-gray-700">
          {t("settings:templates.modal.availability")}
        </span>
        <div className="mt-4 flex gap-12">
          <div className="hidden flex-col gap-2">
            <label>{t("common:invoice", { count: 1 })}</label>
            <Switch
              onCheckedChange={(val) => handleAvailabilityChange(entity.id, "invoice", val)}
            />
          </div>
          <div className="hidden flex-col gap-2">
            <label>{t("common:expense", { count: 1 })}</label>
            <Switch
              onCheckedChange={(val) => handleAvailabilityChange(entity.id, "expense", val)}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label>{t("common:enabled", { count: 1 })}</label>
            <Switch
              defaultChecked={entity.offer}
              onCheckedChange={(val) => handleAvailabilityChange(entity.id, "offer", val)}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4">
        <Button
          variant="tertiary"
          disabled={!isValid || !isModified}
          onClick={() => saveChanges.mutateAsync()}
        >
          {t("common:save")}
        </Button>
        <Button variant="secondary" onClick={() => cancelEdit(entity.content)}>
          {t("common:cancel")}
        </Button>
      </div>
    </div>
  );
}
