import { useTranslation } from "react-i18next";
import ViewTabs from "~/lib/ui/tabs/view-tabs";
import { StreetAddressInput } from "~/lib/ui/street-address-input";
import { CVRInput } from "~/lib/ui/cvr-input";
import { useFormState } from "~/lib/form-state";
import { customerSchema } from "./customer-schema";
import { useAPI } from "~/lib/api";
import { ZipInput } from "~/lib/ui/zip-input";
import { useMutation } from "@tanstack/react-query";
import { CreateContactOperationRequest } from "@apacta/sdk";
import { useToastOnError } from "~/lib/utils/hooks";
import { ContactType } from "~/lib/enums/contact-type.enum";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { getIcon, LabelInput } from "~/lib/ui";
import { FormEvent, useCallback } from "react";
import { useToasts } from "~/lib/toast/use-toasts";

type PropsCommon = {
  onCustomerCreated?: (customerId: string) => void;
  onOpenChange?: (isOpen: boolean) => void;
  defaultCustomerName?: string;
};

export function CreateCustomerDialog(props: PropsCommon) {
  const { t } = useTranslation();
  const api = useAPI();
  const { showTemplate } = useToasts();
  const initialValues = {
    name: props.defaultCustomerName || "",
    address: undefined,
    cityName: undefined,
    email: undefined,
    phone: undefined,
    cvr: undefined,
    ean: undefined,
    zip_code: undefined,
    payment_term_id: undefined,
    cityId: undefined,
  };

  const customerCreate = useMutation({
    mutationFn: (args: CreateContactOperationRequest) => api.createContact(args),
    onSuccess: (data) => {
      if (!data.data.id) {
        console.warn("Customer created, but no id returned", data.data);
        return;
      }
      props.onCustomerCreated?.(data.data.id);
      handleOpenChange(false);
      formState.reset(initialValues);
      showTemplate("CREATED");
    },
  });

  const formState = useFormState({
    mutationError: customerCreate.error,
    schema: customerSchema,
    initialValues,
  });

  const zipToCityId = useCallback(
    async (zipCode?: string): Promise<string | undefined> => {
      if (!zipCode) return undefined;
      return api.zipCodeLookup({ zipCode }).then((res) => res.data.id ?? undefined);
    },
    [api]
  );

  useToastOnError(customerCreate.error);

  function handleOpenChange(isOpen: boolean) {
    props.onOpenChange?.(isOpen);
  }

  async function handleSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    if (customerCreate.isPending) return;

    await customerCreate.mutateAsync({
      createContactRequest: {
        name: formState.getValue("name"),
        address: formState.getValue("address"),
        phone: formState.getValue("phone"),
        email: formState.getValue("email"),
        cvr: formState.getValue("cvr")?.toString(),
        ean: formState.getValue("ean"),
        cityName: formState.getValue("cityName"),
        zipCode: formState.getValue("zip_code"),
        contactTypes: { ids: [ContactType.CUSTOMER] },
        cityId: formState.getValue("cityId"),
      },
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogHeader
        title={t("common:create", {
          defaultValue: "Create {{entity}}",
          replace: { entity: t("common:customer", { count: 1 }).toLocaleLowerCase() },
        })}
        Icon={getIcon("customer")}
      />

      <div className="mt-2">
        <ViewTabs
          tabs={[
            {
              id: "private",
              label: t("customers:modal.create_customer.tabs.private"),
            },
            {
              id: "company",
              label: t("customers:modal.create_customer.tabs.company"),
            },
          ]}
        >
          <div id="private" className="flex flex-col gap-4">
            <LabelInput
              {...formState.registerStringInput("name")}
              label={t("customers:modal.create_customer.input.name")}
              onBlur={() => null} // fix blur issue
            />
            <StreetAddressInput
              label={t("customers:modal.create_customer.input.address")}
              value={formState.getValue("address")}
              onChange={(val) => formState.onChange("address", val)}
              onSelect={async (i) => {
                formState.setValues({
                  address: i.addressWithNumber,
                  cityName: i.cityName,
                  zip_code: i.zipCode,
                  cityId: await zipToCityId(i.zipCode),
                });
              }}
            />

            <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
              <ZipInput
                label={t("common:zip_code")}
                placeholder={t("common:zip_code")}
                onChange={(val) => formState.onChange("zip_code", val)}
                value={formState.getValue("zip_code")}
                onSelect={async (item) => {
                  formState.setValues({
                    zip_code: item.zipCode,
                    cityName: item.cityName,
                    cityId: await zipToCityId(item.zipCode),
                  });
                }}
              />
              <LabelInput
                {...formState.registerStringInput("cityName")}
                label={t("customers:modal.create_customer.input.city")}
              />
            </div>
            <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
              <LabelInput
                type="email"
                {...formState.registerStringInput("email")}
                label={t("customers:modal.create_customer.input.email")}
              />
              <LabelInput
                {...formState.registerStringInput("phone")}
                value={formState.getValue("phone")}
                label={t("customers:modal.create_customer.input.phone")}
              />
            </div>
          </div>
          <div id="company" className="flex flex-col gap-4">
            <CVRInput
              label={t("customers:modal.create_customer.input.cvr")}
              onChange={(val) => formState.onChange("cvr", val)}
              value={formState.getValue("cvr")?.toString() ?? ""}
              error={formState.getField("cvr").errors?.[0]}
              onSelect={async (i) => {
                formState.setValues({
                  cvr: i.vat?.toString(),
                  email: i.email,
                  phone: i.phone,
                  name: i.name,
                  address: i.address,
                  cityName: i.city,
                  zip_code: i.zipcode.toString(),
                  cityId: await zipToCityId(i.zipcode),
                });
              }}
            />
            <LabelInput
              {...formState.registerStringInput("name")}
              label={t("customers:modal.create_customer.input.name")}
            />
            <StreetAddressInput
              label={t("customers:modal.create_customer.input.address")}
              value={formState.getValue("address")}
              onChange={(val) => formState.onChange("address", val)}
              onSelect={async (i) => {
                formState.setValues({
                  address: i.addressWithNumber,
                  cityName: i.cityName,
                  zip_code: i.zipCode,
                  cityId: await zipToCityId(i.zipCode),
                });
              }}
            />

            <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
              <ZipInput
                label={t("common:zip_code")}
                placeholder={t("common:zip_code")}
                onChange={(val) => formState.onChange("zip_code", val)}
                value={formState.getValue("zip_code")}
                onSelect={async (item) => {
                  formState.setValues({
                    zip_code: item.zipCode,
                    cityName: item.cityName,
                    cityId: await zipToCityId(item.zipCode),
                  });
                }}
              />
              <LabelInput
                {...formState.registerStringInput("cityName")}
                label={t("customers:modal.create_customer.input.city")}
              />
            </div>
            <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
              <LabelInput
                type="email"
                {...formState.registerStringInput("email")}
                label={t("customers:modal.create_customer.input.email")}
              />
              <LabelInput
                {...formState.registerStringInput("phone")}
                label={t("customers:modal.create_customer.input.phone")}
              />
            </div>
            <LabelInput
              {...formState.registerStringInput("ean")}
              label={t("customers:modal.create_customer.input.ean")}
            />
          </div>
        </ViewTabs>
      </div>
      <DialogFooter
        primary={{
          label: t("common:create", {
            replace: { entity: t("common:customer", { count: 1 }).toLowerCase() },
          }),
          loading: customerCreate.isPending,
          disabled: !formState.isValid || customerCreate.isPending,
          type: "submit",
        }}
        onClose={() => handleOpenChange(false)}
      />
    </form>
  );
}
