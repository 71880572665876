import { ComponentType, createContext, useContext } from "react";
import { SelectionComboboxProps } from "./combobox-types";

// The `any` here is intentional so the context can be set without the T.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectionComboboxContentType<T = any> = {
  disabled: boolean | undefined;
  enableSelectAll: boolean | undefined;
  error?: string;
  filteredData: Array<T>;
  isSelected: (item: T) => boolean;
  keyFn: SelectionComboboxProps<T>["keyFn"];
  icon: SelectionComboboxProps<T>["icon"];
  labelFn: SelectionComboboxProps<T>["labelFn"];
  loading: boolean | undefined;
  multiple: boolean | undefined;
  onClearSelection: () => void;
  onConfirmMultiSelect: () => void;
  onCreateNew: SelectionComboboxProps<T>["onCreateNew"];
  onQueryChange: (query: string) => void;
  onRemoveItem: (value: string) => void;
  onSelectAll: () => void;
  onValueChange: (value: string) => void;
  open: boolean;
  placeholder: string | undefined;
  readonly: boolean | undefined;
  renderItem: SelectionComboboxProps<T>["renderItem"];
  required?: boolean;
  searchPlaceholder: string | undefined;
  selectedItems: Array<T>;
  TriggerComponent: ComponentType;
  triggerItems: Array<T>;
  valueFn: SelectionComboboxProps<T>["valueFn"];
  contentClassName?: string;
};

export const SelectionComboboxContext = createContext<SelectionComboboxContentType | null>(null);

export function useSelectionCombobox<T>() {
  const context = useContext(SelectionComboboxContext);
  if (!context) {
    throw new Error("useSelectionComboboxContext must be used within a SelectionComboboxProvider");
  }
  return context as SelectionComboboxContentType<T>;
}
