import { Button, getIcon } from "~/lib/ui";
import { CommandItem } from "../../command/command";
import { useTranslation } from "react-i18next";
import { useCommandState } from "cmdk";

// Issues with creation: https://github.com/pacocoursey/cmdk/issues/75
export function DefaultCreateComponent(props: { onCreate?: (query: string) => void }) {
  const { t } = useTranslation();
  const search: string = useCommandState((state) => state.search);

  return (
    <CommandItem
      forceMount
      value="{{create-item}}"
      keywords={["{{create-item}}"]}
      onSelect={() => props.onCreate?.(search)}
      className="mt-1 border-t py-3 pb-2 aria-selected:bg-transparent"
    >
      <Button
        variant="secondary"
        size="small"
        Icon={getIcon("add")}
        iconClassName="mr-2"
        className="mx-auto"
      >
        {t("common:create", { entity: search === "" ? t("common:new").toLowerCase() : search })}
      </Button>
    </CommandItem>
  );
}
