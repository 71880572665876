/**
 * Purpose: One place where we can handle linking to pages that are v1 now, but may not always be.
 * Delete when everything is in React
 */

import { DEFAULT_STATUS_ID_FILTER } from "~/pages/projects";
import { getISOShortDate } from "~/lib/utils/date/date-utils";
import { sub } from "date-fns";
import { randomUUID } from "../uuid/random-uuid";

export function linkToCompanyFrontpage(): string {
  return "/frontpage";
}
export function linkToFormCreate(internalId: string) {
  return `/time-registration/new/${internalId}`;
}

export function linkToForm(id: string) {
  return `/time-registration/${id}`;
}

export function linkToProjectForm(projectId: string, formId: string) {
  return linkToProject(projectId, { subPage: `registrations/${formId}` });
}

export function linkToInvoiceV1(id: string): string {
  return `/companies/invoices/edit/${id}`;
}

export function linkToInvoiceV3(id: string): string {
  return `/invoices/${id}`;
}

/** Links to a new form, either for a project or for the time-registration page */
export function linkToNewForm(projectId?: string) {
  if (projectId) {
    return linkToProject(projectId, {
      subPage: `registrations/new/${randomUUID()}`,
    });
  }
  return `/time-registration/new/${randomUUID()}`;
}

export function linkToNewInvoice({
  projectId,
  from,
  to,
  notInvoicedAmount,
  activities,
}: {
  projectId: string;
  from?: string;
  to?: string;
  notInvoicedAmount?: number;
  activities?: Array<string>;
}) {
  let invoiceUrl = `/companies/invoices/add?project_id=${projectId}`;
  if (notInvoicedAmount !== undefined) invoiceUrl += `&not_invoiced_amount=${notInvoicedAmount}`;
  if (from !== undefined) {
    invoiceUrl += `&date_from=${from}`;
  }
  if (to !== undefined) {
    invoiceUrl += `&date_to=${to}`;
  }
  if (activities !== undefined) {
    invoiceUrl += `&activity_ids=${activities}`;
  }

  return invoiceUrl;
}

export function linkToExpense(id: string): string {
  return `/companies/expenses/edit/${id}`;
}

export function linkToCustomer(id: string): string {
  return `/customers/${id}`;
}

export function linkToOffer(id: string) {
  return `/offers/${id}`;
}

export function linkToPlanning() {
  return "/planning";
}

export function linkToProject(
  id: string,
  options?: {
    subPage?: string; // Adds the string to the URL, e.g. "data"
  }
) {
  let url = `/projects/${id}/`;
  if (options?.subPage) url += options?.subPage;
  return url;
}

export function linkToProjects() {
  return `/projects/?status=${DEFAULT_STATUS_ID_FILTER}`;
}

export function linkToEmployee(id: string) {
  return `/employees/${id}`;
}

export function linkToEmployees(isActive = true, fromDate?: Date, toDate?: Date) {
  const basePath = "/employees";
  const filters = [];

  if (isActive !== undefined) {
    filters.push(`isActive=${isActive}`);
  }

  if (!fromDate) {
    fromDate = sub(new Date(), { days: 14 });
  }
  filters.push(`start_date=${getISOShortDate(fromDate)}`);

  if (!toDate) {
    toDate = new Date();
  }
  filters.push(`end_date=${getISOShortDate(toDate)}`);

  return basePath + (filters.length > 0 ? `?${filters.join("&")}` : "");
}

export function linkToProductNew(id: string) {
  return `/products/${id}`;
}

export function linkToProduct(
  id: string,
  options?: {
    referrer?: string;
  }
) {
  let url = `/products/${id}`;
  if (options?.referrer) url += `?referrer=${options?.referrer}`;
  return url;
}

export function linkToExpenseLine(id: string) {
  return `/products/expense-lines/${id}`;
}

export function linkToVendorProduct(id: string) {
  return `/products/vendor-products/${id}`;
}

export function linkToForms(approved?: boolean, fromDate?: Date, toDate?: Date) {
  const basePath = "/forms";
  const filters = [];

  if (approved !== undefined) {
    filters.push(`approved=${approved}`);
  }

  if (fromDate) {
    filters.push(`dateFrom=${getISOShortDate(fromDate)}`);
  }

  if (toDate) {
    filters.push(`dateTo=${getISOShortDate(toDate)}`);
  }

  return basePath + (filters.length > 0 ? `?${filters.join("&")}` : "");
}

export function linkToExpensesV3(id: string) {
  return `/expenses/${id}`;
}
