import { Link } from "react-router";

import { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavigationItem } from "../navigation-item/navigation-item";
import { useSidebarContext } from "../../../sidebar-context";
import { useNavigationContent } from "../navigation-item/navigation-item.content";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";
import { useRoles } from "~/lib/auth/use-roles";
import { BACKEND_URL } from "~/lib/auth/config";
import { useSession } from "~/lib/auth/session";
import { QuickCreate } from "~/lib/navigation/navigation-layout/sidebar/components/quick-create/quick-create";
import { ReleaseNotesNavItem } from "~/lib/release-notes";
import LanguagePopoverItem from "~/lib/navigation/navigation-layout/sidebar/components/navigation-item/language-popover-item";
import AiSupportItem from "~/lib/navigation/navigation-layout/sidebar/components/navigation-item/ai-support-item";
import { SideBarScrollButtons } from "~/lib/navigation/navigation-layout/sidebar/components/sidebar-scroll-buttons";

export function SidebarCollapsed() {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const navRef = useRef<HTMLElement | null>(null);
  const { t } = useTranslation();
  const { onSidebarCollapse, sidebarCollapsed } = useSidebarContext();
  const { groupedNavigation } = useNavigationContent();

  const roles = useRoles();
  const isAdmin = roles.has("admin");
  const { apiKey } = useSession();

  return (
    <div
      className={twMerge(
        "fixed inset-y-0 hidden w-20 flex-col print:hidden",
        sidebarCollapsed && "flex"
      )}
    >
      {/* [START] Show super admin link if user is admin */}
      {isAdmin && (
        <div className="absolute left-0 top-0">
          <Link
            to={`${BACKEND_URL}/admin?api_key=${apiKey}`}
            target="_blank"
            className="absolute left-0 top-0 -ml-8 -mt-8 h-16 w-16 rotate-45 cursor-pointer bg-hover"
          >
            <Icon
              name="superadmin"
              className="absolute bottom-6 right-1 h-4 w-auto -rotate-45 text-white"
            />
          </Link>
        </div>
      )}
      {/* [END] Show super admin link if user is admin */}
      <div className="pointer-events-none absolute top-2 flex h-10 w-full justify-end">
        <div
          className="pointer-events-auto -mr-3 flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg bg-hover text-white"
          onClick={() => onSidebarCollapse(false)}
        >
          <Icon name="chevronRight" className="h-4 w-4" aria-hidden="true" />
        </div>
      </div>
      <SideBarScrollButtons scrollRef={scrollRef} navRef={navRef} />
      <div
        ref={scrollRef}
        className="scrollbar-hide flex flex-grow flex-col overflow-y-auto bg-primary pt-8"
      >
        <div className="flex flex-shrink-0 items-center justify-center px-4">
          <Link
            to="/"
            state={{ refreshKey: crypto.getRandomValues(new Uint8Array(1))[0] }} // workaround while we have iframes
          >
            <img
              className="h-10 w-auto "
              src="/apacta-bookmark-white.svg"
              alt={t("ui:navigation.logo_alt")}
            />
          </Link>
        </div>
        <div className="flex flex-1 flex-col px-4 pt-4">
          <nav ref={navRef} className="flex h-full flex-col justify-between space-y-1 pt-4">
            <div className="flex flex-col pb-4">
              <QuickCreate />
              <div className="flex flex-col items-center justify-center">
                {groupedNavigation.map((group) => (
                  <Fragment key={group.identifier}>
                    <hr className="border-t-1 my-2 w-full border-white/30" />
                    {group.items.map((item, idx) => (
                      <Fragment key={`${item.name}-${idx}`}>
                        {!!item.componentIdentifier ? (
                          <>{item.componentIdentifier === "news" && <ReleaseNotesNavItem />}</>
                        ) : (
                          <NavigationItem item={item} />
                        )}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <AiSupportItem />
                <LanguagePopoverItem />
                <div className="py-2">
                  <Link
                    to="/logout"
                    className={twMerge(
                      "flex basis-1/2 justify-center gap-2 py-3 text-white hover:bg-hover",
                      sidebarCollapsed && "rounded-lg"
                    )}
                  >
                    <Icon
                      name="logout"
                      className={twMerge("w-auto", sidebarCollapsed ? "h-6" : "h-5")}
                    />
                    {!sidebarCollapsed && <span>{t("common:logout")}</span>}
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
