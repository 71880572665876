import { useTranslation } from "react-i18next";
import { Badge } from "~/lib/ui/badge";
import { Icon } from "~/lib/ui/icons/icon";
import { ENTITY_SHOW_ICON } from "~/lib/ui/table/cells/settings";
import { linkToForm, linkToProjectForm } from "~/lib/utils";
import { useLocale } from "~/lib/utils/date";
import { OptionalLink } from "~/lib/utils/routing/optional-link";

/**
 * Displays a form template name with work description and badges for invoiced and deleted
 */
export function FormCell({
  formId,
  workDescription,
  formTemplateName,
  projectId,
  invoiced,
  deleted,
}: {
  formId: string;
  formTemplateName: string;
  workDescription?: string;
  /** If defined, then we will link to the form within it */
  projectId?: string;
  deleted?: Date;
  invoiced?: Date;
}) {
  const { t } = useTranslation();
  const locale = useLocale();
  return (
    <div className="flex min-w-44 select-none flex-row items-center justify-between">
      <OptionalLink to={projectId ? linkToProjectForm(projectId, formId) : linkToForm(formId)}>
        {ENTITY_SHOW_ICON && <Icon name="registration" size="small" className="mr-2 inline" />}
        {formTemplateName}
        {workDescription && (
          <div className="mt-2 line-clamp-2 block text-gray-400">{workDescription}</div>
        )}
      </OptionalLink>
      <div className="hidden gap-x-2 lg:flex">
        {invoiced ? (
          <Badge size="sm" variant="green" title={locale.format(invoiced)}>
            {t("common:invoiced")}
          </Badge>
        ) : null}
        {deleted && (
          <Badge size="sm" variant="red">
            {t("common:deleted")}
          </Badge>
        )}
      </div>
    </div>
  );
}
