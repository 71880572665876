import { JSX } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, getIcon } from "~/lib/ui";
import { PageBackButton } from "~/lib/navigation/page-back-button";

export function NotFoundComponent({
  errorCode,
  title,
  description,
  hideBackButton = false,
  hideHomeButton = false,
}: {
  errorCode?: string;
  title?: string;
  description?: JSX.Element | Array<JSX.Element> | string;
  hideBackButton?: boolean;
  hideHomeButton?: boolean;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const errorCodeTranslated = errorCode !== undefined ? errorCode : "404";
  const titleTranslated = title !== undefined ? title : t("ui:not-found.default.title");
  const descriptionTranslated =
    description !== undefined ? description : t("ui:not-found.default.description");

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="flex min-h-screen flex-col">
      <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <p className="text-base font-semibold text-hover">{errorCodeTranslated}</p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {titleTranslated}
            </h1>
            <p className="mt-2 text-base text-gray-500">{descriptionTranslated}</p>
            <div className="mt-6 flex flex-row justify-center gap-8">
              <PageBackButton hidden={hideBackButton} onBackOverride={() => navigate(-1)}>
                {t("ui:not-found.default.back")}
              </PageBackButton>
              {!hideHomeButton ? (
                <Button variant="primary" Icon={getIcon("home")} onClick={() => handleGoHome()}>
                  {t("ui:not-found.default.home")}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
