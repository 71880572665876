import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

/**
 * A section of a page.
 * Used to insert a section with a title and children wrapped in a white box with shadow and border applied.
 * @param {string} title - The title of the section to show above the wrapped children
 * @param className - Custom classes to apply to the content area
 * @param {ReactNode} children - The children to render inside the wrapper
 * @constructor
 */
export default function PageSection({
  title,
  className,
  children,
}: {
  title?: string;
  className?: string;
  children: ReactNode;
}) {
  return (
    <div className="flex flex-col">
      {title && <div className="mb-2 text-gray-500">{title}</div>}
      <div
        className={twMerge(
          "flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}
