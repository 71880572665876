import { getSharedTableDefaults, SharedTableBase } from "~/lib/ui/data-table/shared-table-base";
import { useExpensesTableColumns } from "./expenses-table-columns";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { DataTable, useDataTable } from "~/lib/ui/data-table";

/**
 * Used in project/finance/expenses and /expenses.
 * - Fine for now, but needs to be expanded when expenses are a priority
 */
interface ExpensesTableProps extends SharedTableBase {
  projectId?: string;
  dateFrom?: Date;
  dateTo?: Date;
}

export const CACHE_EXPENSES = "expenses";

export function ExpensesTable(props: ExpensesTableProps) {
  const api = useAPI();
  const columns = useExpensesTableColumns();

  const tableState = useDataTableState({
    columnVisibility: props.columnVisibility,
  });

  const dataQ = useQuery({
    queryKey: [
      CACHE_EXPENSES,
      "index",
      tableState.state.search,
      tableState.state.pagination,
      tableState.sortingDirection,
      tableState.sortBy,
      tableState.pageNumber,
      props.dateFrom,
      props.dateTo,
      props.projectId,
    ],
    queryFn: () =>
      api.expensesList({
        page: tableState.pageNumber,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
        q: tableState.state.search,
        projectId: props.projectId, // filter prop
        deliveryOrCreatedAfter: props.dateFrom,
        deliveryOrCreatedBefore: props.dateTo,
      }),
  });
  const defaults = getSharedTableDefaults(props.parentType);
  const table = useDataTable(
    {
      columns,
      tableState,
      data: dataQ.data?.data,
      isLoading: dataQ.isLoading,
      backendPagination: dataQ.data?.pagination,
      getRowId: (row) => row.id,
    },
    {
      enableGlobalFilter: props.enableGlobalFilter ?? defaults.enableGlobalFilter ?? true,
      enableFilters: props.enableFilters ?? defaults.enableFilters ?? true,
    }
  );

  return <DataTable error={dataQ.error} table={table} />;
}
