import { forwardRef, ReactNode } from "react";
import { NavLink } from "react-router";
import { Popover } from "~/lib/ui/popover/popover";
import { NavItem } from "./navigation-item.type";
import { iframeNavigationStateHack } from "~/lib/utils/iframe";

export const NavigationPopoverMenu = forwardRef<
  HTMLDivElement,
  { children: ReactNode; subItems: Array<NavItem> }
>(({ children, subItems }, ref) => {
  return (
    <Popover
      triggerAsChild
      triggerRender={() => children}
      config={{
        side: "right",
        sideOffset: 20,
        align: "end",
        arrow: true,
        arrowColor: "#213449",
        contentClassName: "z-navigation-menu-popover",
        autofocus: false, // prevent tooltip from being triggered by popover
      }}
    >
      {(onClose) => (
        <div
          ref={ref}
          className="flex flex-shrink-0 flex-col overflow-hidden rounded-md bg-primary text-base text-gray-200 shadow-md"
          onClick={() => onClose()}
        >
          {subItems.map((subItem, idx) => {
            if (!subItem.href) return null;
            return (
              <div key={subItem.name + idx}>
                {subItem.external ? (
                  <a
                    href={subItem.href}
                    target="_blank"
                    rel="noreferrer"
                    className="group flex w-full px-4 py-3 hover:bg-hover hover:text-white"
                  >
                    {subItem.name}
                  </a>
                ) : (
                  <NavLink
                    to={subItem.href}
                    // This is a hack to trick the iFrame into re-render, remove when iFrames are gone
                    state={iframeNavigationStateHack()}
                    className="group flex w-full px-4 py-3  hover:bg-hover hover:text-white"
                  >
                    {subItem.name}
                  </NavLink>
                )}
              </div>
            );
          })}
        </div>
      )}
    </Popover>
  );
});
NavigationPopoverMenu.displayName = "NavigationPopoverMenu";
