import { useTranslation } from "react-i18next";
import { ContactPerson } from "@apacta/sdk";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useMutation } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useToasts } from "~/lib/toast/use-toasts";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { getIcon, LabelInput } from "~/lib/ui";
import { emailOptional } from "~/lib/form-state/zod";

type Props = {
  onSuccess: (id?: string) => void;
  onClose: () => void;
  customerId: string;
  defaultContactPersonName?: string;
} & (
  | {
      mode: "create";
      contactPerson?: never;
    }
  | {
      mode: "edit";
      contactPerson?: ContactPerson;
    }
);

export function CreateEditContactPersonModal({
  onSuccess,
  mode,
  onClose,
  contactPerson,
  customerId,
  defaultContactPersonName = "",
}: Props) {
  const api = useAPI();

  const { t } = useTranslation();
  const { showTemplate } = useToasts();

  const mutation = useMutation({
    mutationFn: handleAPIRequest,
    onSuccess: (mdata) => {
      showTemplate("CREATED");
      onSuccess?.(mdata);
      onClose();
    },
    onError: (error) => {
      parseBackendErrors(error);
    },
  });

  const { registerStringInput, isPending, isValid, getValue, parseBackendErrors } = useFormState({
    schema: {
      name: z.string().min(1),
      title: z.string().optional(),
      email: emailOptional(),
      phone: z.string().optional(),
    },
    initialValues: {
      name: contactPerson?.name || defaultContactPersonName,
      title: contactPerson?.title || "",
      email: contactPerson?.email || "",
      phone: contactPerson?.phone || "",
    },
    mutationError: mutation.error,
  });
  // Mode aware API promise
  async function handleAPIRequest() {
    switch (mode) {
      case "create":
        const res = await api.addContactPerson({
          contactId: customerId,
          addContactPersonRequest: {
            name: getValue("name"),
            title: getValue("title"),
            email: getValue("email"),
            phone: getValue("phone"),
          },
        });
        return res.data.id;
      case "edit":
        if (!contactPerson) return;
        await api.editContactPerson({
          contactPersonId: contactPerson.id,
          contactId: customerId,
          editContactPersonRequest: {
            name: getValue("name"),
            title: getValue("title"),
            email: getValue("email"),
            phone: getValue("phone"),
          },
        });
        return;
    }
  }

  function handleSubmit() {
    mutation.mutate();
  }

  const Icon = mode === "create" ? getIcon("addUser") : getIcon("edit");
  const title =
    mode === "create"
      ? t("common:create", {
          replace: { entity: t("common:customer_contact", { count: 1 }).toLocaleLowerCase() },
        })
      : t("common:edit_x", {
          replace: { entity: t("common:customer_contact", { count: 1 }).toLocaleLowerCase() },
        });

  return (
    <>
      <DialogHeader title={title} Icon={Icon} />
      <div className="mb-12">
        <div className="mt-3 text-center sm:mt-5">
          <div className="mt-2">
            <div className="flex flex-col gap-5">
              <>
                <LabelInput
                  label={t("common:name")}
                  {...registerStringInput("name")}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                />
                <LabelInput
                  label={t("common:person_title", "Title")}
                  {...registerStringInput("title")}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                />
                <LabelInput
                  type="email"
                  label={t("common:email_address")}
                  {...registerStringInput("email")}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                />
                <LabelInput
                  label={t("common:phone_number")}
                  {...registerStringInput("phone")}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                />
              </>
            </div>
          </div>
        </div>
      </div>
      <DialogFooter
        primary={{
          label: mode === "create" ? t("common:create", { entity: null }) : t("common:edit"),
          loading: mutation.isPending,
          disabled: !isValid || isPending,
          onClick: handleSubmit,
        }}
        onClose={onClose}
      />
    </>
  );
}
