import { MouseEvent, ReactNode, useId, useState } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { twMerge } from "tailwind-merge";
import colors from "tailwindcss/colors";
import { useBreakpoints } from "~/lib/utils/tailwind/use-breakpoints";

export type PopoverSide = "top" | "right" | "bottom" | "left";
export type PopoverAlign = "start" | "center" | "end";

export type PopoverConfig = {
  side?: PopoverSide;
  sideOffset?: number;
  align?: PopoverAlign;
  alignOffset?: number;
  defaultOpen?: boolean;
  animate?: boolean;
  arrow?: boolean;
  arrowColor?: `#${string}`;
  usePortal?: boolean; // https://github.com/radix-ui/primitives/issues/1159#issuecomment-1702383228
  useFixedAnchor?: boolean; // For multiple selection-combobox we can't have the popover hopping around
  contentClassName?: string;
  autofocus?: boolean;
  isModal?: boolean;
};

type PopoverPropsUncontrolled = {
  label?: string;
  disabled?: boolean;
  triggerRender: ({ open }: { open: boolean }) => ReactNode;
  onOpenChange?: (to: boolean) => void;
  config?: PopoverConfig;
  children: (close: () => void) => ReactNode;
  triggerAsChild?: boolean;
  isOpen?: never;
  onClick?: undefined;
  useMobilePopover?: boolean;
};

type PopoverPropsControlled = {
  label?: string;
  disabled?: boolean;
  triggerRender: ({ open }: { open: boolean }) => ReactNode;
  onOpenChange?: (to: boolean) => void;
  config?: PopoverConfig;
  children: (close: () => void) => ReactNode;
  triggerAsChild?: boolean;
  isOpen: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  useMobilePopover?: boolean;
};

type PopoverProps = PopoverPropsControlled | PopoverPropsUncontrolled;

/**
 * Creates a popover that is controlled by the triggerRender
 * @description Use `triggerAsChild` if the trigger is or contains a button-element, otherwise omit it.
 * @param label
 * @param disabled
 * @param triggerRender Render function that is used to generate the trigger for the popover
 * @param onOpenChange Called when open state changes
 * @param config Configuration for the popover
 * @param children The markup that is shown inside the popover
 * @param triggerAsChild If trigger supports ref, then use this
 * @param isOpen If the popover is controlled, then use this
 * @param controlled If the popover is controlled, then use this
 * @param onClick If the popover is controlled, then use this
 */
export function Popover({
  label,
  disabled,
  triggerRender,
  onOpenChange,
  config,
  children,
  /** If trigger supports ref, then use this */
  triggerAsChild = false,
  isOpen,
  onClick,
  useMobilePopover = false, // TODO: Remove this when popovers have been checked to work on mobile
}: PopoverProps) {
  const { isBreakpoint } = useBreakpoints();
  const isMobile = useMobilePopover && isBreakpoint("md"); // Check if we are at or below the md breakpoint

  const defaultConfig: Required<PopoverConfig> = {
    side: "bottom",
    sideOffset: 10,
    align: "center",
    alignOffset: 0,
    defaultOpen: false,
    animate: true,
    arrow: false,
    arrowColor: colors["white"],
    usePortal: true, // https://github.com/radix-ui/primitives/issues/1159#issuecomment-1702383228
    contentClassName: "",
    autofocus: true,
    useFixedAnchor: false,
    isModal: false,
  };

  const settings: Required<PopoverConfig> = { ...defaultConfig, ...config };

  // Mobile override
  if (isMobile) {
    settings.side = "top";
    settings.sideOffset = -5;
    settings.align = "start";
    settings.alignOffset = 0;
    settings.usePortal = false;
    settings.useFixedAnchor = true;
    settings.isModal = true;
  }

  const [open, setOpen] = useState<boolean>(settings.defaultOpen);

  const id = useId();

  const handleOpenChange = (toState: boolean) => {
    onOpenChange?.(toState);
    setOpen(toState);
  };

  const animationClasses = {
    top: "radix-side-top:animate-slide-up",
    right: "radix-side-right:animate-slide-right",
    bottom: "radix-side-bottom:animate-slide-down",
    left: "radix-side-left:animate-slide-left",
  };

  return (
    <PopoverPrimitive.Root
      defaultOpen={settings.defaultOpen}
      open={isOpen ?? open}
      onOpenChange={(toState) => handleOpenChange(toState)}
      modal={settings.isModal}
    >
      <PopoverPrimitive.Trigger
        id={id}
        disabled={disabled}
        asChild={triggerAsChild}
        onClickCapture={
          onClick
            ? (e) => {
                e.preventDefault();
                onClick?.(e);
              }
            : undefined
        }
      >
        {triggerRender({ open })}
      </PopoverPrimitive.Trigger>
      {settings.useFixedAnchor && (
        <PopoverPrimitive.Anchor asChild={triggerAsChild}>
          <div className={twMerge("fixed", isMobile && "bottom-0 left-1 right-1")} />
        </PopoverPrimitive.Anchor>
      )}

      {settings.usePortal ? (
        <PopoverPrimitive.Portal>
          <PopoverPrimitive.Content
            onOpenAutoFocus={(e) => (settings.autofocus ? undefined : e.preventDefault())}
            onCloseAutoFocus={(e) => (settings.autofocus ? undefined : e.preventDefault())}
            side={settings.side}
            sideOffset={settings.sideOffset}
            align={settings.align}
            alignOffset={settings.alignOffset}
            className={twMerge(
              "focus:outline-0",
              settings.animate ? animationClasses[settings.side] : "",
              settings.contentClassName
            )}
          >
            {children(() => handleOpenChange(false))}
            {settings.arrow && <PopoverPrimitive.Arrow style={{ fill: settings.arrowColor }} />}
          </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
      ) : (
        <PopoverPrimitive.Content
          onOpenAutoFocus={(e) => (settings.autofocus ? undefined : e.preventDefault())}
          side={settings.side}
          sideOffset={settings.sideOffset}
          align={settings.align}
          alignOffset={settings.alignOffset}
          className={twMerge(
            "focus:outline-0",
            settings.animate ? animationClasses[settings.side] : "",
            settings.contentClassName
          )}
        >
          {children(() => handleOpenChange(false))}
          {settings.arrow && <PopoverPrimitive.Arrow style={{ fill: settings.arrowColor }} />}
        </PopoverPrimitive.Content>
      )}
    </PopoverPrimitive.Root>
  );
}

export const PopoverContent = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div
      className={twMerge(
        "min-w-[--radix-popover-trigger-width] overflow-hidden rounded-lg border bg-white p-2 shadow-md",
        className
      )}
    >
      {children}
    </div>
  );
};
