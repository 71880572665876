import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useTranslation } from "react-i18next";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { useLocale } from "~/lib/utils/date";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useQuery } from "@tanstack/react-query";
import { useGraphQL, Procurement_Analysis_List_Sort_Fields } from "~/lib/gql";
import { CACHE_PROCUREMENT, ProcurementPageSearchParams } from "~/pages/procurement";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { twMerge } from "tailwind-merge";
import { ProcurementProgressCell } from "~/pages/procurement/_cmp/table/procurement-progress-cell";
import { VendorCell } from "~/pages/procurement/_cmp/table/vendor-cell";
import { ProcurementTableRowFragment } from "~/lib/gql/generated/graphql";

export const CACHE_PROCUREMENT_STATUS = "procurement-status";

export function ProcurementTable({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) {
  const [searchParams] = useTypedSearchParams<ProcurementPageSearchParams>();
  const { t } = useTranslation();
  const { format } = useLocale();
  const sdk = useGraphQL();

  const tableState = useDataTableState({
    sorting: [
      {
        desc: true,
        id: Procurement_Analysis_List_Sort_Fields.Created,
      },
    ],
  });

  const dataQ = useQuery({
    queryKey: [
      CACHE_PROCUREMENT,
      dateFrom,
      dateTo,
      searchParams.companiesVendorIds,
      tableState.sortBy,
      tableState.sortingDirection,
    ],
    queryFn: () =>
      sdk.procurementTable({
        analysisParams: {
          dateInterval: {
            from: dateFrom,
            to: dateTo,
          },
          companiesVendorIds: searchParams.companiesVendorIds,
          sortBy: tableState.sortBy as Procurement_Analysis_List_Sort_Fields,
          direction: tableState.sortingDirection === "desc" ? "DESC" : "ASC",
        },
      }),
  });

  const data = dataQ.data?.procurementAnalyses.edges.map((edge) => edge.node) || [];

  const columns = useDataColumns<ProcurementTableRowFragment>((columnHelper) => [
    columnHelper.accessor("id", {
      header: t("common:name"),
      meta: {
        cellClassName: "align-top",
      },
      cell: ({ row, getValue }) => {
        const isCompleted = row.original.progress === 100;
        return (
          <OptionalLink to={isCompleted ? getValue() : undefined}>
            <span
              className={twMerge(
                "block shrink-0 whitespace-nowrap",
                isCompleted ? "font-semibold" : "italic"
              )}
            >{`${t("common:price_analysis", { count: 1 })} ${format(row.original.dateStart, { shortDate: true })} - ${format(row.original.dateEnd, { shortDate: true })}`}</span>
          </OptionalLink>
        );
      },
    }),
    columnHelper.accessor("created", {
      id: Procurement_Analysis_List_Sort_Fields.Created,
      enableSorting: true,
      header: t("common:created_date"),
      cell: ({ row }) => <span>{format(row.original.created, { shortDate: true })}</span>,
      meta: {
        cellClassName: "text-center align-top",
        headerClassName: "text-center",
      },
    }),
    columnHelper.accessor("companiesVendors", {
      header: t("common:vendor", { count: 1 }),
      cell: ({ row }) => <VendorCell companiesVendors={row.original.companiesVendors} />,
      meta: {
        cellClassName: "max-w-md align-top",
      },
    }),
    columnHelper.accessor("progress", {
      header: t("common:status"),
      cell: ({ row }) => (
        <ProcurementProgressCell
          id={row.original.id}
          initialProgress={row.original.progress}
          status={row.original.status}
        />
      ),
      meta: {
        cellClassName: "w-52 text-right align-top",
      },
    }),
  ]);

  const table = useDataTable({
    columns,
    tableState,
    data,
    isLoading: dataQ.isLoading,
    getRowId: (edge) => edge.id,
  });

  return <DataTable table={table} error={dataQ.error} />;
}
