// Purpose: Commonly used validation types, with strings
import { z } from "zod";

export function cvr() {
  return z.string().max(12).or(z.literal("")).optional();
}

export function dkZip() {
  return z
    .string()
    .regex(/^\d{4}$/, { message: "Invalid zip code" })
    .optional()
    .or(z.literal(""));
}

export function eanRequired() {
  return z.string().min(13).max(20).regex(/^\d+$/, {
    message: "EAN numbers are numeric and between 13 and 20 characters long",
  });
}

export function eanOptional() {
  return z
    .string()
    .min(13)
    .max(20)
    .regex(/^\d+$/, {
      message: "EAN numbers are numeric and between 13 and 20 characters long",
    })
    .or(z.literal(""))
    .optional();
}

/**
 * Email validation with Danish characters
 * Zod does not support special characters in email validation
 * These are however valid under RFC 6530
 */
export function emailOptional() {
  return z
    .string()
    .transform((val) =>
      val.toLowerCase().replaceAll("æ", "a").replaceAll("ø", "o").replaceAll("å", "a")
    )
    .pipe(z.string().email().optional().or(z.literal("")))
    .optional();
}

export function emailRequired() {
  return z
    .string()
    .transform((val) =>
      val.toLowerCase().replaceAll("æ", "a").replaceAll("ø", "o").replaceAll("å", "a")
    )
    .pipe(z.string().email());
}
