import Calendar from "./calendar";
import * as Popover from "@radix-ui/react-popover";
import { cloneElement, ReactElement, Suspense, useEffect, useState } from "react";

export default function DatePicker({
  value,
  onChange,
  children,
}: {
  value?: Date;
  onChange?: (d: Date) => void;
  children: ReactElement<{ onClick: () => void }>;
}) {
  const [selectedDate, setSelectedDate] = useState<Date>(value || new Date());
  const [open, setOpen] = useState<boolean>(false);

  const handleSelectDate = (v: Date) => {
    setSelectedDate(v);
    onChange?.(v);
    setOpen(false);
  };

  useEffect(() => {
    if (value !== selectedDate) {
      setSelectedDate(value || new Date());
    }
  }, [value]);

  if (!children) {
    console.error("DatePicker component requires a child component");
    return null;
  }

  return (
    <>
      <div>
        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Anchor>
            <div>{cloneElement(children, { onClick: () => setOpen(true) })}</div>
          </Popover.Anchor>
          <Popover.Portal>
            <Popover.Content className="animate-slide-down">
              <div>
                <Suspense>
                  <Calendar
                    defaultDate={selectedDate}
                    onSelect={(d: Date) => handleSelectDate(d)}
                  />
                </Suspense>
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    </>
  );
}
