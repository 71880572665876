import { BaseFile } from "@apacta/sdk";
import { MediaPreviewLink } from "~/lib/ui/media";
import { useEffect, useState } from "react";

type CrudFile = {
  mimeType: string;
  downloadUrl?: string;
  previewUrl?: string;
  href: string;
};

export function FileCrudPreview({ file }: { file: File | BaseFile }) {
  const [crudFile, setCrudFile] = useState<CrudFile | undefined>(undefined);

  // We must make sure we have something to 1) show and 2) download in case we're dealing with a staged file
  useEffect(() => {
    // BaseFile
    if ("id" in file) {
      setCrudFile({
        mimeType: file.mimeType ?? "unknown",
        downloadUrl: file.downloadUrl,
        previewUrl: file.imageUrls?.thumbnail,
        href: file.fileUrl || "",
      });
    } else {
      // File (staged)
      setCrudFile(() => {
        const url = URL.createObjectURL(file as File);
        return {
          mimeType: (file as File).type,
          downloadUrl: url,
          previewUrl: url,
          href: url,
        };
      });
    }

    // Clean up object URL when component unmounts
    return () => {
      if (crudFile) {
        URL.revokeObjectURL(crudFile.href);
      }
    };
  }, [file]);

  // Display nothing if there is no file URL
  if (!crudFile) return null;

  // Display MediaPreviewLink with the file URL
  return (
    <MediaPreviewLink
      previewURL={crudFile.previewUrl}
      downloadURL={crudFile.downloadUrl}
      href={crudFile.href}
      mimeType={crudFile.mimeType}
    />
  );
}
