import { Button, Dialog, getIcon, Icon } from "~/lib/ui";
import { Popover } from "~/lib/ui/popover/popover";
import { CreateCustomerDialog } from "~/pages/customers/_cmp/create-customer-dialog";
import { linkToCustomer, linkToNewForm, linkToProject } from "~/lib/utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { CreateProjectDialog } from "~/pages/projects/_cmp/create-project-dialog";
import { useAPI } from "~/lib/api";
import { useMutation } from "@tanstack/react-query";
import { Spinner } from "~/lib/ui/spinner";
import { useSidebarContext } from "~/lib/navigation/navigation-layout/sidebar-context";
import { twMerge } from "tailwind-merge";
import { useBreakpoints } from "~/lib/utils/tailwind/use-breakpoints";

export function QuickCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const api = useAPI();
  const { sidebarCollapsed, onVisibilityChange } = useSidebarContext();
  const { isBreakpoint } = useBreakpoints();
  const isMobile = isBreakpoint("md"); // Check if we are at or below the md breakpoint

  const createOfferMutation = useMutation({
    mutationFn: (_args: { callback: () => void }) =>
      api.createOffer({ editOfferRequest: { title: t("offers:new_offer"), isQuote: true } }),
    onSuccess: (v, args) => {
      args.callback();
    },
  });

  const handleCreateOffer = async (callback: () => void): Promise<void> => {
    await createOfferMutation.mutateAsync(
      { callback },
      {
        onSuccess: (v) => {
          if (v.data?.id) {
            navigate("/offers/" + v.data.id);
          }
        },
      }
    );
  };

  return (
    <Popover
      config={{
        side: "right",
        align: "start",
        sideOffset: 16,
        arrow: true,
        arrowColor: "#213449",
      }}
      useMobilePopover
      triggerAsChild
      triggerRender={() => (
        <div className={twMerge(sidebarCollapsed && "flex justify-center p-1")}>
          <Button
            variant="tertiary"
            Icon={getIcon("add")}
            className={twMerge(
              "w-full rounded-lg py-1",
              sidebarCollapsed && "justify-center px-1 py-1.5"
            )}
            iconClassName={twMerge("h-7 w-7", sidebarCollapsed && "mr-0 h-6 w-6")}
          >
            {!sidebarCollapsed ? (
              <div className="flex w-full items-center justify-between">
                <span className="text-sm font-normal">
                  {t("common:create", { entity: `${t("common:new").toLowerCase()}...` })}
                </span>
                <Icon name="chevronRight" className="h-4 w-4" />
              </div>
            ) : null}
          </Button>
        </div>
      )}
    >
      {(close) => (
        <div
          className={twMerge(
            "relative z-navigation-menu-popover overflow-hidden",
            isMobile
              ? "-ml-px w-screen rounded-t-lg bg-white p-6 text-zinc-700"
              : "min-w-40 rounded-lg bg-primary p-2 text-white shadow-md"
          )}
        >
          {isMobile && (
            <>
              <div className="absolute right-2 top-2 p-2" onClick={close}>
                <Icon name="close" className="h-6 w-6" />
              </div>
            </>
          )}
          <div className="flex cursor-pointer flex-col text-sm font-normal">
            <Dialog
              onOpenChange={(open) => {
                onVisibilityChange(false);
                if (!open) {
                  close();
                }
              }}
              trigger={
                <div className="flex items-center gap-3 rounded-lg px-3 py-4 md:py-2 md:hover:bg-hover ">
                  <Icon name="customer" className="h-5 w-5" />
                  <span>{t("common:customer", { count: 1 })}</span>
                </div>
              }
              render={({ onClose }) => (
                <CreateCustomerDialog
                  onCustomerCreated={(customerId) => {
                    close();
                    onClose();
                    setTimeout(() => {
                      navigate(linkToCustomer(customerId));
                    }, 1);
                  }}
                  onOpenChange={() => {
                    onClose();
                    close();
                  }}
                />
              )}
            />

            <div
              onClick={() => handleCreateOffer(close)}
              className="flex items-center gap-3 rounded-lg px-3 py-2 md:hover:bg-hover"
            >
              {createOfferMutation.isPending ? (
                <Spinner className="h-5 w-5" />
              ) : (
                <Icon name="offer" className="h-5 w-5" />
              )}
              <span>{t("common:offer_quote", { count: 1 })}</span>
            </div>

            <Dialog
              trigger={
                <div className="flex items-center gap-3 rounded-lg px-3 py-2 md:hover:bg-hover">
                  <Icon name="project" className="h-5 w-5" />
                  <span>{t("common:project", { count: 1 })}</span>
                </div>
              }
              onOpenChange={(open) => {
                onVisibilityChange(false);
                if (!open) {
                  close();
                }
              }}
              className="md:max-w-3xl"
              render={({ onClose: onCloseCreateProject }) => (
                <CreateProjectDialog
                  onProjectCreated={(newProjectId) => {
                    close();
                    onCloseCreateProject();
                    setTimeout(() => {
                      navigate(
                        linkToProject(newProjectId, {
                          subPage: "data",
                        })
                      );
                    }, 1);
                  }}
                  onOpenChange={() => {
                    onCloseCreateProject();
                    close();
                  }}
                />
              )}
            />

            <div
              onClick={() => navigate(linkToNewForm())}
              className="flex items-center gap-3 rounded-lg px-3 py-2 md:hover:bg-hover"
            >
              {createOfferMutation.isPending ? (
                <Spinner className="h-5 w-5" />
              ) : (
                <Icon name="registration" className="h-5 w-5" />
              )}
              <span>{t("common:registration", { count: 1 })}</span>
            </div>
          </div>
        </div>
      )}
    </Popover>
  );
}
