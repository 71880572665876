import { parseError } from "./parse-error";
import bugIllustration from "./bug-fixing-illustration.svg";
import { useTranslation } from "react-i18next";
import { ApactaErrorResponse } from "./types";
import { DEV } from "../auth/config";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Button, getIcon } from "../ui";

/** A component we can put inside our ErrorBoundary that can communicate meaningful error messages
 *
 * Should support both REST and GraphQL errors
 */
export function ErrorElement({ error: unknownError }: { error: unknown }) {
  const { t } = useTranslation();
  const [isDev, setIsDev] = useState(DEV);

  const errorQ = useSuspenseQuery({
    queryKey: ["error", unknownError],
    queryFn: async () => {
      return parseError(unknownError);
    },
  });

  const error = errorQ.data;

  const title = isDev
    ? `${error.errorType} Error (${error.status})`.toLocaleUpperCase()
    : t("errors:unexpected.title");

  return (
    <div className="rounded-md border border-red-200  text-red-700">
      <div className=" flex flex-row items-center justify-between gap-2 rounded-t-md bg-red-50 px-4 py-2 text-base font-medium">
        {title}
        {DEV && (
          <span className="inline-flex items-center gap-4 text-shade-500">
            <Button
              size="small"
              Icon={getIcon("view")}
              variant="secondary"
              onClick={() => setIsDev(!isDev)}
            >
              {isDev ? "Show as end-user" : "Show as developer"}
            </Button>
          </span>
        )}
      </div>
      <div className="rounded-b-md border-t border-red-200 bg-white px-4 py-2 text-shade-900">
        {isDev ? <InnerErrorDeveloper error={error} /> : <InnerErrorPublic error={error} />}
      </div>
    </div>
  );
}

function InnerErrorPublic(_props: { error: ApactaErrorResponse }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row flex-wrap gap-2 md:flex-nowrap">
      <div>
        <p>{t("errors:unexpected.subtitle")}</p>
        <p className="mt-4">
          {t("errors:unexpected.contact_support")}{" "}
          <strong>{t("common:customer_service_telephone")}</strong>
        </p>
      </div>
      <div className="flex flex-1 justify-end">
        <img src={bugIllustration} className="h-52 w-52" alt="Bug fixing" />
      </div>
    </div>
  );
}

function InnerErrorDeveloper({ error }: { error: ApactaErrorResponse }) {
  return (
    <div>
      <p>{error.message}</p>
      <div className="text-base">
        {error?.stack && (
          <details>
            <summary>Stack Trace</summary>
            <pre className="whitespace-pre-line py-2">{error.stack}</pre>
          </details>
        )}
        {error?.graphqlErrors && error.graphqlErrors.length > 0 && (
          <details>
            <summary>GraphQL Errors</summary>
            <ul className="list-disc pl-4">
              {error.graphqlErrors.map((err, i) => (
                <li key={i}>{err.message}</li>
              ))}
            </ul>
          </details>
        )}
        {error?.response && <ResponseUnwrapper response={error.responseData} />}
      </div>
    </div>
  );
}

function ResponseUnwrapper({ response }: { response: ApactaErrorResponse["responseData"] }) {
  if (response === undefined) return null;
  return (
    <details>
      <summary>Response</summary>
      <pre className="whitespace-pre py-2">{JSON.stringify(response, null, 2)}</pre>
    </details>
  );
}
