import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import {
  Pagination_Direction,
  useGraphQL,
  Vendor_Product_Price_File_List_Sort_Fields,
} from "~/lib/gql";
import { useQuery } from "@tanstack/react-query";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { ProcurementPageSearchParams } from "~/pages/procurement";
import { useToastOnError } from "~/lib/utils/hooks";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useLocale } from "~/lib/utils/date";
import LazyImage from "~/lib/ui/lazy-image";
import { Badge } from "~/lib/ui/badge";
import { useTranslation } from "react-i18next";
import { Button, getIcon } from "~/lib/ui";
import { Link } from "react-router";

export type PriceFileProduct = {
  id: string;
  imageUrl: string;
  productName: string;
  productNumber: string;
  quantity: number;
  previousPrice: number;
  currentPrice: number;
};

// TODO remove when GQL implemented
export type PriceFileLine = {
  id: string;
  vendor: {
    vendorName: string;
    imageUrl: string;
  };
  fileName: string;
  uploadedDate: Date;
  products: Array<PriceFileProduct>;
};

// TODO remove when GQL implemented
type PriceFileTable = {
  priceFiles: Array<PriceFileLine>;
};

export const CACHE_PRICE_FILES = "price-files";
export default function PriceFileTable({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) {
  const [searchParams] = useTypedSearchParams<ProcurementPageSearchParams>();
  const { format } = useLocale();
  const { t } = useTranslation();
  const sdk = useGraphQL();

  const tableState = useDataTableState({
    sorting: [
      {
        desc: !!Pagination_Direction.Desc,
        id: "Created",
      },
    ],
  });

  const dataQ = useQuery({
    queryKey: [CACHE_PRICE_FILES, dateFrom, dateTo, searchParams.companiesVendorIds],
    queryFn: async () =>
      sdk.priceFilesIndex({
        params: {
          direction: Pagination_Direction.Desc,
          sortBy: Vendor_Product_Price_File_List_Sort_Fields.Created,
          vendorIds: searchParams.companiesVendorIds,
          dateInterval: {
            from: dateFrom,
            to: dateTo,
          },
        },
      }),
  });

  useToastOnError(dataQ.error);

  const data = dataQ.data?.vendorProductPriceFiles.edges ?? [];

  const columns = useDataColumns<(typeof data)[number]>((columnHelper) => [
    columnHelper.display({
      header: t("common:vendor", { count: 1 }),
      cell: ({ row }) => (
        <div className="flex items-center gap-4">
          {row.original.node.companiesVendor.vendor.imageUrl ? (
            <LazyImage
              src={row.original.node.companiesVendor.vendor.imageUrl}
              alt={row.original.node.companiesVendor.vendorName ?? ""}
              className="h-auto w-16"
            />
          ) : (
            <img
              src="/image_not_found.jpg"
              alt={row.original.node.companiesVendor.vendorName ?? ""}
              className="h-16 w-16"
            />
          )}
          <div>{row.original.node.companiesVendor.vendorName}</div>
        </div>
      ),
    }),
    columnHelper.accessor("node.created", {
      id: "Created",
      header: t("common:created_date"),
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => <span>{format(row.original.node.created, { shortDate: true })}</span>,
    }),
    columnHelper.display({
      header: t("common:price_adjustment"),
      cell: () => (
        <Badge variant="yellow" size="sm">
          {t("common:coming_soon")}
        </Badge>
      ),
    }),
    columnHelper.display({
      id: "actions",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <div>
          <Link to={row.original.node.id}>
            <Button
              as="span"
              size="small"
              Icon={getIcon("view")}
              variant="secondary"
            >{`${t("common:see")} ${t("procurement:tabs.price_file_analysis").toLowerCase()}`}</Button>
          </Link>
        </div>
      ),
    }),
  ]);

  const table = useDataTable(
    {
      columns,
      tableState,
      data,
      isLoading: dataQ.isFetching,
      getRowId: (row) => row.node.id,
    },
    { enableGlobalFilter: false }
  );

  return <DataTable error={dataQ.error} table={table} />;
}
