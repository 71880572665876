import { Context, Fragment, ReactNode } from "react";
import { useSlot } from "./use-slot";
import { SlotContext } from "./slot-context";

export function Slot<SlotName extends string>(props: {
  slot: SlotName;
  context: Context<SlotContext | null>;
  /** Enables you to wrap the slot in a custom component */
  render?: (inner: ReactNode) => ReactNode;
}) {
  const signals = useSlot(props.slot, props.context);
  if (!signals) return [];
  let filteredAndSorted = Array.from(signals ?? [])
    .map(([_id, signal]) => signal)
    .filter((signal) => {
      if (signal.hidden === true) return false;
      return true;
    })
    // Sorted by weight, heaviest at the bottom
    .sort((a, b) => (a.weight ?? 0) - (b.weight ?? 0));

  // If the list contains a replace signal, we can only return that one.
  // If there are multiple replace signals, we can only return the heaviest one.
  if (filteredAndSorted.some((signal) => signal.replace)) {
    const replaceSignal = filteredAndSorted.findLast((signal) => signal.replace);
    if (replaceSignal) {
      filteredAndSorted = [replaceSignal];
    }
  }

  const renderedItems: Array<ReactNode> = filteredAndSorted.map((signal) => {
    if (signal.children) {
      return <Fragment key={signal.id}>{signal.children}</Fragment>;
    }
    return null;
  });

  // If the parent wants to wrap the rendering, do so
  if (props.render) return props.render(renderedItems);
  // Otherwise, just return the rendered items
  return renderedItems;
}
