import {
  ProcurementKpiCard,
  ProcurementKpiCardProps,
} from "~/pages/procurement/_cmp/kpi/procurement-kpi-card";
import { useTranslation } from "react-i18next";
import { PriceFileDetailKpiCardsQuery, useGraphQL } from "~/lib/gql";
import { formatDecimals } from "~/lib/utils/number";
import { ProcurementPageSearchParams } from "~/pages/procurement";
import { KPISkeletonItem } from "~/lib/ui/skeleton/kpi-skeleton";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";

const CACHE_PROCUREMENT_KPI = "procurement-kpi";

type KpiCardKey = keyof Omit<
  PriceFileDetailKpiCardsQuery["vendorProductPriceFileKpiCardData"],
  "__typename"
>;
type KpiCardValue<Key extends KpiCardKey> =
  PriceFileDetailKpiCardsQuery["vendorProductPriceFileKpiCardData"][Key];

export default function KpiSection() {
  const { t } = useTranslation();
  const { priceFileId } = useParams<{ priceFileId: string }>();
  const [searchParams] = useTypedSearchParams<ProcurementPageSearchParams>();
  const sdk = useGraphQL();

  const kpiQ = useQuery({
    queryKey: [CACHE_PROCUREMENT_KPI, searchParams.companiesVendorIds],
    queryFn: () =>
      sdk.priceFileDetailKpiCards({
        params: {
          vendorProductPriceFileId: priceFileId as string,
        },
      }),
  });

  const kpiCardData: Record<KpiCardKey, KpiCardValue<KpiCardKey>> = kpiQ.data
    ?.vendorProductPriceFileKpiCardData ?? {
    globalPriceDevelopment: 0,
    yourPriceDevelopment: 0,
    priceDecreasesCount: 0,
    priceIncreasesCount: 0,
  };

  const kpiCards: Record<KpiCardKey, Omit<ProcurementKpiCardProps, "value" | "loading">> = {
    yourPriceDevelopment: {
      title: `${t("common:your")} ${t("procurement:price_file_analysis_view.kpi.price_development.title").toLowerCase()}`,
      iconName: "chart",
      description: t("procurement:price_file_analysis_view.kpi.price_development.description"),
      valueRender: (value: number) => <span>{formatDecimals(value, 2)}%</span>,
    },
    globalPriceDevelopment: {
      title: `${t("common:general")} ${t("procurement:price_file_analysis_view.kpi.price_development.title").toLowerCase()}`,
      iconName: "chart",
      description: t("procurement:price_file_analysis_view.kpi.price_development.description"),
      valueRender: (value: number) => <span>{formatDecimals(value, 2)}%</span>,
    },
    priceDecreasesCount: {
      title: t("procurement:price_file_analysis_view.kpi.price_decreases.title"),
      iconName: "currency",
      description: t("procurement:price_file_analysis_view.kpi.price_decreases.description"),
      valueRender: (value: number) => <span>{formatDecimals(value, 0)}</span>,
    },
    priceIncreasesCount: {
      title: t("procurement:price_file_analysis_view.kpi.price_increases.title"),
      iconName: "product",
      description: t("procurement:price_file_analysis_view.kpi.price_increases.description"),
      valueRender: (value: number) => <span>{formatDecimals(value, 0)}</span>,
    },
  };

  // Show skeleton loaders while fetching
  if (kpiQ.isFetching) {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        {Array.from({ length: Object.entries(kpiCards).length }).map((_, idx) => (
          <KPISkeletonItem key={idx} />
        ))}
      </div>
    );
  }

  const hasData = Object.keys(kpiCardData).length > 0;

  // Show empty state if no data
  if (!kpiQ.isFetching && !hasData) {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        {Object.values(kpiCards).map((c, idx) => (
          <ProcurementKpiCard key={`procurement-kpi-${idx}`} {...c} value={0} />
        ))}
      </div>
    );
  }

  // Show KPI cards if data is available
  if (!kpiQ.isFetching && hasData) {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        {Object.entries(kpiCards).map(
          ([key, value]) =>
            key !== "__typename" && (
              <ProcurementKpiCard
                key={`procurement-kpi-${key}`}
                {...value}
                value={Number(kpiCardData[key as KpiCardKey] ?? 0)}
              />
            )
        )}
      </div>
    );
  }
}
