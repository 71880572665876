import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { ChevronDownIcon, SwatchIcon } from "@heroicons/react/24/outline";
import TextInput from "~/lib/ui/form-elements/text-input";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useTranslation } from "react-i18next";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import ColorPicker from "~/lib/ui/colors/color-picker";
import { LabelColor, labelColors } from "~/lib/utils/colors";
import colors from "tailwindcss/colors";
import { Popover } from "~/lib/ui/popover/popover";
import { Label } from "~/lib/ui";
import { useAPI } from "~/lib/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateLabelRequest, LabelEntityEntityEnum } from "@apacta/sdk";

export default function CreateLabelModal({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();

  const saveLabelMutation = useMutation({
    mutationFn: (label: CreateLabelRequest) => api.createLabel({ createLabelRequest: label }),
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey: ["labels"] });
    },
  });

  const { register, setValues, getValue, isValid } = useFormState({
    schema: {
      text: z.string().min(1),
      "bg-color": z.string().min(1),
      "text-color": z.string().min(1),
      entity: z.string().min(1),
    },
    initialValues: {
      text: "",
      "bg-color": labelColors[0],
      "text-color": colors.zinc[700],
      entity: "task",
    },
    mutationError: saveLabelMutation.error,
  });

  const handleSubmit = () => {
    saveLabelMutation.mutate({
      text: getValue("text"),
      bgColor: getValue("bg-color"),
      textColor: getValue("text-color"),
      entity: getValue("entity") as LabelEntityEntityEnum,
    });
  };

  return (
    <div>
      <DialogHeader
        title={t("common:create", { entity: t("common:label", { count: 1 }).toLowerCase() })}
        Icon={SwatchIcon}
      />
      <div className="mb-12">
        <div className="flex flex-col gap-8">
          <TextInput {...register("text")} label={t("common:name")} />
          <div className="flex gap-8">
            <div>
              <Label>{t("common:availability")}</Label>
              <Popover
                triggerRender={() => (
                  <div className="flex items-center gap-2 rounded-lg border bg-white px-3 py-2">
                    <span>{t(`settings:labels.availability.${getValue("entity")}`)}</span>
                    <ChevronDownIcon className="h-5 w-5" />
                  </div>
                )}
              >
                {(close) => (
                  <div className="flex flex-col rounded-lg border bg-white shadow-md">
                    <div
                      className="px-6 py-3 hover:bg-shade-100"
                      onClick={() => {
                        setValues({ entity: "employee" });
                        close();
                      }}
                    >
                      {t(`settings:labels.availability.employee`)}
                    </div>
                    <div
                      className="px-6 py-3 hover:bg-shade-100"
                      onClick={() => {
                        setValues({ entity: "task" });
                        close();
                      }}
                    >
                      {t(`settings:labels.availability.task`)}
                    </div>
                  </div>
                )}
              </Popover>
            </div>
            <ColorPicker
              label={t("common:color", { count: 1 })}
              currentColor={getValue("bg-color") as LabelColor}
              onSelect={(bgColor, textColor) => {
                setValues({ "bg-color": bgColor, "text-color": textColor });
              }}
            />
          </div>
        </div>
      </div>
      <DialogFooter
        primary={{
          label: t("common:create_nondistinct"),
          onClick: () => handleSubmit(),
          disabled: !isValid,
          loading: saveLabelMutation.isPending,
        }}
        onClose={onClose}
      />
    </div>
  );
}
