import { getSelectedIds, SelectionCombobox } from "./selection-combobox";
import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { SelectionComboboxExtendable, ValueChangeHandler } from "./combobox-types";
import { ControlPanelApiProjectStatusResponse } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

type Entity = ControlPanelApiProjectStatusResponse; //shortened for readability
export function ProjectStatusSelection<D extends Entity = Entity>(
  props: SelectionComboboxExtendable<D>
) {
  // TODO - Ask Andri if this is something we actually want, it's not implemented at all yet
  //const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  //const [createQuery, setCreateQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const api = useAPI();
  const { t } = useTranslation();
  const vcRef = useRef<ValueChangeHandler>(undefined); // value change ref

  // Array of ids to select. Undefined if searchquery is set
  const idParam = getSelectedIds({
    value: props.value,
    defaultValue: props.defaultValue,
    isSearchDialogOpen,
  });

  const uq = useQuery({
    queryKey: ["project-status", idParam, searchQuery],
    queryFn: () =>
      api.iListProjectStatuses({
        q: searchQuery,
      }),
  });
  const items = uq.data?.data || [];

  const pendingSelection = useRef<string | undefined>(undefined);

  /*function handleCreated(userId?: string) {
    pendingSelection.current = userId;
    uq.refetch();
  }*/

  // Set the value after the data has been loaded
  useEffect(() => {
    if (pendingSelection.current === undefined) return;
    vcRef.current?.(pendingSelection.current);
    pendingSelection.current = undefined;
  }, [items]);

  return (
    <div>
      <SelectionCombobox<D>
        label={t("projects:project_status")}
        {...props}
        data={items as Array<D>}
        valueFn={(entity) => entity.id}
        placeholder={t("projects:project_status_placeholder", "Select project status")}
        valueChangeRef={vcRef}
        labelFn={(entity) => entity.name} // searching
        loading={uq.isFetching}
        onQueryChange={setSearchQuery}
        /*onCreateNew={(query) => {
          setCreateQuery(query);
          setIsCreateDialogOpen(true);
        }}*/
        onOpenChange={setIsSearchDialogOpen}
      />
    </div>
  );
}
