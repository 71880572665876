import { useOutletContext } from "react-router";
import { ExpenseLine } from "@apacta/sdk";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useMutation } from "@tanstack/react-query";
import { Button } from "~/lib/ui";
import { formatCurrency } from "~/lib/utils/number";
import { MediaPreviewLink } from "~/lib/ui/media";
import Barcode from "@apacta/react-barcode";
import PageSection from "~/lib/ui/page-section";

export default function OverviewExpenseLinePage() {
  const api = useAPI();
  const { expenseLine } = useOutletContext<{ expenseLine: ExpenseLine }>();
  const { t } = useTranslation();

  const addAsFavoriteMutation = useMutation({
    mutationFn: () =>
      api.createProduct({
        createProductRequest: {
          expenseLineId: expenseLine.id,
        },
      }),
  });

  return (
    <>
      <TabHeading
        actionArea={
          <Button
            variant="tertiary"
            tabIndex={0}
            onClick={() => addAsFavoriteMutation.mutateAsync()}
            confirm={{
              action: "generic",
            }}
          >
            {t("products:expense_lines.add_to_my_products")}
          </Button>
        }
      >
        {t("products:product_overview")}
      </TabHeading>
      <PageSection>
        <div className="flex gap-8">
          <div className="shrink-0 self-start border p-2">
            {expenseLine.imageUrls?.original && expenseLine.imageUrls?.small ? (
              <MediaPreviewLink
                href={expenseLine.imageUrls.original}
                previewURL={expenseLine.imageUrls.small}
                height={80}
                mimeType="image/jpeg"
                allowDownload={false}
              />
            ) : (
              <div className="flex h-48 w-80 items-center justify-center bg-shade-100">
                <img className="max-h-48 max-w-80" src="/image_not_found.jpg" />
              </div>
            )}
          </div>
          <div className="flex w-full justify-between gap-4">
            <div className="flex basis-4/5 flex-col gap-4">
              <div className="flex items-center gap-4">
                {expenseLine.vendor?.imageUrl ? (
                  <div>
                    <img className="max-h-10" src={expenseLine.vendor.imageUrl} />
                  </div>
                ) : (
                  expenseLine.vendor?.name && (
                    <div className="text-sm text-shade-400">{expenseLine.vendor.name}</div>
                  )
                )}
              </div>
              <div className="text-xl font-semibold">{expenseLine.text}</div>
              <div>
                <div className="flex gap-1">
                  <div className="font-semibold">{t("products:number")}:</div>
                  <div>
                    {expenseLine.productNumber ? (
                      expenseLine.productNumber
                    ) : (
                      <span className="text-gray-400">{t("common:not_available")}</span>
                    )}
                  </div>
                </div>
                <div className="flex gap-1">
                  <div className="font-semibold">{t("common:vendor")}:</div>
                  <div>
                    {expenseLine.vendor?.name ? (
                      expenseLine.vendor?.name
                    ) : (
                      <span className="text-gray-400">{t("common:not_available")}</span>
                    )}
                  </div>
                </div>
                <div className="flex gap-1">
                  <div className="font-semibold">{t("common:barcode")}:</div>
                  <div>
                    {expenseLine.barcode ? (
                      expenseLine.barcode
                    ) : (
                      <span className="text-gray-400">{t("common:not_available")}</span>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex gap-1">
                  <div className="font-semibold">{t("products:latest_buying_price")}:</div>
                  <div>
                    {expenseLine.buyingPrice ? (
                      formatCurrency(expenseLine.buyingPrice)
                    ) : (
                      <span className="text-gray-400">{t("common:not_available")}</span>
                    )}
                  </div>
                </div>
                <div className="flex gap-1">
                  <div className="font-semibold">{t("common:sales_price")}:</div>
                  <div>
                    {expenseLine.sellingPrice ? (
                      formatCurrency(expenseLine.sellingPrice)
                    ) : (
                      <span className="text-gray-400">{t("common:not_available")}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {expenseLine.barcode && <Barcode format="EAN13" value={expenseLine.barcode} />}
            </div>
          </div>
        </div>
      </PageSection>
    </>
  );
}
