import { useCallback, useEffect, useState } from "react";
import { z } from "zod";

const PREFERENCES_KEY = "preferences";

const userPreferences = z.object({
  planningView: z.enum(["day", "week", "fortnight", "month"]).default("day"),
  planningSideBarOpen: z.boolean().default(true),
  dismissBrowswerWarning: z.boolean().default(false),
  tableColumnVisibility: z.record(z.string(), z.record(z.string(), z.boolean())).default({}),
  dismissEconomicMessageLengthWarning: z.boolean().default(false),
});

export type UserPreferences = z.TypeOf<typeof userPreferences>;

// Was considering dot-notation, with help of https://github.com/g-makarov/dot-path-value but decided against it.
// For future consideration.
type SetPreferences = (field: keyof UserPreferences, value: UserPreferences[typeof field]) => void;

function parseAndVerify(): UserPreferences {
  const raw = localStorage.getItem(PREFERENCES_KEY);
  try {
    return userPreferences.parse(JSON.parse(raw || "{}"));
  } catch {
    return userPreferences.parse({});
  }
}

export function usePreferences(): [UserPreferences, SetPreferences] {
  const [preferences, setPreferences] = useState(parseAndVerify);

  useEffect(() => {
    localStorage.setItem(PREFERENCES_KEY, JSON.stringify(preferences));
  }, [preferences]);

  const setPreferencesFn = useCallback(
    (field: keyof UserPreferences, value: UserPreferences[typeof field]) => {
      const newPref: UserPreferences = {
        ...preferences,
        [field]: value,
      };

      setPreferences(userPreferences.parse(newPref));
    },
    [preferences]
  );
  return [preferences, setPreferencesFn];
}
