import { PageLayout } from "~/lib/ui/page-layout";
import PageSection from "~/lib/ui/page-section";
import { useParams } from "react-router";
import { useSuspenseQuery } from "@tanstack/react-query";
import { CACHE_ORDER_COMPLAINTS } from "~/pages/procurement/index/_cmp/order-complaints-table";
import { useGraphQL } from "~/lib/gql";
import LazyImage from "~/lib/ui/lazy-image";
import { useTranslation } from "react-i18next";
import WrongPricesTable from "~/pages/procurement/order-complaints/_cmp/wrong-prices-table";
import UnknownProductsTable from "~/pages/procurement/order-complaints/_cmp/unknown-products-table";
import { Button, getIcon } from "~/lib/ui";
import OrderComplaintStatus from "~/pages/procurement/index/_cmp/order-complaint-status";

export default function OrderComplaintViewPage() {
  const { orderComplaintId } = useParams<{ orderComplaintId: string }>();
  const sdk = useGraphQL();
  const { t } = useTranslation();

  const dataQ = useSuspenseQuery({
    queryKey: [CACHE_ORDER_COMPLAINTS, orderComplaintId],
    queryFn: () => sdk.orderComplaintsDetail({ id: orderComplaintId as string }),
  });

  const data = dataQ.data.orderComplaint;

  const hasWrongPriceErrors = !!data?.wrongPriceErrors?.length;
  const hasUnknownProducts = !!data?.unknownProductErrors?.length;

  return (
    <PageLayout title={t("procurement:order_complaints.title")}>
      <PageLayout.Signal slot="description" id="description">
        <OrderComplaintStatus defaultValue={data.status} orderComplaintId={data.id} />
      </PageLayout.Signal>
      <PageLayout.Signal slot="actions" id="download-pdf">
        <a href={data.pdfUrl} download>
          <Button as="span" variant="secondary" Icon={getIcon("download")}>
            Download PDF
          </Button>
        </a>
      </PageLayout.Signal>
      <PageSection className="p-16">
        <div className="flex justify-between">
          <div className="flex flex-col gap-2">
            <div className="text-lg font-medium">{t("common:vendor", { count: 1 })}</div>
            <div className="flex flex-col gap-2">
              {data.expense?.vendor?.imageUrl && (
                <div>
                  <LazyImage
                    src={data.expense?.vendor?.imageUrl}
                    alt={data.expense?.vendor?.name}
                    className="h-10 w-auto"
                  />
                </div>
              )}
              <div>
                <div>{data.expense?.vendor?.name}</div>
                <div>{`${t("common:cvr_abbr")}: ${data.expense?.vendor?.cvr}`}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 text-right">
            <div className="text-lg font-medium">{t("common:customer", { count: 1 })}</div>
            <div className="flex flex-col gap-2">
              {data.expense?.company?.logoUrl?.small && (
                <div className="flex justify-end">
                  <LazyImage
                    src={data.expense?.company?.logoUrl?.small}
                    alt={data.expense?.company?.name}
                    className="h-10 w-auto"
                  />
                </div>
              )}
              <div>
                <div>{data.expense?.company?.name}</div>
                <div>{data.expense?.company?.streetName}</div>
                <div>{`${data.expense?.company?.city?.zipCode} ${data.expense?.company?.city?.name}`}</div>
                <div>{`${t("common:cvr_abbr")}: ${data.expense?.company?.cvr}`}</div>
                <div>{`${t("common:phone_number_abbr")}: ${data.expense?.company?.phone}`}</div>
                <div>{`${t("common:email")}: ${data.expense?.company?.contactEmail}`}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-12">
          {hasWrongPriceErrors && <WrongPricesTable lines={data.wrongPriceErrors} />}
          {hasUnknownProducts && <UnknownProductsTable lines={data.unknownProductErrors} />}
        </div>
      </PageSection>
    </PageLayout>
  );
}
