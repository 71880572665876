import { ComponentProps, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { twMerge } from "tailwind-merge";
import { Button } from "./buttons/button";
import { ButtonSizes, getButtonClasses, getButtonIconClass } from "./buttons/shared/button.shared";
import { getIcon } from "./icons/icon";
import { Tooltip } from "./tooltips/tooltip";

export interface ButtonAction extends ComponentProps<typeof Button> {
  isHidden?: boolean;
  collapseBehavior?: "default" | "always" | "never";
  label: string;
}

/**
 * Creates a button group with a dropdown for additional actions
 * - Do not use this as a dropdown menu
 */
export function ActionButtons({
  actions,
  collapseAt = 3,
  buttonClassName,
  dropdownClassName,
  size = "medium",
  showLabelInButton,
}: {
  actions: Array<ButtonAction>;
  collapseAt?: number;
  buttonClassName?: string;
  dropdownClassName?: string;
  size?: ButtonSizes;
  showLabelInButton?: boolean;
  /** Affects when not collapsed */
}) {
  const [open, setOpen] = useState<boolean>(false);

  const wrapInTooltip = !showLabelInButton;

  const shownActions = actions.filter((action) => !action.isHidden);

  // Show all buttons with collapse=never, and the remaining buttons up to collapseAt
  const buttonActions: Array<ButtonAction> = [];
  let dropdownActions: Array<ButtonAction> = [];

  for (const a of shownActions) {
    if (a.collapseBehavior === "never") {
      buttonActions.push(a);
      continue;
    }
    if (a.collapseBehavior === "always") {
      dropdownActions.push(a);
      continue;
    }
    if (buttonActions.length < collapseAt - 1) {
      buttonActions.push(a);
      continue;
    }
    dropdownActions.push(a);
  }
  // Show button instead, if only one dropdown action and not always collapsed
  if (dropdownActions.length === 1 && dropdownActions[0].collapseBehavior !== "always") {
    buttonActions.push(dropdownActions[0]);
    // remove from dropdown actions
    dropdownActions = [];
  }

  const buttonClass = (variant: ComponentProps<typeof Button>["variant"] = "secondary") =>
    twMerge(
      getButtonClasses({
        variant: variant,
        size,
      }),
      "rounded-none"
    );

  const showButtons = buttonActions.length > 0;
  const showDropdown = dropdownActions.length > 0;
  const iconSizeClasses = size === "small" ? "h-5 w-5" : "h-6 w-6";

  return (
    <>
      <span className="isolate inline-flex rounded-md shadow-sm">
        {buttonActions.map((actionProps, idx) => {
          const isFirst = idx === 0;
          const isLast = (() => {
            if (showDropdown) return false;
            return idx === buttonActions.length - 1;
          })();

          const {
            isHidden: _isHidden,
            label,
            variant,
            collapseBehavior: _collapseBehavior,
            ...buttonProps
          } = actionProps;

          return (
            <Tooltip
              key={idx}
              contentClassName="bg-hover text-white"
              arrowClassName="fill-hover"
              trigger={
                <Button
                  {...buttonProps}
                  as={wrapInTooltip ? "span" : "button"}
                  size={size}
                  className={twMerge(
                    buttonClass(variant),
                    isFirst && "rounded-l-md",
                    isLast && "rounded-r-md",
                    buttonClassName
                  )}
                  iconClassName={getButtonIconClass({ size })}
                >
                  {showLabelInButton ? label : buttonProps.children}
                </Button>
              }
              triggerAsChild={false} // Intentional, buttons will be shown as spans
              side="bottom"
              disabled={!wrapInTooltip}
            >
              {actionProps.label}
            </Tooltip>
          );
        })}

        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Trigger className={twMerge(!showDropdown && "hidden")} asChild>
            <Button
              Icon={getIcon("menuKebab")}
              size={size}
              className={twMerge(
                buttonClass("secondary"),
                !showDropdown && "hidden",
                !showButtons && "rounded-l-md p-2",
                "rounded-r-md",
                dropdownClassName
              )}
              iconClassName={iconSizeClasses}
            />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content align="end" sideOffset={10}>
              <div className="min-w-[10rem] rounded-lg bg-white p-2 shadow">
                {dropdownActions.map((a, idx) => {
                  const {
                    isHidden: _isHidden,
                    label: _label,
                    collapseBehavior: _collapseBehavior,
                    ...buttonProps
                  } = a;
                  return (
                    <Button
                      key={`item-${idx}`}
                      {...buttonProps}
                      className="flex w-full cursor-pointer gap-2 rounded-lg py-2 hover:bg-shade-100"
                    >
                      {a.label}
                    </Button>
                  );
                })}
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </span>
    </>
  );
}
