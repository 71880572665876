import { SelectionCombobox } from "./selection-combobox";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Dialog } from "..";
import { SelectionComboboxExtendable, ValueChangeHandler } from "./combobox-types";
import { Badge } from "../badge";
import { useTranslation } from "react-i18next";
import { CreateProjectDialog } from "~/pages/projects/_cmp/create-project-dialog";
import {
  ProjectSelectionListQuery,
  ProjectSelectionListQueryVariables,
  useGraphQL,
} from "~/lib/gql";
import { CACHE_PROJECTS } from "~/pages/projects";
import { useToastOnError } from "~/lib/utils/hooks";

type Entity = ProjectSelectionListQuery["projects"]["edges"][0]["node"];

export function ProjectSelection<D extends Entity = Entity>({
  requestOverride,
  ...props
}: SelectionComboboxExtendable<D> & {
  requestOverride?: ProjectSelectionListQueryVariables["params"];
}) {
  const { t } = useTranslation();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [createQuery, setCreateQuery] = useState("");

  const sdk = useGraphQL();
  const vcRef = useRef<ValueChangeHandler>(undefined); // value change ref

  const uq = useInfiniteQuery({
    queryFn: ({ pageParam }) =>
      sdk.projectSelectionList({
        params: {
          sortBy: "PROJECT_NUMBER",
          direction: "DESC",
          page: pageParam,
          projectStatus: ["open"],
          limit: 1000,
          ...requestOverride,
        },
      }),
    queryKey: [CACHE_PROJECTS, "selection"],
    staleTime: 1000 * 60 * 5, // Heavy query, keep the data for 5 minutes before refetching

    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (!lastPage.projects.pageInfo.hasNextPage) return null;
      return lastPage.projects.pageInfo.currentPage + 1;
    },
  });
  useToastOnError(uq.error);

  const items = (() => {
    return uq.data?.pages.flatMap((page) => page.projects.edges.map((edge) => edge.node)) || [];
  })();

  // React to a page fetch, and fetches next page is available
  useEffect(() => {
    if (!uq.isSuccess) return;
    if (uq.hasNextPage) {
      uq.fetchNextPage();
    }
  }, [uq]);

  const pendingSelection = useRef<string | undefined>(undefined);

  // Note that the create project dialog clears the cache - so we don't need to
  async function handleCreated(id: string) {
    pendingSelection.current = id;
  }

  // Set the value after the data has been loaded
  useEffect(() => {
    if (pendingSelection.current === undefined) return;
    vcRef.current?.(pendingSelection.current);
    pendingSelection.current = undefined;
  }, [items]);

  return (
    <div>
      <SelectionCombobox<D>
        label={t("common:project", { count: props.multiple ? 2 : 1 })}
        {...props}
        data={items as Array<D>}
        error={uq.error?.message}
        valueFn={(entity) => entity.id}
        valueChangeRef={vcRef}
        enableSelectAll={true}
        labelFn={(entity) => entity.fullName} // searching
        renderItem={(entity) => (
          <div className="flex items-center">
            {entity.projectNumber && (
              <Badge variant="gray" label={`#${entity.projectNumber}`} className="mr-2 shrink-0" />
            )}
            <div className="line-clamp-2 grow-0">{entity.name}</div>
          </div>
        )} // displaying
        loading={uq.isFetching}
        onCreateNew={(query) => {
          setCreateQuery(query);
          setIsCreateDialogOpen(true);
        }}
      />
      <Dialog
        open={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        className="md:max-w-3xl"
        render={({ onClose }) => (
          <CreateProjectDialog
            onProjectCreated={async (projectId) => {
              await handleCreated(projectId);
              onClose();
            }}
            onOpenChange={() => onClose()}
            defaultName={createQuery}
          />
        )}
      />
    </div>
  );
}
