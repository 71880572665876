import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { sub } from "date-fns";
import { fromLocalDateString, toLocalDateString } from "@apacta/sdk";

// Thin wrapper for search-params with a few helpers
export function useEmployeesParams() {
  const [employeesParams, setEmployeesParams] = useTypedSearchParams<{
    start_date?: string;
    end_date?: string;
    employeeIds?: Array<string>;
  }>();

  const defaultFromDate = sub(new Date(), { days: 14 });
  const defaultToDate = new Date();

  function handlePeriodChange(start?: Date, end?: Date) {
    setEmployeesParams("start_date", start ? toLocalDateString(start) : undefined);
    setEmployeesParams("end_date", end ? toLocalDateString(end) : undefined);
  }

  function handleUnsetPeriod() {
    setEmployeesParams("start_date", undefined);
    setEmployeesParams("end_date", undefined);
  }

  function handleResetPeriod(): void {
    setEmployeesParams("start_date", toLocalDateString(defaultFromDate));
    setEmployeesParams("end_date", toLocalDateString(defaultToDate));
  }

  return {
    handlePeriodChange,
    setEmployeesParams,
    handleUnsetPeriod,
    handleResetPeriod,
    startDate: employeesParams.start_date
      ? fromLocalDateString(employeesParams.start_date)
      : undefined,
    endDate: employeesParams.end_date ? fromLocalDateString(employeesParams.end_date) : undefined,
    employeeIds: employeesParams.employeeIds,
  };
}
