import { User } from "@apacta/sdk";
import { Trans } from "react-i18next";
import { useLocale } from "~/lib/utils/date";
import { IconLine } from "~/lib/ui/text/icon-line";

export function ApprovedLine({
  approved,
  approvedBy,
  visible = true,
}: {
  approved?: Date;
  approvedBy?: User;
  visible?: boolean; // In case approval is disabled by a company setting
}) {
  const locale = useLocale();
  const showApprovedBy = approved && approvedBy;
  const showApproved = !!approved && !showApprovedBy;

  if (!approved) return null;
  if (!visible) return null;

  return (
    <IconLine icon="approve">
      {showApprovedBy && (
        <Trans
          i18nKey="common:approved_by_ago"
          components={{
            by: <span title={approvedBy?.fullName} />,
            ago: <span title={locale.format(approved)} />,
          }}
          values={{
            by: approvedBy?.firstName,
            ago: locale.timeAgo(approved),
          }}
          defaults="Approved by <by>{{by}}</by> <ago>{{ago}}</ago>"
        />
      )}
      {showApproved && (
        <Trans
          i18nKey="common:approved_ago"
          components={{
            ago: <span title={locale.format(approved)} />,
          }}
          values={{
            ago: locale.timeAgo(approved),
          }}
          defaults="Approved <ago>{{ago}}</ago>"
        />
      )}
      .
    </IconLine>
  );
}
