import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { getIcon, Icon, LabelInput } from "~/lib/ui";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { CACHE_INVOICES } from "..";
import { ProjectStatusSelection } from "~/lib/ui/selection-combobox/project-status-selection";
import { useFormState } from "~/lib/form-state";
import type { EditInvoiceOperationRequest, Invoice } from "@apacta/sdk";
import { z } from "zod";
import { eanRequired } from "~/lib/form-state/zod";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { useMe } from "~/lib/auth/use-me";

interface EanInvoiceDialogProps {
  onClose: () => void;
  invoice: Invoice;
  pendingSave?: () => Promise<unknown>;
}

export function EanInvoiceDialog({ onClose, invoice, pendingSave }: EanInvoiceDialogProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const api = useAPI();
  const me = useMe();
  const isERP = me.integrationsEnabled.erp;

  const mut = useMutation({
    mutationFn: (args: EditInvoiceOperationRequest) => api.editInvoice(args),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_INVOICES, invoice.id] });
      onClose();
    },
  });

  const formState = useFormState({
    schema: {
      projectStatusId: z.string().uuid(),
      ean: eanRequired(),
    },
    initialValues: {
      projectStatusId: invoice.project.projectStatusId,
      ean: invoice.contact?.ean || "",
    },
    mutationError: mut.error,
  });

  const handleEditInvoice = async () => {
    await mut.mutateAsync({
      invoiceId: invoice.id,
      editInvoiceRequest: {
        contactId: invoice.contactId || "",
        contactPersonId: invoice.contactPersonId || "",
        isDraft: false,
        toErp: isERP,
        projectStatusId: formState.getValue("projectStatusId"),
        sendToEan: !!formState.getValue("ean"),
        ean: formState.getValue("ean"),
      },
    });
  };

  return (
    <>
      <DialogHeader title={t("invoices:book_ean_invoice")} Icon={getIcon("invoice")} />
      <div className="flex flex-col gap-4">
        {pendingSave && (
          <div className="mb-4 flex items-center gap-4">
            <Icon name="infoCircle" className="h-8 w-8 text-shade-600" />
            <div className="whitespace-pre-line">
              {t("common:unsaved_changes_will_be_saved_before_proceeding")}
            </div>
          </div>
        )}
        <div>
          {isERP && (
            <div className="mb-4 flex items-center gap-4">
              <div>
                <Icon name="warningCircle" className="h-8 w-8 text-red-600" />
              </div>
              <div className="whitespace-pre-line">{t("invoices:warning_before_send_to_erp")}</div>
            </div>
          )}
        </div>
        <ProjectStatusSelection
          required
          label={t("invoices:change_project_status_after_booking")}
          onSelect={(v) => v && formState.onChange("projectStatusId", v)}
          defaultValue={formState.getValue("projectStatusId")}
        />
        <LabelInput
          required
          placeholder={t("common:ean_number")}
          onChange={(v) => formState.onChange("ean", v.currentTarget.value)}
          defaultValue={formState.getValue("ean")}
          label={t("common:ean_invoicing")}
          type="text"
          error={formState.getField("ean").errors[0]}
        />
        <DialogFooter
          onClose={onClose}
          primary={{
            label: t("invoices:invoice"),
            onClick: () => handleEditInvoice(),
            disabled: !formState.isValid || mut.isPending,
          }}
        />
      </div>
    </>
  );
}
