import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router";
import { BACKEND_URL } from "~/lib/auth/config";
import { useSession } from "~/lib/auth/session";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { LoginLayout } from "./layout";
import { ServiceMessage } from "./message";
import { useMutation } from "@tanstack/react-query";
import { Button, LabelInput } from "~/lib/ui";
import { Checkbox } from "~/lib/ui/form-elements";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { useToasts } from "~/lib/toast/use-toasts";
import { UnsupportedBrowserWarning } from "~/lib/utils/browser-support/unsupported-browser-warning";
import { emailRequired } from "~/lib/form-state/zod";
import { LoginWithEmailOperationRequest } from "@apacta/sdk";

// TODO: Remember where the login request came from and redirect to the correct location afterwards.

function LoginPage() {
  const { isLoggedIn, loginWithAPIKey } = useSession();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { api } = useSession();
  const toast = useToasts();

  const loginM = useMutation({
    mutationFn: (args: LoginWithEmailOperationRequest) => api.loginWithEmail(args),
    onError: () => {
      toast.showTemplate("OPERATION_FAILED", {
        title: t("ui:login.error_title", "Login failed"),
        description: t(
          "ui:login.error_description",
          "Please check your email and password and try again."
        ),
      });
    },
    onSuccess: () => null, // User will be redirected, no need for a toast here
    retry: 0,
  });

  const { isValid, onChange, getValue, isPending } = useFormState({
    schema: {
      email: emailRequired(),
      password: z.string().min(1),
      rememberMe: z.boolean(),
    },
    initialValues: {
      email: "",
      password: "",
      rememberMe: true,
    },
    mutationError: loginM.error,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const cancel = setTimeout(() => {
      navigate("/", { replace: true });
    }, 800);
    return () => clearTimeout(cancel);
  }, [isLoggedIn]);

  const handleSubmit = async () => {
    if (!isValid) return;
    const res = await loginM.mutateAsync({
      loginWithEmailRequest: {
        email: getValue("email"),
        password: getValue("password"),
        rememberMe: getValue("rememberMe"),
      },
    });
    if (!res.data?.apiKey) return;
    loginWithAPIKey(res.data.apiKey);
  };

  if (isLoggedIn) {
    return <PageSpinner />;
  }

  return (
    <LoginLayout>
      <UnsupportedBrowserWarning />
      <div>
        <ServiceMessage />

        <div>
          <p className="text-sm font-medium text-gray-700">
            {t("ui:login.sign_in_with", "Sign in with")}
          </p>

          <div className="mt-1 flex flex-row flex-wrap justify-between gap-4">
            <a
              href={`${BACKEND_URL}/login/oauthFacebook`}
              className="inline-flex flex-1 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
            >
              <span className="sr-only">{t("ui:login.with_facebook", "Login with Facebook")}</span>
              <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                  clipRule="evenodd"
                />
              </svg>
            </a>

            <a
              href={`${BACKEND_URL}/login/oauthGoogle`}
              className="inline-flex flex-1 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
            >
              <span className="sr-only">{t("ui:login.with_google", "Sign in Google")}</span>
              <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 16 16">
                <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
              </svg>
            </a>
          </div>
        </div>

        <div className="relative mt-6">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="bg-white px-2 text-gray-500">
              {t("ui:login.or_continue", "Or continue with")}
            </span>
          </div>
        </div>
      </div>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await handleSubmit();
        }}
      >
        <div className="mt-6 flex flex-col gap-2">
          <div>
            <LabelInput
              required
              id="email"
              name="email"
              label={t("common:email_address")}
              onChange={(e) => onChange("email", e.currentTarget.value, 0)}
            />
          </div>

          <div>
            <LabelInput
              required
              id="password"
              name="password"
              type="password"
              label={t("common:password")}
              onChange={(e) => onChange("password", e.currentTarget.value, 0)}
            />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Checkbox
                id="remember-me"
                name="remember-me"
                checked={getValue("rememberMe")}
                onChange={(e) => onChange("rememberMe", e.currentTarget.checked)}
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                {t("ui:login.remember_me", "Remember me")}
              </label>
            </div>

            <div className="text-sm">
              <Link
                to="reset"
                className="font-medium text-primary hover:text-indigo-500"
                state={{ email: getValue("email") }}
              >
                {t("ui:login.forgot_password", "Forgot your password?")}
              </Link>
            </div>
          </div>

          <div>
            <Button
              type="submit"
              loading={isValid && isPending}
              disabled={!isValid}
              onClick={handleSubmit}
              variant="primary"
              className="mt-4 w-full justify-center"
            >
              {t("ui:login.primary_button", "Login")}
            </Button>
          </div>
        </div>
      </form>
    </LoginLayout>
  );
}

export default LoginPage;
