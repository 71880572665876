import { FormEvent, useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import { formStateReducer, initialFormState } from "./reducer/reducer";
import type { MapFromZod, SchemaMap } from "./types";
import { z } from "zod";
import { parseError } from "../errors/parse-error";

export function useFormState<S extends SchemaMap>({
  schema,
  initialValues,
  mutationError,
}: {
  mutationError: unknown;
  schema: S;
  initialValues: MapFromZod<S>;
}) {
  const [state, dispatch] = useReducer(
    formStateReducer<S>,
    { schema, initialValues },
    initialFormState<S>
  );

  const { t } = useTranslation();
  const timeoutRef = useRef<Record<string, ReturnType<typeof setTimeout> | undefined>>({});
  const timeouts = timeoutRef.current;

  // Shallow compare of obj and valueRef.current
  // If they are the same, return the same reference
  // This is to prevent re-renders when the values are the same
  const valueRef = useRef<{ [FK in keyof S]: z.TypeOf<S[FK]> }>(undefined);
  const values = useMemo(() => {
    // Iterate over each state.meta field and return its value with the generic type
    const obj = {} as { [FK in keyof S]: z.TypeOf<S[FK]> };
    for (const key in state.meta) {
      obj[key as keyof S] = state.meta[key as keyof S].value;
    }

    if (
      valueRef.current !== undefined &&
      JSON.stringify(obj) === JSON.stringify(valueRef.current)
    ) {
      return valueRef.current;
    }
    valueRef.current = obj;

    return obj;
  }, [state.meta]);
  const getValues = () => values;

  const getValue = useCallback(
    <K extends keyof S>(field: K) => {
      return values[field];
    },
    [state]
  );

  const getField = <K extends keyof S>(field: K) => {
    return state.meta[field];
  };

  const onChange = <K extends keyof S>(field: K, value: z.TypeOf<S[keyof S]>, delay = 500) => {
    if (timeouts[field as string]) {
      clearTimeout(timeouts[field as string]);
      timeouts[field as string] = undefined;
    }
    dispatch({ type: "onChange", field: field as string, value });
    timeouts[field as string] = setTimeout(() => validate(field), delay);
  };

  const onBlur = useCallback(<K extends keyof S>(field: K) => {
    const f = state.meta[field];
    if (!f.touched) return; // No need to validate untouched fields
    if (field) validate(field);
  }, []); // Empty array to prevent re-renders

  const validate = <K extends keyof S>(field: K) => {
    dispatch({ type: "validate", field: field });
  };

  /**
   * @deprecated Use registerStringInput or registerNumberInput instead
   */
  const register = useCallback(
    <K extends keyof S>(field: K) => {
      const f = state.meta[field];
      return {
        name: field,
        controlled: true,
        value: f.value,
        errorMessage: f.errors?.[0]
          ? t(`${f.errors?.[0]}`, {
              defaultValue: f.errors?.[0],
              ns: "errors",
            })
          : undefined,
        required: f.required,
        onChange: (value: z.TypeOf<S[K]>) => onChange(field, value),
        onBlur: () => onBlur(field),
      };
    },
    [onChange]
  );

  const registerStringInput = useCallback(
    <K extends keyof S>(field: K) => {
      const f = state.meta[field];
      return {
        name: field,
        value: f.value,
        error: f.errors?.[0]
          ? t(`${f.errors?.[0]}`, {
              defaultValue: f.errors?.[0],
              ns: "errors",
            })
          : undefined,
        required: f.required,
        onChange: (e: FormEvent<HTMLInputElement>) => {
          return onChange(field, e.currentTarget.value);
        },
        onBlur: () => onBlur(field),
      };
    },
    [onChange]
  );

  // Same as registerStringInput, except it converts the value to number
  const registerNumberInput = useCallback(
    <K extends keyof S>(field: K) => {
      const f = state.meta[field];
      return {
        name: field,
        value: f.value,
        error: f.errors?.[0]
          ? t(`${f.errors?.[0]}`, {
              defaultValue: f.errors?.[0],
              ns: "errors",
            })
          : undefined,
        required: f.required,
        onChange: (e: FormEvent<HTMLInputElement>) => {
          return onChange(field, Number(e.currentTarget.value));
        },
        onBlur: () => onBlur(field),
      };
    },
    [onChange]
  );

  const parseBackendErrors = async (error: unknown) => {
    const parsedError = await parseError(error);
    if (parsedError.fieldErrors) {
      dispatch({ type: "parseBackendErrors", errors: parsedError.fieldErrors });
    }
  };

  const setValues = (
    obj: {
      [FK in keyof S]?: z.TypeOf<S[FK]>;
    },
    initial = false
  ) => {
    dispatch({ type: "setValues", values: obj, initial, reset: false });
  };

  /**
   * This is a special case, where we want to reset the initial values
   * Usecase is for when you mutate the data in the backend, and want to reset the modified state
   */
  const resetInitialValues = (obj: {
    [FK in keyof S]?: z.TypeOf<S[FK]>;
  }) => {
    dispatch({ type: "setValues", values: obj, initial: true, reset: false });
  };

  const reset = (obj: {
    [FK in keyof S]?: z.TypeOf<S[FK]>;
  }) => {
    dispatch({ type: "setValues", values: obj, initial: true, reset: true });
  };

  useEffect(() => {
    if (!mutationError) return;
    parseBackendErrors(mutationError).then(() => {});
  }, [mutationError]);

  return {
    getValue,
    getValues,
    onChange,
    /**
     * @deprecated Use registerStringInput instead
     */
    register,
    registerStringInput,
    registerNumberInput,
    getField,
    parseBackendErrors,
    onBlur,
    setValues,
    resetInitialValues,
    reset,
    isPending: state.isPending,
    isValid: state.isValid,
    isModified: state.isModified,
    modifiedFields: state.modifiedFields,
  };
}
