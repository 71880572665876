import { DropTargetMonitor, useDrop } from "react-dnd";
import { DragDropCardType, DropZoneOrientation } from "./drag-drop.types";
import { Card } from "~/lib/planning";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export function DropZone({
  isDragging,
  onDrop,
  orientation = DropZoneOrientation.HORIZONTAL,
  showMarker = false,
}: {
  isDragging: boolean;
  onDrop: (item: { card: Card }) => void;
  orientation?: DropZoneOrientation;
  showMarker?: boolean;
}) {
  const { t } = useTranslation();

  const [{ isOver }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: DragDropCardType,
    // Props to collect
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
    drop: (item: { card: Card }) => onDrop(item),
  }));

  if (orientation === "vertical") {
    return (
      <div
        ref={(ref) => {
          drop(ref);
        }}
        className={twMerge(
          "h-full",
          isDragging ? "min-h-8" : "",
          isDragging && showMarker
            ? "flex grow flex-col items-center justify-center border border-dashed border-shade-400 text-shade-400"
            : ""
        )}
      >
        {isDragging && showMarker ? t("planning:drop_marker", { defaultValue: "Drop here" }) : ""}
      </div>
    );
  }

  return (
    <div
      ref={(ref) => {
        drop(ref);
      }}
    ></div>
  );
}
