import { twMerge } from "tailwind-merge";
import { Popover } from "~/lib/ui/popover/popover";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { formatCurrency } from "~/lib/utils/number";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import { OrderLine, OrderLineDiscountType } from "~/lib/ui/order-lines/lib/types";
import { NumberFormatInput } from "~/lib/ui/form-elements/number-format-input";

export function OrderLinesBuilderDiscountPart({
  line,
  editMode,
  parentId,
  width,
}: {
  line: Partial<OrderLine>;
  editMode: boolean;
  parentId?: string | null;
  width: string;
}) {
  const { updateLine, switchDiscountType } = useOrderLinesBuilder();
  const { contextOptions } = useOrderLinesBuilder();
  const { allowedDiscountTypes } = contextOptions;
  if (line.discountType && !allowedDiscountTypes.includes(line.discountType)) {
    console.warn(
      `Discount mode mismatch. Expecting ${allowedDiscountTypes.join(",")}, but got ${line.discountType}`
    );
  }
  const discountDisplayValue = (): string => {
    if (line.discount && line.discount > 0 && line.discountType === "fixed") {
      return formatCurrency(line.discount ?? 0, { currency: "DKK" });
    }

    if (line.discount && line.discount > 0 && line.discountType === "percent") {
      return `${line.discount ?? 0}%`;
    }

    return "";
  };

  return (
    <div style={{ width }} className={twMerge("flex-shrink-0 px-3 py-5 text-right text-zinc-900")}>
      {editMode && !parentId && (
        <div className="flex">
          <NumberFormatInput
            className="rounded-r-none text-right text-sm"
            maximumDecimals={line.discountType === "fixed" ? 2 : 0}
            defaultValue={line.discount ?? 0}
            onChange={(v) => updateLine(["discount"], { discount: v }, line.identifier)}
          />
          <DiscountTypePicker line={line} onSelectDiscountType={switchDiscountType} />
        </div>
      )}
      {!editMode && <span>{!parentId && discountDisplayValue()}</span>}
    </div>
  );
}

function DiscountTypePicker({
  line,
  onSelectDiscountType,
}: {
  line: Partial<OrderLine>;
  onSelectDiscountType: (type: "fixed" | "percent", line: Partial<OrderLine>) => void;
}) {
  const { contextOptions } = useOrderLinesBuilder();
  const { allowedDiscountTypes } = contextOptions;

  const supportedDiscountTypes: Array<{
    id: OrderLineDiscountType;
    label: string;
  }> = [
    {
      id: "fixed",
      label: "kr.",
    },
    {
      id: "percent",
      label: "%",
    },
  ];

  const filteredDiscountTypes = supportedDiscountTypes.filter((type) =>
    allowedDiscountTypes.includes(type.id)
  );

  const isDisabled = (() => {
    if (line.discountType === undefined) {
      return false;
    }
    if (!allowedDiscountTypes.includes(line.discountType)) {
      return true;
    }
    if (filteredDiscountTypes.length === 1) {
      return true;
    }
    return false;
  })();

  return (
    <Popover
      // Disable the column
      disabled={isDisabled}
      config={{ align: "start" }}
      triggerRender={() => (
        <div className="flex h-10 items-center justify-between gap-1 rounded-r-md border border-gray-300 bg-white px-3 py-1 text-gray-700 shadow-sm transition-colors duration-200 hover:border-hover hover:text-hover">
          <span>{line.discountType === "fixed" ? "kr." : "%"}</span>
          {!isDisabled && <ChevronDownIcon className="h-4 w-4" />}
        </div>
      )}
    >
      {(close) => (
        <div className="min-w-[4em] rounded-lg border bg-white p-2 text-left text-base shadow-md">
          <ul>
            {filteredDiscountTypes.map((type) => (
              <li
                key={type.id}
                className="cursor-pointer rounded-lg p-2 hover:bg-tertiary hover:text-white"
                onClick={() => {
                  onSelectDiscountType(type.id, line);
                  close();
                }}
              >
                {type.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Popover>
  );
}
