import { Button, Dialog, getIcon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { LabelColor } from "~/lib/utils/colors";
import { UICategoryLabel } from "~/lib/ui/category-label/u-i-category-label";
import CreateLabelModal from "~/pages/settings/index/_cmp/create-label-modal";
import { useAPI } from "~/lib/api";
import { LabelEntity } from "@apacta/sdk";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { getExpandedRowModel } from "@tanstack/react-table";
import { LabelEditFragment } from "./label-edit-fragment";
import { useState } from "react";
import BlockNavigation from "~/lib/navigation/block-navigation";

export const CACHE_LABELS = "labels";

export default function LabelsPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const [numberOfPendingRows, setNumberOfPendingRows] = useState(0);

  const columns = useDataColumns<LabelEntity>((columnHelper) => [
    columnHelper.accessor("text", {
      header: t("common:label", { count: 1 }),
      meta: { className: "w-4/12 group" },
      cell: ({ row }) => (
        <UICategoryLabel
          id={row.id}
          readonly
          text={row.original.text ?? ""}
          bgColor={row.original.bgColor as LabelColor}
        />
      ),
    }),
    columnHelper.display({
      id: "color",
      header: t("common:color", { count: 1 }),
      meta: { className: "w-4/12 group" },
      cell: ({ row }) => {
        return (
          <div className="flex h-full gap-4" onClick={() => row.toggleExpanded()}>
            <div
              className="h-6 w-6 rounded-full"
              style={{ backgroundColor: row.original.bgColor }}
            ></div>
          </div>
        );
      },
    }),
    columnHelper.accessor("entity", {
      header: t("common:availability"),
      meta: { className: "w-4/12 group" },
      cell: ({ row }) => (
        <div className="flex gap-4 font-semibold">
          <span className="select-none">
            {t(`settings:labels.availability.${row.original.entity}`)}
          </span>
        </div>
      ),
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              Icon: getIcon("delete"),
              label: t("common:delete"),
              onClick: () => deleteLabelMutation.mutateAsync(row.original.id),
              confirm: {
                action: "delete",
                entity: "label",
              },
            },
          ]}
        />
      ),
    }),
  ]);

  const tableState = useDataTableState({
    sorting: [{ id: "created", desc: true }], // defaults
  });

  const dataQ = useQuery({
    queryKey: [
      CACHE_LABELS,
      tableState.state.pagination,
      tableState.state.search,
      tableState.sortBy,
      tableState.sortingDirection,
    ],
    queryFn: () =>
      api.getLabels({
        page: tableState.pageNumber,
        q: tableState.state.search,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
      }),
  });

  const table = useDataTable(
    {
      columns,
      tableState,
      isLoading: dataQ.isLoading,
      data: dataQ.data?.data ?? [],
      singleRowExpansion: true,
      getRowId: (row) => row.id,
      backendPagination: dataQ.data?.pagination,
    },
    {
      enableExpanding: true,
      getExpandedRowModel: getExpandedRowModel(),
      getRowCanExpand: () => true,
      enableGlobalFilter: true,
    }
  );

  const deleteLabelMutation = useMutation({
    mutationFn: async (id: string) => {
      await api.deleteLabel({ labelId: id });
      await queryClient.invalidateQueries({ queryKey: [CACHE_LABELS] });
    },
  });

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
        <h2 className="m-0">{t("settings:tabs.labels")}</h2>
        <div>
          <Dialog
            render={({ onClose }) => <CreateLabelModal onClose={onClose} />}
            trigger={
              <Button variant="tertiary" Icon={getIcon("add")}>
                <span>
                  {t("common:create", { entity: t("common:label", { count: 1 }).toLowerCase() })}
                </span>
              </Button>
            }
          />
        </div>
      </div>
      <div>
        <DataTable
          error={dataQ.error}
          table={table}
          renderExpandedRow={({ row, onHasPendingChanges }) => (
            <LabelEditFragment
              label={row.original}
              onClose={() => row.toggleExpanded(false)}
              onIsModifiedChange={(isModified) => {
                const newCount = onHasPendingChanges(isModified);
                setNumberOfPendingRows(newCount);
              }}
            />
          )}
        />
        <BlockNavigation when={numberOfPendingRows > 0} />
      </div>
    </div>
  );
}
