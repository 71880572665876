import { ColumnDef, ColumnHelper, createColumnHelper, RowData } from "@tanstack/react-table";
import { DependencyList, useMemo } from "react";
import { useTranslation } from "react-i18next";

/** Generic helper function and makes it available through the callback
 */
type HelperFn<TData extends RowData> = (
  helper: ColumnHelper<TData>
  //The `any` could not be avoided.
  // Below is a somewhat OK solution, but requires rework we don't have time for
  // - https://github.com/TanStack/table/issues/4382#issuecomment-2427090881
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Array<ColumnDef<TData, any>>;

/**
 * It's a useMemo that re-renders on language change. Helper now built in.
 * @param factory
 * @returns
 */
export const useDataColumns = <TData extends RowData>(
  factory: HelperFn<TData>,
  deps: DependencyList = []
) => {
  const { i18n } = useTranslation();
  const helper = useMemo(() => createColumnHelper<TData>(), []);
  const columns = useMemo(() => {
    const allColumns = factory(helper);
    return allColumns;
  }, [deps, i18n.language]);
  return columns;
};
