import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import { z } from "zod";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import { useToasts } from "~/lib/toast/use-toasts";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import TextArea from "~/lib/ui/form-elements/textarea";
import { FileItemType } from "..";
import { LabelInput } from "~/lib/ui";
import { emailRequired } from "~/lib/form-state/zod";
import { IProjectSendFilesOperationRequest } from "@apacta/sdk";

export function SendFilesDialog({
  onClose,
  onSuccess,
  defaultEmail = "",
  defaultSubject = "",
  defaultMessage = "",
  files,
  projectId,
}: {
  onClose: () => void;
  onSuccess?: () => void;
  defaultEmail?: string;
  defaultSubject?: string;
  defaultMessage?: string;
  files: Array<FileItemType>;
  projectId: string;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const { showTemplate } = useToasts();

  const m = useMutation({
    mutationFn: (args: IProjectSendFilesOperationRequest) => api.iProjectSendFiles(args),
    onSuccess: () => {
      showTemplate("CHANGES_SAVED");
      onSuccess?.();
      onClose();
    },
  });

  const { getValue, onChange, registerStringInput } = useFormState({
    schema: {
      email: emailRequired(),
      subject: z.string().min(1),
      message: z.string().min(1),
    },
    initialValues: {
      email: defaultEmail,
      subject: defaultSubject,
      message: defaultMessage,
    },
    mutationError: m.error,
  });

  async function handleSend() {
    await m.mutateAsync({
      projectId: projectId,
      iProjectSendFilesRequest: {
        email: getValue("email"),
        message: getValue("message"),
        subject: getValue("subject"),
        projectFiles: files.map((f) => f.id),
      },
    });
  }

  return (
    <>
      <DialogHeader Icon={EnvelopeIcon} title={t("projects:send_files")} />
      <div className="mb-6">
        <Trans
          i18nKey="projects:sending_x_files"
          values={{ x: files.length }}
          count={files.length}
        />
      </div>
      <div className="flex flex-col gap-4">
        <LabelInput
          {...registerStringInput("email")}
          defaultValue={getValue("email")}
          label={t("common:email")}
          onChange={(e) => onChange("email", e.currentTarget.value)}
          type="email"
          required
        />
        <LabelInput
          {...registerStringInput("subject")}
          defaultValue={getValue("subject")}
          label={t("common:subject")}
          onChange={(e) => onChange("subject", e.currentTarget.value)}
        />
        <TextArea
          {...registerStringInput("message")}
          initialValue={getValue("message")}
          onChange={(s) => onChange("message", s)}
          label={t("common:message")}
          required
        />
      </div>
      <DialogFooter primary={{ label: t("common:send"), onClick: handleSend }} onClose={onClose} />
    </>
  );
}
