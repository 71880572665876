import { useTranslation } from "react-i18next";
import { GenerateReportModal } from "./_cmp/generate-report-modal";
import { useState } from "react";
import { PageLayout } from "~/lib/ui/page-layout";
import { ReportTypes } from "~/pages/reports/_cmp/report.content";
import { ReportType } from "~/pages/reports/_cmp/report.types";
import { Button, Dialog, Icon } from "~/lib/ui";

export default function ReportsPage() {
  const { t } = useTranslation();

  const items = ReportTypes;
  const [selectedReport, setSelectedReport] = useState<ReportType>();

  async function handleGenerateReport(r: ReportType) {
    setSelectedReport(r);
  }

  return (
    <>
      <PageLayout title={t("reports:title")}>
        <div className="grid grid-flow-row grid-cols-3 gap-7">
          {items.map((report: ReportType) => (
            <div
              key={report.type}
              className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1">
                  <div className="flex space-x-3 text-center">
                    {report.type === "ongoing_work" && (
                      <Icon name="chart" className="h-6 w-6 text-black" aria-hidden="true" />
                    )}
                    {report.type === "projects_margins" && (
                      <Icon name="project" className="h-6 w-6 text-black" aria-hidden="true" />
                    )}
                    {report.type === "customers_statistics" && (
                      <Icon name="customer" className="h-6 w-6 text-black" aria-hidden="true" />
                    )}
                    <h3 className="text-xl-1 text-center font-bold text-gray-900">
                      {t("reports:" + report.type)}
                    </h3>
                  </div>
                  <div>
                    <p className="display-block sm:h-26 text-m mt-2 line-clamp-3 h-18 overflow-hidden text-ellipsis text-gray-600">
                      {t("reports:" + report.description)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <Button
                  variant="secondary"
                  className="border-0 print:hidden"
                  onClick={() => handleGenerateReport(report)}
                >
                  <div className="flex items-center gap-2">
                    <Icon name="invoice" className="h-5 w-5" />
                    <span>{t("common:send_email")}</span>
                  </div>
                </Button>
              </div>
            </div>
          ))}
        </div>
      </PageLayout>
      <Dialog
        open={!!selectedReport}
        onOpenChange={() => setSelectedReport(undefined)}
        render={({ onClose }) =>
          selectedReport ? <GenerateReportModal report={selectedReport} onClose={onClose} /> : null
        }
      />
    </>
  );
}
