import { useTranslation } from "react-i18next";
import { Button } from "./buttons/button";
import { getIcon } from "./icons/icon";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

/**
 * Shows a "Scroll to Top" button when the user has scrolled down.
 */
export function ScrollToTop({ container }: { container: React.RefObject<HTMLDivElement | null> }) {
  const { t } = useTranslation();

  const [showScrollButton, setShowScrollButton] = useState(false);

  function scrollHandler() {
    if (container.current) {
      setShowScrollButton(container.current.scrollHeight > 100);
    }
  }

  function scrollToTop() {
    setTimeout(() => {
      // Workaround for Chrome: https://stackoverflow.com/questions/29203545/cant-force-chrome-to-scroll-to-top-of-page-with-javascript
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }

  // Subscribe to scroll events
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <div className={twMerge("mt-4 items-center justify-end", showScrollButton ? "flex" : "hidden")}>
      <Button
        title={t("common:scroll_to_top")}
        variant="secondary"
        Icon={getIcon("chevronUp")}
        onClick={scrollToTop}
      />
    </div>
  );
}
