import { Spinner } from "~/lib/ui/spinner";
import { formatCurrency } from "~/lib/utils/number";
import { Vendor, VendorProduct } from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MediaPreviewLink } from "~/lib/ui/media";
import { Icon } from "~/lib/ui";

export default function VendorProductSection({
  searchQuery,
  selectedVendor,
  onSelect,
  showBuyingPrice = false,
  showSellingPrice = false,
  showGrossPrice = false,
}: {
  searchQuery: string;
  selectedVendor?: Vendor;
  onSelect: (item: VendorProduct) => void;
  showBuyingPrice?: boolean;
  showSellingPrice?: boolean;
  showGrossPrice?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();

  const vendorProductsQuery = useQuery({
    queryKey: ["vendorProductsList", searchQuery, selectedVendor],
    queryFn: () =>
      api.vendorProductsList({
        q: searchQuery,
        vendorId: selectedVendor?.id,
      }),
    placeholderData: keepPreviousData,
    refetchOnMount: false,
    staleTime: 1000 * 60,
  });

  if (vendorProductsQuery.isFetching) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  if (!vendorProductsQuery.isFetching && !vendorProductsQuery.data?.data.length) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-2">
        <Icon name="search" className="h-16 w-16" />
        <span>
          {t("common:no_entity_found", {
            entity: t("products:product_type.vendor_product", {
              count: 2,
            }).toLowerCase(),
          })}
        </span>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-2 divide-y pr-1">
      {vendorProductsQuery.data?.data.map((vendorProduct) => (
        <div key={`${vendorProduct.id}`} onClick={() => onSelect(vendorProduct)} className="pt-2">
          <div className="flex cursor-pointer items-center justify-between rounded-lg hover:bg-shade-100">
            <div className="flex w-full gap-2 p-1">
              <div className="flex shrink-0">
                {vendorProduct.imageUrls?.thumbnail && vendorProduct.imageUrls?.original ? (
                  <MediaPreviewLink
                    href={vendorProduct.imageUrls.original}
                    previewURL={vendorProduct.imageUrls.thumbnail}
                    mimeType={vendorProduct.imageMimeType ?? "image/*"}
                    allowDownload={false}
                    stopPropagation={true}
                    height={20}
                  />
                ) : (
                  <img src="/image_not_found.jpg" className="h-20 w-20 rounded-lg" />
                )}
              </div>
              <div className="flex grow flex-col gap-2">
                <div>
                  {vendorProduct.vendor?.imageUrl ? (
                    <img src={vendorProduct.vendor?.imageUrl} className="h-6 w-auto" />
                  ) : (
                    <div>
                      <span className="font-semibold">{t("common:vendor")}: </span>
                      <span>{vendorProduct.vendor?.name}</span>
                    </div>
                  )}
                </div>
                <div className="font-semibold">{vendorProduct.name}</div>
              </div>
              <div className="flex shrink-0 flex-col gap-1 text-right text-sm">
                {showBuyingPrice && (
                  <div>
                    {t("common:cost_price")}:{" "}
                    {vendorProduct.buyingPrice ? (
                      formatCurrency(vendorProduct.buyingPrice ?? 0)
                    ) : (
                      <span className="text-shade-400">{t("common:not_available")}</span>
                    )}
                  </div>
                )}

                {showSellingPrice && (
                  <div>
                    {t("common:sales_price")}:{" "}
                    {vendorProduct.sellingPrice ? (
                      formatCurrency(vendorProduct.sellingPrice ?? 0)
                    ) : (
                      <span className="text-shade-400">{t("common:not_available")}</span>
                    )}
                  </div>
                )}

                {showGrossPrice && (
                  <div>
                    {t("common:gross_price")}:{" "}
                    {vendorProduct.price ? (
                      formatCurrency(vendorProduct.price ?? 0)
                    ) : (
                      <span className="text-shade-400">{t("common:not_available")}</span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
