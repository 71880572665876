import {
  ColumnFiltersState,
  ExpandedState,
  PaginationState,
  SortingState,
  Updater,
  functionalUpdate,
} from "@tanstack/react-table";
import { useCallback, useMemo, useReducer } from "react";
import { SetParamOptions, useTypedSearchParams } from "~/lib/utils/use-typed-search-params";

// Stored in state. Doesn't survive a refresh
export type DataTableState = {
  columnVisibility: Record<string, boolean>;
  expanded: ExpandedState; // should be state
  pagination: PaginationState;
  columnFilters: ColumnFiltersState;
  sorting: SortingState;
  search: string;
};

const DEFAULT_PAGE_SIZE = 100; // Don't override this for no reason (100 default is good)

/**
 * State handler for Table State. Nothing fancy, and you don't need to use it.
 * @returns
 */
export function useDataTableState(hookDefaults?: Partial<DataTableState>) {
  const [defaultsWithParams, updateQueryParams] = useTableParams(hookDefaults);

  const stateReducer = <K extends keyof DataTableState>(
    s: DataTableState,
    action: {
      field: K;
      value: DataTableState[K];
    }
  ) => {
    if (action.value === s[action.field]) return s; // no-op
    updateQueryParams(action.field, action.value);
    return { ...s, [action.field]: action.value };
  };

  const [state, dispatch] = useReducer(stateReducer, defaultsWithParams, createInitialState);

  const handleTableState = useCallback(
    <K extends keyof DataTableState>(field: K) => {
      function updater(upFn: Updater<DataTableState[K]>) {
        const newValue = functionalUpdate(upFn, state[field]);
        return dispatch({ field, value: newValue });
      }
      return updater;
    },
    [state]
  );

  return {
    state,
    handleTableState,
    pageNumber: state.pagination.pageIndex + 1,
    sortingDirection:
      state.sorting.length === 0 ? undefined : state.sorting[0].desc ? "desc" : "asc",
    sortBy: state.sorting.length === 0 ? undefined : state.sorting[0].id,
    dispatch: dispatch,
    queryKeys: [state.pagination, state.search, state.sorting],
  } as const;
}

function createInitialState(defaults?: Partial<DataTableState>): DataTableState {
  return {
    columnVisibility: defaults?.columnVisibility ?? {},
    columnFilters: defaults?.columnFilters ?? [],
    expanded: defaults?.expanded ?? {},
    pagination: defaults?.pagination ?? { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE },
    sorting: defaults?.sorting ?? [],
    search: defaults?.search ?? "",
  };
}

/** Query Params can affect state defaults
 *
 * This is a synchronization mechnism between the URL and the DataTable state. Don't use elsewhere!
 */
type DataTableParams = { q: string; p: number; by: string; o: "desc" | "asc" };
function useTableParams(overrides?: Partial<DataTableState>) {
  const [params, setParams] = useTypedSearchParams<DataTableParams>();

  const handleParamChange = useCallback(
    <K extends keyof DataTableState>(
      key: K,
      value: DataTableState[K] // ensures we get the right type
    ) => {
      const paramOptions: SetParamOptions = {
        directHistoryUpdate: true,
      };
      switch (
        key // Note the scope brackets
      ) {
        case "search": {
          if (value === params.q) break; // no-op
          setParams("q", value as string, paramOptions);
          break;
        }
        case "pagination": {
          const v = value as PaginationState;
          const pageFromNewValue = v.pageIndex + 1;
          if (pageFromNewValue === 1) break; // default, no-op
          if (pageFromNewValue === params.p) break; // no-op
          setParams("p", pageFromNewValue, paramOptions);
          break;
        }
        case "sorting": {
          const sorting = value as SortingState;
          const by = sorting.length === 0 ? "" : sorting[0].id;
          const order = sorting.length === 0 ? "" : sorting[0].desc ? "desc" : "asc";
          setParams("by", by, paramOptions);
          setParams("o", order, paramOptions);
          break;
        }
      }
    },
    [params, setParams]
  );

  const mergedState = useMemo(() => {
    const out = Object.assign(
      {
        search: params.q ?? "",
        pagination: { pageIndex: params.p ? params.p - 1 : 0, pageSize: DEFAULT_PAGE_SIZE },
        by: params.by,
        o: params.o,
      },
      overrides
    );
    return out;
  }, [params.p, params.by, params.o, overrides]);

  return [mergedState, handleParamChange] as const;
}
