import { Button } from "~/lib/ui";
import { Toast } from "~/lib/toast/toast";
import { useState } from "react";

export default function Temp() {
  const [showToast, setShowToast] = useState<boolean>(false);

  return (
    <div>
      <Button onClick={() => setShowToast(!showToast)}>{showToast ? "Hide" : "Show"} toast</Button>
      {showToast && <Toast id="123" title="My local toast" onDestroy={() => setShowToast(false)} />}
    </div>
  );
}
