import { useOutletContext } from "react-router";
import { InvoiceOutlet } from "../[invoiceId]";
import { useTranslation } from "react-i18next";
import DateInput from "~/lib/ui/calendar/date-input";
import { LabelInput } from "~/lib/ui";
import { OrderLinesBuilder } from "~/lib/ui/order-lines/order-lines-builder";
import OrderLinesBuilderProvider from "~/lib/ui/order-lines/order-lines-builder-context";
import { invoiceLineToOrderLine } from "~/lib/ui/order-lines/lib/transformers/invoiceline-to-orderline";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { orderLineToInvoiceLine } from "~/lib/ui/order-lines/lib/transformers/orderline-to-invoiceline";
import { OrderLinesBuilderOnChangeReturn } from "~/lib/ui/order-lines/lib/types";
import { RichTextEditor } from "~/lib/ui/rich-text-editor";
import { useMe } from "~/lib/auth/use-me";
import { Contact, ContactPerson, InvoiceLine } from "@apacta/sdk";
import { InvoiceKPISection } from "./invoice-kpi-section";
import { ListResponse } from "~/lib/entity-ui/types";
import { CACHE_INVOICES } from "~/pages/invoices";
import { useAPI } from "~/lib/api";
import { CustomerSelectionWithPreview } from "~/lib/ui/selection-combobox/customer-selection-with-preview";
import { Boundary } from "~/lib/ui/boundary";
import { InvoiceFileSection } from "~/pages/invoices/_cmp/invoice-file-section";
import { PaymentConditionWithInvoiceDueDate } from "~/lib/ui/selection-combobox/payment-condition-w-invoice-due-date";

export function InvoiceEditPage() {
  const { t } = useTranslation();
  const me = useMe();
  const api = useAPI();

  const { invoice, viewFiles, formState, handleFileStaging, handleFileRemoval } =
    useOutletContext<InvoiceOutlet>();
  const values = formState.getValues();

  const handleOrderlineChange = (d: OrderLinesBuilderOnChangeReturn) => {
    const invoiceLines = d.orderLines.map((v) => orderLineToInvoiceLine(v, invoice.id));
    formState.setValues({ lines: invoiceLines, vatPercent: d.vatPercent }, d.isInitial);
  };

  function handleCustomerSelect(newCustomer?: Contact) {
    formState.setValues({
      customerId: newCustomer?.id,
      contactPersonId: undefined,
    });
  }

  function handleContactPersonSelect(newContactPerson?: ContactPerson) {
    formState.setValues({
      contactPersonId: newContactPerson?.id,
    });
  }

  return (
    <div className="flex flex-col gap-8">
      <div>
        <TabHeading>{t("common:details")}</TabHeading>
        {!invoice.project.isFixedPrice && <InvoiceKPISection />}
        <div className="rounded-md border border-gray-300 bg-white p-8">
          <div className="mb-4 flex w-full flex-row flex-wrap justify-between gap-8">
            <div className="w-full flex-1">
              <CustomerSelectionWithPreview
                selectedCustomer={values.customerId}
                onSelectCustomer={handleCustomerSelect}
                selectedContactPerson={values.contactPersonId}
                onSelectContactPerson={handleContactPersonSelect}
              />
            </div>

            <div className="flex flex-1 flex-col gap-4">
              <DateInput
                label={t("invoices:invoice_date")}
                value={formState.getValue("issueDate")}
                onChange={(v) => formState.onChange("issueDate", v)}
              />
            </div>
          </div>
          <div className="mb-4">
            <LabelInput
              {...formState.registerStringInput("reference")}
              label={t("common:reference")}
            />
          </div>

          <RichTextEditor
            initialData={formState.getValue("message")}
            onChange={(text) => formState.onChange("message", text)}
            characterLimit={me.erpIntegrationCapabilities.invoiceDescriptionLength}
            disableRichText={true}
            editorTheme={{
              paragraph: "text-base",
            }}
          />

          <div className="px-12 py-8">
            <OrderLinesBuilderProvider
              cacheKey={[CACHE_INVOICES, invoice.id, "invoice-lines"]}
              // TODO: WORKAROUND!! Should be refactored when the order lines builder is refactored
              dataFn={() =>
                api.iGetInvoice({ invoiceId: invoice.id as string }).then((r) => {
                  return {
                    success: true,
                    data: r.data.invoiceLines ?? [],
                  } satisfies ListResponse<InvoiceLine>;
                })
              }
              transformerFn={invoiceLineToOrderLine}
              onChange={handleOrderlineChange}
              initialVatPercent={invoice.vatPercent}
              contextOptions={{
                allowedDiscountTypes: ["percent"],
                defaultDiscountType: "percent",
              }}
            >
              <OrderLinesBuilder
                editMode={true}
                hideProductBundleContent={true}
                buttonSizes="small"
                usePlainTextEditor={true}
                options={{
                  allowNegativeNumbers: true,
                  orderLinesActionVisibility: {
                    showAddProduct: true,
                    showAddHours: true,
                    showAddProductBundle: false,
                    showAddText: true,
                    showSubtotal: true,
                    showVAT: true,
                    showTotal: true,
                    sumCostPrice: false,
                  },
                  columns: {
                    product: {
                      visible: true,
                    },
                    costPrice: {
                      visible: true,
                      readOnly: true,
                    },
                    discount: {
                      visible: true,
                    },
                  },
                }}
                companyVatPercent={me.company.vatPercent}
              />
            </OrderLinesBuilderProvider>
          </div>
          <div className="max-w-[20em]">
            <Boundary>
              <PaymentConditionWithInvoiceDueDate
                initialConditionId={invoice.paymentTermId}
                onPaymentConditionChange={(v) =>
                  formState.onChange("paymentTermId", v ?? undefined)
                }
                invoiceId={invoice.id}
                issuedDate={formState.getValue("issueDate")}
              />
            </Boundary>
          </div>
        </div>
      </div>
      <div>
        <InvoiceFileSection
          editMode
          files={viewFiles}
          onSelectFiles={handleFileStaging}
          onRemoveFile={handleFileRemoval}
        />
      </div>
      <div className="mt-6 rounded-md border border-gray-300 bg-white p-6 text-center">
        <div>{`${me.company.name} - ${me.company.streetName} - ${me.company.city?.zipCode}, ${me.company.city?.name}`}</div>
        <div>
          {`Phone: ${me.company.phone}`} -{" "}
          {`Email: ${me.company.contactEmail ?? me.company.invoiceEmail}`}
        </div>
      </div>
    </div>
  );
}
