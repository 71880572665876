import { PageLayout } from "~/lib/ui/page-layout";
import { useTranslation } from "react-i18next";
import { Suspense, useCallback } from "react";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { Outlet, useLocation } from "react-router";
import { getIcon } from "~/lib/ui/icons/icon";
import { TabHeaderItem } from "~/lib/ui/tabs/tab-header";
import { FilterGroupEmployees } from "~/lib/ui/filters/filter-group-employees";
import { useEmployeesParams } from "~/pages/employees/_cmp/use-users-params";
import { Button } from "~/lib/ui";
import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "~/lib/ui/spinner";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { useCSVEndpoint } from "~/lib/api-hacks/use-csv-endpoint";

export const CACHE_EMPLOYEES = "users";
export default function EmployeesPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const employeesParams = useEmployeesParams();
  const csvEndpoint = useCSVEndpoint();
  const { pathname } = useLocation();
  const pageTabs: Array<TabHeaderItem> = [
    {
      id: "overview",
      label: t("users:employees"),
      to: "",
      Icon: getIcon("entityOverview"),
    },
    {
      id: "weekly",
      label: t("common:weekly_overview", "Weekly overview"),
      to: "weekly",
      Icon: getIcon("registration"),
    },
    {
      id: "specified",
      label: t("common:specified"),
      to: "specified/",
      Icon: getIcon("wall"),
    },
    {
      id: "summed",
      label: t("common:summed"),
      to: "summed/",
      Icon: getIcon("masterData"),
    },
  ];

  const payrollIntegration = useQuery({
    queryKey: ["payroll_system"],
    queryFn: () => api.hasIntegrationOfType({ identifier: "payroll_system" }),
  });

  const generateSalaryFile = useCallback(async () => {
    const res = await csvEndpoint.generateSalaryFile({
      dateFrom: employeesParams.startDate,
      dateTo: employeesParams.endDate,
    });

    const blob = await res;
    const url = URL.createObjectURL(blob);
    // Create an anchor element and trigger a download
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "apacta_lon_" + employeesParams.startDate + "-" + employeesParams.endDate + ".csv";
    document.body.appendChild(a);
    a.click();
  }, [employeesParams]);

  return (
    <PageLayout title={t("users:employee_overview")}>
      <PageLayout.Signal
        slot="actions"
        id="generate-salary-file"
        hidden={!payrollIntegration.data?.success}
      >
        <Button
          Icon={getIcon("file")}
          className="print:hidden"
          variant="secondary"
          onClick={generateSalaryFile}
        >
          {t("users:generate_salary_file", { defaultValue: "Generate salary file" })}
        </Button>
      </PageLayout.Signal>
      <PageLayout.Signal slot="description" id="old-description">
        <div className="flex gap-4">
          <DateRangePopover
            allowToDateInFuture
            dateFrom={employeesParams.startDate ? new Date(employeesParams.startDate) : undefined}
            dateTo={employeesParams.endDate ? new Date(employeesParams.endDate) : undefined}
            onChange={(range) => employeesParams.handlePeriodChange(range.from, range.to)}
            resetLabel={t("common:last_x_days", { x: 14 })}
            onReset={() => employeesParams.handleResetPeriod()}
          />
          <FilterGroupEmployees
            value={employeesParams.employeeIds}
            onConfirmSelection={(selection) => {
              employeesParams.setEmployeesParams(
                "employeeIds",
                selection.map((a) => a.id)
              );
            }}
            onClear={() => employeesParams.setEmployeesParams("employeeIds", undefined)}
          />
        </div>
      </PageLayout.Signal>
      {!pathname.match(/\/product_usage/) && <PageTabs tabs={pageTabs} preserveSearchParams />}
      <Suspense
        fallback={
          <div className="flex h-full w-full items-center justify-center">
            <Spinner />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </PageLayout>
  );
}
