import { useTranslation } from "react-i18next";
import { useLocale } from "~/lib/utils/date";

export function DateCell({
  date,
  className,
}: {
  date: Date | null | undefined;
  className?: string;
}) {
  const locale = useLocale();
  const { t } = useTranslation();
  if (!date) return t("common:not_available");
  if (typeof date === "string") throw new Error("DateCell: date is a string, expected a Date");
  return (
    <time title={locale.format(date)} dateTime={date?.toISOString()} className={className}>
      {locale.format(date, { shortDate: true })}
    </time>
  );
}
