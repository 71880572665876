import { useTranslation } from "react-i18next";
import { NumberInput } from "~/lib/ui/form-elements";
import { ProductDataFormState } from "~/pages/products/_cmp/use-product-bundle-formstate";

interface SalesPriceSectionProps {
  formState: ProductDataFormState;
  totalCostPrice: number;
  handleChangeContributionRatio: (ratio: number) => void;
  handleChangeSalesPrice: (price: number) => void;
}

export function SalesPriceSection({
  formState,
  totalCostPrice,
  handleChangeContributionRatio,
  handleChangeSalesPrice,
}: SalesPriceSectionProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-8 md:flex-row">
        <div className="flex-1">
          <h3 className="mb-5">{t("common:sales_price")}</h3>
          <div className="flex flex-col gap-6 bg-white p-4 shadow sm:rounded-lg">
            <div className="flex justify-end gap-8">
              <div className="w-5/12">
                <NumberInput
                  {...formState.registerNumberInput("contribution_ratio")}
                  disabled={totalCostPrice <= 0}
                  label={t("common:contribution_ratio")}
                  onChangeValue={(v) => handleChangeContributionRatio(v)}
                  className="text-right"
                />
              </div>
              <div className="w-2/12 pt-8 text-center">{t("common:or", "eller")}</div>
              <div className="w-5/12">
                <NumberInput
                  {...formState.registerNumberInput("total_sales_price")}
                  disabled={totalCostPrice <= 0}
                  label={t("finance:sales_price")}
                  onChangeValue={(v) => handleChangeSalesPrice(v)}
                  className="text-right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
