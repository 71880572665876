import { Label } from "..";
import { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectionComboboxProps } from "./combobox-types";
import { Popover } from "../popover/popover";
import { SelectionComboboxContext } from "./context";
import { SelectionComboboxContent } from "./selection-combobox-content";
import { DefaultMultiSelectTrigger } from "./defaults/DefaultMultiSelectTrigger";
import { DefaultSingleSelectTrigger } from "./defaults/DefaultSingleSelectTrigger";
import { testIdentifiers } from "./test-ids";

export function SelectionCombobox<D>(props: SelectionComboboxProps<D>) {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const queryChangeTimer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  // Defaults
  const {
    placeholder = t("common:pick_or_search", "Pick or search..."),
    keyFn = props.valueFn,
    required,
    enableSelectAll = false,
    contentClassName,
  } = props;

  const [open, setOpen] = useState(false);
  const selectionDefault = (() => {
    if (props.defaultValue === undefined) return [];
    if (Array.isArray(props.defaultValue)) return props.defaultValue;
    return [props.defaultValue];
  })();

  const [internalSelection, setInternalSelection] = useState(selectionDefault);
  // This is passed to Triggers and updated when the user confirms the selection
  const [triggerSelection, setTriggerSelection] = useState(selectionDefault);

  const values = (() => {
    if (props.value === undefined) return internalSelection;
    if (Array.isArray(props.value)) return props.value;
    return [props.value];
  })() satisfies Array<string>;

  if (values.length === 0 && props.readonly) {
    console.warn("SelectionCombobox is readonly, but no value is provided", props);
  }

  const data = props.filterFn ? props.data.filter((d) => props.filterFn?.(d)) : props.data;

  const selectedItems = data.filter((d) => {
    if (values.includes(props.valueFn(d))) return true;
    return false;
  });

  // Passed to uncontrolled triggers so that don't update until confirmed (multi-select)
  const triggerItems = !props.controlled
    ? data.filter((d) => {
        if (triggerSelection.includes(props.valueFn(d))) return true;
        return false;
      })
    : selectedItems;

  const filteredData = useMemo(
    // Results after search
    () =>
      data.filter((d) => {
        if (search === "") return true;
        // values
        if (props.valueFn(d).toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        // labels
        if (props.labelFn(d).toLowerCase().includes(search.toLowerCase())) {
          return true;
        }

        return false;
      }),
    [search, data]
  );

  // TODO: This is a bit of a mess. Refactor when we have time (tm)
  const handleValueChange = useCallback(
    // `changedValue` because it could be a toggle
    (changedValue: string) => {
      const selectedItem = data.find((d) => props.valueFn(d) === changedValue);
      if (!selectedItem) {
        console.warn("Item selected, but no item found in data", changedValue, data);
        return;
      }
      // Single select
      if (!props.multiple) {
        // Allow deselecting (clear)
        if (internalSelection.includes(changedValue)) {
          setInternalSelection([]);
          setTriggerSelection([]);
          informParentOfSelection([], []);
          setOpen(false);
        } else {
          setInternalSelection([changedValue]); // uncontrolled
          setTriggerSelection([changedValue]);
          informParentOfSelection([changedValue], [selectedItem]);
          setOpen(false);
        }
      }

      // Multi select (controlled  + uncontrolled)
      if (props.multiple) {
        // If already selected, remove from selection
        if (values.includes(changedValue)) {
          // Remove existing
          setInternalSelection(() => {
            const newSelection = values.filter((v) => v !== changedValue);
            if (props.controlled) {
              informParentOfSelection(
                newSelection,
                data.filter((d) => newSelection.includes(props.valueFn(d)))
              );
            }
            return newSelection;
          });
        } else {
          // Add new
          setInternalSelection(() => {
            const newSelection = [...values, changedValue];
            if (props.controlled) {
              informParentOfSelection(
                newSelection,
                data.filter((d) => newSelection.includes(props.valueFn(d)))
              );
            }
            return newSelection;
          });
        }

        // If there is only one value to select, just close it
        if (data.length === 1) {
          setOpen(false);
        }
      }
    },
    [setInternalSelection, values, data]
  );

  // Register a value change handler with parent, if available.
  // This allows the parent to trigger a value change (e.g. creating items)
  useEffect(() => {
    if (props.valueChangeRef === undefined) return;
    props.valueChangeRef.current = handleValueChange;
    // Dependencies need to match that of handleValueChange + valueChangeRef
  }, [props.valueChangeRef, handleValueChange, values, data, setInternalSelection]);

  function handleQueryChange(query: string) {
    clearTimeout(queryChangeTimer.current);
    queryChangeTimer.current = setTimeout(() => {
      setSearch(query);
      props.onQueryChange?.(query);
    }, 500);
  }

  function handleClearSelection() {
    setInternalSelection([]);
    setTriggerSelection([]);
    informParentOfSelection([], []);
    setOpen(false);
  }

  const handleSelectAll = useCallback(() => {
    if (!enableSelectAll) {
      console.warn("SelectionCombobox select-all called. Not enabled!");
      return;
    }
    const allValues = data.map((d) => props.valueFn(d));
    setInternalSelection(allValues);
    setTriggerSelection(allValues);
    if (props.multiple) {
      informParentOfSelection(allValues, data);
    } else {
      console.warn("Select all is only supported for multi-select");
    }
    setOpen(false);
  }, [data]);

  function handleOpenChange(nextOpen: boolean) {
    setOpen(nextOpen);

    if (nextOpen === false && props.multiple) {
      // When closing multi-select, we'd expect it to remember the selection
      // TODO: Revisit that decision
      informParentOfSelection(values, selectedItems);
    }
    if (nextOpen === false) {
      // This automatically resets the query when the user closes the dialog
      props.onQueryChange?.("");
      setSearch("");
    }
    props.onOpenChange?.(nextOpen);
  }

  function handleConfirmMultiSelect() {
    if (props.multiple) {
      const items = data.filter((d) => values.includes(props.valueFn(d)));
      setTriggerSelection(values);
      informParentOfSelection(values, items);
    }
    setOpen(false);
  }

  // Handler for removing items in multi-select
  function handleRemoveItem(value: string) {
    if (!props.multiple) return; // Only supported for multi-select
    const newSelection = values.filter((v) => v !== value);
    setInternalSelection(newSelection);
    informParentOfSelection(
      newSelection,
      data.filter((d) => newSelection.includes(props.valueFn(d)))
    );
    setTriggerSelection(newSelection);
  }

  const isSelected = useCallback(
    (item: D) => {
      return values.includes(props.valueFn(item));
    },
    [values]
  );

  function informParentOfSelection(vals: Array<string>, items: Array<D>) {
    if (props.multiple) {
      props.onSelect?.(vals, items);
      props.onSelectValues?.(vals);
      props.onSelectItems?.(items);
    } else {
      if (vals.length === 0) {
        props.onSelect?.(undefined, undefined);
        props.onSelectValue?.(undefined);
        props.onSelectItem?.(undefined);
      } else {
        props.onSelect?.(vals[0], items[0]);
        props.onSelectValue?.(vals[0]);
        props.onSelectItem?.(items[0]);
      }
    }
    setSearch(""); // Reset search to avoid filtered data on re-select
  }

  // To prevent unnecessary re-renders. Trigger components should not change
  const TriggerComponent = useMemo(() => {
    if (props.trigger?.Component) return props.trigger.Component;
    if (props.multiple) return DefaultMultiSelectTrigger;
    return DefaultSingleSelectTrigger;
  }, []) satisfies ComponentType;

  return (
    <SelectionComboboxContext.Provider
      value={{
        selectedItems: selectedItems,
        filteredData,
        open,
        searchPlaceholder: props.searchPlaceholder,
        renderItem: props.renderItem,
        onCreateNew: props.onCreateNew,
        disabled: props.disabled,
        onQueryChange: handleQueryChange,
        labelFn: props.labelFn,
        keyFn: keyFn,
        valueFn: props.valueFn,
        isSelected,
        multiple: props.multiple,
        onConfirmMultiSelect: handleConfirmMultiSelect,
        loading: props.loading,
        onValueChange: handleValueChange,
        onRemoveItem: handleRemoveItem,
        onClearSelection: handleClearSelection,
        onSelectAll: handleSelectAll,
        TriggerComponent,
        triggerItems,
        error: props.error,
        readonly: props.readonly,
        placeholder,
        icon: props.icon,
        required,
        enableSelectAll,
        contentClassName,
      }}
    >
      {props.label && (
        <Label required={props.required} linkSlot={props.labelSlot}>
          {props.label}
        </Label>
      )}

      <Popover
        isOpen={open}
        disabled={props.disabled}
        onOpenChange={handleOpenChange}
        triggerAsChild={props.trigger?.asChild ?? true}
        config={{
          align: "start",
          sideOffset: props.multiple ? 5 : -41, // To cover the input
          side: "bottom",
          ...props.popover?.config,
        }}
        triggerRender={() => <TriggerComponent data-testid={testIdentifiers.trigger} />}
      >
        {() => <SelectionComboboxContent />}
      </Popover>
    </SelectionComboboxContext.Provider>
  );
}

/**
 * Used for server-side searching. Returns undefined is searchQuery is defined...
 * Value if defined, defaultValue if defined... or undefined
 * @deprecated You probably want to feed all the data into SelectionCombobox instead
 */
export function getSelectedIds(props: {
  value?: Array<string> | string;
  defaultValue?: Array<string> | string;
  isSearchDialogOpen?: boolean;
}): Array<string> | undefined {
  if (props.isSearchDialogOpen) return undefined;

  if (props.value && Array.isArray(props.value)) {
    if (props.value.length === 0) return undefined;
    return props.value;
  }
  if (props.value) return [props.value];
  if (props.defaultValue && Array.isArray(props.defaultValue)) {
    if (props.defaultValue.length === 0) return undefined;
    return props.defaultValue;
  }
  if (props.defaultValue) return [props.defaultValue];
  return undefined;
}
