import { forwardRef, HTMLProps, Ref, useId } from "react";
import { Label } from "../label";
import { twMerge } from "tailwind-merge";

const BASE_CLASS =
  "w-full border h-10 rounded-md border-gray-300 text-gray-700 focus:border-hover focus:outline-none transition-colors focus:ring-transparent disabled:bg-gray-100";

type LabeledTextareaProps = HTMLProps<HTMLTextAreaElement> & {
  label?: string;
  labelClassName?: string;
  error?: string;
};

export const LabeledTextarea = forwardRef(function LabeledTextareaInner(
  props: LabeledTextareaProps,
  ref: Ref<HTMLTextAreaElement>
) {
  const id = useId();
  const { label, labelClassName, required, error, ref: _, ...restProps } = props;

  return (
    <div>
      {label && (
        <Label htmlFor={id} required={required} className={labelClassName}>
          {label}
        </Label>
      )}
      <textarea
        id={id}
        ref={ref}
        {...restProps}
        className={twMerge(BASE_CLASS, error && "border-error", props.className)}
      />
      {error ? <p className="mt-1 text-left text-sm text-red-600">{props.error}</p> : null}
    </div>
  );
});
