import { z } from "zod";
import { useFormState } from "~/lib/form-state";
import { type ProductBundlesGetKpiCardsData200ResponseData } from "@apacta/sdk";

export const kpiProductDataSchema = {
  hoursCostPrice: z.number(),
  productsCostPrice: z.number(),
  totalCostPrice: z.number(),
  hoursSalesPrice: z.number(),
  productsSalesPrice: z.number(),
  totalSalesPrice: z.number(),
  contributionMargin: z.number(),
  workingHoursMargin: z.number(),
  profitMargin: z.number(),
};

export type KpiProductDataFormState = ReturnType<typeof useKpiProductBundleFormstate>;

export function useKpiProductBundleFormstate(
  kpiData: ProductBundlesGetKpiCardsData200ResponseData
) {
  const getInitialValues = () => {
    return {
      hoursCostPrice: kpiData.hoursCostPrice ?? 0,
      productsCostPrice: kpiData.productsCostPrice ?? 0,
      totalCostPrice: kpiData.totalCostPrice ?? 0,
      hoursSalesPrice: kpiData.hoursSalesPrice ?? 0,
      productsSalesPrice: kpiData.productsSalesPrice ?? 0,
      totalSalesPrice: kpiData.totalSalesPrice ?? 0,
      contributionMargin: kpiData.contributionMargin ?? 0,
      workingHoursMargin: kpiData.workingHoursMargin ?? 0,
      profitMargin: kpiData.profitMargin ?? 0,
    };
  };

  const kpiFormData = useFormState({
    schema: kpiProductDataSchema,
    initialValues: getInitialValues(),
    mutationError: undefined,
  });

  return kpiFormData;
}
