import { PageLayout } from "~/lib/ui/page-layout";
import { Button, Dialog, getIcon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { CreateInvoiceDialog } from "./_cmp/create-invoice-dialog";
import { InvoiceTable } from "./_cmp/invoice-table";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { useInvoicesParams } from "~/pages/invoices/_cmp/use-invoices-params";

export const CACHE_INVOICES = "invoices";

export default function InvoicesPage() {
  const { t } = useTranslation();
  const invoicesParams = useInvoicesParams();
  const startDate = invoicesParams.startDate;
  const endDate = invoicesParams.endDate;
  return (
    <>
      <PageLayout title={t("navigation:invoices.title")}>
        <PageLayout.Signal slot="actions" id="create-invoice">
          <Dialog
            render={({ onClose }) => <CreateInvoiceDialog onClose={onClose} />}
            trigger={
              <Button variant="tertiary" className="print:hidden" Icon={getIcon("add")}>
                {t("common:create", {
                  defaultValue: "Create {{entity}}",
                  replace: { entity: t("common:invoice", { count: 1 }).toLocaleLowerCase() },
                })}
              </Button>
            }
          />
        </PageLayout.Signal>
        <PageLayout.Signal slot="description" id="date-filter">
          <DateRangePopover
            dateFrom={startDate}
            dateTo={endDate}
            onChange={(range) => invoicesParams.handlePeriodChange(range.from, range.to)}
            resetLabel={t("common:last_x_days", { x: 14 })}
            onReset={() => invoicesParams.handleResetPeriod()}
          />
        </PageLayout.Signal>
        <InvoiceTable
          dateFrom={startDate}
          dateTo={endDate}
          parentType="page"
          columnVisibility={{
            grossPayment: false,
          }}
        />
      </PageLayout>
    </>
  );
}
