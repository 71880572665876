import { ComponentProps, ReactNode } from "react";
import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";

// Lots of examples here: https://skeletonreact.com/
export const templates = {
  box: { options: {}, render: () => <rect x={0} y={0} width="100%" height="100%"></rect> },
  list: {
    options: {
      speed: 2,
      width: 207,
      height: 135,
      viewBox: "0 0 207 135",
    },
    render: (opts) => (
      <>
        <rect x="10" y="10" rx="4" ry="4" width="20" height="15" />
        <rect x="47" y="10" rx="4" ry="4" width="150" height="15" />
        <rect x="10" y="35" rx="4" ry="4" width="20" height="15" />
        <rect x="47" y="35" rx="4" ry="4" width="150" height="15" />
        <rect x="10" y="60" rx="4" ry="4" width="20" height="15" />
        <rect x="47" y="60" rx="4" ry="4" width="150" height="15" />
        <rect x="10" y="85" rx="4" ry="4" width="20" height="15" />
        <rect x="47" y="85" rx="4" ry="4" width="150" height="15" />
        <rect x="10" y="110" rx="4" ry="4" width="20" height="15" />
        <rect x="47" y="110" rx="4" ry="4" width="150" height="15" />
      </>
    ),
  },
  carouselThumbnails: {
    options: {
      height: 64,
      width: 720,
      speed: 2,
      viewBox: "0 0 720 64",
    },
    render: (opts) => (
      <>
        <rect x={0} y={0} width={72} height={64} />
        <rect x={80} y={0} width={72} height={64} />
        <rect x={160} y={0} width={72} height={64} />
        <rect x={240} y={0} width={72} height={64} />
        <rect x={320} y={0} width={72} height={64} />
        <rect x={400} y={0} width={72} height={64} />
        <rect x={480} y={0} width={72} height={64} />
        <rect x={560} y={0} width={72} height={64} />
        <rect x={640} y={0} width={72} height={64} />
      </>
    ),
  },
  bulletList: {
    options: {
      speed: 2,
      width: 400,
      height: 150,
      viewBox: "0 0 400 150",
    },
    render: (opts) => (
      <>
        <circle cx="10" cy="20" r="8" />
        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="50" r="8" />
        <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="80" r="8" />
        <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="110" r="8" />
        <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
      </>
    ),
  },
  code: {
    options: {
      speed: 2,
      width: 340,
      height: 84,
      viewBox: "0 0 340 84",
    },
    render: (opts) => (
      <>
        <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
        <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
        <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
        <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
        <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
        <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
        <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
        <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
      </>
    ),
  },
  facebook: {
    options: {
      speed: 2,
      width: 476,
      height: 124,
      viewBox: "0 0 476 124",
    },
    render: () => (
      <>
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </>
    ),
  },
  // A single card-row with 4 cards
  row: {
    options: {
      speed: 2,
      viewBox: "0 0 2000 200",
    },
    render: () => (
      <>
        <rect x="0" y="0" rx="3" ry="3" width="24%" height="100%" />
        <rect x="25%" y="0" rx="3" ry="3" width="24%" height="100%" />
        <rect x="50%" y="0" rx="3" ry="3" width="24%" height="100%" />
        <rect x="75%" y="0" rx="3" ry="3" width="24%" height="100%" />
      </>
    ),
  },
  image: {
    options: {
      speed: 2,
      width: 200,
      height: 200,
      viewBox: "0 0 409 410",
    },
    render: () => (
      <>
        <path
          d="M408.6,46.4c0,105.6,0,211.2,0,316.8c-0.2,0.7-0.6,1.5-0.7,2.2c-3.8,20.6-15.7,34.3-35.3,41.5c-3.3,1.2-6.9,1.8-10.3,2.7
		c-105.4,0-210.8,0-316.2,0c-1.8-0.4-3.6-0.9-5.4-1.3c-24.9-5.9-40.9-26.4-40.9-52.6c0-84.1,0-168.2,0-252.3
		c0-17.2-0.2-34.4,0.1-51.6C0.1,29.3,14.1,10.1,35.4,2.8c3.5-1.2,7.1-1.9,10.7-2.8c105.4,0,210.8,0,316.2,0c0.6,0.2,1.2,0.6,1.8,0.7
		c20.6,3.6,34.4,15.5,41.7,35C407.1,39.1,407.7,42.8,408.6,46.4z M23.8,281.4c1.6-1.3,2.6-2,3.6-2.9c19.4-16.5,38.7-33,58.1-49.5
		c14.5-12.3,30.2-12.5,44.8-0.5c6.1,5,12.1,10,18.1,15.1c7,5.8,14.1,11.7,22,18.3c1.1-1.5,1.8-3,3-4.1
		c34.8-34.9,69.5-69.8,104.4-104.7c2.7-2.7,5.7-5.4,9-7.2c14.7-8.2,30.8-4.7,41.9,9.1c17.5,21.6,35,43.2,52.6,64.9
		c0.9,1.1,1.8,2.1,3.4,3.8c0-2,0-2.9,0-3.8c0-55.2,0-110.4,0-165.6c0-18.3-12-30.2-30.4-30.2c-99.9,0-199.8,0-299.7,0
		c-3.2,0-6.4,0.3-9.5,1.1c-13.3,3.5-21.1,14.5-21.1,29.3c0,74.2,0,148.5,0,222.7C23.8,278.4,23.8,279.5,23.8,281.4z M204.3,385.5
		c50,0,100,0,150.1,0c17.9,0,30.1-11.9,30.2-29.6c0.1-30.4,0.1-60.8,0-91.2c0-1.4-0.6-3.2-1.5-4.3c-24.6-30.5-49.2-60.9-74-91.2
		c-1.5-1.9-4.7-3.5-7.1-3.4c-2.4,0.1-5.1,2.1-6.9,4c-38.1,38-76,76.2-114,114.3c-7.2,7.2-12.5,7.5-20.5,0.9
		c-15.1-12.5-30.1-25-45.1-37.5c-5.9-4.9-8.6-5-14.3-0.1c-25,21.3-50.1,42.6-75,64.1c-1.2,1-2.2,3-2.2,4.6c-0.2,13.3-0.3,26.7,0,40
		c0.1,4.5,1.1,9.4,3,13.5c5.4,11.5,15.3,16,27.6,16C104.5,385.5,154.4,385.5,204.3,385.5z"
        />

        <path
          d="M70.5,108.7c-0.2-31.8,25.8-58.1,57.7-58.3c31.9-0.2,58.3,26,58.4,58c0.1,31.9-25.7,57.9-57.8,58.2
		C96.8,166.9,70.6,140.9,70.5,108.7z M128.5,142.5c18.5,0,33.7-15.1,33.9-33.8c0.2-18.4-15.5-34.3-34-34.2
		c-18.6,0-34,15.6-33.8,34.3C94.8,127.5,109.9,142.5,128.5,142.5z"
        />
      </>
    ),
  },
  document: {
    options: {
      speed: 3,
      viewBox: "0 0 600 800",
    },
    render: () => (
      <>
        <rect x="0" y="10" rx="6" ry="6" width="40%" height="14" />

        <rect x="0" y="60" rx="6" ry="6" width="60%" height="14" />
        <rect x="0" y="90" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="120" rx="6" ry="6" width="100%" height="14" />

        <rect x="0" y="200" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="230" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="260" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="290" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="320" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="350" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="380" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="410" rx="6" ry="6" width="80%" height="14" />

        <rect x="0" y="500" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="530" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="560" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="590" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="620" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="650" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="680" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="710" rx="6" ry="6" width="80%" height="14" />
      </>
    ),
  },
} satisfies Record<
  string,
  {
    options: ContentLoaderOptions;
    render: (opts?: Record<string, unknown>) => ReactNode;
  }
>;

type ContentLoaderOptions = {
  width?: ComponentProps<typeof ContentLoader>["width"];
  height?: ComponentProps<typeof ContentLoader>["height"];
  viewBox?: ComponentProps<typeof ContentLoader>["viewBox"];
  backgroundColor?: ComponentProps<typeof ContentLoader>["backgroundColor"];
  foregroundColor?: ComponentProps<typeof ContentLoader>["foregroundColor"];
  speed?: ComponentProps<typeof ContentLoader>["speed"];
  repeat?: number;
};

type BaseSkeletonLoaderProps = {
  className?: string;
  options?: ContentLoaderOptions;
};

interface SkeletonTemplateProps extends BaseSkeletonLoaderProps {
  template: keyof typeof templates;
  children?: never;
}

interface SkeletonChildrenProps extends BaseSkeletonLoaderProps {
  template?: never;
  children: ReactNode;
}

type SkeletonLoaderProps = SkeletonTemplateProps | SkeletonChildrenProps;

/**
 * Got sick of forgetting how to use ContentLoader every time.
 *
 * This has predefined shapes we can use, or if you pass children it will override.
 *
 */
export function SkeletonLoader(props: SkeletonLoaderProps) {
  let templateOptions: Record<string, unknown> = {
    ...props.options,
  };
  if (props.template) {
    templateOptions = {
      ...templateOptions,
      ...templates[props.template].options,
    };
  }

  return (
    <ContentLoader
      className={twMerge(defaultClasses, props.className)}
      {...defaultOptions}
      {...templateOptions}
      {...props.options}
    >
      {props.template && templates[props.template].render(templateOptions)}
      {props.children}
    </ContentLoader>
  );
}

const defaultClasses = "";

const defaultOptions: ContentLoaderOptions = {
  backgroundColor: "#f1f5f9",
  foregroundColor: "#94a3b8",
};
