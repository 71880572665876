import { Contact, ContactPerson } from "@apacta/sdk";
import { CustomerSelection } from "~/lib/ui/selection-combobox/customer-selection";
import { useTranslation } from "react-i18next";
import { CustomerEntry } from "~/lib/ui/customer-entry";
import { ContactPersonSelection } from "~/lib/ui/selection-combobox/contact-person-selection";
import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { CACHE_CUSTOMERS } from "~/pages/customers";
import { CACHE_CONTACT_PERSONS } from "~/pages/customers/[id]/contacts";
import { linkToCustomer } from "~/lib/utils";
import { Icon } from "../icons/icon";
import { TextLink } from "../text-link";

/** Controlled CustomerSelection with Preview */
export function CustomerSelectionWithPreview({
  selectedCustomer,
  onSelectCustomer,
  selectedContactPerson,
  onSelectContactPerson,
}: {
  selectedCustomer?: string;
  onSelectCustomer?: (customer?: Contact) => void;
  selectedContactPerson?: string;
  onSelectContactPerson?: (contactPerson?: ContactPerson) => void;
}) {
  const { t } = useTranslation();
  const queryOptions = {
    // Allows us to react to changes if they do them in another tab
    // We're encouring edits here (by linking), so lets refresh if they do
    refetchOnWindowFocus: true,
  };

  const api = useAPI();
  const customerQ = useQuery({
    ...queryOptions,
    queryKey: [CACHE_CUSTOMERS, selectedCustomer],
    queryFn: () => api.getContact({ contactId: selectedCustomer! }),
  });

  const contactPersonQ = useQuery({
    ...queryOptions,
    queryKey: [CACHE_CONTACT_PERSONS, selectedCustomer, selectedContactPerson],
    queryFn: () =>
      api.getContactPerson({
        contactId: selectedCustomer!,
        contactPersonId: selectedContactPerson!,
      }),
    enabled: !!selectedCustomer && !!selectedContactPerson,
  });

  const customer = customerQ.data?.data;
  const contactPerson = contactPersonQ.data?.data;

  return (
    <>
      <div className="flex flex-col gap-2 bg-white">
        <CustomerSelection
          placeholder={customerQ.isPending ? t("common:loading") : t("common:search")}
          label={t("common:customer", { count: 1 })}
          value={selectedCustomer}
          controlled
          onSelectItem={(newCustomer) => onSelectCustomer?.(newCustomer)}
          labelSlot={
            customer?.id ? (
              <span className="inline-flex items-center">
                <TextLink
                  href={linkToCustomer(customer.id)}
                  target="_blank"
                  className="text-shade-400"
                >
                  {`${t("common:go_to")} ${t("common:customer", { count: 1 }).toLocaleLowerCase()}`}
                  <Icon name="externalLink" className="ml-1 inline" />
                </TextLink>
              </span>
            ) : undefined
          }
          queryOptions={queryOptions}
        />
        {customer && (
          <>
            <CustomerEntry
              phone={customer.phone ?? undefined}
              ean={customer.ean ?? undefined}
              email={customer.email ?? undefined}
              address={{
                street: customer.address ?? "",
                zipCode: customer.zipCode,
                cityName: customer.cityName,
              }}
              inline={true}
            />
          </>
        )}
      </div>
      <div className="mt-4">
        <div className="flex flex-col gap-2 bg-white">
          <ContactPersonSelection
            controlled
            disabled={!onSelectContactPerson}
            label={t("common:customer_contact", { count: 1 })}
            customerId={selectedCustomer}
            value={selectedContactPerson}
            onSelectItem={(newContactPerson) => onSelectContactPerson?.(newContactPerson)}
            queryOptions={queryOptions}
          />
          {contactPerson && (
            <CustomerEntry email={contactPerson?.email} phone={contactPerson?.phone} />
          )}
        </div>
      </div>
    </>
  );
}
