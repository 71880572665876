import { useEffect, useId, useRef, useState } from "react";
import { AutoCompleteInputProps } from "./types";
import * as Popover from "@radix-ui/react-popover";
import { useItemSelection } from "./use-item-selection";
import { SelectionContent } from "./selection-content";
import { twMerge } from "tailwind-merge";
import NoResultsWidget from "./no-results-widget";
import { Spinner } from "../spinner";
import { Label } from "../label";
import { Input } from "~/lib/ui/form-elements";
import { SkeletonLoader } from "~/lib/ui/skeleton";

/**
 * @description A TextInput component with autocomplete functionality. Use if you want the input to be free text, but with suggestions. Typical use-cases; search, address input, cvr input, zip input. If you don't want the field to be free text, use Combobox instead.
 */
export function AutoCompleteInput<ItemType extends object>({
  id,
  label,
  name,
  defaultValue,
  placeholder,
  className,
  value,
  items,
  disabled,
  valueFn,
  displayFn,
  onChange,
  onSelect,
  loading,
  error,
}: AutoCompleteInputProps<ItemType>) {
  const [open, setOpen] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const popupRef = useRef<HTMLUListElement | null>(null);
  const itemRef = useRef<Array<HTMLLIElement | null>>([]);

  const inputId = useId(); // Only used if no id is provided

  const { currentIndex, onInputBlur, onInputFocus, onInputKeyUp, onInputKeydown, onInputClick } =
    useItemSelection({
      items,
      onOpenChange: setOpen,
      onSelectIndex: handleSelect,
      selectionOpen: open,
    });

  // Scroll focused item into view
  useEffect(() => {
    if (currentIndex === -1) return;
    const item = itemRef.current[currentIndex];
    if (!item) return;
    item.scrollIntoView({ block: "nearest" });
  }, [currentIndex]);

  function handleSelect(index: number) {
    const item = items[index];
    if (item) {
      if (!value && valueFn && inputRef.current) {
        inputRef.current.value = valueFn(item);
      }
      onSelect?.(item);
    }
    setTimeout(() => {
      // Must not be called at the time of the click
      inputRef.current?.focus();
    }, 1);

    setOpen(false);
  }

  function handleChange(s: string) {
    onChange?.(s);
    // User is still searching, keep the popup open
    if (s.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }

  const noResultsCondition = !items.length && !loading;

  const itemClass =
    "py-2 px-4 rounded-md cursor-pointer hover:bg-shade-100 focus:bg-pink-50 active:bg-pink-50";
  const focusedClass = "bg-shade-100";

  const isLoading = loading && value && value.length > 0 && open;

  return (
    <div className={twMerge(className)}>
      {label && <Label htmlFor={id || inputId}>{label}</Label>}
      <Popover.Root open={open} modal={false}>
        <Popover.Trigger className="w-full" asChild>
          <div>
            <div className="relative">
              <Input
                id={id || inputId}
                ref={inputRef}
                name={name}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                onKeyUp={onInputKeyUp}
                onKeyDown={onInputKeydown}
                onChange={(e) => handleChange(e.currentTarget.value)}
                onClick={onInputClick}
                className={className}
              />
              {isLoading && (
                <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-hover">
                  <Spinner className="h-5 w-5" />
                </div>
              )}
            </div>
            {error ? <p className="mt-2 text-left text-sm text-red-600">{error}</p> : null}
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            side="bottom"
            align="start"
            asChild
            sideOffset={0}
            style={{
              minWidth: "var(--radix-popover-trigger-width",
              maxHeight: "var(--radix-popover-content-available-height)",
              minHeight: "var(--radix-popover-trigger-height)",
            }}
            onInteractOutside={(e) => {
              if (inputRef.current?.isSameNode(e.currentTarget as HTMLElement)) {
                // Ignore if clicking the input
                return;
              }
              if (popupRef.current?.contains(e.currentTarget as HTMLElement)) {
                // Ignore if clicking inside the popup
                return;
              }

              setOpen(false);
            }}
          >
            <SelectionContent ref={popupRef}>
              {!loading &&
                items.map((item, index) => (
                  <li
                    key={`autocomplete-item-${index}`}
                    ref={(el) => {
                      itemRef.current[index] = el;
                    }}
                    tabIndex={-1}
                    className={twMerge(itemClass, currentIndex === index && focusedClass)}
                    onClick={() => handleSelect(index)}
                    aria-current={currentIndex === index}
                  >
                    {/* This must be wrapped in a span, otherwise Google Translate crashes the app */}
                    <span>{displayFn(item)}</span>
                  </li>
                ))}

              {!loading && noResultsCondition && (
                <li tabIndex={-1} className="flex text-center">
                  <NoResultsWidget query={inputRef.current?.value || ""} />
                </li>
              )}

              {loading && <SkeletonLoader template="box" className="h-8 w-full" />}
            </SelectionContent>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
}
