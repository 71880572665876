import { z } from "zod";
import { useFormState } from "~/lib/form-state";
import { ProductBundle } from "@apacta/sdk";

export const productDataSchema = {
  name: z.string(),
  description: z.string().optional(),
  file_id: z.string().optional(),
  total_sales_price: z.number().optional(),
  contribution_ratio: z.number().optional(),
};

export type ProductDataFormState = ReturnType<typeof useProductBundleFormState>;

export function useProductBundleFormState(productBundle: ProductBundle) {
  const getInitialValues = () => {
    return {
      name: productBundle.name,
      description: productBundle.description ?? undefined,
      file_id: productBundle.file?.id,
      total_sales_price: productBundle.salesPrice || 0.0,
      contribution_ratio: 0.0,
    };
  };

  const formState = useFormState({
    schema: productDataSchema,
    initialValues: getInitialValues(),
    mutationError: undefined,
  });

  return formState;
}
