import { twMerge } from "tailwind-merge";
import { formatDecimals } from "~/lib/utils/number";

export function NumberCell({
  value = 0,
  warnOnZero,
  warnOnPositive,
}: {
  value?: number;
  warnOnZero?: boolean;
  warnOnPositive?: boolean;
}) {
  const showWarning = (() => {
    if (warnOnZero) {
      return value === 0;
    }
    if (warnOnPositive) {
      return value > 0;
    }
    return false;
  })();

  return (
    <span className={twMerge("text-right", showWarning && "text-orange-600")}>
      {formatDecimals(value)}
    </span>
  );
}
