import { MutableRefObject, useEffect, useState } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { DragDropCardType } from "~/pages/planning/_cmp/drag/drag-drop.types";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";

type ScrollContainer = {
  top: number;
  bottom: number;
  left: number;
  right: number;
  clientWidth: number;
  clientHeight: number;
  canScrollLeft: boolean;
  canScrollRight: boolean;
  canScrollTop: boolean;
  canScrollBottom: boolean;
};

export function ScrollLayer({
  boundingRef,
  disableHorizontal = false,
  disableVertical = false,
  isDragging,
}: {
  boundingRef: MutableRefObject<HTMLDivElement | null>;
  disableHorizontal?: boolean;
  disableVertical?: boolean;
  isDragging: boolean;
}) {
  const defaultScrollContainer = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    clientHeight: 0,
    clientWidth: 0,
    canScrollLeft: false,
    canScrollRight: false,
    canScrollTop: false,
    canScrollBottom: false,
  };

  const [settings, setSettings] = useState<ScrollContainer>(defaultScrollContainer);

  const getSettings = (): ScrollContainer => {
    if (boundingRef.current) {
      const el = boundingRef.current;
      const top = el.offsetTop;
      const left = el.offsetLeft;
      const right = el.offsetLeft + el.clientWidth;
      const bottom = el.offsetTop + el.clientHeight;
      const canScrollLeft = el.scrollLeft > 0;
      const canScrollTop = el.scrollTop > 0;
      const canScrollRight =
        el.scrollWidth > el.clientWidth && el.scrollLeft < el.scrollWidth - el.clientWidth;
      const canScrollBottom =
        el.scrollHeight > el.clientHeight && el.scrollTop < el.scrollHeight - el.clientHeight;
      return {
        top,
        left,
        right,
        bottom,
        clientHeight: el.clientHeight,
        clientWidth: el.clientWidth,
        canScrollLeft,
        canScrollRight,
        canScrollTop,
        canScrollBottom,
      };
    }
    return defaultScrollContainer;
  };

  useEffect(() => {
    setSettings(getSettings());
  }, [isDragging]);

  const [{ overTop }, dropTop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: DragDropCardType,
    // Props to collect
    collect: (monitor: DropTargetMonitor) => ({
      overTop: monitor.isOver({ shallow: true }),
    }),
  }));

  const [{ overLeft }, dropLeft] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: DragDropCardType,
    // Props to collect
    collect: (monitor: DropTargetMonitor) => ({
      overLeft: monitor.isOver({ shallow: true }),
    }),
  }));

  const [{ overRight }, dropRight] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: DragDropCardType,
    // Props to collect
    collect: (monitor: DropTargetMonitor) => ({
      overRight: monitor.isOver({ shallow: true }),
    }),
  }));

  const [{ overBottom }, dropBottom] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: DragDropCardType,
    // Props to collect
    collect: (monitor: DropTargetMonitor) => ({
      overBottom: monitor.isOver({ shallow: true }),
    }),
  }));

  useEffect(() => {
    let i: ReturnType<typeof setInterval>;
    if (boundingRef.current) {
      const el = boundingRef.current;
      if (overTop && isDragging) {
        i = setInterval(() => {
          el.scrollBy({ left: 0, top: -100, behavior: "smooth" });
          setSettings(getSettings());
        }, 100);
      }
      if (overLeft && isDragging) {
        i = setInterval(() => {
          el.scrollBy({ left: -100, top: 0, behavior: "smooth" });
          setSettings(getSettings());
        }, 100);
      }
      if (overRight && isDragging) {
        i = setInterval(() => {
          el.scrollBy({ left: 100, top: 0, behavior: "smooth" });
          setSettings(getSettings());
        }, 100);
      }
      if (overBottom && isDragging) {
        i = setInterval(() => {
          el.scrollBy({ left: 0, top: 100, behavior: "smooth" });
          setSettings(getSettings());
        }, 100);
      }
    }

    return () => clearInterval(i);
  }, [overTop, overLeft, overRight, overBottom, isDragging]);

  return (
    <>
      {!disableVertical && (
        <>
          <div
            ref={(ref) => {
              dropTop(ref);
            }}
            style={{ top: settings.top, width: settings.clientWidth }}
            className={twMerge(
              "z-20 flex h-16 flex-shrink-0 items-center justify-center bg-gradient-to-b from-transparent to-black/30",
              isDragging && settings.canScrollTop ? "fixed" : "hidden"
            )}
          >
            <div className="animate-pulse">
              <Icon name="chevronDoubleUp" className="h-10 w-10 text-white drop-shadow-md" />
            </div>
          </div>
          <div
            ref={(ref) => {
              dropBottom(ref);
            }}
            style={{ top: `calc(${settings.bottom}px - 4em)`, width: settings.clientWidth }}
            className={twMerge(
              "z-20 flex h-16 flex-shrink-0 items-center justify-center bg-gradient-to-b from-transparent to-black/30",
              isDragging && settings.canScrollBottom ? "fixed" : "hidden"
            )}
          >
            <div className="animate-pulse">
              <Icon name="chevronDoubleDown" className="h-10 w-10 text-white drop-shadow-md" />
            </div>
          </div>
        </>
      )}
      {!disableHorizontal && (
        <>
          <div
            ref={(ref) => {
              dropLeft(ref);
            }}
            style={{ left: settings.left, height: settings.clientHeight }}
            className={twMerge(
              "z-20 flex w-16 flex-shrink-0 items-center justify-center bg-gradient-to-l from-transparent to-black/30",
              isDragging && settings.canScrollLeft ? "fixed" : "hidden"
            )}
          >
            <div className="animate-pulse">
              <Icon name="chevronDoubleLeft" className="h-10 w-10 text-white drop-shadow-md" />
            </div>
          </div>
          <div
            ref={(ref) => {
              dropRight(ref);
            }}
            style={{ left: `calc(${settings.right}px - 4em)`, height: settings.clientHeight }}
            className={twMerge(
              "z-20 flex w-16 flex-shrink-0 items-center justify-center bg-gradient-to-r from-transparent to-black/30",
              isDragging && settings.canScrollRight ? "fixed" : "hidden"
            )}
          >
            <div className="animate-pulse">
              <Icon name="chevronDoubleRight" className="h-10 w-10 text-white drop-shadow-md" />
            </div>
          </div>
        </>
      )}
    </>
  );
}
