import Calendar from "./calendar";
import * as Popover from "@radix-ui/react-popover";
import { useEffect, useId, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Button, Icon, Label } from "~/lib/ui";
import { useLocale } from "~/lib/utils/date";
import { Input } from "../form-elements";

/**
 * Controlled DateInput. The value is a Date object or placeholder on undefined.
 *
 * It supports both dd.mm.yyyy and dd/mm/yyyy formats and will validate the input.
 *
 * Note: There is room for improvement in the validation and error handling.
 */
export default function DateInput({
  label,
  name,
  value,
  disabled = false,
  className,
  onChange,
  disabledBefore,
  disabledAfter,
  required,
}: {
  label: string;
  name?: string;
  value?: Date;
  disabled?: boolean;
  className?: string;
  onChange: (d: Date) => void;
  disabledBefore?: Date;
  disabledAfter?: Date;
  required?: boolean;
}) {
  const locale = useLocale();
  const [internalString, setInternalString] = useState<string>(dateToString);
  const [open, setOpen] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const id = useId();
  const { t } = useTranslation();

  // initializer function
  function dateToString(d?: Date): string {
    if (!d) return "";
    return locale.format(d, { excludeTime: true, shortDate: true });
  }

  function handleSelectDate(v: Date) {
    if (v === value) return;
    // onChange is called by validateAndUpdate (called immeditely after)
    handleInputChange(dateToString(v), false);
    setOpen(false);
  }

  function handleInputChange(v: string, debounce: boolean) {
    setIsValid(true);
    setInternalString(v);
    // Debounce validation
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (debounce) {
      timeoutRef.current = setTimeout(() => {
        validateAndUpdate(v);
      }, 2000); // In case blur doesn't trigger
    } else {
      validateAndUpdate(v);
    }
  }

  function validateAndUpdate(v: string) {
    if (v.length === 0) return;
    const date = locale.stringToDate(v);
    if (date === null) {
      // Date is invalid
      setInternalString(v);
      setIsValid(false);
      return;
    } else {
      onChange(date);
      return;
    }
  }

  function handleAutoFocus(e: Event) {
    e.preventDefault();
    buttonRef.current?.focus();
  }

  // In case value is changed by parent
  useEffect(() => {
    if (internalString === dateToString(value)) {
      return;
    }
    setInternalString(dateToString(value));
    // Note: If the language changes, we have an invalid string in state
  }, [value, locale.fnsLocale]);

  return (
    <div className={twMerge("w-full", className)}>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      <div className="flex items-end">
        <Input
          name={name}
          label={label}
          disabled={disabled}
          value={internalString}
          placeholder={locale.stringPlaceholder}
          onChange={(e) => handleInputChange(e.currentTarget.value, true)}
          onBlur={(e) => validateAndUpdate(e.currentTarget.value)}
          className="rounded-r-none"
        />
        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Trigger disabled={disabled} className={twMerge("")} asChild>
            <Button
              ref={buttonRef}
              disabled={disabled}
              variant="secondary"
              className="rounded-l-none border-l-0 border-gray-300 bg-gray-50 ring-0 hover:border-l"
            >
              <Icon name="calendar" size="small" className="my-px -ml-px" />
            </Button>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className="animate-slide-down"
              onCloseAutoFocus={(e) => handleAutoFocus(e)}
            >
              <div className="hidden sm:block">
                <Calendar
                  defaultDate={value}
                  onSelect={(d: Date) => handleSelectDate(d)}
                  disabledBefore={disabledBefore}
                  disabledAfter={disabledAfter}
                />
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
      {!isValid ? (
        <p className="mt-1 text-left text-sm text-red-600">
          {t("errors:invalid_date", {
            replace: { expectedFormat: locale.stringPlaceholder },
          })}
        </p>
      ) : null}
    </div>
  );
}
