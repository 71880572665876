import { CalendarDate, CalendarDates, WeekDates } from "./calendar.types";
import { useState } from "react";
import { getWeekNumber } from "~/lib/utils/date/date-utils";
import { dayNames, monthNames } from "~/lib/calendar/calendar.helpers";
import { useHolidays } from "~/lib/calendar/use-holidays";
import { i18n } from "~/lib/i18n/i18n";

type IndexWithName = {
  index: number;
  name: string;
};

export type UseCalendar = {
  initialDate: Date;
  selectedDate: Date;
  setSelectedDate: (v: Date) => void;
  getDaysInMonth: (d: Date) => CalendarDates;
  months: Array<IndexWithName>;
  days: Array<IndexWithName>;
  calendarControls: {
    nextMonth: () => void;
    previousMonth: () => void;
    nextYear: () => void;
    previousYear: () => void;
  };
};

const createCalendarDate = (addDate: Date, targetMonth: number): CalendarDate => {
  const calendarDate: CalendarDate = {
    date: addDate,
    shortDate: addDate.getDate(),
    isToday: false,
    isCurrentMonth: true,
    weekNumber: getWeekNumber(addDate),
    calendarIndex: 0,
  };
  if (
    addDate.getDate() === new Date().getDate() &&
    addDate.getMonth() === new Date().getMonth() &&
    addDate.getFullYear() === new Date().getFullYear()
  ) {
    calendarDate.isToday = true;
  }

  if (addDate.getMonth() !== targetMonth) {
    calendarDate.isCurrentMonth = false;
  }

  return calendarDate;
};

const months = () => monthNames(i18n.language);
const days = () => dayNames(i18n.language);

export function useCalendar(initialDate: Date = new Date()): UseCalendar {
  const [selectedDate, setSelectedDate] = useState<Date>(initialDate || new Date());
  const { isHoliday } = useHolidays(selectedDate);

  const nextMonth = () => {
    setSelectedDate((prevState) => {
      const d = new Date(prevState);
      d.setMonth(d.getMonth() + 1);
      return d;
    });
  };

  const previousMonth = () => {
    setSelectedDate((prevState) => {
      const d = new Date(prevState);
      d.setMonth(d.getMonth() + 1);
      return d;
    });
  };

  const nextYear = () => {
    setSelectedDate((prevState) => {
      const d = new Date(prevState);
      d.setFullYear(d.getFullYear() + 1);
      return d;
    });
  };

  const previousYear = () => {
    setSelectedDate((prevState) => {
      const d = new Date(prevState);
      d.setFullYear(d.getFullYear() + 1);
      return d;
    });
  };

  const calendarControls = {
    nextMonth,
    previousMonth,
    nextYear,
    previousYear,
  };

  /**
   * @returns {CalendarDates} - Returns calculated and parsed dates for the month
   * @param d
   */
  function getDaysInMonth(d: Date): CalendarDates {
    const date = new Date(d.getFullYear(), d.getMonth(), 1);

    const month = date.getMonth();
    const year = date.getFullYear();
    const targetMonth = date.getMonth();

    const dates: Array<CalendarDate> = [];

    const nameOfMonth = months()[targetMonth].name;

    const weeks: Array<WeekDates> = [];

    const addWeek = (_d: Date) => {
      weeks.push(getDaysInWeek(_d));
    };

    let weekNumber = 0; // safe, no week 0

    while (date.getMonth() === targetMonth) {
      const _d = createCalendarDate(new Date(date), targetMonth);
      dates.push({ ..._d, isHoliday: !!isHoliday(_d.date) });

      const w = getWeekNumber(date);
      if (w !== weekNumber) {
        addWeek(date);
        weekNumber = w;
      }
      date.setDate(date.getDate() + 1);
    }

    // We are enforcing first day of the week to be Monday, act accordingly
    const paddingLeft = dates[0].date.getDay() - 1 < 0 ? 6 : dates[0].date.getDay() - 1;

    for (let i = 1; i <= paddingLeft; i++) {
      const newDate = new Date(year, month, 1);
      newDate.setDate(newDate.getDate() - i);
      const _d = createCalendarDate(newDate, -1);
      dates.unshift({
        ..._d,
        isHoliday: !!isHoliday(_d.date),
        isBeforeCurrentMonth: true,
      });
    }

    let paddingRight = 6 - dates[dates.length - 1].date.getUTCDay();

    if (dates.length + paddingRight <= 35) {
      paddingRight += 7;
    }

    for (let i = 1; i <= paddingRight; i++) {
      const newDate = new Date(year, month + 1, 1);
      // reset date
      newDate.setDate(newDate.getDate() - 1);

      // calculate new date
      newDate.setDate(newDate.getDate() + i);
      const _d = createCalendarDate(newDate, -1);
      const w = getWeekNumber(newDate);
      if (w !== weekNumber) {
        addWeek(newDate);
        weekNumber = w;
      }
      dates.push({
        ..._d,
        isHoliday: !!isHoliday(_d.date),
        isAfterCurrentMonth: true,
      });
    }

    const indexedDates = dates.map((cd, i) => {
      return {
        ...cd,
        calendarIndex: i,
      };
    });

    return {
      monthName: nameOfMonth,
      monthIndex: month,
      year,
      days: indexedDates,
      weeks,
    };
  }

  function getDaysInWeek(v: Date): WeekDates {
    const dc = new Date(v);
    const dayOffset = 1 - v.getDay();
    const m = new Date(dc.setDate(v.getDate() + dayOffset));

    // if sunday, go back 7 days because weeks start on monday, not sunday.
    // if this is not done, sunday will be counted as part of the next week.
    if (v.getDay() === 0) {
      m.setDate(m.getDate() - 7);
    }

    const daysInWeek: Array<CalendarDate> = [];

    for (let i = 0; i < 7; i++) {
      const d = new Date(dc);
      const dd = new Date(d.setDate(m.getDate() + i));
      daysInWeek.push(createCalendarDate(dd, -1));
    }

    return {
      weekNumber: getWeekNumber(v),
      firstDate: daysInWeek[0].date,
      lastDate: daysInWeek[daysInWeek.length - 1].date,
      days: daysInWeek,
    };
  }

  return {
    initialDate,
    selectedDate,
    setSelectedDate,
    getDaysInMonth,
    months: months(),
    days: days(),
    calendarControls,
  };
}
