import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { createApiInstance } from "~/lib/api";
import { deleteCookie, restoreApiKey as getSessionCookie, saveCookie } from "./cookie";
import { FeaturesType, SessionContext } from "./session";
import { API_KEY_NAME } from "./config";
import { useMutation, useSuspenseQueries } from "@tanstack/react-query";
import { GetMe200ResponseFeaturesEnum } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { availableLanguagesList, LANGUAGE_STORE_KEY, LanguageDefinition } from "../i18n/i18n";

export const CACHE_ME = "meData";
export const CACHE_FEATURES = "featuresData";

// Note that ANY changes to the context state will result in a complete re-render of the application!
// - Try not to add anything unnecessary in here
export function SessionContextProvider({ children }: { children?: ReactNode }) {
  const { i18n } = useTranslation();
  const [apiKey, setApiKey] = useState<string | undefined>(getAPIKey());

  const sessionData = useRestoredSession(apiKey);

  // Configure i18next with the user's language
  useEffect(() => {
    if (!sessionData.me) {
      // Not logged in
      const lang = localStorage.getItem(LANGUAGE_STORE_KEY);
      if (lang) {
        i18n.changeLanguage(lang);
      }
    } else {
      // Logged in
      const langDef = availableLanguagesList.find(
        ([, l]) => l.id === sessionData.me.user.languageId
      );
      if (!langDef) return;
      i18n.changeLanguage(langDef[1].defaultLocale);
    }
  }, [sessionData.me?.user.languageId]);

  // Update API key persistence (cookie and local-storage)
  useEffect(() => {
    if (apiKey) {
      // Login
      saveCookie({ apiKey, scope: "unknown" });
      localStorage.setItem(API_KEY_NAME, apiKey);
    } else {
      // Logout
      deleteCookie();
      localStorage.removeItem(API_KEY_NAME);
    }
  }, [apiKey]);

  const loginWithAPIKey = useCallback(async (newAPIKey: string) => {
    setApiKey(newAPIKey);
  }, []);

  const logout = useCallback(() => {
    setApiKey(undefined);
  }, []);

  const setLanguageM = useMutation({
    mutationFn: ({ langId }: { langId: string }) =>
      sessionData.api.editUser({
        userId: sessionData.me?.user.id as string,
        editUserRequest: {
          languageId: langId,
        },
      }),
    onSuccess: () => undefined, // Do not show toast
  });

  const setLanguage = useCallback(async (language: LanguageDefinition) => {
    try {
      await i18n.changeLanguage(language.defaultLocale); // Tell i18next
      await setLanguageM.mutateAsync({ langId: language.id }); // Tell backend
      // Store in local storage
      localStorage.setItem(LANGUAGE_STORE_KEY, language.defaultLocale);
    } catch {
      // Ignore if it fails
    }
  }, []);

  return (
    <SessionContext.Provider value={{ ...sessionData, logout, setLanguage, loginWithAPIKey }}>
      {children}
    </SessionContext.Provider>
  );
}

function getAPIKey(): string | undefined {
  // 1. Check if we have an API key in the URL
  const params = new URL(document.location.toString()).searchParams;
  const apiKey = params.get("api_key");
  if (apiKey) {
    return apiKey;
  }
  // 2. Check if we have an API key in the cookie
  const cookie = getSessionCookie();
  if (cookie?.apiKey) {
    return cookie.apiKey;
  }

  // 3. Check if we have an API key in the local storage
  const localStoreAPIKey = localStorage.getItem(API_KEY_NAME);
  if (localStoreAPIKey) {
    return localStoreAPIKey;
  }

  // No API key found
  return undefined;
}

function useRestoredSession(apiKey?: string) {
  const api = useMemo(() => createApiInstance(apiKey), [apiKey]);

  const [me, features] = useSuspenseQueries({
    queries: [
      {
        queryKey: [CACHE_ME, apiKey],
        queryFn: async () => (apiKey ? api.getMe() : null), // skip api if no key
        // 30 minute gc time
        gcTime: 1000 * 60 * 30,
        // 1 minute of stale-time so we're not constantly hammering the API
        staleTime: 1000 * 60 * 1,
        refetchOnWindowFocus: true,
      },
      {
        queryKey: [CACHE_FEATURES, apiKey],
        queryFn: async () => (apiKey ? api.getFeatures() : null), // skip api if no key
      },
    ],
  });

  const isLoggedIn = me.data !== null || me.error !== null;

  // Not logged in, returning an API client without authentication
  if (!isLoggedIn || me.data === null) {
    return {
      api,
      apiKey,
      isLoggedIn: false,
      features: new Set<GetMe200ResponseFeaturesEnum>(),
    };
  }
  const featuresWithIdentifiers =
    features.data?.data.filter((f) => f.identifier).map((f) => f.identifier as FeaturesType) ?? [];

  return {
    api,
    apiKey,
    features: new Set(featuresWithIdentifiers),
    isLoggedIn,
    me: me.data,
  };
}
