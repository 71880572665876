import { twMerge } from "tailwind-merge";
import { Tooltip } from "./tooltips/tooltip";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../utils/number";

// Text Decorator with an optional Tooltip
export function TextDecorator({
  children,
  decorate = true,
  className,
  tooltip,
  variant,
}: {
  decorate?: boolean | ((v: string | number) => boolean);
  children: string | number;
  tooltip?: string;
  variant?: "warning" | "error" | "success"; // Optional variant. Affects styling
  className?: string;
}) {
  const decorateEnabled = typeof decorate === "function" ? decorate(children) : decorate;
  let decorationColor = "";
  if (variant === "warning") decorationColor = "decoration-yellow-400";
  if (variant === "error") decorationColor = "decoration-red-400";
  if (variant === "success") decorationColor = "decoration-green-400";

  const decorateClass = twMerge(
    decorateEnabled && "underline  decoration-wavy underline-offset-8",
    decorationColor
  );

  if (tooltip) {
    return (
      <Tooltip trigger={<span className={twMerge(decorateClass, className)}>{children}</span>}>
        {tooltip}
      </Tooltip>
    );
  }

  if (!tooltip) {
    return <span className={twMerge(decorateClass, className)}>{children}</span>;
  }
}

// Used for empty currency values
export function WarnOnZeroCurrency({ children }: { children: number }) {
  const { t } = useTranslation();
  return (
    <TextDecorator
      decorate={children === 0}
      variant="error"
      tooltip={t("common:warning_zero_currency", "Price is zero")}
    >
      {formatCurrency(children)}
    </TextDecorator>
  );
}
