import { useEffect, useRef } from "react";

/**
 * This is a helper to work-around components that do not support controlled inputs.
 *
 * It listens for a value change (subscribedValue) and sets the value of the input to that value.
 *
 * NOTE: This is a workaround. This isn't an excuse to not support controlled inputs.
 * - If you're writing new code, consider doing this instead:
 * - https://github.com/Apacta/issues/issues/1944#issue-2735416484
 */
export function useDirtyInputValueHack<V = unknown>(subscribedValue: V | undefined) {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    if (ref.current.value === subscribedValue) return; // No need to set the value if it's the same
    if (subscribedValue === undefined) return; // We can't set value to undefined

    // Note: we don't bail out on disabled inputs, because they can still have a value we must change
    // value insists that it's a string - but we know better (probably)
    ref.current.value = subscribedValue as unknown as string;
  }, [ref, subscribedValue]);
  return ref;
}
