import { User } from "@apacta/sdk";
import { Trans } from "react-i18next";
import { useLocale } from "~/lib/utils/date";
import { IconLine } from "~/lib/ui/text/icon-line";

/**
 * Purpose of this is to be able to show a line of "Created By Jim on 5 hours ago. Modfied by John 2 minutes ago"
 * with the feature of titles for additional information.
 * This gets somewhat tricky when we have modified, but no modifiedBy
 */
export function CreatedModifiedLine({
  modified,
  modifiedBy,
  created,
  createdBy,
}: {
  modified?: Date;
  modifiedBy?: User;
  created?: Date;
  createdBy?: User;
}) {
  const locale = useLocale();
  const showCreatedText = !!created;
  const showModfiedBy =
    modified && modifiedBy && created && locale.timeAgo(modified) !== locale.timeAgo(created);
  const showModified = !!modified && !showModfiedBy;

  return (
    <IconLine icon="registration">
      {showCreatedText && (
        <>
          <Trans
            i18nKey="common:created_by_ago"
            components={{
              by: <span title={createdBy?.fullName} />,
              ago: <span title={locale.format(created)} />,
            }}
            values={{
              by: createdBy?.firstName,
              ago: locale.timeAgo(created),
            }}
            defaults="Created by <by>{{by}}</by> on <ago>{{ago}}</ago>"
          />
          .&nbsp;
        </>
      )}
      {showModfiedBy && (
        <Trans
          i18nKey="common:modified_by_ago"
          components={{
            by: <span title={modifiedBy?.fullName} />,
            ago: <span title={locale.format(modified)} />,
          }}
          values={{
            by: modifiedBy?.firstName,
            ago: locale.timeAgo(modified),
          }}
          defaults="Modified by <by>{{by}}</by> <ago>{{ago}}</ago>"
        />
      )}
      {showModified && (
        <Trans
          i18nKey="common:modified_ago"
          components={{
            ago: <span title={locale.format(modified)} />,
          }}
          values={{
            ago: locale.timeAgo(modified),
          }}
          defaults="Modified <ago>{{ago}}</ago>"
        />
      )}
      .
    </IconLine>
  );
}
