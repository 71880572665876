import { JSX } from "react";
import { useTranslation } from "react-i18next";
import { FilterGroup } from "./filter-group";

/**
 * FilterGroupBoolean Component
 *
 * A component for rendering a boolean filter group.
 *
 * @param {string} name - The name of the filter group.
 * @param {boolean|undefined} value - The current value of the filter group.
 * @param {() => void} onClear - Callback function to clear the filter.
 * @param {(selection: boolean) => void} onConfirmSelection - Callback function when selection is confirmed.
 * @returns {JSX.Element} - Returns the JSX for the FilterGroupBoolean component.
 */
export function FilterGroupBoolean({
  name,
  value,
  onClear,
  onConfirmSelection,
}: {
  name: string;
  value: boolean | undefined;
  onClear: Parameters<typeof FilterGroup>[0]["onClear"];
  onConfirmSelection: (selection: boolean) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const selectionItems = [
    { value: true, label: t("common:yes") },
    { value: false, label: t("common:no") },
  ];
  return (
    <FilterGroup
      name={name}
      value={value}
      onClear={onClear}
      selection={{
        items: selectionItems,
        allowMultiple: false,
        valueFn: (item) => item.value,
        renderSelectionItem: (item) => item.label,
        onConfirmSelection: (selection) => onConfirmSelection(selection[0].value),
      }}
    />
  );
}
