import { useTranslation } from "react-i18next";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { KPIBetaCard } from "~/pages/projects/_cmp/kpi-beta-card";
import { ContributionMarginCard, ContributionRatioCard } from "~/pages/projects/_kpi-cards";
import { KpiProductDataFormState } from "~/pages/products/_cmp/use-kpi-product-bundle-formstate";

export function KPICardsSection({ kpiFormData }: { kpiFormData: KpiProductDataFormState }) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-4">
      <KPIBetaCard
        label={t("finance:cost_price")}
        current={{
          label: t("common:work_hour_usage"),
          value: kpiFormData.getValue("hoursCostPrice"),
          className: "bg-gray-300 text-black",
        }}
        estimated={{
          label: t("finance:cost_price"),
          value: kpiFormData.getValue("totalCostPrice"),
        }}
        remainder={{
          label: t("common:product_usage"),
          className: "bg-green-600 text-white",
        }}
        unit="currency"
        Icon={CurrencyDollarIcon}
        barClasses={{ primary: "bg-gray-300", remainder: "bg-green-600" }}
        containerClass="w-72"
      />
      <KPIBetaCard
        label={t("finance:total")}
        current={{
          label: t("finance:cost_price"),
          value: kpiFormData.getValue("totalCostPrice"),
          className: "bg-gray-300 text-black",
        }}
        estimated={{
          label: t("finance:sales_price"),
          value: kpiFormData.getValue("totalSalesPrice"),
        }}
        remainder={{
          label: t("finance:contribution"),
          className: "bg-green-600 text-white",
        }}
        unit="currency"
        Icon={CurrencyDollarIcon}
        barClasses={{ primary: "bg-gray-300", remainder: "bg-green-600" }}
        containerClass="w-72"
      />
      <ContributionMarginCard
        label={t("finance:contribution_margin_per_hour")}
        paddingXClassname=""
        contributionMargin={kpiFormData.getValue("workingHoursMargin")}
      />
      <ContributionRatioCard
        label={t("common:contribution_ratio")}
        paddingXClassname=""
        contributionRatio={kpiFormData.getValue("profitMargin")}
      />
    </div>
  );
}
