import PriceFileTable from "~/pages/procurement/index/_cmp/price-file-table";
import { useOutletContext } from "react-router";
import { DateRange } from "~/lib/utils/date/date-utils.types";

export default function PriceFiles() {
  const { dateRange } = useOutletContext<{ dateRange: Required<DateRange> }>();

  return (
    <>
      <PriceFileTable dateFrom={dateRange.from} dateTo={dateRange.to} />
    </>
  );
}
