import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | undefined | null;
export type InputMaybe<T> = T | undefined | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: Date; output: Date; }
  LocalDateString: { input: Date; output: Date; }
};

export type ApactaVendor = Entity & {
  created: Scalars['DateTime']['output'];
  cvr?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isCustom: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export const Companies_Vendor_List_Sort_Fields = {
  Id: 'ID',
  VendorName: 'VENDOR_NAME'
} as const;

export type Companies_Vendor_List_Sort_Fields = typeof Companies_Vendor_List_Sort_Fields[keyof typeof Companies_Vendor_List_Sort_Fields];
export type City = Entity & {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type CompaniesVendor = Entity & {
  companyId?: Maybe<Scalars['ID']['output']>;
  created: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  modified: Scalars['DateTime']['output'];
  modifiedById?: Maybe<Scalars['ID']['output']>;
  receiveAutomaticPriceFiles?: Maybe<Scalars['Boolean']['output']>;
  receiveCopyInvoice?: Maybe<Scalars['Boolean']['output']>;
  receiveInvoice?: Maybe<Scalars['Boolean']['output']>;
  receiveInvoiceMails?: Maybe<Scalars['Boolean']['output']>;
  useCompanyProducts?: Maybe<Scalars['Boolean']['output']>;
  usePriceFiles?: Maybe<Scalars['Boolean']['output']>;
  vendor: ApactaVendor;
  vendorAccountReference?: Maybe<Scalars['String']['output']>;
  vendorDepartmentId?: Maybe<Scalars['ID']['output']>;
  vendorEmail?: Maybe<Scalars['String']['output']>;
  vendorId: Scalars['ID']['output'];
  /** This is the company's name for the vendor (the associated 'Vendor' should be the official name from cvr.dk) */
  vendorName?: Maybe<Scalars['String']['output']>;
  vendorPhone?: Maybe<Scalars['String']['output']>;
  vendorPhoneCountrycode?: Maybe<Scalars['String']['output']>;
};

export type CompaniesVendorConnection = {
  edges: Array<CompaniesVendorEdge>;
  pageInfo: PageInfo;
};

export type CompaniesVendorEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: CompaniesVendor;
};

export type CompaniesVendorListParams = {
  direction?: InputMaybe<Pagination_Direction | `${Pagination_Direction}`>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Companies_Vendor_List_Sort_Fields | `${Companies_Vendor_List_Sort_Fields}`>;
  withExpensesWithinDateInterval?: InputMaybe<DateInterval>;
};

export type Company = {
  acceptedGdpr?: Maybe<Scalars['Boolean']['output']>;
  acceptedProcurement?: Maybe<Scalars['Boolean']['output']>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['ID']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactPersonId?: Maybe<Scalars['ID']['output']>;
  countryId?: Maybe<Scalars['ID']['output']>;
  created: Scalars['DateTime']['output'];
  createdById: Scalars['ID']['output'];
  cvr?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  dropboxAccessToken?: Maybe<Scalars['String']['output']>;
  dropboxFolder?: Maybe<Scalars['String']['output']>;
  dropboxRefreshToken?: Maybe<Scalars['String']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  invoiceEmail?: Maybe<Scalars['String']['output']>;
  isCustomer?: Maybe<Scalars['Boolean']['output']>;
  languageId?: Maybe<Scalars['ID']['output']>;
  logoUrl?: Maybe<ImageUrls>;
  modified: Scalars['DateTime']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nextInvoiceNumber?: Maybe<Scalars['Int']['output']>;
  nextOfferNumber?: Maybe<Scalars['Int']['output']>;
  nextProjectNumber?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneCountrycode?: Maybe<Scalars['String']['output']>;
  receiveFormMails?: Maybe<Scalars['String']['output']>;
  statusTypeId?: Maybe<Scalars['ID']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  vatPercent?: Maybe<Scalars['Int']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type Contact = Entity & {
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<City>;
  cityId: Scalars['ID']['output'];
  cityName?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['ID']['output']>;
  contactPersons?: Maybe<Array<ContactPerson>>;
  country?: Maybe<Country>;
  countryId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  createdById: Scalars['ID']['output'];
  cvr?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  erpDaysOfCredit?: Maybe<Scalars['Int']['output']>;
  erpId?: Maybe<Scalars['String']['output']>;
  erpPaymentTermId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  phoneCountrycode?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type ContactConnection = {
  edges: Array<ContactEdge>;
  pageInfo: PageInfo;
};

export type ContactEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: Contact;
};

export type ContactListParams = {
  direction?: InputMaybe<Pagination_Direction | `${Pagination_Direction}`>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Project_List_Sort_Fields | `${Project_List_Sort_Fields}`>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPerson = Entity & {
  contactId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  createdById: Scalars['ID']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Country = Entity & {
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CreateProcurementAnalysisInput = {
  companiesVendorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateInterval?: InputMaybe<DateInterval>;
};

export type DateInterval = {
  from: Scalars['LocalDateString']['input'];
  to: Scalars['LocalDateString']['input'];
};

export type Entity = {
  id: Scalars['ID']['output'];
};

export type ErrorReports = {
  open: Scalars['Int']['output'];
  solved: Scalars['Int']['output'];
};

export type ErrorTypes = {
  priceIsWrong: Scalars['Int']['output'];
  productIsUnknown: Scalars['Int']['output'];
};

export type Expense = {
  activityId?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  companyId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deliveryDate?: Maybe<Scalars['LocalDateString']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['LocalDateString']['output']>;
  erpId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isImported?: Maybe<Scalars['Boolean']['output']>;
  issuedDate?: Maybe<Scalars['LocalDateString']['output']>;
  modified: Scalars['DateTime']['output'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  rogerId?: Maybe<Scalars['String']['output']>;
  sentToEmail?: Maybe<Scalars['Boolean']['output']>;
  shortText?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supplierInvoiceNumber?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<ApactaVendor>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export const Form_List_Sort_Fields = {
  FormDate: 'formDate',
  Id: 'id'
} as const;

export type Form_List_Sort_Fields = typeof Form_List_Sort_Fields[keyof typeof Form_List_Sort_Fields];
export type Form = Entity & {
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Project>;
  projectId: Scalars['ID']['output'];
};

export type FormConnection = {
  edges: Array<FormEdge>;
  pageInfo: PageInfo;
};

export type FormEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: Form;
};

export type FormsListParams = {
  direction?: InputMaybe<Pagination_Direction | `${Pagination_Direction}`>;
  formDate?: InputMaybe<Scalars['LocalDateString']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Form_List_Sort_Fields | `${Form_List_Sort_Fields}`>;
};

export type ImageUrls = {
  large?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  original?: Maybe<Scalars['String']['output']>;
  small?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type Invoice = Entity & {
  combineProductLines: Scalars['Boolean']['output'];
  combineWorkingTimeLines: Scalars['Boolean']['output'];
  companyId: Scalars['ID']['output'];
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['ID']['output']>;
  contributionMargin: Scalars['Float']['output'];
  contributionMarginPerHour: Scalars['Float']['output'];
  contributionRate: Scalars['Float']['output'];
  costPriceTotal: Scalars['Float']['output'];
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['ID']['output'];
  currencyId?: Maybe<Scalars['ID']['output']>;
  dateFrom?: Maybe<Scalars['LocalDateString']['output']>;
  dateTo?: Maybe<Scalars['LocalDateString']['output']>;
  daysOfCredit?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  discountPercent: Scalars['Float']['output'];
  erpId?: Maybe<Scalars['String']['output']>;
  erpPaymentTermId?: Maybe<Scalars['String']['output']>;
  euCustomer: Scalars['Boolean']['output'];
  grossPayment: Scalars['Float']['output'];
  grossSalesPriceTotal: Scalars['Float']['output'];
  groupByForms: Scalars['Boolean']['output'];
  hasProjectPdfAttached: Scalars['Boolean']['output'];
  hourUsageAmount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  includeInvoicedForms: Scalars['Boolean']['output'];
  includingVat?: Maybe<Scalars['Boolean']['output']>;
  integrationId?: Maybe<Scalars['ID']['output']>;
  integrationImageUrl?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['Int']['output']>;
  isBooked: Scalars['Boolean']['output'];
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  isFinalInvoice: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  isManuallyInvoiced: Scalars['Boolean']['output'];
  isOffer: Scalars['Boolean']['output'];
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  issuedDate?: Maybe<Scalars['LocalDateString']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  netPayment: Scalars['Float']['output'];
  netSalesPriceTotal: Scalars['Float']['output'];
  offerNumber?: Maybe<Scalars['Int']['output']>;
  paidDate?: Maybe<Scalars['DateTime']['output']>;
  paymentDueDate?: Maybe<Scalars['LocalDateString']['output']>;
  paymentTermId?: Maybe<Scalars['ID']['output']>;
  pdfUrl: Scalars['String']['output'];
  prettyDates?: Maybe<Scalars['String']['output']>;
  productUsageAmount?: Maybe<Scalars['Float']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']['output']>;
  projectOverviewAttached: Scalars['Boolean']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['ID']['output']>;
  showEmployeeName: Scalars['Boolean']['output'];
  showPaymentTerm: Scalars['Boolean']['output'];
  showPrices: Scalars['Boolean']['output'];
  showProductImages: Scalars['Boolean']['output'];
  showProductsProductBundle: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
  totalCostPrice: Scalars['Float']['output'];
  totalDiscountPercent: Scalars['Float']['output'];
  vatPercent?: Maybe<Scalars['Int']['output']>;
};

export type MarketPrices = {
  avg?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type Mutation = {
  _undefined?: Maybe<Scalars['Boolean']['output']>;
  login: Scalars['String']['output'];
  procurementAnalysisCreate: ProcurementAnalysis;
  procurementAnalysisDelete: ProcurementAnalysis;
  procurementAnalysisRestartProcessing: ProcurementAnalysis;
  procurementAnalysisRestartProcessingSync: ProcurementAnalysis;
  projectCreate: Project;
  projectDelete?: Maybe<Scalars['Boolean']['output']>;
  projectUpdate: Project;
  updateOrderComplaint: OrderComplaint;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationProcurementAnalysisCreateArgs = {
  input: CreateProcurementAnalysisInput;
};


export type MutationProcurementAnalysisDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProcurementAnalysisRestartProcessingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProcurementAnalysisRestartProcessingSyncArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProjectCreateArgs = {
  params: ProjectCreateDto;
};


export type MutationProjectDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProjectUpdateArgs = {
  id: Scalars['ID']['input'];
  params: ProjectUpdateDto;
};


export type MutationUpdateOrderComplaintArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOrderComplaintInput;
};

export type NotImplemented = {
  id: Scalars['ID']['output'];
};

export const Order_Complaint_Error_Type = {
  MultipleErrors: 'multiple_errors',
  PriceIsWrong: 'price_is_wrong',
  ProductIsUnknown: 'product_is_unknown'
} as const;

export type Order_Complaint_Error_Type = typeof Order_Complaint_Error_Type[keyof typeof Order_Complaint_Error_Type];
export const Order_Complaint_List_Sort_Fields = {
  Created: 'CREATED',
  DeliveryDate: 'DELIVERY_DATE',
  Id: 'ID'
} as const;

export type Order_Complaint_List_Sort_Fields = typeof Order_Complaint_List_Sort_Fields[keyof typeof Order_Complaint_List_Sort_Fields];
export const Order_Complaint_Status = {
  Ignored: 'ignored',
  Open: 'open',
  Pending: 'pending',
  Solved: 'solved'
} as const;

export type Order_Complaint_Status = typeof Order_Complaint_Status[keyof typeof Order_Complaint_Status];
export type OrderComplaint = Entity & {
  created: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  errorType?: Maybe<Order_Complaint_Error_Type | `${Order_Complaint_Error_Type}`>;
  expense?: Maybe<Expense>;
  expenseId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  modifiedById?: Maybe<Scalars['ID']['output']>;
  pdfUrl: Scalars['String']['output'];
  status: Order_Complaint_Status | `${Order_Complaint_Status}`;
  unknownProductErrors?: Maybe<Array<Maybe<UnknownProductError>>>;
  vendor?: Maybe<ApactaVendor>;
  wrongPriceErrors?: Maybe<Array<Maybe<WrongPriceError>>>;
};

export type OrderComplaintConnection = {
  edges: Array<OrderComplaintEdge>;
  pageInfo: PageInfo;
};

export type OrderComplaintEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: OrderComplaint;
};

export type OrderComplaintListParams = {
  companiesVendorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateInterval?: InputMaybe<DateInterval>;
  direction?: InputMaybe<Pagination_Direction | `${Pagination_Direction}`>;
  errorTypes?: InputMaybe<Array<Order_Complaint_Error_Type | `${Order_Complaint_Error_Type}`>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sortBy?: InputMaybe<Order_Complaint_List_Sort_Fields | `${Order_Complaint_List_Sort_Fields}`>;
  statuses?: InputMaybe<Array<Order_Complaint_Status | `${Order_Complaint_Status}`>>;
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type OrderComplaintsKpiCards = {
  averagePriceDifference: Scalars['Float']['output'];
  errorReports: ErrorReports;
  errorTypes: ErrorTypes;
  priceDifference: Scalars['Float']['output'];
};

export type OrderComplaintsKpiCardsParams = {
  dateInterval?: InputMaybe<DateInterval>;
  errorTypes?: InputMaybe<Array<Order_Complaint_Error_Type | `${Order_Complaint_Error_Type}`>>;
  projectIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses?: InputMaybe<Array<Order_Complaint_Status | `${Order_Complaint_Status}`>>;
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export const Pagination_Direction = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type Pagination_Direction = typeof Pagination_Direction[keyof typeof Pagination_Direction];
export const Procurement_Analysis_Line_List_Sort_Fields = {
  CountBought: 'COUNT_BOUGHT',
  CountOfExpenses: 'COUNT_OF_EXPENSES',
  Name: 'NAME',
  OptimizationPotential: 'OPTIMIZATION_POTENTIAL',
  TotalAmountPaid: 'TOTAL_AMOUNT_PAID'
} as const;

export type Procurement_Analysis_Line_List_Sort_Fields = typeof Procurement_Analysis_Line_List_Sort_Fields[keyof typeof Procurement_Analysis_Line_List_Sort_Fields];
export const Procurement_Analysis_List_Sort_Fields = {
  Created: 'CREATED',
  Id: 'ID'
} as const;

export type Procurement_Analysis_List_Sort_Fields = typeof Procurement_Analysis_List_Sort_Fields[keyof typeof Procurement_Analysis_List_Sort_Fields];
export const Procurement_Analysis_Status = {
  Completed: 'completed',
  Failed: 'failed',
  Pending: 'pending',
  Processing: 'processing'
} as const;

export type Procurement_Analysis_Status = typeof Procurement_Analysis_Status[keyof typeof Procurement_Analysis_Status];
export const Project_List_Sort_Fields = {
  Created: 'CREATED',
  Id: 'ID',
  Name: 'NAME',
  ProjectNumber: 'PROJECT_NUMBER'
} as const;

export type Project_List_Sort_Fields = typeof Project_List_Sort_Fields[keyof typeof Project_List_Sort_Fields];
export type PageInfo = {
  currentPage: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPrevPage: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
};

export type PaginationEdge = {
  cursor: Scalars['String']['output'];
  node: Entity;
};

export type ProcurementAnalysis = Entity & {
  companiesVendors: Array<CompaniesVendor>;
  companyId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  dateEnd: Scalars['LocalDateString']['output'];
  dateStart: Scalars['LocalDateString']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  logs?: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  modifiedById?: Maybe<Scalars['ID']['output']>;
  procurementAnalysisLines: Array<ProcurementAnalysisLine>;
  progress: Scalars['Int']['output'];
  status: Procurement_Analysis_Status | `${Procurement_Analysis_Status}`;
  /** This is calculated runtime, so use with caution if you want fast response times */
  summedPotential: Scalars['Float']['output'];
};


export type ProcurementAnalysisProcurementAnalysisLinesArgs = {
  params?: InputMaybe<ProcurementAnalysisLineListParams>;
};

export type ProcurementAnalysisConnection = {
  edges: Array<ProcurementAnalysisEdge>;
  pageInfo: PageInfo;
};

export type ProcurementAnalysisEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: ProcurementAnalysis;
};

export type ProcurementAnalysisLine = Entity & {
  averageMarketPrice?: Maybe<Scalars['Float']['output']>;
  companiesVendor?: Maybe<CompaniesVendor>;
  companiesVendorId?: Maybe<Scalars['ID']['output']>;
  countBought: Scalars['Float']['output'];
  countOfExpenses: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  diffBetweenAverageAndLastPrice: Scalars['Float']['output'];
  expenseLineKeyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastPricePaid: Scalars['Float']['output'];
  minimumMarketPrice?: Maybe<Scalars['Float']['output']>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  optimizationPotential: Scalars['Float']['output'];
  procurementAnalysisId: Scalars['ID']['output'];
  productImageUrls?: Maybe<ResizedImageUrls>;
  totalAmountPaid: Scalars['Float']['output'];
  vendor?: Maybe<ApactaVendor>;
  vendorId?: Maybe<Scalars['ID']['output']>;
  vendorProductId?: Maybe<Scalars['ID']['output']>;
};

export type ProcurementAnalysisLineConnection = {
  edges: Array<ProcurementAnalysisLineEdge>;
  pageInfo: PageInfo;
};

export type ProcurementAnalysisLineEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: ProcurementAnalysisLine;
};

export type ProcurementAnalysisLineListParams = {
  direction?: InputMaybe<Pagination_Direction | `${Pagination_Direction}`>;
  optimizationPotentialGte?: InputMaybe<Scalars['Float']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  showOnlyWithPotential?: InputMaybe<Scalars['Boolean']['input']>;
  showOnlyWithoutPotential?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<Procurement_Analysis_Line_List_Sort_Fields | `${Procurement_Analysis_Line_List_Sort_Fields}`>;
};

export type ProcurementAnalysisListParams = {
  companiesVendorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateInterval?: InputMaybe<DateInterval>;
  direction?: InputMaybe<Pagination_Direction | `${Pagination_Direction}`>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Procurement_Analysis_List_Sort_Fields | `${Procurement_Analysis_List_Sort_Fields}`>;
};

export type ProcurementKpiCards = {
  averageTotalPricePerExpense: Scalars['Float']['output'];
  countOfProductsBought: Scalars['Int']['output'];
  priceUpdatesCount: Scalars['Int']['output'];
  totalMoneySpent: Scalars['Float']['output'];
};

export type ProcurementKpiCardsParams = {
  companiesVendorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateInterval?: InputMaybe<DateInterval>;
};

export type Project = Entity & {
  city?: Maybe<City>;
  cityName?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['ID']['output'];
  /** Is heavy calculation - consider using only this when selecting single project */
  completionPercentage?: Maybe<Scalars['Float']['output']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['ID']['output']>;
  contactPersonId?: Maybe<Scalars['ID']['output']>;
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  firstActivityDate?: Maybe<Scalars['LocalDateString']['output']>;
  forms: FormConnection;
  fullName: Scalars['String']['output'];
  hasFinalInvoice: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoiceDiscountPercent?: Maybe<Scalars['Float']['output']>;
  isFixedPrice: Scalars['Boolean']['output'];
  isOffer: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  modifiedById?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  notInvoicedAmount: Scalars['Float']['output'];
  offerId?: Maybe<Scalars['ID']['output']>;
  productsTotalCostPrice: Scalars['Float']['output'];
  projectFiles: ProjectFileConnection;
  projectImageUrl?: Maybe<Scalars['String']['output']>;
  projectNumber?: Maybe<Scalars['Int']['output']>;
  projectStatus: ProjectStatus;
  projectStatusId: Scalars['ID']['output'];
  project_type?: Maybe<ProjectTypeEnum | `${ProjectTypeEnum}`>;
  streetName?: Maybe<Scalars['String']['output']>;
  totalInvoicedAmount?: Maybe<Scalars['Float']['output']>;
  totalSalesPrice: Scalars['Float']['output'];
  workingHoursTotalCostPrice: Scalars['Float']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type ProjectFormsArgs = {
  params?: InputMaybe<FormsListParams>;
};


export type ProjectProjectFilesArgs = {
  params?: InputMaybe<ProjectFilesListParams>;
};

export type ProjectConnection = {
  edges: Array<ProjectEdge>;
  pageInfo: PageInfo;
};

export type ProjectCreateDto = {
  name: Scalars['String']['input'];
};

export type ProjectEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: Project;
};

export type ProjectFile = Entity & {
  created: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  fileOriginalName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
};

export type ProjectFileConnection = {
  edges: Array<ProjectFileEdge>;
  pageInfo: PageInfo;
};

export type ProjectFileEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: ProjectFile;
};

export type ProjectFilesListParams = {
  direction?: InputMaybe<Pagination_Direction | `${Pagination_Direction}`>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  sortBy?: InputMaybe<Project_List_Sort_Fields | `${Project_List_Sort_Fields}`>;
};

export type ProjectListParams = {
  direction?: InputMaybe<Pagination_Direction | `${Pagination_Direction}`>;
  employeeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectNumber?: InputMaybe<Scalars['Int']['input']>;
  projectStatus?: InputMaybe<Array<ProjectStatusEnum | `${ProjectStatusEnum}`>>;
  q?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Project_List_Sort_Fields | `${Project_List_Sort_Fields}`>;
};

export type ProjectStatus = Entity & {
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  modified: Scalars['DateTime']['output'];
  name?: Maybe<Scalars['String']['output']>;
  projectStatusType: ProjectStatusType;
  projectStatusTypeId: Scalars['ID']['output'];
};

export const ProjectStatusEnum = {
  Closed: 'closed',
  Open: 'open',
  ReadyForBilling: 'ready_for_billing'
} as const;

export type ProjectStatusEnum = typeof ProjectStatusEnum[keyof typeof ProjectStatusEnum];
export type ProjectStatusType = Entity & {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export const ProjectTypeEnum = {
  Estimate: 'estimate',
  FixedPrice: 'fixed_price',
  Hourly: 'hourly',
  Offer: 'offer'
} as const;

export type ProjectTypeEnum = typeof ProjectTypeEnum[keyof typeof ProjectTypeEnum];
export type ProjectUpdateDto = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  _undefined?: Maybe<Scalars['Boolean']['output']>;
  companiesVendor: CompaniesVendor;
  companiesVendors: CompaniesVendorConnection;
  contact: Contact;
  contacts: ContactConnection;
  invoice: Invoice;
  me: User;
  orderComplaint: OrderComplaint;
  orderComplaints: OrderComplaintConnection;
  orderComplaintsKpiCards: OrderComplaintsKpiCards;
  procurementAnalyses: ProcurementAnalysisConnection;
  procurementAnalysis: ProcurementAnalysis;
  procurementKpiCards: ProcurementKpiCards;
  project: Project;
  projectFile: ProjectFile;
  projectFiles: ProjectFileConnection;
  projects: ProjectConnection;
  vendorProduct: VendorProduct;
  vendorProductPriceFile: VendorProductPriceFile;
  vendorProductPriceFileKpiCardData: VendorProductPriceFileKpiCardData;
  vendorProductPriceFileLines: VendorProductPriceFileLineConnection;
  vendorProductPriceFiles: VendorProductPriceFileConnection;
};


export type QueryCompaniesVendorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompaniesVendorsArgs = {
  params?: InputMaybe<CompaniesVendorListParams>;
};


export type QueryContactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactsArgs = {
  params?: InputMaybe<ContactListParams>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderComplaintArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderComplaintsArgs = {
  params?: InputMaybe<OrderComplaintListParams>;
};


export type QueryOrderComplaintsKpiCardsArgs = {
  params?: InputMaybe<OrderComplaintListParams>;
};


export type QueryProcurementAnalysesArgs = {
  params?: InputMaybe<ProcurementAnalysisListParams>;
};


export type QueryProcurementAnalysisArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProcurementKpiCardsArgs = {
  params?: InputMaybe<ProcurementKpiCardsParams>;
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectFilesArgs = {
  params?: InputMaybe<ProjectFilesListParams>;
};


export type QueryProjectsArgs = {
  params?: InputMaybe<ProjectListParams>;
};


export type QueryVendorProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVendorProductPriceFileArgs = {
  params: VendorProductPriceFileViewParams;
};


export type QueryVendorProductPriceFileKpiCardDataArgs = {
  params: VendorProductPriceFileKpiCardDataParams;
};


export type QueryVendorProductPriceFileLinesArgs = {
  params?: InputMaybe<VendorProductPriceFileLineListParams>;
};


export type QueryVendorProductPriceFilesArgs = {
  params?: InputMaybe<VendorProductPriceFileListParams>;
};

export type ResizedImageUrls = {
  large: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  original: Scalars['String']['output'];
  small: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
};

export type UnknownProductError = {
  id?: Maybe<Scalars['ID']['output']>;
  paidPrice: Scalars['Float']['output'];
  productName: Scalars['String']['output'];
  productNumber: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
};

export type UpdateOrderComplaintInput = {
  status: Order_Complaint_Status | `${Order_Complaint_Status}`;
};

export type User = {
  companyId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
};

export const Vendor_Product_Price_File_Line_List_Sort_Fields = {
  Consequence: 'CONSEQUENCE',
  Id: 'ID',
  PriceChange: 'PRICE_CHANGE',
  QuantityBought: 'QUANTITY_BOUGHT'
} as const;

export type Vendor_Product_Price_File_Line_List_Sort_Fields = typeof Vendor_Product_Price_File_Line_List_Sort_Fields[keyof typeof Vendor_Product_Price_File_Line_List_Sort_Fields];
export const Vendor_Product_Price_File_List_Sort_Fields = {
  Created: 'CREATED'
} as const;

export type Vendor_Product_Price_File_List_Sort_Fields = typeof Vendor_Product_Price_File_List_Sort_Fields[keyof typeof Vendor_Product_Price_File_List_Sort_Fields];
export type VendorProduct = {
  barcode?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  eanProductId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  imageUrls?: Maybe<ResizedImageUrls>;
  marketPrices?: Maybe<MarketPrices>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  productCategoryNumber?: Maybe<Scalars['String']['output']>;
  productNumber?: Maybe<Scalars['String']['output']>;
  productNumberWithoutFirstFour?: Maybe<Scalars['String']['output']>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export type VendorProductPriceFile = Entity & {
  companiesVendor: CompaniesVendor;
  companiesVendorId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  dir?: Maybe<Scalars['String']['output']>;
  downloadUrl: Scalars['String']['output'];
  file: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  originalFileName?: Maybe<Scalars['String']['output']>;
  /** 5 Past price files from that same vendor */
  pastPriceFiles: VendorProductPriceFileConnection;
  previousPriceFile?: Maybe<VendorProductPriceFile>;
  progress?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  vendorProductPriceFileLines: Array<VendorProductPriceFileLine>;
  vendorProductsCount: Scalars['Int']['output'];
  vendorProductsCountTotal: Scalars['Int']['output'];
};

export type VendorProductPriceFileConnection = {
  edges: Array<VendorProductPriceFileEdge>;
  pageInfo: PageInfo;
};

export type VendorProductPriceFileEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: VendorProductPriceFile;
};

export type VendorProductPriceFileKpiCardData = {
  globalPriceDevelopment: Scalars['Float']['output'];
  priceDecreasesCount: Scalars['Int']['output'];
  priceIncreasesCount: Scalars['Int']['output'];
  yourPriceDevelopment: Scalars['Float']['output'];
};

export type VendorProductPriceFileKpiCardDataParams = {
  dateInterval?: InputMaybe<DateInterval>;
  vendorProductPriceFileId: Scalars['ID']['input'];
};

export type VendorProductPriceFileLine = Entity & {
  consequence: Scalars['Float']['output'];
  currentPrice: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  previousPrice: Scalars['Float']['output'];
  priceChange: Scalars['Float']['output'];
  priceChangePercentage: Scalars['Float']['output'];
  quantityBought: Scalars['Float']['output'];
  vendorProduct: VendorProduct;
  vendorProductId: Scalars['ID']['output'];
  vendorProductPriceFileId: Scalars['ID']['output'];
};

export type VendorProductPriceFileLineConnection = {
  edges: Array<VendorProductPriceFileLineEdge>;
  pageInfo: PageInfo;
};

export type VendorProductPriceFileLineEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: VendorProductPriceFileLine;
};

export type VendorProductPriceFileLineListParams = {
  direction?: InputMaybe<Pagination_Direction | `${Pagination_Direction}`>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Vendor_Product_Price_File_Line_List_Sort_Fields | `${Vendor_Product_Price_File_Line_List_Sort_Fields}`>;
  vendorProductPriceFileId: Scalars['ID']['input'];
};

export type VendorProductPriceFileListParams = {
  dateInterval?: InputMaybe<DateInterval>;
  direction?: InputMaybe<Pagination_Direction | `${Pagination_Direction}`>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyNonCustomVendors?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Vendor_Product_Price_File_List_Sort_Fields | `${Vendor_Product_Price_File_List_Sort_Fields}`>;
  vendorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type VendorProductPriceFileViewParams = {
  dateInterval?: InputMaybe<DateInterval>;
  id: Scalars['ID']['input'];
};

export type WrongPriceError = {
  id?: Maybe<Scalars['ID']['output']>;
  paidPrice: Scalars['Float']['output'];
  priceDifference: Scalars['Float']['output'];
  priceFilePrice: Scalars['Float']['output'];
  productName: Scalars['String']['output'];
  productNumber: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
};

export type ProjectSelectionListQueryVariables = Exact<{
  params?: InputMaybe<ProjectListParams>;
}>;


export type ProjectSelectionListQuery = { projects: { edges: Array<{ node: { id: string, projectNumber?: number | undefined | null, fullName: string, name: string } }>, pageInfo: { limit: number, hasNextPage: boolean, currentPage: number } } };

export type CreateFixedInvoiceProjectDetailsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type CreateFixedInvoiceProjectDetailsQuery = { project: { id: string, totalInvoicedAmount?: number | undefined | null, completionPercentage?: number | undefined | null, notInvoicedAmount: number, totalSalesPrice: number } };

export type InvoiceKpiDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InvoiceKpiDataQuery = { invoice: { id: string, contributionRate: number, contributionMarginPerHour: number, costPriceTotal: number, grossSalesPriceTotal: number, hourUsageAmount?: number | undefined | null, productUsageAmount?: number | undefined | null } };

export type ListProjectsTableQueryVariables = Exact<{
  params: ProjectListParams;
}>;


export type ListProjectsTableQuery = { projects: { pageInfo: { limit: number, hasNextPage: boolean, hasPrevPage: boolean, currentPage: number }, edges: Array<{ node: { id: string, name: string, created: Date } }> } };

export type ListProjectsTestQueryVariables = Exact<{
  params: ProjectListParams;
}>;


export type ListProjectsTestQuery = { projects: { pageInfo: { limit: number, hasNextPage: boolean, hasPrevPage: boolean, currentPage: number }, edges: Array<{ node: { id: string, name: string, created: Date } }> } };

export type ListProjectsQueryVariables = Exact<{
  params: ProjectListParams;
}>;


export type ListProjectsQuery = { projects: { pageInfo: { limit: number, hasNextPage: boolean, hasPrevPage: boolean, currentPage: number }, edges: Array<{ node: { id: string, name: string, created: Date } }> } };

export type EditProjectNameMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type EditProjectNameMutation = { projectUpdate: { id: string, projectNumber?: number | undefined | null } };

export type ProcurementTableRowFragment = { id: string, created: Date, dateStart: Date, dateEnd: Date, status: Procurement_Analysis_Status, progress: number, companiesVendors: Array<{ id: string, vendorName?: string | undefined | null, vendor: { name: string } }> };

export type ProcurementTableQueryVariables = Exact<{
  analysisParams?: InputMaybe<ProcurementAnalysisListParams>;
}>;


export type ProcurementTableQuery = { procurementAnalyses: { pageInfo: { hasPrevPage: boolean, hasNextPage: boolean, limit: number, currentPage: number }, edges: Array<{ node: { id: string, created: Date, dateStart: Date, dateEnd: Date, status: Procurement_Analysis_Status, progress: number, companiesVendors: Array<{ id: string, vendorName?: string | undefined | null, vendor: { name: string } }> } }> } };

export type OrderComplaintsDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OrderComplaintsDetailQuery = { orderComplaint: { id: string, status: Order_Complaint_Status, pdfUrl: string, expense?: { id: string, supplierInvoiceNumber?: string | undefined | null, company?: { id: string, name?: string | undefined | null, streetName?: string | undefined | null, cvr?: string | undefined | null, phone?: string | undefined | null, phoneCountrycode?: string | undefined | null, contactEmail?: string | undefined | null, city?: { name: string, zipCode?: string | undefined | null } | undefined | null, logoUrl?: { small?: string | undefined | null } | undefined | null } | undefined | null, vendor?: { id: string, cvr?: string | undefined | null, name: string, email?: string | undefined | null, imageUrl?: string | undefined | null } | undefined | null } | undefined | null, wrongPriceErrors?: Array<{ id?: string | undefined | null, productNumber: string, productName: string, quantity: number, paidPrice: number, priceFilePrice: number, priceDifference: number } | undefined | null> | undefined | null, unknownProductErrors?: Array<{ id?: string | undefined | null, productNumber: string, productName: string, quantity: number, paidPrice: number } | undefined | null> | undefined | null } };

export type OrderComplaintsIndexQueryVariables = Exact<{
  params: OrderComplaintListParams;
}>;


export type OrderComplaintsIndexQuery = { orderComplaints: { edges: Array<{ node: { id: string, errorType?: Order_Complaint_Error_Type | undefined | null, status: Order_Complaint_Status, pdfUrl: string, expense?: { id: string, issuedDate?: Date | undefined | null, supplierInvoiceNumber?: string | undefined | null, vendor?: { id: string, name: string, imageUrl?: string | undefined | null } | undefined | null, project?: { id: string, fullName: string } | undefined | null } | undefined | null } }> } };

export type OrderComplaintsUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateOrderComplaintInput;
}>;


export type OrderComplaintsUpdateMutation = { updateOrderComplaint: { id: string, status: Order_Complaint_Status } };

export type PriceFileDetailKpiCardsQueryVariables = Exact<{
  params: VendorProductPriceFileKpiCardDataParams;
}>;


export type PriceFileDetailKpiCardsQuery = { vendorProductPriceFileKpiCardData: { yourPriceDevelopment: number, globalPriceDevelopment: number, priceDecreasesCount: number, priceIncreasesCount: number } };

export type PriceFileDetailLinesQueryVariables = Exact<{
  params: VendorProductPriceFileLineListParams;
}>;


export type PriceFileDetailLinesQuery = { vendorProductPriceFileLines: { edges: Array<{ node: { id: string, consequence: number, currentPrice: number, previousPrice: number, priceChange: number, priceChangePercentage: number, quantityBought: number, vendorProductId: string, vendorProductPriceFileId: string, vendorProduct: { id: string, name: string, productNumber?: string | undefined | null, imageUrls?: { thumbnail: string, original: string } | undefined | null } } }> } };

export type PriceFileDetailsQueryVariables = Exact<{
  params: VendorProductPriceFileViewParams;
}>;


export type PriceFileDetailsQuery = { vendorProductPriceFile: { id: string, downloadUrl: string, originalFileName?: string | undefined | null, created: Date, companiesVendor: { id: string, vendorName?: string | undefined | null, vendor: { name: string, imageUrl?: string | undefined | null } }, previousPriceFile?: { created: Date } | undefined | null } };

export type PriceFileDetailsPreviousFilesQueryVariables = Exact<{
  params: VendorProductPriceFileViewParams;
}>;


export type PriceFileDetailsPreviousFilesQuery = { vendorProductPriceFile: { id: string, pastPriceFiles: { edges: Array<{ node: { id: string, originalFileName?: string | undefined | null, created: Date } }> } } };

export type PriceFilesIndexQueryVariables = Exact<{
  params?: InputMaybe<VendorProductPriceFileListParams>;
}>;


export type PriceFilesIndexQuery = { vendorProductPriceFiles: { edges: Array<{ node: { id: string, originalFileName?: string | undefined | null, created: Date, companiesVendor: { id: string, vendorName?: string | undefined | null, vendor: { name: string, imageUrl?: string | undefined | null } } } }> } };

export type ProcurementAnalysisLinesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  linesParams?: InputMaybe<ProcurementAnalysisLineListParams>;
}>;


export type ProcurementAnalysisLinesQuery = { procurementAnalysis: { id: string, procurementAnalysisLines: Array<{ id: string, name: string, companiesVendorId?: string | undefined | null, countOfExpenses: number, countBought: number, totalAmountPaid: number, lastPricePaid: number, averageMarketPrice?: number | undefined | null, minimumMarketPrice?: number | undefined | null, diffBetweenAverageAndLastPrice: number, optimizationPotential: number, productImageUrls?: { thumbnail: string, original: string } | undefined | null }> } };

export type ProcurementAnalysisCreateMutationVariables = Exact<{
  input: CreateProcurementAnalysisInput;
}>;


export type ProcurementAnalysisCreateMutation = { procurementAnalysisCreate: { id: string, progress: number } };

export type ProcurementDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProcurementDetailQuery = { procurementAnalysis: { id: string, created: Date, dateStart: Date, dateEnd: Date, status: Procurement_Analysis_Status, progress: number, summedPotential: number, companiesVendors: Array<{ id: string, vendorName?: string | undefined | null, vendor: { name: string, imageUrl?: string | undefined | null } }> } };

export type ProcurementIndexProgressCheckQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProcurementIndexProgressCheckQuery = { procurementAnalysis: { id: string, progress: number, status: Procurement_Analysis_Status } };

export type ProcurementIndexQueryVariables = Exact<{
  kpiParams?: InputMaybe<ProcurementKpiCardsParams>;
  analysisParams?: InputMaybe<ProcurementAnalysisListParams>;
}>;


export type ProcurementIndexQuery = { procurementKpiCards: { priceUpdatesCount: number, totalMoneySpent: number, countOfProductsBought: number, averageTotalPricePerExpense: number }, procurementAnalyses: { pageInfo: { hasPrevPage: boolean, hasNextPage: boolean, limit: number, currentPage: number }, edges: Array<{ node: { id: string, created: Date, dateStart: Date, dateEnd: Date, status: Procurement_Analysis_Status, progress: number, companiesVendors: Array<{ id: string, vendorName?: string | undefined | null, vendor: { name: string } }> } }> } };

export type VendorSelectionQueryVariables = Exact<{
  params?: InputMaybe<CompaniesVendorListParams>;
}>;


export type VendorSelectionQuery = { companiesVendors: { edges: Array<{ node: { id: string, vendorName?: string | undefined | null, vendor: { name: string } } }> } };

export type VendorProductMarketPriceQueryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type VendorProductMarketPriceQueryQuery = { vendorProduct: { id: string, marketPrices?: { avg?: number | undefined | null, min?: number | undefined | null } | undefined | null } };

export const ProcurementTableRowFragmentDoc = gql`
    fragment ProcurementTableRow on ProcurementAnalysis {
  id
  created
  dateStart
  dateEnd
  companiesVendors {
    id
    vendorName
    vendor {
      name
    }
  }
  status
  progress
}
    `;
export const ProjectSelectionListDocument = gql`
    query projectSelectionList($params: ProjectListParams) {
  projects(params: $params) {
    edges {
      node {
        id
        projectNumber
        fullName
        name
      }
    }
    pageInfo {
      limit
      hasNextPage
      currentPage
    }
  }
}
    `;
export const CreateFixedInvoiceProjectDetailsDocument = gql`
    query createFixedInvoiceProjectDetails($projectId: ID!) {
  project(id: $projectId) {
    id
    totalInvoicedAmount
    completionPercentage
    notInvoicedAmount
    totalSalesPrice
  }
}
    `;
export const InvoiceKpiDataDocument = gql`
    query invoiceKPIData($id: ID!) {
  invoice(id: $id) {
    id
    contributionRate
    contributionMarginPerHour
    costPriceTotal
    grossSalesPriceTotal
    hourUsageAmount
    productUsageAmount
  }
}
    `;
export const ListProjectsTableDocument = gql`
    query listProjectsTable($params: ProjectListParams!) {
  projects(params: $params) {
    pageInfo {
      limit
      hasNextPage
      hasPrevPage
      currentPage
    }
    edges {
      node {
        id
        name
        created
      }
    }
  }
}
    `;
export const ListProjectsTestDocument = gql`
    query listProjectsTest($params: ProjectListParams!) {
  projects(params: $params) {
    pageInfo {
      limit
      hasNextPage
      hasPrevPage
      currentPage
    }
    edges {
      node {
        id
        name
        created
      }
    }
  }
}
    `;
export const ListProjectsDocument = gql`
    query listProjects($params: ProjectListParams!) {
  projects(params: $params) {
    pageInfo {
      limit
      hasNextPage
      hasPrevPage
      currentPage
    }
    edges {
      node {
        id
        name
        created
      }
    }
  }
}
    `;
export const EditProjectNameDocument = gql`
    mutation editProjectName($id: ID!, $name: String!) {
  projectUpdate(id: $id, params: {name: $name}) {
    id
    projectNumber
  }
}
    `;
export const ProcurementTableDocument = gql`
    query procurementTable($analysisParams: ProcurementAnalysisListParams) {
  procurementAnalyses(params: $analysisParams) {
    pageInfo {
      hasPrevPage
      hasNextPage
      limit
      currentPage
    }
    edges {
      node {
        ...ProcurementTableRow
      }
    }
  }
}
    ${ProcurementTableRowFragmentDoc}`;
export const OrderComplaintsDetailDocument = gql`
    query orderComplaintsDetail($id: ID!) {
  orderComplaint(id: $id) {
    id
    expense {
      id
      company {
        id
        name
        streetName
        city {
          name
          zipCode
        }
        cvr
        phone
        phoneCountrycode
        contactEmail
        logoUrl {
          small
        }
      }
      supplierInvoiceNumber
      vendor {
        id
        cvr
        name
        email
        imageUrl
      }
    }
    status
    wrongPriceErrors {
      id
      productNumber
      productName
      quantity
      paidPrice
      priceFilePrice
      priceDifference
    }
    unknownProductErrors {
      id
      productNumber
      productName
      quantity
      paidPrice
    }
    pdfUrl
  }
}
    `;
export const OrderComplaintsIndexDocument = gql`
    query orderComplaintsIndex($params: OrderComplaintListParams!) {
  orderComplaints(params: $params) {
    edges {
      node {
        id
        expense {
          id
          issuedDate
          supplierInvoiceNumber
          vendor {
            id
            name
            imageUrl
          }
          project {
            id
            fullName
          }
        }
        errorType
        status
        pdfUrl
      }
    }
  }
}
    `;
export const OrderComplaintsUpdateDocument = gql`
    mutation orderComplaintsUpdate($id: ID!, $input: UpdateOrderComplaintInput!) {
  updateOrderComplaint(id: $id, input: $input) {
    id
    status
  }
}
    `;
export const PriceFileDetailKpiCardsDocument = gql`
    query priceFileDetailKpiCards($params: VendorProductPriceFileKpiCardDataParams!) {
  vendorProductPriceFileKpiCardData(params: $params) {
    yourPriceDevelopment
    globalPriceDevelopment
    priceDecreasesCount
    priceIncreasesCount
  }
}
    `;
export const PriceFileDetailLinesDocument = gql`
    query priceFileDetailLines($params: VendorProductPriceFileLineListParams!) {
  vendorProductPriceFileLines(params: $params) {
    edges {
      node {
        id
        consequence
        currentPrice
        previousPrice
        priceChange
        priceChangePercentage
        quantityBought
        vendorProduct {
          id
          name
          productNumber
          imageUrls {
            thumbnail
            original
          }
        }
        vendorProductId
        vendorProductPriceFileId
      }
    }
  }
}
    `;
export const PriceFileDetailsDocument = gql`
    query priceFileDetails($params: VendorProductPriceFileViewParams!) {
  vendorProductPriceFile(params: $params) {
    id
    downloadUrl
    companiesVendor {
      id
      vendorName
      vendor {
        name
        imageUrl
      }
    }
    originalFileName
    created
    previousPriceFile {
      created
    }
  }
}
    `;
export const PriceFileDetailsPreviousFilesDocument = gql`
    query priceFileDetailsPreviousFiles($params: VendorProductPriceFileViewParams!) {
  vendorProductPriceFile(params: $params) {
    id
    pastPriceFiles {
      edges {
        node {
          id
          originalFileName
          created
        }
      }
    }
  }
}
    `;
export const PriceFilesIndexDocument = gql`
    query priceFilesIndex($params: VendorProductPriceFileListParams) {
  vendorProductPriceFiles(params: $params) {
    edges {
      node {
        id
        companiesVendor {
          id
          vendorName
          vendor {
            name
            imageUrl
          }
        }
        originalFileName
        created
      }
    }
  }
}
    `;
export const ProcurementAnalysisLinesDocument = gql`
    query procurementAnalysisLines($id: ID!, $linesParams: ProcurementAnalysisLineListParams) {
  procurementAnalysis(id: $id) {
    id
    procurementAnalysisLines(params: $linesParams) {
      id
      name
      companiesVendorId
      countOfExpenses
      countBought
      totalAmountPaid
      lastPricePaid
      averageMarketPrice
      minimumMarketPrice
      diffBetweenAverageAndLastPrice
      optimizationPotential
      productImageUrls {
        thumbnail
        original
      }
    }
  }
}
    `;
export const ProcurementAnalysisCreateDocument = gql`
    mutation procurementAnalysisCreate($input: CreateProcurementAnalysisInput!) {
  procurementAnalysisCreate(input: $input) {
    id
    progress
  }
}
    `;
export const ProcurementDetailDocument = gql`
    query procurementDetail($id: ID!) {
  procurementAnalysis(id: $id) {
    id
    created
    dateStart
    dateEnd
    status
    progress
    summedPotential
    companiesVendors {
      id
      vendorName
      vendor {
        name
        imageUrl
      }
    }
  }
}
    `;
export const ProcurementIndexProgressCheckDocument = gql`
    query procurementIndexProgressCheck($id: ID!) {
  procurementAnalysis(id: $id) {
    id
    progress
    status
  }
}
    `;
export const ProcurementIndexDocument = gql`
    query procurementIndex($kpiParams: ProcurementKpiCardsParams, $analysisParams: ProcurementAnalysisListParams) {
  procurementKpiCards(params: $kpiParams) {
    priceUpdatesCount
    totalMoneySpent
    countOfProductsBought
    averageTotalPricePerExpense
  }
  procurementAnalyses(params: $analysisParams) {
    pageInfo {
      hasPrevPage
      hasNextPage
      limit
      currentPage
    }
    edges {
      node {
        id
        created
        dateStart
        dateEnd
        companiesVendors {
          id
          vendorName
          vendor {
            name
          }
        }
        status
        progress
      }
    }
  }
}
    `;
export const VendorSelectionDocument = gql`
    query vendorSelection($params: CompaniesVendorListParams) {
  companiesVendors(params: $params) {
    edges {
      node {
        id
        vendorName
        vendor {
          name
        }
      }
    }
  }
}
    `;
export const VendorProductMarketPriceQueryDocument = gql`
    query vendorProductMarketPriceQuery($id: ID!) {
  vendorProduct(id: $id) {
    id
    marketPrices {
      avg
      min
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    projectSelectionList(variables?: ProjectSelectionListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProjectSelectionListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProjectSelectionListQuery>(ProjectSelectionListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'projectSelectionList', 'query', variables);
    },
    createFixedInvoiceProjectDetails(variables: CreateFixedInvoiceProjectDetailsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateFixedInvoiceProjectDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateFixedInvoiceProjectDetailsQuery>(CreateFixedInvoiceProjectDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createFixedInvoiceProjectDetails', 'query', variables);
    },
    invoiceKPIData(variables: InvoiceKpiDataQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<InvoiceKpiDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<InvoiceKpiDataQuery>(InvoiceKpiDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'invoiceKPIData', 'query', variables);
    },
    listProjectsTable(variables: ListProjectsTableQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ListProjectsTableQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListProjectsTableQuery>(ListProjectsTableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listProjectsTable', 'query', variables);
    },
    listProjectsTest(variables: ListProjectsTestQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ListProjectsTestQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListProjectsTestQuery>(ListProjectsTestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listProjectsTest', 'query', variables);
    },
    listProjects(variables: ListProjectsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ListProjectsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListProjectsQuery>(ListProjectsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listProjects', 'query', variables);
    },
    editProjectName(variables: EditProjectNameMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EditProjectNameMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EditProjectNameMutation>(EditProjectNameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'editProjectName', 'mutation', variables);
    },
    procurementTable(variables?: ProcurementTableQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementTableQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementTableQuery>(ProcurementTableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementTable', 'query', variables);
    },
    orderComplaintsDetail(variables: OrderComplaintsDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrderComplaintsDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrderComplaintsDetailQuery>(OrderComplaintsDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'orderComplaintsDetail', 'query', variables);
    },
    orderComplaintsIndex(variables: OrderComplaintsIndexQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrderComplaintsIndexQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrderComplaintsIndexQuery>(OrderComplaintsIndexDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'orderComplaintsIndex', 'query', variables);
    },
    orderComplaintsUpdate(variables: OrderComplaintsUpdateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<OrderComplaintsUpdateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrderComplaintsUpdateMutation>(OrderComplaintsUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'orderComplaintsUpdate', 'mutation', variables);
    },
    priceFileDetailKpiCards(variables: PriceFileDetailKpiCardsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PriceFileDetailKpiCardsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PriceFileDetailKpiCardsQuery>(PriceFileDetailKpiCardsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'priceFileDetailKpiCards', 'query', variables);
    },
    priceFileDetailLines(variables: PriceFileDetailLinesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PriceFileDetailLinesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PriceFileDetailLinesQuery>(PriceFileDetailLinesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'priceFileDetailLines', 'query', variables);
    },
    priceFileDetails(variables: PriceFileDetailsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PriceFileDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PriceFileDetailsQuery>(PriceFileDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'priceFileDetails', 'query', variables);
    },
    priceFileDetailsPreviousFiles(variables: PriceFileDetailsPreviousFilesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PriceFileDetailsPreviousFilesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PriceFileDetailsPreviousFilesQuery>(PriceFileDetailsPreviousFilesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'priceFileDetailsPreviousFiles', 'query', variables);
    },
    priceFilesIndex(variables?: PriceFilesIndexQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PriceFilesIndexQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PriceFilesIndexQuery>(PriceFilesIndexDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'priceFilesIndex', 'query', variables);
    },
    procurementAnalysisLines(variables: ProcurementAnalysisLinesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementAnalysisLinesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementAnalysisLinesQuery>(ProcurementAnalysisLinesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementAnalysisLines', 'query', variables);
    },
    procurementAnalysisCreate(variables: ProcurementAnalysisCreateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementAnalysisCreateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementAnalysisCreateMutation>(ProcurementAnalysisCreateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementAnalysisCreate', 'mutation', variables);
    },
    procurementDetail(variables: ProcurementDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementDetailQuery>(ProcurementDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementDetail', 'query', variables);
    },
    procurementIndexProgressCheck(variables: ProcurementIndexProgressCheckQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementIndexProgressCheckQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementIndexProgressCheckQuery>(ProcurementIndexProgressCheckDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementIndexProgressCheck', 'query', variables);
    },
    procurementIndex(variables?: ProcurementIndexQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementIndexQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementIndexQuery>(ProcurementIndexDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementIndex', 'query', variables);
    },
    vendorSelection(variables?: VendorSelectionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<VendorSelectionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VendorSelectionQuery>(VendorSelectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vendorSelection', 'query', variables);
    },
    vendorProductMarketPriceQuery(variables: VendorProductMarketPriceQueryQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<VendorProductMarketPriceQueryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VendorProductMarketPriceQueryQuery>(VendorProductMarketPriceQueryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vendorProductMarketPriceQuery', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;