import { DialogDescription, DialogTitle } from "@radix-ui/react-dialog";
import { IconProp } from "../types";
import { twMerge } from "tailwind-merge";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

export function DialogHeader({
  title,
  Icon,
  variant,
  className,
  description,
}: {
  title: string;
  description?: string;
  Icon: IconProp;
  variant?: "success" | "warning" | "alert";
  className?: string;
}) {
  return (
    <div className="mb-8 flex items-center gap-4">
      <div
        className={twMerge(
          "flex h-12 w-12 items-center justify-center rounded-full",
          (!variant || variant === "success") && "bg-tertiary",
          variant === "warning" && "bg-orange-500",
          variant === "alert" && "bg-red-500",
          className
        )}
      >
        <Icon className="h-6 w-6 text-white" aria-hidden="true" />
      </div>
      <div className="">
        <DialogTitle className="mb-0 text-lg font-medium leading-6 text-gray-900">
          {title}
        </DialogTitle>
        {description ? (
          <DialogDescription className="line-clamp-1 max-w-full text-sm text-shade-400">
            {description}
          </DialogDescription>
        ) : (
          <VisuallyHidden>
            <DialogDescription />
          </VisuallyHidden>
        )}
      </div>
    </div>
  );
}
