import { z } from "zod";
import { cvr, eanOptional, emailOptional } from "~/lib/form-state/zod";

export const customerSchema = {
  name: z.string().min(1),
  address: z.string().optional(),
  cityName: z.string().optional(),
  email: emailOptional().or(z.literal("")),
  phone: z.string().optional(),
  cvr: cvr(),
  ean: eanOptional(),
  zip_code: z.string().optional(),
  payment_term_id: z.string().nullish(),
  cityId: z.string().nullish(),
};
