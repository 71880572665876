import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DataTable, useDataTable } from "~/lib/ui/data-table";
import { SharedTableBase } from "~/lib/ui/data-table/shared-table-base";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useToastOnError } from "~/lib/utils/hooks";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { useRegistrationTableColumns } from "./registration-table-columns";
import { useProjectParams } from "~/pages/projects/_cmp/use-project-params";
import { useAPI } from "~/lib/api";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { useState } from "react";
import { FilterGroup } from "~/lib/ui/filters/filter-group";
import { useTranslation } from "react-i18next";
import { FilterGroupEmployees } from "~/lib/ui/filters/filter-group-employees";
import { Dialog, getIcon } from "~/lib/ui";
import { SendFormsDialog } from "~/pages/projects/[id]/registrations/_cmp/send-form-dialog";
import { useOutletContext } from "react-router";
import { ProjectOutletContext } from "~/pages/projects/[id]";
import { CACHE_REGISTRATIONS } from "~/pages/projects/[id]/registrations";
import { CACHE_PROJECTS } from "~/pages/projects";

interface RegistrationTableProps extends SharedTableBase {
  projectId: string; // Currently this table only makes sense for project registrations
}

/**
 * Shows forms, expenses and invoices (used in Projects)
 * - We're considering re-use so it has its own folder for now
 * - NOTE: Don't try to use this outside of projects
 */
export function RegistrationTable(props: RegistrationTableProps) {
  const api = useAPI();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { entity: project } = useOutletContext<ProjectOutletContext>();
  const [previewFormURL, setPreviewFormURL] = useState<string | undefined>();
  const [formDrafts, setFormDrafts] = useState<Array<string>>(); // For sending forms

  const approveMutation = useMutation({
    mutationFn: async ({ formIds, checked }: { formIds: Array<string>; checked: boolean }) => {
      return api.changeStatus({
        projectId: props.projectId,
        changeStatusRequest: {
          approve: checked,
          forms: formIds,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CACHE_REGISTRATIONS],
      });
      await queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS, props.projectId],
      });
    },
  });

  const columns = useRegistrationTableColumns({
    projectId: props.projectId,
    onPreviewPDF: (pdfURL) => setPreviewFormURL(pdfURL),
  });
  const projectParams = useProjectParams();
  const tableState = useDataTableState({
    columnVisibility: props.columnVisibility,
  });

  const [registrationFilters, setRegistrationFilters] = useTypedSearchParams<{
    employees?: Array<string>;
    sort?: string;
    documentType: string;
    approved?: boolean;
    direction: "desc" | "asc";
  }>();

  const dataQ = useQuery({
    queryKey: [
      CACHE_REGISTRATIONS,
      "index",
      props.projectId,
      tableState.state.search,
      tableState.state.pagination,
      tableState.sortingDirection,
      tableState.sortBy,
      tableState.pageNumber,
      registrationFilters,
      projectParams,
    ],
    queryFn: () =>
      api.getAllRegistrations({
        projectId: props.projectId,
        page: tableState.pageNumber,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
        dateFrom: projectParams?.startDate,
        dateTo: projectParams?.endDate,
        userId: registrationFilters.employees?.[0],
        approved: registrationFilters.approved,
        documentType: registrationFilters.documentType,
        activityIds: projectParams.activities ?? undefined,
        isInvoiced: projectParams.isInvoiced,
      }),
  });
  const data = dataQ.data;
  useToastOnError(dataQ.error);

  const table = useDataTable(
    {
      columns,
      tableState,
      data: dataQ.data?.data,
      isLoading: dataQ.isLoading,
      backendPagination: dataQ.data?.pagination,
      getRowId: (row) => `${row.id}-${row.type}`,
    },
    {
      enableGlobalFilter: false, // endpoint does not support search
      enableFilters: props.enableFilters ?? true, // not using defaults, special case
      enableRowSelection: (row) => row.original.type === "form",
    }
  );

  async function handleOpenSendDialog(forms: Array<string>) {
    setFormDrafts(forms);
  }

  return (
    <>
      <DataTable
        error={dataQ.error}
        table={table}
        selectionActions={(rows) => [
          {
            Icon: getIcon("send"),
            label: t("common:send"),
            onClick: () => handleOpenSendDialog(rows.map((row) => row.original.id)),
          },
          {
            Icon: getIcon("approve"),
            label: t("common:approve", "Approve"),
            onClick: () =>
              approveMutation.mutateAsync({
                formIds: rows.map((row) => row.original.id),
                checked: true,
              }),
          },
          {
            Icon: getIcon("reject"),
            label: t("common:reject", "Reject"),
            onClick: () =>
              approveMutation.mutateAsync({
                formIds: rows.map((row) => row.original.id),
                checked: false,
              }),
          },
        ]}
        renderFilters={() => (
          <>
            <FilterGroupEmployees
              value={registrationFilters.employees}
              allowMultiple={false} // Endpoint doesn't support multiple
              onConfirmSelection={(selectedUsers) => {
                setRegistrationFilters(
                  "employees",
                  selectedUsers.map((u) => u.id)
                );
              }}
              onClear={() => setRegistrationFilters("employees", undefined)}
            />
            <FilterGroup
              name={t("projects:document_type")}
              isLoading={!data}
              value={registrationFilters.documentType}
              selection={{
                items: data?.documentTypes ?? [],
                valueFn: (item) => {
                  switch (item.type) {
                    case "form":
                      return item.formTemplateId?.toString();
                    default:
                      return item.type;
                  }
                },
                allowMultiple: false,
                renderSelectionItem: (item) => {
                  switch (item.type) {
                    case "form":
                      return item.formTitle;
                    default:
                      return t(`common:${item.type}`, { count: 1 });
                  }
                },
                onConfirmSelection: (selected) => {
                  const docType =
                    selected[0].type === "form" ? selected[0].formTemplateId : selected[0].type;
                  setRegistrationFilters("documentType", docType ?? t("common:not_available"));
                },
              }}
              onClear={() => setRegistrationFilters("documentType", undefined)}
            />
            <FilterGroup
              name={t("common:approved")}
              isLoading={!data}
              value={registrationFilters.approved}
              selection={{
                items: [
                  { value: true, label: t("common:yes") },
                  { value: false, label: t("common:no") },
                ],
                valueFn: (item) => item.value,
                renderSelectionItem(item) {
                  return item.label;
                },
                onConfirmSelection: (selection) => {
                  setRegistrationFilters("approved", selection[0].value);
                },
              }}
              onClear={() => setRegistrationFilters("approved", undefined)}
            />
          </>
        )}
      />
      <Dialog
        open={!!formDrafts}
        onOpenChange={() => setFormDrafts(undefined)}
        render={({ onClose }) => (
          <SendFormsDialog
            projectId={props.projectId}
            onClose={onClose}
            defaultEmail={project.contactPerson?.email ?? project.contact?.email ?? ""}
            defaultSubject={t("projects:send_registrations_subject", {
              replace: { workaddress: project.streetName || "projekt" },
              defaultValue: "Documentation for {{workaddress}}",
            })}
            defaultMessage={t("projects:send_registrations_message", {
              replace: { name: project.contactPerson?.name ?? project.contact?.name ?? "" },
            })}
            formIds={formDrafts ?? []}
          />
        )}
      />
      <FullScreenFilePreview
        open={!!previewFormURL}
        fileUrl={previewFormURL}
        onClose={() => setPreviewFormURL(undefined)}
      />
    </>
  );
}
