import { useMemo, useState } from "react";
import { BaseFile } from "@apacta/sdk";

export function useEntityFileStaging(existingFiles: Array<BaseFile> = []) {
  const [filesToUpload, setFilesToUpload] = useState<Array<File>>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<Array<string>>([]);

  // This function is used to set the default files that are already uploaded to the server.
  const stageFileUpload = (files: Array<File>) => {
    setFilesToUpload((prevState) => [...prevState, ...files]);
  };

  const unstageFileUpload = (file: File) => {
    setFilesToUpload((prevState) => prevState.filter((f) => f !== file));
  };

  const stageFileRemoval = (file: BaseFile) => {
    setFileIdsToDelete((prevState) => [...prevState, file.id]);
  };

  const stageFile = (files: Array<File>) => {
    stageFileUpload(files);
  };

  const unstageFile = (file: File | BaseFile) => {
    if ("id" in file) {
      stageFileRemoval(file);
    } else {
      unstageFileUpload(file);
    }
  };

  const viewFiles = useMemo(() => {
    const files = existingFiles.filter((f) => !fileIdsToDelete.includes(f.id));
    return [...files, ...filesToUpload].sort((a, b) => a.name.localeCompare(b.name));
  }, [existingFiles, filesToUpload, fileIdsToDelete]);

  const clearStaging = (type: "upload" | "delete") => {
    if (type === "delete") {
      setFileIdsToDelete([]);
    }
    if (type === "upload") {
      setFilesToUpload([]);
    }
  };

  return {
    stageFile,
    unstageFile,
    clearStaging,
    filesToUpload,
    fileIdsToDelete,
    viewFiles,
  };
}
