import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ToastContext, ToastMessage } from "~/lib/toast/context";

export type TemplateIdentifier =
  | "CHANGES_SAVED"
  | "OPERATION_FAILED"
  | "UNEXPECTED_ERROR"
  | "CREATED"
  | "DELETED"
  | "MESSAGE_SENT";

export const useToasts = () => {
  const toastCtx = useContext(ToastContext);
  const { t } = useTranslation();

  const templates: Record<TemplateIdentifier, ToastMessage> = {
    CHANGES_SAVED: {
      title: t("common:changes_saved"),
      iconName: "successCircle",
      variant: "success",
    },
    OPERATION_FAILED: {
      iconName: "errorCircle",
      title: t("errors:operation_failed.title"),
      variant: "error",
    },
    UNEXPECTED_ERROR: {
      iconName: "errorCircle",
      title: t("errors:unexpected.title"),
      description: t("errors:unexpected.subtitle"),
      variant: "error",
    },
    CREATED: {
      title: t("common:created", "Created"),
      variant: "success",
      iconName: "successCircle",
    },
    DELETED: {
      title: t("common:deleted", "Deleted"),
      variant: "warning",
      iconName: "successCircle",
    },
    MESSAGE_SENT: {
      title: t("common:message_sent", "Message sent"),
      variant: "info",
      iconName: "send",
    },
  } as const;

  if (!toastCtx) {
    throw new Error("Expecting toast context, but couldn't find it.");
  }

  const show = useCallback((toast: ToastMessage) => {
    toastCtx.add(toast);
  }, []);

  type Overrides = Partial<(typeof templates)[keyof typeof templates]>;
  const showTemplate = useCallback(
    (templateName: keyof typeof templates, overrides?: Overrides) => {
      if (!templates[templateName]) throw new Error("Invalid template");
      let temp: ToastMessage = templates[templateName];
      if (overrides) {
        temp = { ...temp, ...overrides };
      }
      show(temp);
    },
    []
  );

  return { show, showTemplate };
};
