import { ProductVariant } from "@apacta/sdk";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { Checkbox, Radio } from "~/lib/ui/form-elements";
import { CurrencyCell } from "~/lib/ui/table/cells/currency-cell";
import { DateCell } from "~/lib/ui/table/cells/date-cell";
import { VendorCell } from "~/lib/ui/table/cells/vendor-cell";

export function VariantPricingTable({
  productVariants,
  onDelete,
  onToggleBuyingOption,
  selectedVariantId,
  priceType,
}: {
  priceType: "manual" | "cost_based";
  productVariants: Array<ProductVariant>;
  selectedVariantId: string | undefined;
  onDelete: (variant: ProductVariant) => void;
  onToggleBuyingOption: (variant: ProductVariant | undefined) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="table">
      <table className="mt-0 text-left">
        <thead>
          <tr>
            <th>{t("products:variants.primary")}</th>
            <th>{t("common:name")}</th>
            <th>{t("products:variants.latest_update")}</th>
            <th>{t("products:variants.latest_price")}</th>
            <th>{t("common:vendor")}</th>
            <th>{t("common:type")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {productVariants.map((item) => (
            <Fragment key={item.id}>
              <tr>
                <td>
                  <div className="flex items-center gap-4">
                    {priceType === "manual" ? (
                      <Checkbox
                        checked={selectedVariantId === item.id}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          const val = isChecked ? item : undefined;
                          onToggleBuyingOption(val);
                        }}
                      />
                    ) : (
                      <Radio
                        checked={selectedVariantId === item.id}
                        onChange={() => onToggleBuyingOption(item)}
                      />
                    )}
                  </div>
                </td>
                <td className="font-semibold">
                  <div className="items-center">{item.name}</div>
                </td>
                <td>
                  <div className="items-center">
                    {item.latestUpdated ? <DateCell date={item.latestUpdated} /> : "-"}
                  </div>
                </td>
                <td className="items-center">
                  <CurrencyCell value={item.latestPrice} />
                </td>
                <td className="items-center">
                  <VendorCell vendor={item.vendor} />
                </td>
                <td className="items-center">
                  <div>{t(`products:${item.type}`)}</div>
                </td>
                <td className="items-center">
                  <ActionButtons
                    size="small"
                    actions={[
                      {
                        Icon: getIcon("delete"),
                        label: t("products:variant.modal.delete.title"),
                        confirm: {
                          action: "delete",
                          entity: "product_variant",
                        },
                        onClick: () => onDelete(item),
                      },
                    ]}
                  />
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
