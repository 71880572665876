import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMe } from "~/lib/auth/use-me";
import { DraftInvoiceDialog } from "./draft-invoice-dialog";
import { BookInvoiceDialog } from "./book-invoice-dialog";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { getIcon } from "~/lib/ui";
import type { Invoice } from "@apacta/sdk";
import type { InvoiceFormState } from "~/pages/invoices/_cmp/use-invoice-formstate";
import { InvoiceActionContext } from "~/pages/invoices/_cmp/invoice-action-context";
import { LabeledSelect } from "~/lib/ui/form-elements/labeled-select";
import { InformationBox } from "~/lib/ui/dialog/components/information-box";

interface InvoiceActionDialogProps {
  onClose: () => void;
  invoice: Invoice;
  pendingSave?: () => Promise<unknown>;
  invoiceState: InvoiceFormState;
}

type ActionType = "draft" | "invoice";

function InvoiceActionDialogContent({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const me = useMe();
  const isERP = me.integrationsEnabled.erp;

  const [actionType, setActionType] = useState<ActionType>("draft");

  const getDialogTitle = () => {
    switch (actionType) {
      case "draft":
        return t("invoices:save_draft");
      case "invoice":
        return t("invoices:book_invoice");
      default:
        return "";
    }
  };

  const renderDialogContent = () => {
    if (!isERP) {
      return <BookInvoiceDialog onClose={onClose} />;
    }
    switch (actionType) {
      case "draft":
        return <DraftInvoiceDialog onClose={onClose} />;
      case "invoice":
        return <BookInvoiceDialog onClose={onClose} />;
      default:
        return null;
    }
  };

  return (
    <>
      <DialogHeader title={getDialogTitle()} Icon={getIcon("invoice")} />
      <div className="mb-4 flex flex-col gap-4">
        <div>
          {isERP && (
            <InformationBox variant="danger">
              <div className="whitespace-pre-line">{t("invoices:warning_before_send_to_erp")}</div>
            </InformationBox>
          )}
        </div>
        {isERP && (
          <LabeledSelect
            value={actionType}
            onChange={(e) => setActionType(e.currentTarget.value as ActionType)}
          >
            <option value="draft">{t("common:draft")}</option>
            <option value="invoice">{t("invoices:booked", { count: 1 })}</option>
          </LabeledSelect>
        )}
        {renderDialogContent()}
      </div>
    </>
  );
}

export function InvoiceActionDialog({
  onClose,
  invoice,
  pendingSave,
  invoiceState,
}: InvoiceActionDialogProps) {
  return (
    <InvoiceActionContext.Provider value={{ invoice, pendingSave, invoiceState }}>
      <InvoiceActionDialogContent onClose={onClose} />
    </InvoiceActionContext.Provider>
  );
}
